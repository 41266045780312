import './AccountMenu.scss';

import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../../../../context/AuthContext';
import { useOutsideClick } from '../../../../../hooks/ClickHooks';

const propTypes = {};

function AccountMenu() {
  const { user, hasAdminAccess } = useAuth();
  const menuRef = useRef(null);
  const { firstName } = user || { firstName: 'Lance' };
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  useOutsideClick(menuRef, () => setOpen(false));
  const menuItems = [
    {
      displayText: 'My account',
      href: '/account',
      hrefs: ['/account', '/account/edit'],
      hasAccess: true,
    },
    {
      displayText: 'My tasks',
      href: '/tasks',
      hrefs: ['/tasks'],
      hasAccess: true,
    },
    /**
    {
      displayText: 'Billing',
      href: '/billing',
      hrefs: ['/billing'],
    },
    */
    {
      displayText: 'Integrations',
      href: '/account/integrations',
      hrefs: ['/account/integrations'],
      hasAccess: hasAdminAccess,
    }
  ];
  const menuList = menuItems
    .filter(({ hasAccess }) => hasAccess)
    .map(({ displayText, href, hrefs }) => {
    const onClick = () => {
      setOpen(false);
      navigate(href);
    };
    const selected = hrefs.includes(pathname);
    return (
      <li className={'menu-item'} key={displayText}>
        <button className={`menu-button-item ${selected ? 'selected' : ''}`} onClick={onClick}>{displayText}</button>
      </li>
    );
  })
  return (
    <section className={'account-menu-container'} ref={menuRef}>
      <button className={'menu-button'} onClick={() => setOpen(!open)}>{`Hello, ${firstName}!`}</button>
      {
        open && (
          <section className={'account-menu'}>
            <ul className={'menu-list'}>
              {menuList}
            </ul>
          </section>
        )
      }
    </section>
  )
}

AccountMenu.displayName = 'AccountMenu';
AccountMenu.propTypes = propTypes;
export default AccountMenu;