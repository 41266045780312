import './Email.scss';

import React from 'react';

import { convert } from 'html-to-text';
import TemplateTextInput from '../../../templates/common/TemplateTextInput';
import CopyTextButton from './CopyTextButton';
import UPLOAD_ICON from '../assets/upload-icon.svg';
import PreviewToolButtons from './PreviewToolButtons';

function Email({ preview, setPreview, proceed }) {
  const { message, subjectLine } = preview;
  const setMessage = (text) => setPreview({...preview, message: text });
  const setTitle = (text) => setPreview({...preview, subjectLine: text });
  return (
    <section className={'email'}>
      <section className={'email-input-container'}>
        <label className={'title-label'} htmlFor={'title'}>{'Edit title'}</label>
        <TemplateTextInput
          editable={true}
          text={subjectLine}
          setText={setTitle}
          placeholder={'Type your title here...'}
          shouldUseVariables={false}
          showCharCount={false}
          additionalClassNames={['title-input']}
        />
        <label className={'message-label'} htmlFor={'message'}>{'Edit message'}</label>
        <TemplateTextInput
          editable={true}
          text={message}
          setText={setMessage}
          placeholder={'Type your message here...'}
          shouldUseVariables={true}
          showCharCount={true}
          maxCharCount={400}
          additionalClassNames={['message-input']}
        />
        <PreviewToolButtons text={message} proceed={proceed} uploadText={'Attach a file'} />
      </section>
    </section>
  );
};

Email.displayName = 'Email';
export default Email;