import './CampaignSelectionModal.scss';

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import WorkRexModal from '../../../../../common/WorkRexModal';
import SearchDropdown from '../common/SearchDropdown';

const propTypes = {};

function CampaignSelectionModal({ close, campaigns, setCampaign, selectedCampaign }) {
  const [currentCampaign, setCurrentCampaign] = useState(selectedCampaign);
  const removeCampaign = () => setCurrentCampaign(null);
  const confirm = () => {
    setCampaign(currentCampaign);
    close();
  }
  const selectedOption = campaigns.find(({ key }) => key === currentCampaign);
  const selectedDisplayText = selectedOption ? selectedOption.displayText : null;
  return (
    <WorkRexModal close={close} additionalClassNames={['campaign-selection-modal']}>
      <h4 className={'campaign-selection-title'}>{'Select from your campaigns'}</h4>
      <section className={'search-campaigns-container'}>
        <SearchDropdown options={campaigns} selected={currentCampaign} select={setCurrentCampaign} />
        <section className={'selected-campaign-text'}>
          {
            selectedDisplayText && (
              <span className={'text'}>
                {selectedDisplayText}
                <button className={'remove-button'} onClick={removeCampaign}>{'×'}</button>
              </span>
            )
          }
        </section>
      </section>
      <section className={'confirm-buttons'}>
        <button className={'back-button'} onClick={close}>{'Back'}</button>
        <button className={'confirm-button'} onClick={confirm}>{'Confirm'}</button>
      </section>
    </WorkRexModal>
  );
}

CampaignSelectionModal.displayName = 'CampaignSelectionModal';
CampaignSelectionModal.propTypes = propTypes;
export default CampaignSelectionModal;
