import './CopyTextButton.scss';

import React from 'react';

import COPY_ICON from '../assets/copy-icon.svg';

function CopyTextButton({ label, onClick }) {
  return (
    <section className={'copy-text-button-container'}>
      <span className={'label-text'}>{label}</span>
      <button className={'copy-text-button'} onClick={onClick}>
        <img className={'copy-icon'} src={COPY_ICON} alt={'Copy icon'} />
      </button>
    </section>
  );
};

CopyTextButton.displayName = 'CopyTextButton';
export default CopyTextButton;