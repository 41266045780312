import './TemplateSidebar.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddButton from '../../../common/AddButton';
import SearchInput from '../../../common/SearchInput';

import { TEMPLATE_TYPES } from '../TemplatesConstants';

import CUSTOMER_ICON from '../assets/customer-icon.svg';
import EMPLOYEE_ICON from '../assets/employee-icon.svg';
import FilterButtonList from '../../../common/FilterButtonList';

const { EMPLOYEE, CUSTOMER } = TEMPLATE_TYPES;

const propTypes = {};

function TemplateSidebar({ href, searchText, setSearchText, isLibrary, libraryTypeFilter, setLibraryTypeFilter }) {
  const navigate = useNavigate();
  const selectCustomer = () => {
    if (libraryTypeFilter.includes(CUSTOMER)) {
      setLibraryTypeFilter(libraryTypeFilter.filter(s => s !== CUSTOMER));
    }
    else {
      setLibraryTypeFilter([...libraryTypeFilter, CUSTOMER]);
    }
  }
  const selectEmployee = () => {
    if (libraryTypeFilter.includes(EMPLOYEE)) {
      setLibraryTypeFilter(libraryTypeFilter.filter(s => s !== EMPLOYEE));
    }
    else {
      setLibraryTypeFilter([...libraryTypeFilter, EMPLOYEE]);
    }
  }
  const filterList = [
    {
      buttonText: 'Customer',
      icon: CUSTOMER_ICON,
      selected: libraryTypeFilter.includes(CUSTOMER),
      onClick: selectCustomer,
    },
    {
      buttonText: 'Employee',
      icon: EMPLOYEE_ICON,
      selected: libraryTypeFilter.includes(EMPLOYEE),
      onClick: selectEmployee,
    },
  ]
  return (
    <aside className={'template-sidebar'}>
      <AddButton buttonText={'Add template'} onClick={() => navigate(`${href}/new`)} />
      <span className={'divider'} />
      <SearchInput searchText={searchText} setSearchText={setSearchText} />
      { isLibrary && <FilterButtonList filters={filterList} /> }
    </aside>
  );
}

TemplateSidebar.displayName = 'TemplateSidebar';
TemplateSidebar.propTypes = propTypes;
export default TemplateSidebar;