import './NavigationTabList.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import NavigationTab from './NavigationTab';

const propTypes = {};

function NavigationTabList({ selectedTab = null, tabs = [], counts = null, additionalClassNames = [] }) {
  const navigate = useNavigate();
  const tabList = tabs.map(({ key, unselectedIcon, selectedIcon, displayText, url }) => {
    return (
      <NavigationTab
        key={key}
        unselectedIcon={unselectedIcon}
        selectedIcon={selectedIcon}
        displayText={displayText}
        onClick={() => navigate(url)}
        selected={selectedTab === key}
        count={counts !== null ? counts[key] : null}
      />
    )
  });
  return (
    <ul className={`navigation-tab-list ${additionalClassNames.join(' ')}`}>
      {tabList}
    </ul>
  )
}

NavigationTabList.displayName = 'NavigationTabList';
NavigationTabList.propTypes = propTypes;
export default NavigationTabList;