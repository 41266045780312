import './SearchButton.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../../Constants';

const SEARCH_ICON_WITH_PURPLE_CIRCLE = `${ASSET_CDN_URL}/icons/workrex_search_icon_with_purple_circle.svg`;
const SEARCH_ICON_WITH_WHITE_CIRCLE = `${ASSET_CDN_URL}/icons/workrex_search_icon_with_white_circle.svg`;

const propTypes = {};

function SearchButton({ additionalClassNames = [], setOpenSearch, searchText }) {
  const SearchButtonIcon = searchText.length > 0 ? SEARCH_ICON_WITH_PURPLE_CIRCLE : SEARCH_ICON_WITH_WHITE_CIRCLE;
  return (
    <button className={`search-icon-button ${additionalClassNames.join(' ')}`} onClick={() => setOpenSearch(true)} type={'button'}>
      <img alt={'search-icon'} className={`search-icon ${additionalClassNames.join(' ')}`} src={SearchButtonIcon} />
    </button>
  )
}

SearchButton.displayName = 'SearchButton';
SearchButton.propTypes = propTypes;
export default SearchButton;