import './OpportunityScore.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { OPPORTUNITY_SCORE } from '../EngagementConstants';
import { calculateOpportunityScoreKey } from '../../../../../../utils/DataUtils';

const propTypes = {};

function OpportunityScore({ opportunityScorePercentage }) {
  const opportunityScoreKey = calculateOpportunityScoreKey(opportunityScorePercentage);
  const opportunityScoreIcon = OPPORTUNITY_SCORE[opportunityScoreKey].icon;
  return <img alt={'opportunity score'} className={'opportunity-score-item'} src={opportunityScoreIcon} />;
}

OpportunityScore.displayName = 'OpportunityScore';
OpportunityScore.propTypes = propTypes;
export default OpportunityScore;