import './AutomationRequestModal.scss';

import React from 'react';
import PropTypes from 'prop-types';
import WorkRexModal from '../../../common/WorkRexModal';

const propTypes = {};

function AutomationRequestModal({ close }) {
  return (
    <WorkRexModal close={close} additionalClassNames={['automation-request-modal']}>
      <p className={'description'}>
        {'Your automation request has been submitted. The WorkRex team will be in touch with any questions to get your automation activated.'}
      </p>
    </WorkRexModal>
  );
}

AutomationRequestModal.displayName = 'AutomationRequestModal';
AutomationRequestModal.propTypes = propTypes;
export default AutomationRequestModal;