import './EditAutomation.scss';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

import { postAutomationEdit } from '../../../../../../../apis/Api';

import { DUMMY_AUTOMATION } from '../../AutomationsConstants';
import useAuth from '../../../../../../../context/AuthContext';

import EditAutomationInput from './EditAutomationInput';
import EditAutomationActions from './EditAutomationActions';
import DotBricksLoading from '../../../../../../common/DotBricksLoading';

const propTypes = {};

function EditAutomation() {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const { state } = useLocation();
  const stateAutomationId = state && state.automationId ? state.automationId : window.localStorage.getItem('lastAutomationId');
  const [automationId, setAutomationId] = useState(stateAutomationId);
  const automations = user && user.automations ? user.automations : [];
  let automation = null;
  if (automationId) {
    automation = automations.find(({ _id }) => automationId.toString() === _id.toString());
  }
  useEffect(() => {
    if (stateAutomationId !== null) {
      setAutomationId(stateAutomationId);
      window.localStorage.setItem('lastAutomationId', stateAutomationId);
    }
    else {
      setAutomationId(window.localStorage.getItem('lastAutomationId'));
    }
  }, []);
  if (automationId && automation === null) {
    return <Navigate to={'/automations/available'} />;
  }
  if (automation === null) {
    return <DotBricksLoading />;
  }
  const { _id, title, description, actions } = automation;
  const close = () => navigate('/automations/view',  { state: { automationId } });
  const saveNewTitle = (text) => {
    postAutomationEdit(_id, { title: text })
      .then((response) => {
        if (response.user) {
          setUser(response.user);
        }
      });
  };
  const saveNewDescription = (text) => {
    postAutomationEdit(_id, { description: text })
      .then((response) => {
        if (response.user) {
          setUser(response.user);
        }
      });
  };
  return (
    <article className={'edit-automation'}>
      <h3 className={'automation-title'}>
        {'View automation'}
      </h3>
      <EditAutomationInput initialDescription={title} save={saveNewTitle} />
      <EditAutomationInput initialDescription={description} save={saveNewDescription} />
      <section className={'edit-automation-actions-container'}>
        <EditAutomationActions actions={actions} />
      </section>
      <button className={'close-button'} onClick={close}>{'Close'}</button>
    </article>
  )
}

EditAutomation.displayName = 'EditAutomation';
EditAutomation.propTypes = propTypes;
export default EditAutomation;