import './ToggleInput.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function ToggleInput({ checked, value, toggle, label, additionalClassNames = [], disabled = false }) {
  return (
    <section className={`toggle-input-container ${additionalClassNames.join(' ')}`}>
      <input type={'checkbox'} className={'toggle-input'} onChange={() => toggle(value)} checked={checked} disabled={disabled} />
      <label className={'toggle-input-label'}>{label}</label>
    </section>
  );
}

ToggleInput.displayName = 'ToggleInput';
ToggleInput.propTypes = propTypes;
export default ToggleInput;