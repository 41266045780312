import './SearchBar.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../../Constants';

const searchIcon = `${ASSET_CDN_URL}/icons/rex-messaging-navigation-search-icon.svg`;
const exitIcon = `${ASSET_CDN_URL}/icons/workrex_purple_x_icon.svg`;

const propTypes = {};

function SearchBar({ additionalClassNames = [], placeholder = '', searchText, search, exitIconType = true, setOpenSearch = () => {} }) {
  const searchBarIcon = exitIconType ? exitIcon : searchIcon;
  return (
    <section className={`search-bar ${additionalClassNames.join(' ')}`}>
      <input className={'search-input'} placeholder={placeholder} value={searchText} onChange={search} type={'text'} />
      <button className={`search-icon-button ${additionalClassNames.join(' ')}`} onClick={() => setOpenSearch(false)} type={'button'}>
        <img alt={'search-icon'} className={'search-icon'} src={searchBarIcon} />
      </button>
    </section>
  )
}

SearchBar.displayName = 'SearchBar';
SearchBar.propTypes = propTypes;
export default SearchBar;
