import './TemplateWelcomeSection.scss';

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import TemplateTextInput from '../common/TemplateTextInput';
import TemplateDropdown from '../common/TemplateDropdown';

import { createNewEmptySection } from '../TemplatesHelpers';
import {
  RESPONSE_FORWARDING_INTENTION_TYPES,
  RESPONSE_FORWARDING_INTENTION_TYPES_LIST,
  RESPONSE_FORWARDING_TYPES_LIST,
} from '../TemplatesConstants';
import TemplateAttachmentButton from '../common/TemplateAttachmentButton';
import AttachmentModal from '../modals/AttachmentModal';
import Attachment from '../common/Attachment';

const { GATHER_AND_SEND_RESPONSES } = RESPONSE_FORWARDING_INTENTION_TYPES;

const propTypes = {};

function TemplateWelcomeSection({ template, setTemplate, editable }) {
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const { welcomeSection, description, title } = template;
  const { message, responseForwardingIntent, responseForwarding, attachment } = welcomeSection;
  const setWelcomeSection = (welcomeSection) => setTemplate({ ...template, welcomeSection });
  const setTitle = (title) => {
    setTemplate({ ...template, title });
  }
  const setDescription = (description) => setTemplate({ ...template, description });
  const setWelcomeMessage = (message) => setWelcomeSection({ ...welcomeSection, message });
  const setResponseForwardingIntent = (responseForwardingIntent) => setWelcomeSection({ ...welcomeSection, responseForwardingIntent });
  const setResponseForwarding = (responseForwarding) => setWelcomeSection({ ...welcomeSection, responseForwarding });
  const closeAttachmentModal = () => setIsAttachmentModalOpen(false);
  const deleteAttachment =  () => setWelcomeSection({ ...welcomeSection, attachment: null });
  const openAttachmentModal = () => setIsAttachmentModalOpen(true);
  const setAttachment = (attachment) => setWelcomeSection({ ...welcomeSection, attachment });
  return (
    <section className={'template-welcome-section'}>
      { isAttachmentModalOpen && <AttachmentModal close={closeAttachmentModal} setAttachment={setAttachment} initialFile={attachment} /> }
      <TemplateTextInput
        editable={editable}
        text={title}
        setText={setTitle}
        placeholder={'Template name'}
        shouldUseVariables={false}
        additionalClassNames={['title-template-input']}
      />
      <section className={'template-description-section'}>
        <TemplateTextInput
          editable={editable}
          text={description}
          setText={setDescription}
          placeholder={'Template description for internal use only, not sent to recipients'}
          shouldUseVariables={false}
          additionalClassNames={['description-template-input']}
        />
      </section>
      <section className={'template-welcome-details'}>
        <TemplateTextInput
          editable={editable}
          text={message}
          setText={setWelcomeMessage}
          placeholder={'Write a brief welcome message here that will be sent to recipients...'}
          shouldUseVariables={true}
          additionalClassNames={['welcome-message-template-input']}
          showCharCount={true}
          maxCharCount={800}
        />
      </section>
      <section className={'forward-response-container'}>
        { (editable || responseForwardingIntent !== null) &&
          <TemplateDropdown
            defaultButtonText={'Forward responses'}
            onlyShowDefault={true}
            options={RESPONSE_FORWARDING_INTENTION_TYPES_LIST}
            selected={responseForwardingIntent}
            select={setResponseForwardingIntent}
            showDisplayTextOnSide={true}
          />
        }
        <TemplateAttachmentButton onClick={openAttachmentModal} deleteAttachment={deleteAttachment} attachment={attachment} disabled={!editable} />
      </section>
      {
        responseForwardingIntent === GATHER_AND_SEND_RESPONSES && (editable || responseForwarding !== null) && (
          <TemplateDropdown
            defaultButtonText={'Send responses to:'}
            onlyShowDefault={true}
            options={RESPONSE_FORWARDING_TYPES_LIST}
            additionalClassNames={['response-forwarding-container']}
            selected={responseForwarding}
            select={setResponseForwarding}
            showDisplayTextOnSide={true}
          />
        )
      }
    </section>
  )
}

TemplateWelcomeSection.displayName = 'TemplateWelcomeSection';
TemplateWelcomeSection.propTypes = propTypes;
export default TemplateWelcomeSection;