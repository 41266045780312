import './TimeSelectorPanel.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { HOUR_MAP, MINUTE_MAP } from '../../../MessagingConstants';
import TimeOption from './TimeOption';

const propTypes = {};

function TimeSelectorPanel({ selectedHour, selectedMinute, setSelectedHour, setSelectedMinute }) {

  return (
    <section className={'time-picker-modal-container'}>
      <section className={'time-title-container'}>
        <hr className={'time-div'}/>
        <span className={'time-title'}>{'HOURS'}</span>
      </section>
      <section className={'time-selections'}>
        <section className={'time-selection'}>
          {Object.keys(HOUR_MAP).slice(0, 6).map(hour => <TimeOption selectedTime={selectedHour} time={HOUR_MAP[hour]} setTime={setSelectedHour} />)}
        </section>
        <section className={'time-selection'}>
          {Object.keys(HOUR_MAP).slice(6, 12).map(hour => <TimeOption selectedTime={selectedHour} time={HOUR_MAP[hour]} setTime={setSelectedHour} />)}
        </section>
      </section>
      <section className={'time-title-container'}>
        <span className={'time-title'}>{'MINUTES'}</span>
        <hr className={'time-div'}/>
      </section>
      <section className={'time-selections'}>
        <section className={'time-selection'}>
          {Object.keys(MINUTE_MAP).slice(0, 6).map(minute => <TimeOption selectedTime={selectedMinute} time={MINUTE_MAP[minute]} setTime={setSelectedMinute} />)}
        </section>
        <section className={'time-selection'}>
          {Object.keys(MINUTE_MAP).slice(6, 12).map(minute => <TimeOption selectedTime={selectedMinute} time={MINUTE_MAP[minute]} setTime={setSelectedMinute} />)}
        </section>
      </section>
    </section>
  )
}

TimeSelectorPanel.displayName = 'TimeSelectorPanel';
TimeSelectorPanel.propTypes = propTypes;
export default TimeSelectorPanel;