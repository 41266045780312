import './MessagingPage.scss';

import React from 'react';
import PropTypes from 'prop-types';

import MessagingPageWrapper from './MessagingPageWrapper';
import MessagingContainer from './core/MessagingContainer';

const propTypes = {};

function MessagingPage() {
  return (
    <MessagingPageWrapper>
      <MessagingContainer />
    </MessagingPageWrapper>
  );
}

MessagingPage.displayName = 'MessagingPage';
MessagingPage.propTypes = propTypes;
export default MessagingPage;