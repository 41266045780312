import './TemplateSection.scss';

import React, { useState } from 'react';

import TemplateTextInput from '../common/TemplateTextInput';
import TemplateDropdown from '../common/TemplateDropdown';
import TemplateLabelContainer from '../common/TemplateLabelContainer';
import TemplateAttachmentButton from '../common/TemplateAttachmentButton';
import { QUESTION_INTENT_TYPES_LIST, RESPONSE_TYPES, RESPONSE_TYPE_LIST, QUESTION_INTENT_TYPES } from '../TemplatesConstants';

import AttachmentModal from '../modals/AttachmentModal';

const propTypes = {};

function TemplateSection({ section, setSection, currentIndex, editable }) {
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const { title, message, questionIntent, responseExpected, attachment } = section;
  const setTitle = (title) => setSection({ ...section, title }, currentIndex);
  const setMessage = (message) => setSection({ ...section, message }, currentIndex);
  const setQuestionIntent = (questionIntent) => setSection({ ...section, questionIntent }, currentIndex);
  const setResponseExpected = (responseExpected) => {
    if (section.responseExpected === responseExpected) {
      return;
    }
    const questionIntent = responseExpected === RESPONSE_TYPES.QUALITATIVE ? QUESTION_INTENT_TYPES.GENERATE_IMPROVEMENT_IDEAS : null;
    setSection({ ...section, responseExpected, questionIntent }, currentIndex);
  }
  const closeAttachmentModal = () => setIsAttachmentModalOpen(false);
  const deleteAttachment =  () => setSection({ ...section, attachment: null }, currentIndex);
  const openAttachmentModal = () => setIsAttachmentModalOpen(true);
  const setAttachment = (newAttachment) => {
    let newMessage = message;
    if (attachment && message.includes(attachment.code)) {
      newMessage = message.replaceAll(attachment.code, newAttachment.code);
    }
    else {
      newMessage = `${message}<div>View attachment:</div><div>managers.workrex.io/c/${newAttachment.code}`;
    }
    setSection({ ...section, message: newMessage, attachment: newAttachment }, currentIndex);
  };
  return (
    <section className={'template-section'}>
      { isAttachmentModalOpen && <AttachmentModal close={closeAttachmentModal} setAttachment={setAttachment} initialFile={attachment} /> }
      <TemplateTextInput
        editable={editable}
        text={title}
        setText={setTitle}
        placeholder={'Section title'}
        shouldUseVariables={false}
        additionalClassNames={['title-template-input']}
      />
      <section className={'template-section-details'}>
        <TemplateTextInput
          editable={editable}
          text={message}
          setText={setMessage}
          placeholder={'Write your message here which will be sent out to recipients...'}
          shouldUseVariables={true}
          additionalClassNames={['section-message-template-input']}
          showCharCount={true}
          maxCharCount={800}
        />
      </section>
      <section className={'response-expectation-container'}>
        <TemplateLabelContainer label={'What response do you expect?'}>
          <TemplateDropdown
            defaultButtonText={'Select'}
            onlyShowDefault={true}
            options={RESPONSE_TYPE_LIST}
            selected={responseExpected}
            select={setResponseExpected}
            showDisplayTextOnSide={true}
            disabled={!editable}
          />
        </TemplateLabelContainer>
        <TemplateAttachmentButton onClick={openAttachmentModal} deleteAttachment={deleteAttachment} attachment={attachment} disabled={!editable} />
      </section>
      { responseExpected === RESPONSE_TYPES.QUALITATIVE &&
          <TemplateLabelContainer label={'Select the closest intent to your question from this list'}>
            <TemplateDropdown
              defaultButtonText={'Select category'}
              onlyShowDefault={true}
              options={QUESTION_INTENT_TYPES_LIST}
              selected={questionIntent}
              select={setQuestionIntent}
              showDisplayTextOnSide={true}
              disabled={!editable}
            />
          </TemplateLabelContainer>
      }
    </section>
  )
}

TemplateSection.displayName = 'TemplateSection';
TemplateSection.propTypes = propTypes;
export default TemplateSection;