import './TemplatePage.scss';

import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { convert } from 'html-to-text';
import PropTypes from 'prop-types';

import TemplatesPageWrapper from '../TemplatesPageWrapper';
import TemplateNavigationTabs from './TemplateNavigationTabs';
import TemplateSection from './TemplateSection';
import TemplateWelcomeSection from './TemplateWelcomeSection';
import useAuth from '../../../../../../context/AuthContext';
import { postCreateTemplate } from '../../../../../../apis/TemplateApi';

import { MAXIMUM_NUMBER_OF_SECTIONS, RESPONSE_FORWARDING_INTENTION_TYPES, TEMPLATE_TYPES } from '../TemplatesConstants';
import { createNewEmptySection } from '../TemplatesHelpers';
import CreateTemplateConfirmationModal from '../modals/CreateTemplateConfirmationModal';
import CreateTemplateErrorModal from '../modals/CreateTemplateErrorModal';
import DraftTemplateConfirmationModal from '../modals/DraftTemplateConfirmationModal';

const { PUBLISHED, DRAFT } = TEMPLATE_TYPES;
const { GATHER_AND_SEND_RESPONSES }  = RESPONSE_FORWARDING_INTENTION_TYPES;

const propTypes = {};

const EMPTY_WELCOME_SECTION = {
  title: '',
  message: '',
  attachment: null,
  responseForwardingIntent: GATHER_AND_SEND_RESPONSES,
  responseForwarding: null,
};

const getEmptyTemplate = () => {
  return {
    key: null,
    title: '',
    type: DRAFT,
    label: 'Your draft template',
    description: '',
    welcomeSection: { ...EMPTY_WELCOME_SECTION },
    sections: [],
  };
}

const validateTemplate = (template) => {
  const { title, description, welcomeSection, sections } = template;
  let errors = [];
  if (title.length === 0) {
    errors.push('missing template title');
  }
  if (description.length === 0) {
    errors.push('missing template description');
  }
  const { message, responseForwarding, responseForwardingIntent } = welcomeSection;
  if (message.length === 0) {
    errors.push('missing welcome message');
  }
  if (responseForwardingIntent === null) {
    errors.push('missing response forwarding');
  }
  else {
    if (responseForwardingIntent === GATHER_AND_SEND_RESPONSES && responseForwarding === null) {
      errors.push('missing response forwarding recipient');
    }
  }
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const { title, message } = section;
    const sectionTitle = title ? title : `Section ${i + 1}`;
    if (title.length === 0) {
      errors.push(`missing title for ${sectionTitle}`);
    }
    if (message.length === 0) {
      errors.push(`missing message for ${sectionTitle}`);
    }
  }
  return errors;
}

const sanitizeTemplate = (template) => {
  const { title, description, welcomeSection, sections } = template;
  const options = {
    preserveNewlines: true
  };
  const sanitizedTitle = convert(title.replaceAll('&nbsp;', ' '), options);
  const sanitizedDescription = convert(description.replaceAll('&nbsp;', ' '), options);
  const sanitizedWelcomeMessage = welcomeSection.message.replaceAll('&nbsp;', ' ');
  const sanitizedWelcomeSection = {
    ...welcomeSection,
    message: sanitizedWelcomeMessage,
  };
  const sanitizedSections = sections.map((section) => {
    const { title, message } = section;
    const sanitizedTitle = convert(title.replaceAll('&nbsp;', ' '), options);
    const sanitizedMessage = message.replaceAll('&nbsp;',  ' ');
    return { ...section, title: sanitizedTitle, message: sanitizedMessage };
  });
  const newTemplate = {
    ...template,
    title: sanitizedTitle,
    description: sanitizedDescription,
    welcomeSection: sanitizedWelcomeSection,
    sections: sanitizedSections,
  }
  return newTemplate;
}

function TemplatePage({ editable }) {
  const { state, pathname } = useLocation();
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const close = () => navigate(pathname.replace('/view', ''));
  const [loadingTemplateCreation, setLoadingTemplateCreation] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [errors, setErrors] = useState([]);
  const className = editable ? 'editing-page' : 'view-page';
  const title = editable ? 'Create new template' : 'View template';
  const initialTemplate = state && state.template ? state.template : getEmptyTemplate();
  const [template, setNewTemplate] = useState(initialTemplate);
  const setTemplate = (template) => {
    setNewTemplate(template);
    setErrors([]);
  }
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { sections } = template;
  const setSections = (sections) => setTemplate({ ...template, sections });
  const setSection = (section, index) => {
    const { sections } = template;
    const newSections = [...sections];
    newSections[index] = section;
    setSections(newSections);
  }
  const addSection = (section) => {
    const { sections } = template;
    const newSections = [...sections, section];
    setSections(newSections);
  }
  const addNewSection = () => {
    const numSections = sections.length;
    if (numSections < MAXIMUM_NUMBER_OF_SECTIONS) {
      addSection(createNewEmptySection(numSections + 1));
      setCurrentIndex(numSections);
    }
  };
  const deleteSection = (index) => {
    const { sections } = template;
    const newSections = [...sections].filter((_, i) => i !== index);
    if (currentIndex === index) {
      const newIndex = currentIndex >= newSections.length ? currentIndex - 1 : currentIndex;
      setCurrentIndex(newIndex);
    }
    else if (currentIndex >= index) {
      setCurrentIndex(currentIndex - 1);
    }
    setSections(newSections);
  }
  const isWelcomePage = currentIndex === -1;
  const selectedSection = currentIndex >= 0 ? sections[currentIndex] : null;
  const publishTemplate = () => {
    const newTemplate = { ...sanitizeTemplate(template), type: PUBLISHED };
    const isExistingDraft = template.type === DRAFT && template._id;
    setLoadingTemplateCreation(true);
    postCreateTemplate(newTemplate, isExistingDraft ? template._id : null)
      .then(res => {
        if (res.user) {
          setUser(res.user);
          setLoadingTemplateCreation(false);
          navigate('/templates/published');
        }
      });
  }
  const openCreateConfirmationModal = () => {
    const errors = validateTemplate(template);
    if (errors.length === 0) {
      setOpenCreateModal(true);
    }
    else {
      setIsDraft(false);
      setOpenErrorModal(true);
    }
    setErrors(errors);
  }
  const openDraftTemplateModal = () => {
    const errors = validateTemplate(template);
    if (errors.length === 0) {
      setOpenDraftModal(true);
    }
    else {
      setIsDraft(true);
      setOpenErrorModal(true);
    }
    setErrors(errors);
  }
  const draftTemplate = () => {
    const newTemplate = { ...sanitizeTemplate(template), type: DRAFT }
    setLoadingTemplateCreation(true);
    const isExistingDraft = template.type === DRAFT && template._id;
    postCreateTemplate(newTemplate, isExistingDraft ? template._id : null)
      .then(res => {
        if (res.user) {
          setUser(res.user);
          setLoadingTemplateCreation(false);
          navigate('/templates/draft');
        }
      });
  };
  const closeOpenCreateModal = () => setOpenCreateModal(false);
  const closeOpenErrorModal = () => setOpenErrorModal(false);
  const closeOpenDraftTemplateModal = () => setOpenDraftModal(false);
  const errorText = errors.length > 0 ? `Please fix the following issues: ${errors.join(', ')}` : null;
  return (
    <TemplatesPageWrapper showTabs={false} additionalClassNames={[className]}>
      { openCreateModal && <CreateTemplateConfirmationModal close={closeOpenCreateModal} confirm={publishTemplate} loading={loadingTemplateCreation} /> }
      { openErrorModal && <CreateTemplateErrorModal close={closeOpenErrorModal} isDraft={isDraft}/> }
      { openDraftModal && <DraftTemplateConfirmationModal close={closeOpenDraftTemplateModal} confirm={draftTemplate} loading={loadingTemplateCreation} /> }
      <section className={'template-page-container'}>
        <header className={'template-title-header'}>
          <section className={'template-title-container'}>
          <h3 className={'template-title'}>{title}</h3>
            <section className={'template-buttons-container'}>
              <section className={'template-buttons'}>
                { editable && <button className={'save-draft-button'} onClick={openDraftTemplateModal}>{'Save draft'}</button> }
                { editable && <button className={'create-template-button'} onClick={openCreateConfirmationModal}>{'Publish template'}</button> }
                { !editable && <button className={'close-button'} onClick={close}>{'Close'}</button> }
              </section>
            </section>
          </section>
          { errorText && <span className={'error-text'}>{errorText}</span> }
        </header>
        <section className={'template-details'}>
          <TemplateNavigationTabs
            editable={editable}
            setIndex={setCurrentIndex}
            addSection={addNewSection}
            sections={sections}
            selectedIndex={currentIndex}
            deleteSection={deleteSection}
          />
          { isWelcomePage
            ? <TemplateWelcomeSection
                template={template}
                setTemplate={setTemplate}
                editable={editable}
              />
            : <TemplateSection
                section={selectedSection}
                setSection={setSection}
                currentIndex={currentIndex}
                editable={editable}
              />
          }
        </section>
      </section>
    </TemplatesPageWrapper>
  );
}

TemplatePage.displayName = 'TemplatePage';
TemplatePage.propTypes = propTypes;
export default TemplatePage;