export const TEMPLATE_TYPES = {
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
  EMPLOYEE: 'EMPLOYEE',
  CUSTOMER: 'CUSTOMER',
}

export const TEMPLATE_PAGE_TYPES = {
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
  LIBRARY: 'LIBRARY',
};

const { SAVED, EMPLOYEE, CUSTOMER } = TEMPLATE_TYPES;

export const QUESTION_INTENT_TYPES = {
  GENERATE_IMPROVEMENT_IDEAS: 'GENERATE_IMPROVEMENT_IDEAS',
  PROVIDE_RECOGNITION_OR_REWARD: 'PROVIDE_RECOGNITION_OR_REWARD',
  IDENTIFY_FOCUS_AREAS: 'IDENTIFY_FOCUS_AREAS',
  BROADCAST_A_MESSAGE: 'BROADCAST_A_MESSAGE',
  PROACTIVELY_SURFACE_ISSUES: 'PROACTIVELY_SURFACE_ISSUES',
  OTHER: 'OTHER',
}

export const RESPONSE_TYPES = {
  NONE_REQUIRED: 'NONE_REQUIRED',
  QUANTITATIVE: 'QUANTITATIVE',
  QUALITATIVE: 'QUALITATIVE',
}

export const RESPONSE_FORWARDING_TYPES = {
  EMPLOYEES_MANAGER: 'EMPLOYEES_MANAGER',
  ALL_MANAGERS: 'ALL_MANAGERS',
  ALL_ADMINS: 'ALL_ADMINS',
}

export const RESPONSE_FORWARDING_INTENTION_TYPES = {
  GATHER_AND_SEND_RESPONSES: 'GATHER_AND_SEND_RESPONSES',
  GATHER_RESPONSES: 'GATHER_RESPONSES',
};

const { BROADCAST_A_MESSAGE, PROACTIVELY_SURFACE_ISSUES, OTHER, IDENTIFY_FOCUS_AREAS, PROVIDE_RECOGNITION_OR_REWARD, GENERATE_IMPROVEMENT_IDEAS } = QUESTION_INTENT_TYPES;
const { NONE_REQUIRED, QUANTITATIVE, QUALITATIVE } = RESPONSE_TYPES;
const { EMPLOYEES_MANAGER, ALL_MANAGERS, ALL_ADMINS } = RESPONSE_FORWARDING_TYPES;
const { GATHER_AND_SEND_RESPONSES, GATHER_RESPONSES } = RESPONSE_FORWARDING_INTENTION_TYPES;
 
export const CUSTOMER_TEMPLATES = {
  PERSONALIZED_PATIENT_RECALLS: {
    key: 'PERSONALIZED_PATIENT_RECALLS',
    title: 'Personalized patient recalls',
    type: CUSTOMER,
    label: 'Optical practice',
    description: 'Highly effective recall message to your patients asking them to come back and/or re-book into an appointment',
    recommendedUrl: 'https://www.workrex.io/templates/personalised-recall-template-for-optical-practices',
    welcomeSection: {
      title: 'Patient recall',
      message: `Hi @firstName, it\'s [Your name] from [Your store name] here.
Thanks for visiting us in the past! I noticed it\'s been 12 months since your last visit, so I wanted to check in to see if you\'d like me to help you book into a clean up. We recommend it each year to maintain eye health.

You can reply here, book directly on: [Your website booking link], or call on [Your phone number] to book.`,
      responseForwardingIntent: null,
      responseForwarding: null,
    },
    sections: [],
  },
  POST_APPOINTMENT_PATIENT_FEEDBACK: {
    key: 'POST_APPOINTMENT_PATIENT_FEEDBACK',
    title: 'Post appointment patient feedback',
    type: CUSTOMER,
    label: 'Optical practice',
    description: 'Gather feedback from your patient after their appointment, including NPS score and more',
    recommendedUrl: 'https://www.workrex.io/templates/collect-feedback-post-appointment-for-patients-at-optical-practices',
    welcomeSection: {
      title: 'Patient feeback request',
      message: `Hi @firstName, thanks for visiting [Your Practice's Name]. My name is [Your Name] and I run the practice.

Having served the community for years, we're always eager to hear feedback from our patients so that we can continuously improve your experience. \n\n Could you take 30 seconds to tell us about your visit?
Just reply 'GO' to get started.`,
      responseForwardingIntent: null,
      responseForwarding: null,
    },
    sections: [
      {
        title: 'NPS score',
        message: `On a scale between 0 to 10, how likely are you to recommend [Your Practice's Name] to a friend or colleague?

Note: Must be a numerical value between 0 and 10. `,
        responseExpected: QUANTITATIVE,
        questionIntent: null,
        attachment: null,
      },
      {
        title: 'Positive areas',
        message: `What did you enjoy most about your visit to [Your Practice's Name]?`,
        responseExpected: QUALITATIVE,
        questionIntent: IDENTIFY_FOCUS_AREAS,
        attachment: null,
      },
      {
        title: 'Improvement areas',
        message: `What did you enjoy least about your visit to [Your Practice's Name]?`,
        responseExpected: QUALITATIVE,
        questionIntent: IDENTIFY_FOCUS_AREAS,
        attachment: null,
      },
      {
        title: 'Thank you message',
        message: `We really appreciate your feedback. I read every response to understand how our practice can improve your experience at your next visit.`,
        responseExpected: NONE_REQUIRED,
        questionIntent: null,
        attachment: null,
      },
    ],
  },
  GENERATE_POSITIVE_GOOGLE_REVIEWS: {
    key: 'GENERATE_POSITIVE_GOOGLE_REVIEWS',
    title: 'Generate Positive Google Reviews',
    type: CUSTOMER,
    label: 'Optical practice',
    description: 'Request your patients to add positive Google Reviews for you practice',
    recommendedUrl: 'https://www.workrex.io/templates/generate-positive-google-reviews-for-optical-practices',
    welcomeSection: {
      title: 'Google Review Request',
      message: `Hi @firstName, thanks again for your kind feedback during your visit at [Your Practice's Name]. Word of mouth is such an important part of our business. I would appreciate it hugely if you could take 30 seconds to leave a google review.
      
You can leave one by clicking on this link: [Your Practice's Google Review Link].`,
      responseForwardingIntent: null,
      responseForwarding: null,
    },
    sections: [],
  },
  PERSONALISED_WELCOME_MESSAGE: {
    key: 'PERSONALISED_WELCOME_MESSAGE',
    title: 'Personalised Welcome Message',
    type: CUSTOMER,
    label: 'Optical practice',
    description: 'Offer a personalised welcome to your new patients ahead of their appointment, and showcase how your practice stands out from the rest',
    recommendedUrl: 'https://www.workrex.io/templates/genuine-welcome-message-for-new-patients-at-optical-practices',
    welcomeSection: {
      title: 'Personalised welcome',
      message: `Hi @firstName, we're thrilled to have you as a new member of our eye care family.

At [Insert Your Practice's Name], we think we stand out from the rest. Here's what you can expect during your  visit:
1. Comprehensive Eye Exam: Our technology ensures a thorough examination to assess your eye health and vision needs
2. Personalised Care: We take the time to understand your lifestyle so we can tailor our recommendations just for you
3. Knowledgeable Team: Our friendly and knowledgeable staff is here to answer any questions and make your visit seamless

To make your first visit even more special, enjoy [Insert any special offers or perks for new patients]. Please send a message if you need to reschedule or give us a call at [Your Practice's Contact Number].

Thanks! [Insert your name]`,
      responseForwardingIntent: null,
      responseForwarding: null,
    },
    sections: [],
  },
  RESCHEDULE_APPOINTMENT_NO_SHOW_MESSAGE: {
    key: 'RESCHEDULE_APPOINTMENT_NO_SHOW_MESSAGE',
    title: 'Reschedule Appointment No-Show Message',
    type: CUSTOMER,
    label: 'Optical practice',
    description: 'Offer your patients that did not show up to their appointment an opportunity to easily reschedule',
    recommendedUrl: 'https://www.workrex.io/templates/reschedule-no-shows-and-cancellations-for-patients-at-optical-practices',
    welcomeSection: {
      title: 'Reschedule No-Show',
      message: `Hi @firstName! Sorry we missed you at [Your Practice Name] for your appointment today.

Life happens! Reschedule your appointment at your convenience. Let me know when suits, and I'd be happy to help book you in. Alternatively you can visit our website [Your website's booking link] to choose a new time. I look forward to your visit!

Thanks, [Your first name].`,
      responseForwardingIntent: null,
      responseForwarding: null,
    },
    sections: [],
  },
  GENERATE_WORD_OF_MOUTH_PATIENT_REFERRALS: {
    key: 'GENERATE_WORD_OF_MOUTH_PATIENT_REFERRALS',
    title: 'Generate Word-of-Mouth Patient Referrals',
    type: CUSTOMER,
    label: 'Optical practice',
    description: 'Request word-of-mouth referrals from new patients',
    recommendedUrl: 'https://www.workrex.io/templates/word-of-mouth-patient-referrals-for-optical-practices',
    welcomeSection: {
      title: 'Word-of-mouth referral request',
      message: `Hi @firstName, thanks again for visiting [Insert Your Practice's Name]. Word of mouth is such an important part of our business. I would appreciate it hugely if you can mention our practice to your friends & family. For their first visit, we'd like to offer [Insert Your Promotional Offer].

Thanks again, and it's great to welcome you to [Insert Your Practice's Name]!`,
      responseForwardingIntent: null,
      responseForwarding: null,
    },
    sections: [],
  },
  IN_STORE_PROMOTION_ANNOUNCEMENT: {
    key: 'IN_STORE_PROMOTION_ANNOUNCEMENT',
    title: 'In-Store Promotion Announcement',
    type: CUSTOMER,
    label: 'Optical practice',
    description: 'Announce a new in-store promotion to your customers to encourage another visit',
    welcomeSection: {
      title: 'In-store promotion announcement',
      message: `Hi @firstName, it's [Insert your name] from [Insert your practice's name]. Thanks for visiting us in the past! I wanted to reach out and let you know that we have a special offer in store which I thought would be relevant for you.

[Insert your promotion]

Let me know if you'd like me to book you in for an appointment. You can message me directly here or give me a call on [Insert your practice's phone number].`,
      responseForwardingIntent: null,
      responseForwarding: null,
    },
    sections: [],
  },
  NEW_PRODUCT_ANNOUNCEMENT: {
    key: 'NEW_PRODUCT_ANNOUNCMENT',
    title: 'New Product Announcement',
    type: CUSTOMER,
    label: 'Optical practice',
    description: 'Announce a new product (e.g. frame style) released in your store',
    welcomeSection: {
      title: 'New Product Announcement',
      message: `Hi @firstName, it's [Insert your name] from [Insert your practice's name]. Thanks for visiting us in the past! I wanted to reach out and let you know that we just released a new product that I thought you'd like.

[Insert your new product announcement]

Feel free to pop by to see it in person, or I can book you in for an appointment. You can message me directly here or give me a call on [Insert your practice's phone number].`,
      responseForwardingIntent: null,
      responseForwarding: null,
    },
    sections: [],
  },
  CONVERT_CUSTOMERS_POST_APPOINTMENT: {
    key: 'CONVERT_CUSTOMERS_POST_APPOINTMENT',
    title: 'Convert Customers Post-Appointment',
    type: CUSTOMER,
    label: 'Optical practice',
    description: 'Offer further support and/or product recommendations to patients that did not convert during their appointment/visit',
    welcomeSection: {
      title: 'Offer further support post appointment',
      message: `Hi @firstName, it's [Insert your name] from [Insert your practice's name]. Thanks again for visiting us & supporting our business. If you would like any more support or recommendations before deciding on your frame style, I'm here to help.

[Optional: Insert any customer-specific product recommendation you'd like to make]

Feel free to pop by the store when it suits, or I can book you in for an appointment. You can message me directly here or give me a call on [Insert your practice's phone number].`,
      responseForwardingIntent: null,
      responseForwarding: null,
    },
    sections: [],
  },
};

export const EMPLOYEE_TEMPLATES = {
  CONDUCT_EMPLOYEE_PULSE_CHECK: {
    key: 'CONDUCT_EMPLOYEE_PULSE_CHECK',
    title: 'Conduct Employee Pulse Check',
    type: EMPLOYEE,
    label: 'All Companies',
    description: 'Rapid pulse check to proactively track & identify employee engagement',
    recommendedUrl: 'https://www.workrex.io/templates/employee-pulse-check-survey-for-all-industries',
    welcomeSection: {
      title: 'Intro to employee pulse check',
      message: `Hi @firstName, @managerFirstName here. Could you share your employee experience through a few short questions?

This will help me to provide you with support faster and create a better work experience. I'd appreciate it if you could complete this in the next 2 days.

Just reply with "GO2" to get started!`,
      responseForwardingIntent: GATHER_AND_SEND_RESPONSES,
      responseForwarding: EMPLOYEES_MANAGER,
    },
    sections: [
      {
        title: 'Pulse check score',
        message: `On a scale between 0 to 10, how satisfied have you been with your experience at [Your Business' Name] in the past month?

Note: Must be a numerical integer value between 0 and 10`,
        responseExpected: QUANTITATIVE,
        questionIntent: null,
        attachment: null,
      },
      {
        title: 'Positive areas',
        message: `What have you enjoyed most about your experience at [Your Business' Name] in the past month?`,
        responseExpected: QUALITATIVE,
        questionIntent: PROVIDE_RECOGNITION_OR_REWARD,
        attachment: null,
      },
      {
        title: 'Areas for improvement',
        message: `How could I help to make your experience at [Your Business' Name] even better next month?`,
        responseExpected: QUALITATIVE,
        questionIntent: GENERATE_IMPROVEMENT_IDEAS,
        attachment: null,
      },
      {
        title: 'Thank you message',
        message: `Thanks @firstName! Really appreciate it and I'll take the time to read through each response.`,
        responseExpected: NONE_REQUIRED,
        questionIntent: null,
        attachment: null,
      },
    ],
  },
  EMPLOYEE_PERFORMANCE_SELF_ASSESSMENT: {
    key: 'EMPLOYEE_PERFORMANCE_SELF_ASSESSMENT',
    title: 'Employee Performance Self Assessment',
    type: EMPLOYEE,
    label: 'All Companies',
    description: 'Help managers prepare for goal setting & performance discussions with an employee self-assessment',
    welcomeSection: {
      title: 'Intro to employee self assessment',
      message: `Hi @firstName, @managerFirstName here. To support your development at [Insert Business Name], I would love to have a goal setting discussion.

To help me prepare, could you complete a quick self-assessment by answering a few questions? I'd appreciate it if you could complete this in the next 2 days.

Just reply with "GO3" to get started`,
      responseForwardingIntent: GATHER_AND_SEND_RESPONSES,
      responseForwarding: EMPLOYEES_MANAGER,
    },
    sections: [
      {
        title: 'Enjoyment qualitative response',
        message: `What have you enjoyed most about your role in the past 6 months?

Note: Free feel to offer specific examples`,
        responseExpected: QUALITATIVE,
        questionIntent: PROVIDE_RECOGNITION_OR_REWARD,
        attachment: null,
      },
      {
        title: 'Key upcoming goals',
        message: `Tell me about any professional goals that is important to you in the next 6 to 12 months

Note: Feel free to offer specific examples`,
        responseExpected: QUALITATIVE,
        questionIntent: IDENTIFY_FOCUS_AREAS,
        attachment: null,
      },
      {
        title: 'Product self assessment',
        message: `Between 1 to 10, how would you rate your confidence in articulating the benefits of our product range?`,
        responseExpected: QUANTITATIVE,
        questionIntent: null,
        attachment: null,
      },
      {
        title: 'Product self assessment',
        message: `Between 1 to 10, how would you rate your confidence in the technical aspects of dispensing?`,
        responseExpected: QUANTITATIVE,
        questionIntent: null,
        attachment: null,
      },
      {
        title: 'Thank you message',
        message: `Thanks @firstName! Really appreciate it and I'll take the time to read through your response when I prepare for our discussion`,
        responseExpected: NONE_REQUIRED,
        questionIntent: null,
        attachment: null,
      },
    ],
  },
  STORE_ONBOARDING_FOR_OPTICAL_DISPENSERS: {
    key: 'STORE_ONBOARDING_FOR_OPTICAL_DISPENSERS',
    title: 'Store Onboarding for Optical Dispensers',
    type: EMPLOYEE,
    label: 'Optical practice',
    description: 'Provide new starter Optical Dispensers with a quick, interactive, and comprehensive guide to the basics of working at your Optical Practice',
    recommendedUrl: 'https://www.workrex.io/templates/rapid-store-onboarding-for-optical-dispensers',
    welcomeSection: {
      title: 'Introduction',
      message: `Hi @firstName, welcome onboard to [Insert your practice's name]! To help bring you up to speed on our practice's operations & unique processes, could you please complete this onboarding module in the next 2 days? It will just take 5 minutes.`,
      responseForwardingIntent: null,
      responseForwarding: null,
    },
    sections: [
      {
        title: 'Who\'s who',
        message: `Key contacts at the store

You will work primarily with the store manager [Insert your store manager’s name] and our optometrist [Insert your optometrist’s name]. In total we have [insert number of team members at your store] whom you will see on a frequent basis.

Contact details for [Insert your store manager’s name] can be found here:
Mobile: [Insert your store manager’s mobile number]
Email: [Insert your store manager’s email address]

Contact details for [Insert your optometrist’s name] can be found here:
Mobile: [Insert your optometrist’s mobile number]
Email: [Insert your optometrist’s email address]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Store basics',
        message: `General working hours

Whilst these working hours may change in special circumstances (which we’ll inform you about beforehand, such as during peak trading times), in general team members will start at [Insert your store’s start time] and finish at [Insert your store’s end time]. There is a standard break [Insert your store’s break time].

Key meetings to attend

[Insert any recurring meetings your store may have, such as a weekly planning meeting]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Where things are',
        message: `Location of common items

Spectacle orders for pick up: [Insert where this is kept in your store]
Contact lens trials: [Insert where this is kept in your store]
Contact lens orders for pick up: [Insert where this is kept in your store]
General stationery such as scissors, alcohol swabs: [Insert where this is kept in your store]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Store Process #1',
        message: `Welcoming new visitors to the store

[Insert welcome practices such as taking appointments for visitors, information that should be asked and captured]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Store Process #2',
        message: `Process for patients after arriving for appointment

[Insert process for patients post arrival, such as confirming their details, sitting them in the waiting area for pre-testing, letting the optometrist know their patient has arrived]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Store Process #3',
        message: `Handover process from optometrists after appointment

[Insert process for patients once they have completed the optometrist appointment, such as 3-way handovers]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Store Process #4',
        message: `Process for contact lens trials

[Insert process for ordering trial contact lenses, such as documentation]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Store Process #5',
        message: `Process for closing the store

[Insert checklist items for closing the store, such as counting & storing cash received, backing up appointments in the database, vacuuming floors]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Store Process #6',
        message: `Other things to know

[Insert other processes important for your store, such as not doing CL teaches on weekends, escalating to store managers to process refunds]

All done! Thanks for completing the onboarding. Just let us know if you have any other questions.`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
    ],
  },
  STORE_ONBOARDING_FOR_OPTOMETRISTS: {
    key: 'STORE_ONBOARDING_FOR_OPTOMETRISTS',
    title: 'Store Onboarding for Optometrists',
    type: EMPLOYEE,
    label: 'Optical practice',
    description: 'Provide new starter Optometrists with a quick, interactive, and comprehensive guide to the basics of working at your Optical Practice',
    welcomeSection: {
      title: 'Introduction',
      message: `Hi @firstName, welcome onboard to [Insert your practice's name]! To help bring you up to speed on our practice's operations & unique processes, could you please complete this onboarding module in the next 2 days? It will just take 5 minutes.`,
      responseForwardingIntent: null,
      responseForwarding: null,
    },
    sections: [
      {
        title: 'Who\'s who',
        message: `Key contacts in the store

You will work primarily with the store manager [Insert your store manager’s name] and our senior dispenser [Insert your lead dispenser's name]. In total we have [insert number of team members at your store] whom you will see on a frequent basis.

Contact details for [Insert your store manager’s name] can be found here:
Mobile: [Insert your store manager’s mobile number]
Email: [Insert your store manager’s email address]

Contact details for [Insert your lead dispenser's name] can be found here:
Mobile: [Insert your dispenser's mobile number]
Email: [Insert your dispenser's email address]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Setting up',
        message: `Setting up a Medicare provider number

You can refer to these details when setting up a Medicare provider number [Insert Shop Name], [Insert ABN], [Insert Account Number and BSB]

Parking at the store

[Insert details about your staff parking available, where it is, and how much it costs]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Appointment basics',
        message: `Our appointment book structure

[Insert details about your appointment book, such as whether they are 20 or 30 mins, initial contact lens appointments, etc]

Our billing structure

[Insert details about your extra fees charged for tests on top of medicare such as Visual Fields or Scans]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Contact lenses',
        message: `Our contact lenses range & offers

[Insert details about which contact lenses are on offer at your practice]

Our processes for contact lenses

[Insert details specific to your practice about contact lenses]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Spectacles and other products',
        message: `Our core Spectacles range

[Insert details about your top-seller Spectacles, the types of lenses offered, their prices and what their main benefits are over competitors]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Store Equipment',
        message: `Equipment available to you for use

[Insert details about the equipment that your store has available, such as Topographers, OCTs]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Computer logins',
        message: `Accessing our computers and practice management software

[Insert details such as account usernames and passwords for how the computer and software can be accessed]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Invoicing and payment',
        message: `Getting paid

[Insert details about your invoicing preferences, such as emailing invoices and information to include]

Reply with 'OK' to proceed`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
      {
        title: 'Other instructions',
        message: `Other instructions

[Insert details about any other instructions you'd like the Optometrist to know, such as covering store floor when dispenser is on lunch break, or teaching contact lenses]

All done! Thanks for completing the onboarding. Just let us know if you have any other questions.`,
        responseExpected: QUALITATIVE,
        questionIntent: OTHER,
        attachment: null,
      },
    ],
  },
}

export const DUMMY_TEMPLATES = [
  {
    _id: '1',
    title: 'Dummy Template 1',
    type: SAVED,
    label: null,
    description: 'I\'m just a dummy template',
    welomeSection: {
      title: '',
      message: '',
      responseExpected: NONE_REQUIRED,
      questionIntent: null,
      attachment: null,
      responseForwarding: null,
    },
    sections: [],
  },
  {
    _id: '2',
    title: 'Dummy Template 2',
    type: SAVED,
    label: null,
    description: 'I\'m just a dummy template',
    welomeSection: {
      title: '',
      message: '',
      responseExpected: NONE_REQUIRED,
      questionIntent: null,
      attachment: null,
      responseForwarding: null,
    },
    sections: [],
  },
  {
    _id: '3',
    title: 'Dummy Template 3',
    type: SAVED,
    label: null,
    description: 'I\'m just a dummy template',
    welomeSection: {
      title: '',
      message: '',
      responseExpected: NONE_REQUIRED,
      questionIntent: null,
      attachment: null,
      responseForwarding: null,
    },
    sections: [],
  },
  {
    _id: '4',
    title: 'Dummy Template 4',
    type: SAVED,
    label: null,
    description: 'I\'m just a dummy template',
    welomeSection: {
      title: '',
      message: '',
      responseExpected: NONE_REQUIRED,
      questionIntent: null,
      attachment: null,
      responseForwarding: null,
    },
    sections: [],
  },
];

export const MAXIMUM_NUMBER_OF_SECTIONS = 9;

export const RESPONSE_FORWARDING_INTENTION_TYPES_LIST = [
  {
    key: GATHER_AND_SEND_RESPONSES,
    displayText: 'Forward gathered responses',
  },
  {
    key: GATHER_RESPONSES,
    displayText: 'Do not forward responses',
  }
]

export const RESPONSE_FORWARDING_TYPES_LIST = [
  {
    key: EMPLOYEES_MANAGER,
    displayText: 'The employee\'s manager',
  },
  {
    key: ALL_MANAGERS,
    displayText: 'All managers',
  },
  {
    key: ALL_ADMINS,
    displayText: 'All admins',
  }
];

export const QUESTION_INTENT_TYPES_LIST = [
  {
    key: GENERATE_IMPROVEMENT_IDEAS,
    displayText: 'Generate improvement ideas',
  },
  {
    key: PROVIDE_RECOGNITION_OR_REWARD,
    displayText: 'Provide recognition/rewards',
  },
  {
    key: IDENTIFY_FOCUS_AREAS,
    displayText: 'Identify areas to focus on',
  },
  {
    key: BROADCAST_A_MESSAGE,
    displayText: 'Broadcast a message',
  },
  {
    key: PROACTIVELY_SURFACE_ISSUES,
    displayText: 'Proactively surface issues',
  },
  {
    key: OTHER,
    displayText: 'Other',
  }
];

export const RESPONSE_TYPE_LIST = [
  {
    key: NONE_REQUIRED,
    displayText: 'None required',
  },
  {
    key: QUANTITATIVE,
    displayText: 'Numerical',
  },
  {
    key: QUALITATIVE,
    displayText: 'Free text',
  },
];