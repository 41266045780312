import './DirectMessage.scss';

import React from 'react';

import TemplateTextInput from '../../../templates/common/TemplateTextInput';
import PreviewToolButtons from './PreviewToolButtons';
import PreviewToolbar from './PreviewToolbar';

function DirectMessage({
  preview,
  setPreview,
  proceed = null,
  goToNextVariant,
  goToPreviousVariant,
  addVariant,
  removeVariant,
  hasMultipleVariants,
  viewOnly = false,
}) {
  const { message, attachment } = preview;
  const setMessage = (text) => setPreview({...preview, message: text });
  const setAttachment = (attachment) => setPreview({...preview, attachment });
  return (
    <section className={'direct-message'}>
      <section className={'message-container'}>
        <header className={'message-header'}>
          <label className={'message-label'} htmlFor={'message'}>{'Edit message'}</label>
          <PreviewToolbar
            text={message}
            goToNextVariant={goToNextVariant}
            goToPreviousVariant={goToPreviousVariant}
            addVariant={addVariant}
            removeVariant={removeVariant}
            hasMultipleVariants={hasMultipleVariants}
            viewOnly={viewOnly}
          />
        </header>
        <TemplateTextInput
          editable={!viewOnly}
          text={message}
          setText={setMessage}
          placeholder={'Type your message here...'}
          shouldUseVariables={true}
          showCharCount={true}
          maxCharCount={400}
        />
        <PreviewToolButtons
          proceed={proceed}
          uploadText={'Attach a file'}
          attachment={attachment}
          setAttachment={setAttachment}
          viewOnly={viewOnly}
        />
      </section>
    </section>
  );
};

DirectMessage.displayName = 'DirectMessage';
export default DirectMessage;