import './ChangeLoginMethod.scss';

import React from 'react';

function ChangeLoginMethod({ optionText, setLoginMethod, newLoginMethod }) {
  return (
    <button className={'login-method'} onClick={() => setLoginMethod(newLoginMethod)} type={'button'}>
      <p>{optionText}</p>
    </button>
  )
}

export default ChangeLoginMethod;