import './EngagementsSidebar.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { postEngagementCreation } from '../../../../../../apis/EngagementApi';

import FilterButtonList from '../../../common/FilterButtonList';

import ACTIVE_ICON from '../assets/active-icon.svg';
import AddButton from '../../../common/AddButton';
import SearchInput from '../../../common/SearchInput';

const propTypes = {};

function EngagementsSidebar({ assistants, selectAssistantKey, selectedAssistantKey, searchText, setSearchText }) {
  const filters = assistants.map(({ key, displayText, icon, active }) => {
    const activeIcon = active ? <img className={'active-icon'} src={ACTIVE_ICON} alt={'active'} /> : null;
    return {
      buttonText: displayText,
      icon,
      selected: key === selectedAssistantKey,
      onClick: () => selectAssistantKey(key),
      additionalComponent: activeIcon,
    }
  });
  return (
    <aside className={'engagements-sidebar'}>
      <AddButton buttonText={'Add engagement'} onClick={postEngagementCreation} />
      <span className={'divider'} />
      <SearchInput searchText={searchText} setSearchText={setSearchText} />
      <FilterButtonList filters={filters} />
    </aside>
  );
}

EngagementsSidebar.displayName = 'EngagementsSidebar';
EngagementsSidebar.propTypes = propTypes;
export default EngagementsSidebar;