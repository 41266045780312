import './SortDashboard.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { SORT_OPTIONS } from './SortConstants';
import SortOption from './SortOption';

const propTypes = {};

function SortDashboard({ sortBy, setSortBy, containerToggle }) {
  const applySort = (sortMethod) => {
    setSortBy(sortMethod);
    containerToggle(true);
  }
  
  const sortOptions = Object.keys(SORT_OPTIONS).map(option => <SortOption option={SORT_OPTIONS[option]} selected={sortBy} setSelected={applySort} />)

  return (
    <section className={'sort-dashboard'}>
      <section className={'sort-header'}>
        <span className={'sort-header-text'}>{'Sort by'}</span>
      </section>
      <section className={'sort-option-container'}>
        {sortOptions}
      </section>
    </section>
  )
}

SortDashboard.displayText = 'SortDashboard';
SortDashboard.propTypes = propTypes;
export default SortDashboard;
