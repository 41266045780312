import './ChannelTab.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function ChannelTab({ displayText, selected, style = null, onClick }) {
  return (
    <button
      className={`channel-tab ${selected ? 'selected' : ''}`}
      onClick={onClick}
      style={style}
    >
      {displayText}
    </button>
  )
}

ChannelTab.displayName = 'ChannelTab';
ChannelTab.propTypes = propTypes;
export default ChannelTab;