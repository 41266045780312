import './MessagingContainer.scss';

import React from 'react';
import PropTypes from 'prop-types';

import MessagingBody from './MessagingBody';

const propTypes = {};

function MessagingContainer() {
  return (
    <section className={'messaging-page'}>
      <section className={'messaging'}>
        <MessagingBody />
      </section>
    </section>
  )
}

MessagingContainer.displayName = 'MessagingContainer';
MessagingContainer.propTypes = propTypes;
export default MessagingContainer;