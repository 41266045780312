import './LinkedInPreview.scss';

import React from 'react';
import PreviewWrapper from './PreviewWrapper';

import LINKEDIN_PREVIEW_IMAGE from './assets/linkedin-preview-image.png';
import SocialMediaPost from './common/SocialMediaPost';

function LinkedInPreview({ preview, setPreview, proceed }) {
  return (
    <PreviewWrapper title={'LinkedIn'} previewImage={LINKEDIN_PREVIEW_IMAGE}>
      <SocialMediaPost preview={preview} setPreview={setPreview} proceed={proceed} />
    </PreviewWrapper>
  );
};

LinkedInPreview.displayName = 'LinkedInPreview';
export default LinkedInPreview;