import './AutomationDropdown.scss';

import React from 'react';
import PropTypes from 'prop-types';
import EllipsisDropdown from '../../../common/EllipsisDropdown';


const propTypes = {};

function AutomationDropdown({ disabled = false, options }) {
  return (
    <EllipsisDropdown
      additionalClassNames={['automation-dropdown']}
      disabled={disabled}
      options={options}
    />
  )
}

AutomationDropdown.displayName = 'AutomationDropdown';
AutomationDropdown.propTypes = propTypes;
export default AutomationDropdown;