import './ManagerDropdown.scss';

import React, { useState, useRef } from 'react';

import { useOutsideClick } from '../../../../hooks/ClickHooks';

import DROPDOWN_ARROW from './assets/dropdown-arrow.svg';

import SELECTED_ICON from './assets/selected-icon.svg';
import UNSELECTED_ICON from './assets/unselected-icon.svg'

const propTypes = {};

function ManagerDropdown({ buttonText, options, select, selectedOptions }) {
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!open);
  const dropdownList = options.map(({ key, displayText }) => {
    const toggleOn = () => select(key);
    const selected = selectedOptions.includes(key);
    return (
      <li className={'option-item'} key={key}>
        <button className={'option-selection'} onClick={toggleOn}>
          <img className={'option'} src={selected ? SELECTED_ICON : UNSELECTED_ICON} alt={''} />
          <span className={'option-text'}>{displayText}</span>
        </button>
      </li>
    )
  });
  useOutsideClick(dropdownRef, () => setOpen(false));
  const isOpen = open && options.length > 0;
  const disabled = options.length === 0;
  return (
    <section className={`manager-dropdown-container ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
      <button className={`manager-dropdown-selector ${disabled ? 'disabled' : ''}`} onClick={toggleDropdown} disabled={disabled}>
        {buttonText}
        <img className={'dropdown-arrow'} src={DROPDOWN_ARROW} alt={''} />
      </button>
      {
        isOpen && (
          <section className={'manager-dropdown-options'}>
            <ul className={'manager-dropdown-list'}>
              {dropdownList}
            </ul>
          </section>
        )
      }
    </section>
  );
}

ManagerDropdown.displayName = 'ManagerDropdown';
ManagerDropdown.propTypes = propTypes;
export default ManagerDropdown;