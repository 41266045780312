import './MobileManagerAuthOptions.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import useAuth from '../../../../../context/AuthContext';
import { ASSET_CDN_URL } from '../../../../../Constants';

import Overlay from '../../../../common/Overlay';

const logo = `${ASSET_CDN_URL}/logo/Rex_Yellow_White_Logo.svg`;

const propTypes = {};

function MobileManagerAuthOptions({ open, setOpen }) {
  const { logout, hasAdminAccess } = useAuth();
  const navigate = useNavigate();

  const closeOverlay = () => setOpen(false);

  const goToDashboard = () => {
    navigate('/dashboard');
    closeOverlay();
  }
  const goToRecipients = () => {
    navigate('/recipients');
    closeOverlay();
  }
  const goToTemplates = () => {
    navigate('/messaging');
    closeOverlay();
  }
  const goToAccount = () => {
    navigate('/account');
    closeOverlay();
  }
  const goToTasks = () => {
    navigate('/tasks');
    closeOverlay();
  }
  /**
  const goToBilling = () => {
    navigate('/billing');
    closeOverlay();
  }
  */
  const goToIntegrations = () => {
    navigate('/account/integrations');
    closeOverlay();
  }
  return (
    <Overlay additionalClassNames={['nav-mobile-overlay']} closeOverlay={closeOverlay} img={logo} isOpen={open}>
      <section className={'mobile-navbar-options-container'}>
        <section className={'navbar-option-container'}>
          { hasAdminAccess && <button className={'navbar-option'} onClick={goToDashboard} type={'button'}>{'Dashboard'}</button>}
          { hasAdminAccess && <button className={'navbar-option'} onClick={goToRecipients} type={'button'}>{'Recipients'}</button> }
          <button className={'navbar-option'} onClick={goToTemplates} type={'button'}>{'Communications'}</button>
          <button className={'navbar-option'} onClick={goToAccount} type={'button'}>{'My account'}</button>
          <button className={'navbar-option'} onClick={goToTasks} type={'button'}>{'My tasks'}</button>
          {/** <button className={'navbar-option'} onClick={goToBilling} type={'button'}>{'Billing'}</button> */}
          { hasAdminAccess && <button className={'navbar-option'} onClick={goToIntegrations} type={'button'}>{'Integrations'}</button> }
          <button className={'navbar-option'} onClick={logout} type={'button'}>{'Log out'}</button>
        </section>
      </section>
    </Overlay>
  );
}

MobileManagerAuthOptions.displayName = 'MobileManagerAuthOptions';
MobileManagerAuthOptions.propTypes = propTypes;
export default MobileManagerAuthOptions;