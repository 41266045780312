import './ChannelSelectionOptions.scss';

import React from 'react';
import { CHANNELS } from '../CampaignConstants';
import ChannelSelectionButton from './ChannelSelectionButton';

function ChannelSelectionOptions({ availableOptions = [], selectedChannels, setSelectedChannels, showTitle = true, enableAll = false, viewOnly = false, hideTooltips = false, isSingleSelect = false }) {
  const availableOptionsList = availableOptions.map((key) => {
    const { displayText, selectedIcon, unselectedIcon, disabled, tooltipText } = CHANNELS[key];
    const selected = selectedChannels.includes(key);
    const onClick = () => {
      if (isSingleSelect) {
        setSelectedChannels(selected ? [] : [key]);
        return;
      }
      if (selectedChannels.includes(key)) {
        setSelectedChannels(selectedChannels.filter((selectedOption) => selectedOption !== key));
      }
      else {
        setSelectedChannels([...selectedChannels, key]);
      }
    }
    return (
      <ChannelSelectionButton
        key={key}
        displayText={displayText}
        selectedIcon={selectedIcon}
        unselectedIcon={unselectedIcon}
        selected={selected}
        onClick={onClick}
        disabled={disabled && !enableAll}
        hideTooltip={viewOnly || hideTooltips}
        tooltipText={tooltipText}
        viewOnly={viewOnly}
      />
    );
  })
  return (
    <section className={'channel-selection-options'}>
      { showTitle && <h5 className={'selection-title'}>{'What channels would you like to generate for?'}</h5> }
      <ul className={'channel-selection-list'}>
        {availableOptionsList}
      </ul>
    </section>
  );
};

ChannelSelectionOptions.displayName = 'ChannelSelectionOptions';
export default ChannelSelectionOptions;