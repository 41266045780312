import './SendOnboardingModal.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WorkRexModal from '../../common/WorkRexModal';

import { RECIPIENT_USER_ACCESS } from '../RecipientsConstants';

import { postOnboardUser } from '../../../../../apis/Api';

const propTypes = {};

function SendOnboardingModal({ close, userId, userAccess, firstName, lastName, emailAddress, setOnboarding }) {
  const [loading, setLoading] = useState(false);
  const { modalDisplayText } = RECIPIENT_USER_ACCESS[userAccess];
  const onboard = () => {
    setLoading(true);
    setOnboarding();
    postOnboardUser(userId)
      .then((res) => {
        if (res.success) {
          setLoading(false);
          close();
        }
      });
  }
  const fullName = `${firstName ? firstName : ''}${lastName ? ` ${lastName}` : ''}`;
  const displayedEmailAddress = `${emailAddress ? emailAddress.toLowerCase() : ''}`;
  return (
    <WorkRexModal close={close} additionalClassNames={['create-template-confirmation']}>
      <p className={'description'}>
        {`This will create a WorkRex account with `}
        <i>{`${modalDisplayText}`}</i>
        {` access for `}
        <i>{`${fullName}`}</i>
        {` and send an email to `}
        <i>{displayedEmailAddress}</i>
        {` with login instructions.`}
      </p>
      <button className={'confirm-button'} onClick={onboard} disabled={loading}>{'Confirm'}</button>
    </WorkRexModal>
  );
}

SendOnboardingModal.displayName = 'SendOnboardingModal';
SendOnboardingModal.propTypes = propTypes;
export default SendOnboardingModal;