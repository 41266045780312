import './TaskTypeSelections.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ToggleButton from './ToggleButton';
import TeamPicker from './TeamPicker';

import DIAMOND_ICON from '../../assets/diamond-list-icon.svg';

const propTypes = {};

function TaskTypeSelections({ tasks, setTasks, teamMembers }) {
  const taskList = tasks.map(({ key, name, members, active }, index) => {
    const setActive = (active) => {
      const newTasks = [...tasks];
      newTasks[index].active = active;
      setTasks(newTasks);
    };
    const setMembers = (members) => {
      const newTasks = [...tasks];
      newTasks[index].members = members;
      setTasks(newTasks);
    };
    return (
      <li className={'task-type'} key={key}>
        <section className={'task-type-info'}>
          <section className={'task-type-identifier'}>
            <img className={'diamond-icon'} src={DIAMOND_ICON} alt={''} />
            <span className={'task-type-name'}>{name}</span>
          </section>
          <ToggleButton active={active} setActive={setActive} />
        </section>
        { active &&
          <TeamPicker
            setSelectedTeamMemberIds={setMembers}
            teamMembers={teamMembers}
            selectedTeamMemberIds={members}
          />
        }
      </li>
    )
  })
  return (
    <section className={'task-type-selections'}>
      <ul className={'task-type-list'}>
        {taskList}
      </ul>
    </section>
  );
}

TaskTypeSelections.displayName = 'TaskTypeSelections';
TaskTypeSelections.propTypes = propTypes;
export default TaskTypeSelections;