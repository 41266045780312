import './Overlay.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  /* Child component of the HOC */
  children: PropTypes.node.isRequired,

  /* Function to close overlay */
  closeOverlay: PropTypes.func.isRequired,

  /* Whether the overlay is open or not */
  isOpen: PropTypes.bool.isRequired,
}

function Overlay({ additionalClassNames, children, closeOverlay, img, isOpen }) {
  return (
    <section className={`overlay ${isOpen ? 'open' : ''} ${additionalClassNames.join(' ')}`}>
      <section className={'overlay-container'}>
        <section className={'overlay-controls'}>
          {img && <img alt={'logo'} className={`overlay-logo ${additionalClassNames.join(' ')}`} src={img} />}
          <button className={`overlay-close ${additionalClassNames.join(' ')}`} onClick={closeOverlay} type={'button'}>
            <span className={'line'} />
            <span className={'line'} />
            <span className={'line'} />
          </button>
        </section>
        {children}
      </section>
    </section>
  )
}

Overlay.displayName = 'Overlay';
Overlay.propTypes = propTypes;
export default Overlay;
