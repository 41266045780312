import './EditAutomationAction.scss';

import React from 'react';
import PropTypes from 'prop-types';
import EditAutomationInput from './EditAutomationInput';
import EditAutomationDropdown from './EditAutomationDropdown';

const propTypes = {};

function EditAutomationAction({ title, target, trigger, output }) {
  // TODO: Save title
  const saveActionTitle = () => {};
  const saveActionDescription = () => {};
  return (
    <section className={'edit-automation-action'}>
      <EditAutomationDropdown additionalClassNames={['action']} title={title} save={saveActionTitle}>
        <section className={'action-details'}>
          <EditAutomationInput initialDescription={title} shouldUseVariables={false} save={saveActionDescription} />
          <section className={'action-detail'}>
            <h5 className={'label'}>{'Triggered when?'}</h5>
            <EditAutomationInput initialDescription={trigger} editable={false} shouldUseVariables={true} />
          </section>
          <section className={'action-detail'}>
            <h5 className={'label'}>{'To who?'}</h5>
            <EditAutomationInput initialDescription={target} editable={false} shouldUseVariables={true} />
          </section>
          <section className={'action-detail'}>
            <h5 className={'label'}>{'What happens?'}</h5>
            <EditAutomationInput initialDescription={output} editable={false} shouldUseVariables={true} />
          </section>
        </section>
      </EditAutomationDropdown>
    </section>
  )
}

EditAutomationAction.displayName = 'EditAutomationAction';
EditAutomationAction.propTypes = propTypes;
export default EditAutomationAction;