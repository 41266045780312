import './LeadsSelection.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AssistantSelection from '../common/AssistantSelection';
import AssistantSelectionSection from '../common/AssistantSelectionSection';
import PlusButton from '../common/PlusButton';
import AssistantDropdown from '../common/AssistantDropdown';
import AssistantTextInput from '../common/AssistantTextInput';
import DeleteButton from '../../../../common/DeleteButton';
import ToggleButton from '../common/ToggleButton';
import { SALES_STAGES_LIST, DUMMY_CAMPAIGNS, APPOINTMENT_DATE_FIELDS } from '../../EngagementConstants';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../../../../context/AuthContext';
import { postActivateAssistant } from '../../../../../../../apis/AssistantApi';

const propTypes = {};

function LeadsSelection({ context, active, setAssistantKey, assistantKey }) {
  const { setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [assistantContext, setAssistantContext] = useState(context);
  const cancel = () => setAssistantKey(null);
  const { autopilot, template, trigger, contactFrequency, appointmentDateFieldName, messages } = assistantContext;
  const setAppointmentDateFieldName = (appointmentDateFieldName) => setAssistantContext({ ...assistantContext, appointmentDateFieldName });
  const setAutopilot = (autopilot) => setAssistantContext({ ...assistantContext, autopilot });
  const setTemplate = (template) => setAssistantContext({ ...assistantContext, template });
  const setTrigger = (trigger) => setAssistantContext({ ...assistantContext, trigger });
  const setContactFrequency = (contactFrequency) => setAssistantContext({ ...assistantContext, contactFrequency });
  const autopilotDescription = autopilot 
    ? 'Rex will create and send messages automatically.'
    : 'Rex will create draft messages for you to approve before sending out.';
  const triggerDescription = 'Select the stage which triggers this assistant.';
  const templateDescription = 'Select and review a template for Rex to use.';
  const maxContactDescription = 'Enter the maximum number of review requests per recipient each year (recommended is 1).';

  // TODO: Implement is integrated check
  const isIntegrated = false;
  const integrationDescription = isIntegrated
    ? 'Select the field that represents ‘appointment date’.'
    : 'Find your CRM from available integrations and click on ‘request’.';
  const integrationText = isIntegrated ? 'Integrated' : 'Not integrated';
  const integrationTextDisplay = <span className={'integration-text'}>{integrationText}</span>;
  const integrationButton = isIntegrated 
    ? <AssistantDropdown
      defaultButtonText={'Select field'}
      options={APPOINTMENT_DATE_FIELDS}
      select={setAppointmentDateFieldName}
      selected={appointmentDateFieldName}
    />
    : <PlusButton onClick={() => navigate('/integrations')} buttonText={'Integrate CRM'} />;

  const activate = (callback) => {
    if (loading) {
      return;
    }
    setLoading(true);
    postActivateAssistant(assistantKey, assistantContext)
      .then((res) => {
        setUser(res.user);
        setLoading(false);
        callback();
      });
  }

  const messageList = messages.map((message, index) => {
    const setMessage = (newMessage) => {
      const newMessages = [...messages];
      newMessages[index] = newMessage;
      setAssistantContext({ ...assistantContext, messages: newMessages });
    }
    const deleteMessage = () => setAssistantContext({
      ...assistantContext, messages: messages.filter((_, i) => i !== index) });
    return (
      <AssistantTextInput
        text={message}
        setText={setMessage}
        placeholder={'Insert your message here'}
        additionalClassNames={['message-input-container']}
        additionalButton={<DeleteButton onClick={deleteMessage} />}
        key={`message-${index}`}
      />
    );
  });
  const addMessage = () => {
    const newMessages = [...messages, ''];
    setAssistantContext({ ...assistantContext, messages: newMessages });
  }

  return (
    <AssistantSelection title={'Leads Assistant'} additionalClassNames={['leads-assistant']} cancel={cancel} activateAssistant={activate} assistantKey={assistantKey}>
      <AssistantSelectionSection
        title={'Auto pilot'}
        description={autopilotDescription}
        toggleButton={<ToggleButton active={autopilot} setActive={setAutopilot} />}
      />
      { autopilot && (
        <AssistantSelectionSection title={'CRM Integration'} description={integrationDescription} toggleButton={integrationTextDisplay}>
          {integrationButton}
        </AssistantSelectionSection>
      )}
      <AssistantSelectionSection title={'Triggered by'} description={triggerDescription}>
        <AssistantDropdown
          defaultButtonText={'Sales stage'}
          selected={trigger}
          select={setTrigger}
          options={SALES_STAGES_LIST}
        />
      </AssistantSelectionSection>
      <AssistantSelectionSection title={'Select template'} description={templateDescription}>
        <AssistantDropdown
          defaultButtonText={'Select template'}
          selected={template}
          select={setTemplate}
          options={DUMMY_CAMPAIGNS}
        />
        <ul className={'message-list'}>{messageList}</ul>
        <PlusButton onClick={addMessage} buttonText={'Add message'} />
      </AssistantSelectionSection>
      <AssistantSelectionSection title={'Max contact frequency'} description={maxContactDescription}>
        <AssistantTextInput placeholder={'Type a number'} text={contactFrequency} setText={setContactFrequency} />
      </AssistantSelectionSection>
    </AssistantSelection>
  );
}

LeadsSelection.displayName = 'LeadsSelection';
LeadsSelection.propTypes = propTypes;
export default LeadsSelection;