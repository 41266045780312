import './ListViewManager.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { MESSAGING_VIEWS, RECORD_TYPES } from '../../MessagingConstants';

import MessagingConsumer from '../../../../../../context/MessagingContext';

import SearchListView from './views/search/SearchListView';
import ContactListView from './views/ContactListView';
import BroadcastListView from './views/broadcasts/BroadcastListView';
import TaskListView from './views/tasks/TaskListView';
import InboxListView from './views/inbox/InboxListView';
import NewBroadcastListView from './views/broadcasts/NewBroadcastListView';

const { SEARCH, BROADCAST, TASKS, INBOX, CUSTOMERS, EMPLOYEES } = MESSAGING_VIEWS;

const propTypes = {};

function ListViewManager({ view, selectedRecord, setRecord, setSelectedRecord, viewFilters, broadcastList, setBroadcastList, setSelected }) {
  const { customers, employees, tasks } = MessagingConsumer()
  let listView = null;

  if (view === SEARCH.key) {
    listView = <SearchListView selectedRecord={selectedRecord} setRecord={setRecord} />
  } else if (view === CUSTOMERS.key) {
    listView = <ContactListView contacts={customers} selectedRecord={selectedRecord} setRecord={setRecord} view={view} viewFilters={viewFilters} />
  } else if (view === EMPLOYEES.key) {
    listView = <ContactListView contacts={employees} selectedRecord={selectedRecord} setRecord={setRecord} view={view} viewFilters={viewFilters} />
  } else if (view === BROADCAST.key && viewFilters === BROADCAST.filters.NEW.key) {
    listView = <NewBroadcastListView broadcastList={broadcastList} setSelected={setSelected} setBroadcastList={setBroadcastList} />
  } else if (view === BROADCAST.key && viewFilters === BROADCAST.filters.UPCOMING.key) {
    listView = <BroadcastListView setRecord={setRecord(RECORD_TYPES.BROADCAST.key)} />
  } else if (view === TASKS.key) {
    listView = <TaskListView tasks={tasks} setRecord={setRecord(RECORD_TYPES.TASK.key)} viewFilters={viewFilters} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} />
  } else if (view === INBOX.key) {
    listView = <InboxListView broadcastList={broadcastList} setSelected={setSelected} setBroadcastList={setBroadcastList} setRecord={setRecord} viewFilters={viewFilters} />
  }

  return (
    <section className={'messaging-body-list-view'}>
      {listView}
    </section>
  )
}

ListViewManager.displayName = 'ListViewManager';
ListViewManager.propTypes = propTypes;
export default ListViewManager;