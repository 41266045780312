import './TimeSelector.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../Constants';
import { HOUR_MAP, MINUTE_MAP } from '../../../MessagingConstants';
import { useOutsideClick } from '../../../../../../../hooks/ClickHooks';
import TimeSelectorPanel from './TimeSelectorPanel';

const DropdownArrow = `${ASSET_CDN_URL}/icons/solid-filled-dropdown-arrow.svg`;

const propTypes = {};

function TimeSelector({ time, selectHour, selectMinute, toggleAmPm }) {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const timeSelectorRef = useRef(null);
  useOutsideClick(timeSelectorRef, () => closeModal());

  const ampm = time.am ? 'AM' : 'PM';

  return (
    <section className={'time-picker-container'} ref={timeSelectorRef}>
      <button className={'time-picker-button'} onClick={() => setOpen(!open)} type={'button'}>
        <span className={'time-display'}>{`${HOUR_MAP[time.hour].text}:${MINUTE_MAP[time.minute].text}`}</span>
        <img src={DropdownArrow} alt={''} className={`rec-header-icon ${open ? 'open' : ''}`} />
      </button>
      <button className={'am-pm-button'} onClick={toggleAmPm} type={'button'}>
        {ampm}
      </button>
      {open && 
        <TimeSelectorPanel selectedHour={time.hour} setSelectedHour={selectHour} selectedMinute={time.minute} setSelectedMinute={selectMinute} />
      }
    </section>
  )
}

TimeSelector.displayName = 'TimeSelector';
TimeSelector.propTypes = propTypes;
export default TimeSelector;