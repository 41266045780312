import React from 'react';
import PropTypes from 'prop-types';

import { COMMUNICATION_TABS } from '../CommunicationsConstants';
import CommunicationsPageWrapper from '../CommunicationsPageWrapper';

const { ENGAGEMENTS } = COMMUNICATION_TABS;

const propTypes = {};

function EngagementsPageWrapper({ children, showTabs = true, additionalClassNames = [], additionalOptions = [] }) {
  const options = [{ displayName: 'Engagements centre', href: '/engagements' }, ...additionalOptions];
  return (
    <CommunicationsPageWrapper
      additionalClassNames={['assistant-management-page', ...additionalClassNames]}
      additionalOptions={options}
      selectedTab={ENGAGEMENTS}
      showNavigationTabs={showTabs}
    >
      { children }
    </CommunicationsPageWrapper>
  );
}

EngagementsPageWrapper.displayName = 'EngagementsPageWrapper';
EngagementsPageWrapper.propTypes = propTypes;
export default EngagementsPageWrapper;