import SELECTED_EMAIL_ICON from './assets/selected-email-icon.svg';
import UNSELECTED_EMAIL_ICON from './assets/unselected-email-icon.svg';
import SELECTED_SMS_ICON from './assets/selected-sms-icon.svg';
import UNSELECTED_SMS_ICON from './assets/unselected-sms-icon.svg';
import SELECTED_WHATSAPP_ICON from './assets/selected-whatsapp-icon.svg';
import UNSELECTED_WHATSAPP_ICON from './assets/unselected-whatsapp-icon.svg';
import SELECTED_FACEBOOK_ICON from './assets/selected-facebook-icon.svg';
import UNSELECTED_FACEBOOK_ICON from './assets/unselected-facebook-icon.svg';
import SELECTED_INSTAGRAM_ICON from './assets/selected-instagram-icon.svg';
import UNSELECTED_INSTAGRAM_ICON from './assets/unselected-instagram-icon.svg';
import SELECTED_LINKEDIN_ICON from './assets/selected-linkedin-icon.svg';
import UNSELECTED_LINKEDIN_ICON from './assets/unselected-linkedin-icon.svg';
import SELECTED_VOICEMAIL_ICON from './assets/selected-voicemail-icon.svg';
import UNSELECTED_VOICEMAIL_ICON from './assets/unselected-voicemail-icon.svg';
import FacebookPreview from './previews/FacebookPreview';
import InstagramPreview from './previews/InstagramPreview';
import LinkedInPreview from './previews/LinkedInPreview';
import SmsPreview from './previews/SmsPreview';
import WhatsAppPreview from './previews/WhatsAppPreview';
import EmailPreview from './previews/EmailPreview';
import VoicemailPreview from './previews/VoicemailPreview';

export const CAMPAIGN_TYPES = {
  CONTACT_LENS_RE_ACTIVATION: 'CONTACT_LENS_RE_ACTIVATION',
  INCREASE_CUSTOMER_RECALLS: 'INCREASE_CUSTOMER_RECALLS',
  GET_NEW_GOOGLE_REVIEWS: 'GET_NEW_GOOGLE_REVIEWS',
};

export const CHANNEL_TYPES = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP',
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  LINKEDIN: 'LINKEDIN',
  VOICEMAIL: 'VOICEMAIL',
};

export const CAMPAIGN_PAGE_TYPES = {
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
  LIBRARY: 'LIBRARY',
};

const { PUBLISHED, DRAFT, LIBRARY } = CAMPAIGN_PAGE_TYPES;

const { EMAIL, SMS, WHATSAPP, FACEBOOK, INSTAGRAM, LINKEDIN, VOICEMAIL } = CHANNEL_TYPES;

const { CONTACT_LENS_RE_ACTIVATION, INCREASE_CUSTOMER_RECALLS, GET_NEW_GOOGLE_REVIEWS } = CAMPAIGN_TYPES;

export const CAMPAIGN_LIST = [
  {
    key: CONTACT_LENS_RE_ACTIVATION,
    title: 'Contact lens re-activation campaign',
    description: 'The purpose of this campaign is to generate increased contact lens orders from customers that haven\'t purchased in the last few months.',
    expectedResults: '5 to 8 contact lens orders per 100 recipients',
    urlSuffix: 'contact-lens-re-activation',
    channels: [EMAIL, SMS, WHATSAPP, FACEBOOK, INSTAGRAM, LINKEDIN, VOICEMAIL],
    previews: {
      [WHATSAPP]: {
        key: WHATSAPP,
        message: 'Hi @customerFirstName. The team at WorkRex here. I noticed it\'s been 12 months or more since your last visit, so wanted to ask if you\'d like me to help book you into an eye check up? We recommend it frequently to maintain eye health. Thanks!',
        attachment: null
      },
      [SMS]: {
        key: SMS,
        message: 'Hi @customerFirstName. The team at WorkRex here. I noticed it\'s been 12 months or more since your last visit, so wanted to ask if you\'d like me to help book you into an eye check up? We recommend it frequently to maintain eye health. Thanks!',
        attachment: null
      },
    },
    type: LIBRARY,
    tag: 'Engage customers',
    label: 'Campaign library - Optical practice',
  },
  {
    key: INCREASE_CUSTOMER_RECALLS,
    title: 'Increase Customer Recalls',
    description: 'The purpose of this campaign is to encourage existing customers to return with a genuine reminder message',
    expectedResults: '15 to 30 appointment bookings per 100 recipients',
    urlSuffix: 'increase-customer-recalls',
    channels: [EMAIL, SMS, WHATSAPP, FACEBOOK, INSTAGRAM, LINKEDIN, VOICEMAIL],
    previews: {
      [WHATSAPP]: {
        key: WHATSAPP,
        message: 'Hi @customerFirstName. The team at WorkRex here. I noticed it\'s been 12 months or more since your last visit, so wanted to ask if you\'d like me to help book you into an eye check up? We recommend it frequently to maintain eye health. Thanks!',
        attachment: null
      },
      [SMS]: {
        key: SMS,
        message: 'Hi @customerFirstName. The team at WorkRex here. I noticed it\'s been 12 months or more since your last visit, so wanted to ask if you\'d like me to help book you into an eye check up? We recommend it frequently to maintain eye health. Thanks!',
        attachment: null
      },
    },
    type: LIBRARY,
    tag: 'Engage customers',
    label: 'Campaign library - All industries',
  },
  {
    key: GET_NEW_GOOGLE_REVIEWS,
    title: 'Get New Google Reviews',
    description: 'The purpose of this campaign is to generate more google reviews with a simple, authentic message.',
    expectedResults: '6 to 8 google reviews per 100 recipients',
    urlSuffix: 'get-new-google-reviews',
    channels: [EMAIL, SMS, WHATSAPP, FACEBOOK, INSTAGRAM, LINKEDIN, VOICEMAIL],
    previews: {
      [WHATSAPP]: {
        key: WHATSAPP,
        message: 'Thanks for visiting @storeName! @managerFirstName at @storeName here. We strive to provide the best quality eyecare. If you had a good experience, could you take 30 seconds to add a google review? You can do so here: [insert review url]. Thanks!',
        attachment: null
      },
      [SMS]: {
        key: SMS,
        message: 'Thanks for visiting @storeName! @managerFirstName at @storeName here. We strive to provide the best quality eyecare. If you had a good experience, could you take 30 seconds to add a google review? You can do so here: [insert review url]. Thanks!',
        attachment: null
      },
    },
    type: LIBRARY,
    tag: 'Grow customers',
    label: 'Campaign library - All industries',
  },
];

export const CHANNELS = {
  [EMAIL]: {
    key: EMAIL,
    displayText: 'Email',
    selectedIcon: SELECTED_EMAIL_ICON,
    unselectedIcon: UNSELECTED_EMAIL_ICON,
    disabled: true,
    tooltipText: 'Send an individual email to your recipients, with multi-media attachments',
  },
  [SMS]: {
    key: SMS,
    displayText: 'SMS',
    selectedIcon: SELECTED_SMS_ICON,
    unselectedIcon: UNSELECTED_SMS_ICON,
    disabled: false,
    tooltipText: 'Instantly message your recipients, excluding multi-media',
  },
  [WHATSAPP]: {
    key: WHATSAPP,
    displayText: 'WhatsApp',
    selectedIcon: SELECTED_WHATSAPP_ICON,
    unselectedIcon: UNSELECTED_WHATSAPP_ICON,
    disabled: false,
    tooltipText: 'Instantly message your recipients, including multi-media'
  },
  [FACEBOOK]: {
    key: FACEBOOK,
    displayText: 'Facebook',
    selectedIcon: SELECTED_FACEBOOK_ICON,
    unselectedIcon: UNSELECTED_FACEBOOK_ICON,
    disabled: true,
    tooltipText: 'Publish a post on your Facebook page',
  },
  [INSTAGRAM]: {
    key: INSTAGRAM,
    displayText: 'Instagram',
    selectedIcon: SELECTED_INSTAGRAM_ICON,
    unselectedIcon: UNSELECTED_INSTAGRAM_ICON,
    disabled: true,
    tooltipText: 'Publish a post on your Instagram page',
  },
  [LINKEDIN]: {
    key: LINKEDIN,
    displayText: 'LinkedIn',
    selectedIcon: SELECTED_LINKEDIN_ICON,
    unselectedIcon: UNSELECTED_LINKEDIN_ICON,
    disabled: true,
    tooltipText: 'Publish a post on your LinkedIn page',
  },
  [VOICEMAIL]: {
    key: VOICEMAIL,
    displayText: 'Voicemail',
    selectedIcon: SELECTED_VOICEMAIL_ICON,
    unselectedIcon: UNSELECTED_VOICEMAIL_ICON,
    disabled: true,
    tooltipText: 'Leave a voicemail without calling',
  },
};

export const EMPTY_CHANNEL_PREVIEW = {
  [EMAIL]: {
    key: EMAIL,
    subjectLine: '',
    message: '',
  },
  [SMS]: {
    key: SMS,
    message: '',
    attachment: null,
  },
  [WHATSAPP]: {
    key: WHATSAPP,
    message: '',
    attachment: null,
  },
  [FACEBOOK]: {
    key: FACEBOOK,
    caption: '',
    content: null,
  },
  [INSTAGRAM]: {
    key: INSTAGRAM,
    caption: '',
    content: null,
  },
  [LINKEDIN]: {
    key: LINKEDIN,
    caption: '',
    content: null,
  },
  [VOICEMAIL]: {
    key: VOICEMAIL,
    content: null,
  }
};

export const PREVIEWS = {
  [EMAIL]: {
    key: EMAIL,
    displayText: 'Email',
    PreviewComponent: EmailPreview,
  },
  [SMS]: {
    key: SMS,
    displayText: 'SMS',
    PreviewComponent: SmsPreview,
  },
  [WHATSAPP]: {
    key: WHATSAPP,
    displayText: 'WhatsApp',
    PreviewComponent: WhatsAppPreview,
  },
  [FACEBOOK]: {
    key: FACEBOOK,
    displayText: 'Facebook',
    PreviewComponent: FacebookPreview,
  },
  [INSTAGRAM]: {
    key: INSTAGRAM,
    displayText: 'Instagram',
    PreviewComponent: InstagramPreview,
  },
  [LINKEDIN]: {
    key: LINKEDIN,
    displayText: 'LinkedIn',
    PreviewComponent: LinkedInPreview,
  },
  [VOICEMAIL]: {
    key: VOICEMAIL,
    displayText: 'Voicemail',
    PreviewComponent: VoicemailPreview,
  }
}

export const CAMPAIGN_TYPE_URL_SUFFIX = {
  [PUBLISHED]: 'published',
  [DRAFT]: 'draft',
  [LIBRARY]: 'library',
};

export const EMPTY_CAMPAIGN = {
  name: '',
  description: '',
  objective: '',
  offers: [],
  channels: [],
  channelOutputs: {},
}


export const PENDING_CHANNEL_LIST = [EMAIL, SMS, WHATSAPP];
export const AVAILABLE_CHANNEL_LIST = [SMS, WHATSAPP];