import './BroadcastCard.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { BROADCAST_REPEAT_MAP } from '../../../../MessagingConstants';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';
import { RECURRENCE_OPTIONS } from '../../../actions/broadcast/BroadcastConstants';

import GenericLabel from '../GenericLabel';
import DotBricksLoading from '../../../../../../../common/DotBricksLoading';
import useAuth from '../../../../../../../../context/AuthContext';

const propTypes = {};

function BroadcastCard({ broadcast, setRecord }) {
  const { user } = useAuth();
  const { recurrence, recipients, scheduledSendDate, paused } = broadcast;
  const { templates } = MessagingConsumer();
  const formattedNextBroadcastDate = scheduledSendDate === null ? 'None' : new Date(scheduledSendDate).toLocaleDateString('en-au', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  if (templates === null) {
    return <DotBricksLoading isFixed={false} />
  }

  const nextBroadcastDateString = paused ? 'Paused' : formattedNextBroadcastDate;
  const broadcastDisplayName = broadcast.name;
  const frequencyText = recurrence ? RECURRENCE_OPTIONS[recurrence].displayText : 'Does not repeat';

  return (
    <button className={'broadcast-card-container'} onClick={() => setRecord(broadcast)} type={'button'}>
      <section className={'broadcast-name-container'}>
        <span className={'broadcast-name'}>{broadcastDisplayName}</span>
      </section>
      <section className={'broadcast-schedule-container'}>
        <span className={'broadcast-schedule'}>{`Next scheduled broadcast date: ${nextBroadcastDateString}`}</span>
      </section>
      <section className={'broadcast-tags-container'}>
        <GenericLabel additionalClassNames={['broadcast-count']} text={`Broadcasted to ${recipients.length} people`} />
        <GenericLabel additionalClassNames={['broadcast-frequency']} text={frequencyText} />
      </section>
    </button>
  )
}

BroadcastCard.displayName = 'BroadcastCard';
BroadcastCard.propTypes = propTypes;
export default BroadcastCard;