export const EMPLOYEE_HEADERS = [
  { display: 'Employee First Name', key: 'firstName' },
  { display: 'Employee Last Name', key: 'lastName' },
  { display: 'Role type', key: 'roleType' },
  { display: 'Groups', key: 'groups' },
  { display: 'Mobile No.', key: 'mobileNumber' },
  { display: 'Email', key: 'emailAddress' },
  { display: 'Manager Name', key: 'managers' },
  { display: 'Address', key: 'address' },
  { display: 'Birthdate', key: 'birthday' },
  { display: 'Status', key: 'status' },
  { display: 'Deliverability', key: 'deliverability' },
  { display: 'User Access', key: 'userAccess' },
  { display: 'Onboarding', key: 'onboarding' },
];

export const CUSTOMER_TEXT_HEADERS = [
  { display: 'Customer first name', key: 'firstName' },
  { display: 'Customer last name', key: 'lastName' },
  { display: 'Groups', key: 'groups' },
  { display: 'Mobile No.', key: 'mobileNumber' },
  { display: 'Email', key: 'emailAddress' },
  { display: 'Address', key: 'address' },
  { display: 'Birthdate', key: 'formattedBirthday' },
  { display: 'Source', key: 'source' },
  { display: 'Favourite Brands', key: 'favouriteBrands' },
  { display: 'Customer Segment Category', key: 'customerSegmentCategory' },
  // { display: 'Sales stage', key: 'salesStage' },
];

export const CUSTOMER_STATUS_HEADERS = [
  { display: 'Status', key: 'status' },
  { display: 'Deliverability', key: 'deliverability' },
]

export const SALES_STAGE = {
  LEAD: {
    key: 'LEAD',
    displayText: 'Lead',
  },
  INSPECTION: {
    key: 'INSPECTION',
    displayText: 'Inspection',
  },
  PAYMENT: {
    key: 'PAYMENT',
    displayText: 'Payment',
  },
  DELIVERY: {
    key: 'DELIVERY',
    displayText: 'Delivery',
  },
  CONSIDERATION: {
    key: 'CONSIDERATION',
    displayText: 'Consideration',
  },
}

export const RECIPIENT_ACTIVE_STATUSES = {
  ACTIVE: {
    key: 'Active',
    displayText: 'Active',
  },
  NOT_ACTIVE: {
    key: 'Not active',
    displayText: 'Not active',
  },
  DELETE: {
    key: 'Delete',
    displayText: 'Delete',
  }
};

export const DELIVERABILITY = {
  SUBSCRIBED: {
    key: 'Subscribed',
    displayText: 'Subscribed'
  },
  OPTED_OUT: {
    key: 'Opted out',
    displayText: 'Opted out',
  },
  UNDELIVERABLE: {
    key: 'Undeliverable',
    displayText: 'Undeliverable',
  },
}

export const RECIPIENT_USER_ACCESS = {
  NONE: {
    key: 'NONE',
    displayText: 'None',
    modalDisplayText: 'None',
  },
  TEAM_MEMBER: {
    key: 'TEAM_MEMBER',
    displayText: 'Team member',
    modalDisplayText: 'Team Member',
  },
  MANAGER: {
    key: 'MANAGER',
    displayText: 'Manager',
    modalDisplayText: 'Manager',
  },
  ADMIN: {
    key: 'ADMIN',
    displayText: 'Admin',
    modalDisplayText: 'Admin',
  }
};

export const RECIPIENT_ONBOARDING = {
  ONBOARD: 'Onboard',
  SENT: 'Sent',
  ONBOARDED: 'Onboarded',
}

const { ACTIVE, NOT_ACTIVE, DELETE } = RECIPIENT_ACTIVE_STATUSES;

const { SUBSCRIBED, OPTED_OUT, UNDELIVERABLE } = DELIVERABILITY;

export const EMPTY_RECIPIENT = {
  firstName: '',
  lastName: '',
  roleType: '',
  groups: [],
  mobileNumber: '',
  emailAddress: '',
  isManager: false,
  address: '',
  birthday: null,
  managers: [],
  status: ACTIVE.key,
  deliverability: SUBSCRIBED.key,
  userAccess: RECIPIENT_USER_ACCESS.NONE.key,
  onboarding: RECIPIENT_ONBOARDING.ONBOARD,
};

export const EMPTY_CUSTOMER = {
  customerFirstName: '',
  customerLastName: '',
  groups: [],
  mobileNumber: '',
  emailAddress: '',
  address: '',
  birthday: null,
  source: '',
  favouriteBrands: '',
  managers: [],
  status: ACTIVE.key,
  deliverability: SUBSCRIBED.key,
};