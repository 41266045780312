import './InteractionsPage.scss';

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import InteractionsList from './InteractionsList';
import DotBricksLoading from '../../../../../../../common/DotBricksLoading';
import { useOutsideClick } from '../../../../../../../../hooks/ClickHooks';
import InteractionsFlatFilePortal from '../../../../../recipients/flatFileContactImport/InteractionsFlatFile';

const propTypes = {};

function InteractionsPage({ selectedRecord, createEditInteraction, setSelectedRecord }) {
  const [open, setOpen] = useState(false);
  const [flatfileOpen, setFlatfileOpen] = useState(false);
  const interactionRef = useRef(null);
  const { interactions } = selectedRecord;
  useOutsideClick(interactionRef, () => setOpen(false));
  if (!interactions) {
    return <DotBricksLoading isFixed={false} />
  }

  const upcomingInteractions = interactions.filter(interaction => interaction.startDateTime > Date.now()).sort((a, b) => a.startDateTime - b.startDateTime);
  const pastInteractions = interactions.filter(interaction => interaction.startDateTime <= Date.now()).sort((a, b) => b.startDateTime - a.startDateTime);

  const addManually = () => {
    setOpen(false);
    createEditInteraction(true)(null)();
  }
  const toggleDropdown = () => setOpen(!open);
  const openInteractionFlatfile = () => {
    setOpen(false);
    setFlatfileOpen(true);
  };
  return (
    <section className={'interactions-inbox'}>
      { flatfileOpen && <InteractionsFlatFilePortal /> }
      <section className={'interaction-header'}>
        <section className={'new-interaction-dropdown'} ref={interactionRef}>
          <button className={'new-interaction-button'} onClick={toggleDropdown} type={'button'}>{'New interaction +'}</button>
          { open && (
            <ul className={'dropdown-options'}>
              <li className={'dropdown-option'}>
                <button className={'dropdown-option-button'} onClick={addManually} type={'button'}>
                  {'Add manually'}
                </button>
              </li>
              <li className={'dropdown-option'}>
                <button className={'dropdown-option-button'} onClick={openInteractionFlatfile} type={'button'}>
                  {'Add in bulk'}
                </button>
              </li>
            </ul>
            )
          }
        </section>
      </section>
      <section className={'interaction-list'}>
        {upcomingInteractions.length > 0 && <InteractionsList interactions={upcomingInteractions} subtitle={'Upcoming'} createEditInteraction={createEditInteraction} setSelectedRecord={setSelectedRecord} />}
        {pastInteractions.length > 0 && <InteractionsList interactions={pastInteractions} subtitle={'Past'} createEditInteraction={createEditInteraction} setSelectedRecord={setSelectedRecord} />}
      </section>
    </section>
  )
}

InteractionsPage.displayName = 'InteractionsPage';
InteractionsPage.propTypes = propTypes;
export default InteractionsPage;