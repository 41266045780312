import './BroadcastsDisplay.scss';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import useAuth from '../../../../../context/AuthContext';
import { createCsv, downloadCsv } from '../../ManagerUtils';
import AnalyticsTable from '../../common/AnalyticsTable';

const propTypes = {};

const HEADERS = [
  {
    display: 'Broadcast name',
    key: 'broadcastName',
  },
  {
    display: 'Date sent',
    key: 'sentDate',
  },
  {
    display: 'Channel',
    key: 'channel',
  },
  {
    display: 'Total Sent',
    key: 'sentCount',
  },
  {
    display: 'Delivered',
    key: 'deliveredCount',
  },
  {
    display: 'Read',
    key: 'readCount',
  },
  {
    display: 'Replied',
    key: 'repliedCount',
  },
  {
    display: 'Responses',
    key: 'responses',
  },
];

const nth = (d) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
};

function BroadcastsDisplay() {
  const { user, } = useAuth();
  const userAnalytics = user.broadcastAnalytics
    .sort((a, b) => b.sentDate - a.sentDate)
    .map(({ name, sentDate, channel, recipientMap, sentCount, deliveredCount, readCount, repliedCount }) => {
      const titleNode = <section className={`title-button`}>{name}</section>;
      const dateSentDate = new Date(sentDate);
      const day = `${dateSentDate.getDate()}${nth(dateSentDate.getDate())}`;
      const month = dateSentDate.toLocaleDateString('en-AU', { month: 'short' });
      const year = dateSentDate.getFullYear().toString().substr(-2);
      const recipients = Object.values(recipientMap);
      const download = () => {
        const csv = createCsv(Object.values(recipientMap));
        downloadCsv(csv, `${name}-${day}-${month}-${year}.csv`);
      }
      const responsesNode = <button className={`download-button ${recipients.length === 0 ? 'no-responses' : ''}`} disabled={recipients.length === 0} onClick={download}>{'Download'}</button>;
      return {
        broadcastName: titleNode,
        sentDate: `${day} ${month} ${year}`,
        channel,
        sentCount,
        deliveredCount,
        readCount,
        repliedCount,
        responses: responsesNode,
      }
    });

  return (
    <section className={'templates-display'}>
      <AnalyticsTable headers={HEADERS} rows={userAnalytics} noRowsText={'No broadcasts have been sent out yet!'}>
      </AnalyticsTable>
    </section>
  )
}

BroadcastsDisplay.displayName = 'BroadcastsDisplay';
BroadcastsDisplay.propTypes = propTypes;
export default BroadcastsDisplay;