import './AutomationActionCards.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AutomationActionCard from './AutomationActionCard';

import LEFT_CARET_ICON from '../../assets/white-left-caret-icon.svg';
import RIGHT_CARET_ICON from '../../assets/white-right-caret-icon.svg';

const propTypes = {};

function AutomationActionCards({ actions }) {
  const [actionIndex, setActionIndex] = useState(0);
  const action = actions[actionIndex];
  const { title, target, trigger, output } = action;
  const moveLeft = () => {
    if (actionIndex === 0) {
      setActionIndex(actions.length - 1);
    }
    else {
      setActionIndex(actionIndex - 1);
    }
  }
  const moveRight = () => {
    if (actionIndex === actions.length - 1) {
      setActionIndex(0);
    }
    else {
      setActionIndex(actionIndex + 1);
    }
  }
  return (
    <section className={'automation-action-cards-container'}>
      <section className={'action-button-container left'}>
        <button className={'automation-action-button left'} onClick={moveLeft}>
          <img className={'white-caret'} src={LEFT_CARET_ICON} />
        </button>
      </section>
      <ul className={'automation-action-cards'}>
        <AutomationActionCard
          title={title}
          actionNumber={actionIndex + 1}
          totalActions={actions.length}
          target={target}
          trigger={trigger}
          output={output}
        />
      </ul>
      <section className={'action-button-container'}>
        <button className={'automation-action-button right'} onClick={moveRight}>
          <img className={'white-caret'} src={RIGHT_CARET_ICON} />
        </button>
      </section>
    </section>
  )
}

AutomationActionCards.displayName = 'AutomationActionCards';
AutomationActionCards.propTypes = propTypes;
export default AutomationActionCards;