import './AutomationsPageWrapper.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { COMMUNICATION_TABS } from '../CommunicationsConstants';
import CommunicationsPageWrapper from '../CommunicationsPageWrapper';

const { AUTOMATIONS } = COMMUNICATION_TABS;

const propTypes = {};

function AutomationsPageWrapper({ children, showTabs = true }) {
  const location = useLocation();
  const options = [{ displayName: 'Automations centre', href: '/automations' }];
  const { pathname } = location;
  if (pathname.includes('available')) {
    options.push({ displayName: 'Your available automations', href: '/automations/available' });
  }
  else if (pathname.includes('request')) {
    options.push({ displayName: 'Request a custom automation', href: '/automations/request' });
  }
  else if (pathname.includes('library')) {
    options.push({ displayName: 'The WorkRex automation library', href: '/automations/library' });
  }

  if (pathname.includes('view') || pathname.includes('edit')) {
    options.push({ displayName: 'Your available automations', href: '/automations/available' });
    options.push({ displayName: 'View automation', href: '/automations/view' });
  }

  if (pathname.includes('edit')) {
    options.push({ displayName: 'Edit automation', href: '/automations/edit' });
  }
  return (
    <CommunicationsPageWrapper
      additionalClassNames={['automations-page']}
      additionalOptions={options}
      selectedTab={AUTOMATIONS}
      showNavigationTabs={showTabs}
    >
      { children }
    </CommunicationsPageWrapper>
  );
}

AutomationsPageWrapper.displayName = 'AutomationsPageWrapper';
AutomationsPageWrapper.propTypes = propTypes;
export default AutomationsPageWrapper;