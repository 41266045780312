import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import AccountPageWrapper from './AccountPageWrapper';

import { PAGES } from './AccountConstants';

import AccountNavigationTabs from './AccountNavigationTabs';

const { EDIT_PAGE, GROUPS_PAGE, INTEGRATIONS_PAGE } = PAGES;

const propTypes = {};

function AccountPage() {
  const { pathname } = useLocation();
  let selectedTab = EDIT_PAGE;
  const options = [];
  if (pathname.includes('edit')) {
    selectedTab = EDIT_PAGE;
    options.push({ displayName: 'Edit account', href: '/account/edit' });
  }
  else if (pathname.includes('groups')) {
    selectedTab = GROUPS_PAGE;
    options.push({ displayName: 'Groups', href: '/account/groups' });
  }
  else if (pathname.includes('integrations')) {
    selectedTab = INTEGRATIONS_PAGE;
    options.push({ displayName: 'Integrations', href: '/account/integrations' });
  }
  return (
    <AccountPageWrapper additionalOptions={options}>
      <AccountNavigationTabs selectedTab={selectedTab} />
      <Outlet />
    </AccountPageWrapper>
  )
}

AccountPage.displayName = 'AccountPage';
AccountPage.propTypes = propTypes;
export default AccountPage;