import './TemplateListContainer.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TemplateList from './TemplateList';
import TemplateSidebar from './TemplateSidebar';
import { TEMPLATE_PAGE_TYPES } from '../TemplatesConstants';

const { LIBRARY } = TEMPLATE_PAGE_TYPES;

const propTypes = {};

function TemplateListContainer({ templates = [], href, templatePageType }) {
  const [searchText, setSearchText] = useState('');
  const isLibrary = templatePageType === LIBRARY;
  const [libraryTypeFilter, setLibraryTypeFilter] = useState([]);
  return (
    <section className={'template-list-container'}>
      <TemplateSidebar
        href={href}
        searchText={searchText}
        setSearchText={setSearchText}
        isLibrary={isLibrary}
        libraryTypeFilter={libraryTypeFilter}
        setLibraryTypeFilter={setLibraryTypeFilter}
      />
      <TemplateList
        templates={templates}
        templatePageType={templatePageType}
        searchText={searchText}
        libraryTypeFilter={isLibrary ? libraryTypeFilter : []}
      />
    </section>
  );
}

TemplateListContainer.displayName = 'TemplateListContainer';
TemplateListContainer.propTypes = propTypes;
export default TemplateListContainer;