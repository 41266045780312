import './GenericLabel.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function GenericLabel({ children, additionalClassNames = [], text }) {

  return (
    <section className={`generic-label ${additionalClassNames.join(' ')}`}>
      <span className={'generic-label-text'}>{text}</span>
      {children}
    </section>
  )
}

GenericLabel.displayName = 'GenericLabel';
GenericLabel.propTypes = propTypes;
export default GenericLabel;