import { RESPONSE_TYPES } from './TemplatesConstants';

const { NONE_REQUIRED } = RESPONSE_TYPES;

export const createNewEmptySection = (index) => {
  return {
    title: `Section ${index}`,
    message: '',
    responseExpected: NONE_REQUIRED,
    questionIntent: null,
    attachment: null,
  }
};
