import { useEffect, useState } from 'react';

/*
 * View height bug on mobile.
 * See https://css-tricks.com/the-trick-to-viewport-units-on-mobile/#css-custom-properties-the-trick-to-correct-sizing
 * Use resize event listeners if user resizes window.
 */
const MIN_HEIGHT_ADJUSTMENT = 0.01;
const adjustMobileViewHeight = () => {
  const vh = window.innerHeight * MIN_HEIGHT_ADJUSTMENT;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

/**
 * TODO: Add documentation
 */
export const useAdjustMobileViewHeight = () => {
  useEffect(() => {
    window.addEventListener('resize', adjustMobileViewHeight);
    adjustMobileViewHeight();
    return () => window.removeEventListener('resize', adjustMobileViewHeight);
  }, []);
};

/**
 * TODO: Add documentation
 * @param {*} query 
 * @returns 
 */
export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);
  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);
  return matches;
};
