import './IntegrationModal.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function IntegrationModal({ additionalClassNames, children, close }) {
  return (
    <section className={`integration-modal-container ${additionalClassNames.join(' ')}`}>
      <section className={'integration-modal'}>
        <button className={'close-modal-button very-dark'} onClick={close} type={'button'}>
          {'×'}
        </button>
        <section className={'integration-modal-content'}>
          {children}
        </section>
      </section>
    </section>
  );
}

IntegrationModal.displayName = 'IntegrationModal';
IntegrationModal.propTypes = propTypes;
export default IntegrationModal;