import './MessagingNavDropdownOption.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { ASSET_CDN_URL } from '../../../../../../../Constants';
import { useNavigate } from 'react-router-dom';

const messagingNavigationSubCategoryIcon = `${ASSET_CDN_URL}/icons/rex-messaging-navigation-sub-category-icon.svg`;

const propTypes = {};

function MessagingNavDropdownOption({ count, option, viewFilters, setViewFilters, setSelectedRecord }) {
  const navigate = useNavigate();
  const answerSelected = viewFilters === option.key;
  const setFilters = () => {
    setViewFilters(option.key);
    if (!answerSelected) {
      setSelectedRecord(null);

      navigate(option.url);
    }
  }

  return (
    <button className={`navigation-dropdown-button`} onClick={setFilters} type={'button'}>
      <section className={'navigation-filter'}>
        <img alt={'sub-nav-icon'} className={'sub-nav-icon'} src={messagingNavigationSubCategoryIcon} />
        <span className={`navigation-dropdown-button-text ${answerSelected ? 'selected' : ''}`}>{option.text}</span>
      </section>
      {count > 0 && <span className={'filter-count'}>{count}</span>}
    </button>
  )
}

MessagingNavDropdownOption.displayText = 'MessagingNavDropdownOption';
MessagingNavDropdownOption.propTypes = propTypes;
export default MessagingNavDropdownOption;