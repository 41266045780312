import './MarketingSelection.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AssistantSelection from '../common/AssistantSelection';
import AssistantSelectionSection from '../common/AssistantSelectionSection';
import ChannelSelectionOptions from '../../../campaigns/common/ChannelSelectionOptions';
import TeamPicker from '../common/TeamPicker';
import AssistantDropdown from '../common/AssistantDropdown';
import ToggleButton from '../common/ToggleButton';
import { DUMMY_TEAM_MEMBERS, WEEKLY_FREQUENCY_OPTIONS } from '../../EngagementConstants';
import useAuth from '../../../../../../../context/AuthContext';
import { postActivateAssistant } from '../../../../../../../apis/AssistantApi';

import { CHANNEL_TYPES } from '../../../campaigns/CampaignConstants';


const propTypes = {};

function MarketingSelection({ context, active, setAssistantKey, assistantKey }) {
  const availableChannels = Object.values(CHANNEL_TYPES);
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const teamMembers = user && user.recipients ? user.recipients : DUMMY_TEAM_MEMBERS;
  const [assistantContext, setAssistantContext] = useState(context);
  const cancel = () => setAssistantKey(null);
  const { selectedChannels, shouldUseStoreContent, frequency, teamMemberIds } = assistantContext;
  const [selectedTeamMemberIds, setSelectedTeamMemberIds] = useState(teamMemberIds);
  const setFrequency = (frequency) => setAssistantContext({ ...assistantContext, frequency });
  const setSelectedChannels = (channels) => setAssistantContext({ ...assistantContext, selectedChannels: channels });
  const setShouldUseStoreContent = (shouldUseStoreContent) => setAssistantContext({ ...assistantContext, shouldUseStoreContent });
  const generatedContentDescription = shouldUseStoreContent
    ? 'Rex will ask your team members to send in content such as images or video clips of frame styles.'
    : 'Rex will only pull images and content from the WorkRex content library.'
  const channelDescription = 'Rex will create content for your selected channels.';
  const frequencyDescription = 'Choose how frequently you’d like Rex to create campaigns.';
  const activate = (callback) => {
    if (loading) {
      return;
    }
    setLoading(true);
    postActivateAssistant(assistantKey, { ...assistantContext, teamMemberIds: selectedTeamMemberIds })
      .then((res) => {
        setUser(res.user);
        setLoading(false);
        callback();
      });
  }
  return (
    <AssistantSelection title={'Marketing Assistant'} additionalClassNames={['marketing-assistant']} cancel={cancel} activateAssistant={activate} assistantKey={assistantKey}>
      <AssistantSelectionSection
        title={'Do you want store generated content?'}
        description={generatedContentDescription}
        toggleButton={<ToggleButton active={shouldUseStoreContent} setActive={setShouldUseStoreContent} />}
      >
        { shouldUseStoreContent && 
          <TeamPicker
            selectedTeamMemberIds={selectedTeamMemberIds}
            setSelectedTeamMemberIds={setSelectedTeamMemberIds}
            teamMembers={teamMembers}
          /> 
        }
      </AssistantSelectionSection>
      <AssistantSelectionSection title={'Select channels'} description={channelDescription}>
        <ChannelSelectionOptions
          availableOptions={availableChannels}
          selectedChannels={selectedChannels}
          setSelectedChannels={setSelectedChannels}
          showTitle={false}
          enableAll={true}
        />
      </AssistantSelectionSection>
      <AssistantSelectionSection title={'Select frequency'} description={frequencyDescription}>
        <AssistantDropdown
          defaultButtonText={'Frequency'}
          selected={frequency}
          select={setFrequency}
          options={WEEKLY_FREQUENCY_OPTIONS}
        />
      </AssistantSelectionSection>
    </AssistantSelection>
  );
}

MarketingSelection.displayName = 'MarketingSelection';
MarketingSelection.propTypes = propTypes;
export default MarketingSelection;