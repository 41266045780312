import './LinkCopiedModal.scss';

import React from 'react';
import WorkRexModal from '../../../common/WorkRexModal';

const propTypes = {};

function LinkCopiedModal({ close }) {
  return (
    <WorkRexModal close={close} additionalClassNames={['link-copied-modal']}>
      <h2 className={'modal-title'}>{'Link copied to clipboard'}</h2>
      <button className={'close-button'} onClick={close}>{'Close'}</button>
    </WorkRexModal>
  );
};

LinkCopiedModal.propTypes = propTypes;
LinkCopiedModal.displayName = 'LinkCopiedModal';
export default LinkCopiedModal;