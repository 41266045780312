import './ConfirmBroadcastModal.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { BROADCAST_REPEAT_MAP, CONTACT_TYPES } from '../../../../MessagingConstants';
import useAuth from '../../../../../../../../context/AuthContext';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';
import { populateMessage } from '../../../MessagingHelpers';
import { DELIVERABILITY } from '../../../../../recipients/RecipientsConstants';

import NewBroadcastModalWrapper from './NewBroadcastModalWrapper';
import GenericLabel from '../../../list/views/GenericLabel';
import { ASSET_CDN_URL } from '../../../../../../../../Constants';

const { SUBSCRIBED, OPTED_OUT } = DELIVERABILITY;

const BROADCAST_LEFT_ARROW = `${ASSET_CDN_URL}/icons/rex-broadcast-review-left-arrow.svg`;
const BROADCAST_RIGHT_ARROW = `${ASSET_CDN_URL}/icons/rex-broadcast-review-right-arrow.svg`;
const ERROR_ICON = `${ASSET_CDN_URL}/icons/rex-error-icon.svg`;
const { ADMIN } = CONTACT_TYPES;

const propTypes = {};

function ConfirmBroadcastModal({ closeModal, confirmModal, broadcast, recipients, variantAssignments }) {
  const { user } = useAuth();
  const { templates, employees } = MessagingConsumer();
  const { campaigns } = user;
  const { message, templateId, nextBroadcastDate, frequency, campaignId, channel } = broadcast;
  const [currentIndex, setCurrentIndex] = useState(0);
  const template = templates.find(template => template._id === templateId);
  const broadcastMessage = templateId && template && template.welcomeSection ? template.welcomeSection.message : message;
  const [unableToSendError, setUnableToSendError] = useState('');
  const campaign = campaigns.find(({ _id }) => _id === campaignId);

  const getHighlightedText = (message) => {
    if (message) {
      return message.split('||').map(messageSection => {
        if (messageSection.startsWith('@')) {
          return <span className={'matching-variable'} dangerouslySetInnerHTML={{ __html: messageSection.substring(1) }} />;
        } else if (messageSection.startsWith('[@')) {
          return <span className={'missing-variable'} dangerouslySetInnerHTML={{ __html: messageSection }} />;
        }
        return <span dangerouslySetInnerHTML={{ __html: messageSection }} />;
      })
    }
    return message;
  }

  const recipientsWithMessages = recipients.map(recipient => {
    const managers = Object.hasOwn(recipient, 'managers') ? recipient.managers.filter(id => {
      const manager = employees.find((({ _id }) => id === _id));
      return manager && manager.deletionDate === null;
    }) : [];
    const recipientWithManagers = { ...recipient, managers };
    let messageToSend;
    if (campaign) {
      const { channelOutputs } = campaign;
      const messageVariantNumber = variantAssignments[recipient._id];
      messageToSend = channelOutputs[channel][messageVariantNumber].message;
    }
    else {
      messageToSend = broadcastMessage;
    }
    const { message, missingVariableInputs } = populateMessage(messageToSend, recipientWithManagers, user, employees);
    const colourCodedMessage = getHighlightedText(message);
    return { ...recipientWithManagers, message, missingVariableInputs, colourCodedMessage };
  })
  
  const recipientsMissingVariableInputs = recipientsWithMessages.filter(recipient => recipient.missingVariableInputs).length;
  const recipientMissingVariableInputsText = recipientsMissingVariableInputs === 1 ? 'recipient is missing inputs for variables' : 'recipients are missing inputs for variables';

  const recipientsWithoutMobile = recipientsWithMessages.filter(recipient => !recipient.mobileNumber).length;
  const recipientWithoutMobileText = recipientsWithoutMobile === 1 ? 'recipient does not have a mobile number and will be removed from the broadcast' : 'recipients do not have a valid mobile number and will be removed from the broadcast';

  const recipientsOptedOut = recipientsWithMessages.filter(recipient => recipient.deliverability === OPTED_OUT.key || (Object.hasOwn(recipient, 'marketingPreferences') && !recipient.marketingPreferences.optIn)).length;
  const recipientOptedOutText = recipientsOptedOut === 1 ? 'recipient has opted-out of communications and will be removed from the broadcast' : 'recipients have opted-out of communications and will be removed from the broadcast';

  const missingMobileErrorMessage = recipientsWithoutMobile ? `${recipientsWithoutMobile} ${recipientWithoutMobileText}` : '';
  const missingVariableInputsErrorMessage = recipientsMissingVariableInputs ? `${recipientsMissingVariableInputs} ${recipientMissingVariableInputsText}` : '';
  const recipientOptedOutErrorMessage = recipientsOptedOut ? `${recipientsOptedOut} ${recipientOptedOutText}` : '';

  const hasError = recipientsMissingVariableInputs || recipientsWithoutMobile || recipientsOptedOut;
  const customerHasError = !recipientsWithMessages[currentIndex].mobileNumber || recipientsWithMessages[currentIndex].missingVariableInputs || (recipientsWithMessages[currentIndex].deliverability === OPTED_OUT.key || (Object.hasOwn(recipientsWithMessages[currentIndex], 'marketingPreferences') && !recipientsWithMessages[currentIndex].marketingPreferences.optIn))

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? recipientsWithMessages.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  }

  const goToNext = () => {
    const isLastSlide = currentIndex === recipientsWithMessages.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }

  const createBroadcast = () => {
    if (!!recipientsWithoutMobile || !!recipientsOptedOut) {
      const viableRecipients = recipients.filter(recipient => recipient.mobileNumber && (recipient.contactType = ADMIN.key || recipient.deliverability === SUBSCRIBED.key));
      if (viableRecipients.length === 0) {
        setUnableToSendError('None of the selected recipients are opted-in to communications or have a valid mobile number.');
        return;
      }
    }
    confirmModal();
  }

  const broadcastDate = new Date(nextBroadcastDate);
  const scheduleDate = broadcastDate.toLocaleString('en-au', {
    year: "numeric",
    month: "numeric",
    day: 'numeric',
  });
  const scheduleTime = broadcastDate.toLocaleTimeString('en-au', {
    hour: "numeric",
    minute: 'numeric',
  });

  return (
    <NewBroadcastModalWrapper additionalClassNames={['confirm-broadcast']} closeModal={closeModal} confirmModal={createBroadcast} modalTitle={'Broadcast messages review'} closeButtonText={'Back'} confirmButtonText={hasError ? 'Broadcast anyway' : 'Broadcast message'}>
      <p className={'unable-to-send-error-text'}>{unableToSendError}</p>
      <section className={'confirm-broadcast-body'}>
        <section className={'broadcast-schedule'}>
          <span>{`Scheduled: ${scheduleTime} on ${scheduleDate}`}</span>
          <span>{BROADCAST_REPEAT_MAP[frequency].text}</span>
        </section>
        <section className={'broadcast-recipients-row'}>
          <section className={'broadcast-recipients'}>
            <span>{'To: '}</span>
            <GenericLabel additionalClassNames={customerHasError ? ['confirm-broadcast-recipients', 'missing-mobile'] : ['confirm-broadcast-recipients']} text={`${recipients[currentIndex].firstName} ${recipients[currentIndex].lastName}`} />
          </section>
          <span className={'broadcast-recipient-count'}>{`${currentIndex + 1}/${recipients.length}`}</span>
        </section>
        <section className={'broadcast-message-container'}>
          <section className={'broadcast-message'}>{recipientsWithMessages[currentIndex].colourCodedMessage}</section>
          <button className={'review-next-broadcast-button prev-button'} onClick={goToPrevious} type={'button'}>
            <img alt={'broadcast-prev-arrow-button'} className={'next-broadcast-icon'} src={BROADCAST_LEFT_ARROW} />
          </button>
          <button className={'review-next-broadcast-button next-button'} onClick={goToNext} type={'button'}>
            <img alt={'broadcast-next-arrow-button'} className={'next-broadcast-icon'} src={BROADCAST_RIGHT_ARROW} />
          </button>
        </section>
        <section className={'broadcast-review-error-container'}>
          {!!hasError && <img alt={'review-broadcast-error-icon'} className={'review-broadcast-error-icon'} src={ERROR_ICON} />}
          <section className={'broadcast-error-text-container'}>
            {missingMobileErrorMessage && <p className={'confirm-broadcast-error-text'}>{missingMobileErrorMessage}</p>}
            {missingVariableInputsErrorMessage && <p className={'confirm-broadcast-error-text'}>{missingVariableInputsErrorMessage}</p>}
            {recipientOptedOutErrorMessage && <p className={'confirm-broadcast-error-text'}>{recipientOptedOutErrorMessage}</p>}
          </section>
        </section>
      </section>
    </NewBroadcastModalWrapper>
  )
}

ConfirmBroadcastModal.displayName = 'ConfirmBroadcastModal';
ConfirmBroadcastModal.propTypes = propTypes;
export default ConfirmBroadcastModal;