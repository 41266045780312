import './ManagerRecipientsPage.scss';

import React, { useEffect, useState } from 'react';
import { RECIPIENT_ACTIVE_STATUSES, DELIVERABILITY } from './RecipientsConstants';

import { getManagerUser, getMoreCustomersToCustomersTable, submitRecipientRows } from '../../../../apis/Api';
import { calculateDerivedFields, getRelatedInteractions } from '../../../../utils/DataUtils';

import useAuth from '../../../../context/AuthContext';
import MessagingConsumer from '../../../../context/MessagingContext';
import RecipientsListView from './RecipientsListView';
import ManagerRecipientsPageWrapper from './ManagerRecipientsPageWrapper';
import DoubleToggleButton from '../common/DoubleToggleButton';
import CustomersListView from './CustomersListView';
import FlatfilePortal from './flatFileContactImport/FlatFile';
import SearchBar from '../communications/messaging/list/views/search/SearchBar';
import SearchButton from '../communications/messaging/list/views/search/SearchButton';
import { searchFilterContacts } from '../communications/messaging/MessagingHelpers';

const propTypes = {};

const { ACTIVE, DELETE } = RECIPIENT_ACTIVE_STATUSES;
const { SUBSCRIBED } = DELIVERABILITY;
const SEARCH_BUTTON_ADDITIONAL_CLASSNAME = ['manager-recipients-page-search-button'];
const SEARCH_ADDITIONAL_CLASSNAMES = ['new-broadcast'];

const getInitialRows = (user, interactions) => {
  if (user && user.recipients && interactions) {
    return user.recipients
      .filter(({ deletionDate }) => !deletionDate)
      .map((recipient) => {
        const managers = recipient.managers.filter(id => {
          const manager = user.recipients.find(({ _id }) => id === _id);
          return manager && manager.deletionDate === null;
        });
        const relatedInteractions = getRelatedInteractions(recipient, interactions);
        const formattedBirthday = recipient.birthday ? new Date(recipient.birthday).toLocaleString('en-au', { day: 'numeric', month: 'numeric', year: 'numeric' }) : null;
        return calculateDerivedFields({ ...recipient, formattedBirthday, managers, interactions: relatedInteractions });
      });
  }
  return [];
}

const getInitialCustomerRows = (user, interactions) => {
  if (user && user.customers && interactions) {
    return user.customers
      .filter(({ deletionDate }) => !deletionDate)
      .map((customer) => {
        const relatedInteractions = getRelatedInteractions(customer, interactions);
        const formattedBirthday = customer.birthday ? new Date(customer.birthday).toLocaleString('en-au', { day: 'numeric', month: 'numeric', year: 'numeric' }) : null;
        return calculateDerivedFields({ ...customer, formattedBirthday, interactions: relatedInteractions });
      })
  }
  return [];
}

function ManagerRecipientsPage() {
  const { user, setUser } = useAuth();
  const { interactions, setInitialLoad } = MessagingConsumer();
  const groupMap = user.groups.reduce((groupMapping, group) => ({ ...groupMapping, [group._id]: group.name.toLowerCase() }), {});
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
  const [customersPageCount, setCustomersPageCount] = useState(Object.hasOwn(user, 'customersPageCount') ? parseInt(user.customersPageCount) : 1);
  const increasePageCount = () => setCustomersPageCount(customersPageCount + 1);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [openSearch, setOpenSearch] = useState(false);

  const initialRows = getInitialRows(user, interactions);
  const initialCustomerRows = getInitialCustomerRows(user, interactions);

  const [rows, setRows] = useState(initialRows);
  const [customerRows, setCustomerRows] = useState(initialCustomerRows);
  const [open, setOpen] = useState(false);
  const closeFlatFileModal = () => setOpen(false);
  const [isEmployeeView, setIsEmployeeView] = useState(false);
  const [editingRows, setEditingRows] = useState(new Array(initialRows.length).fill(false));
  const [customerEditingRows, setCustomerEditingRows] = useState(new Array(initialCustomerRows.length).fill(false));
  const numActiveEmployees = rows.filter(row => row.status === ACTIVE.key && row.deliverability === SUBSCRIBED.key).length;
  const numActiveCustomers = user.activeCustomerCount;
  const employeesText = numActiveEmployees === 1 ? 'employee' : 'employees';
  const customersText = numActiveCustomers === 1 ? 'customer' : 'customers';
  const numActive = isEmployeeView ? numActiveEmployees : numActiveCustomers;
  const headerText = isEmployeeView ? employeesText : customersText;
  const importText = isEmployeeView ? 'Import Employees' : 'Import Customers';

  const closeSearchBar = () => {
    setOpenSearch(false);
    setSearchText('');
    setSearching(false);
    setRows(initialRows);
    setCustomerRows(initialCustomerRows);
  }

  const search = (e) => {
    if (!searching) {
      setSearching(true);
    }
    if (e.target.value.length === 0) {
      setSearching(false);
      setRows(initialRows);
      setCustomerRows(initialCustomerRows);
    }
    else {
      setRows(filterContacts(initialRows));
      setCustomerRows(filterContacts(initialCustomerRows));
    }
    setSearchText(e.target.value);
  }

  const filterContacts = (users) => {
    return searchFilterContacts(users, searchText, groupMap);
  }
  
  const submit = () => {
    const newEditingRows = new Array(rows.filter(({ status }) => status !== DELETE.key).length).fill(false);
    const newCustomerEditingRows = new Array(customerRows.filter(({ status }) => status !== DELETE.key).length).fill(false);
    const rowsToSubmit = rows
      .filter((_, index) => editingRows[index])
      .map((row, index) => ({ ...row, rowNumber: index }));
    const customerRowsToSubmit = customerRows
      .filter((_, index) => customerEditingRows[index])
      .map((row, index) => ({ ...row, rowNumber: index }));

    console.log(customerRowsToSubmit);
    submitRecipientRows(rowsToSubmit, customerRowsToSubmit, customersPageCount).then((response) => {
      if (response.success) {
        setUser(prevState => ({ ...prevState, ...response.user }));
        setRows(response.user.recipients);
        const updatedCustomers = response.user.customers
          .map((customer) => {
            const relatedInteractions = getRelatedInteractions(customer, interactions);
            const formattedBirthday = customer.birthday ? new Date(customer.birthday).toLocaleString('en-au', { day: 'numeric', month: 'numeric', year: 'numeric' }) : null;
            return calculateDerivedFields({ ...customer, group: groupMap[customer.groupId], formattedBirthday, interactions: relatedInteractions });
          })
        setCustomerRows(updatedCustomers);
      }
    });
    setEditingRows(newEditingRows);
    setCustomerEditingRows(newCustomerEditingRows);
    setOpenSearch(false);
    setSearchText('');
    setSearching(false);
  }
  const enterSubmit = (e) => {
    if (e.key === 'Enter') {
      submit();
    }
  }
  const selectLeft = () => {
    setIsEmployeeView(false);
    closeSearchBar();
  }
  const selectRight = () => {
    setIsEmployeeView(true);
    closeSearchBar();
  }
  const reset = () => {
    if (isEmployeeView) {
      console.log(initialRows);
      setRows(initialRows);
      setEditingRows(new Array(initialRows.length).fill(false));
    }
    else {
      console.log(initialCustomerRows)
      setCustomerRows(initialCustomerRows);
      setCustomerEditingRows(new Array(initialCustomerRows.length).fill(false));
    }
  }

  useEffect(() => {
    if (initialLoadCompleted || customerRows.length === 0) {
      setLoading(true);
      getMoreCustomersToCustomersTable(customersPageCount)
        .then(response => {
          if (response.success) {
            setUser(prevState => {
              return ({ ...prevState, queriedAllCustomers: response.queriedAllCustomers, customers: [ ...customerRows, ...response.customers ], customersPageCount: response.customersPageCount });
            });
            
            const newCustomerRows = [ ...customerRows, ...response.customers ].map(customer => {
              const relatedInteractions = getRelatedInteractions(customer, interactions);
              const formattedBirthday = customer.birthday ? new Date(customer.birthday).toLocaleString('en-au', { day: 'numeric', month: 'numeric', year: 'numeric' }) : null;
              return calculateDerivedFields({ ...customer, group: groupMap[customer.groupId], formattedBirthday, interactions: relatedInteractions });
            });
            const newCustomerEditingRows = [ ...customerEditingRows, ...new Array(response.customers.length).fill(false) ];
            setCustomerRows(newCustomerRows);
            setCustomerEditingRows(newCustomerEditingRows);
          }
        })
        .finally(() => {
          setLoading(false);
        })
    }
    setInitialLoadCompleted(true);
  }, [customersPageCount])

  useEffect(() => {
    if (user && interactions) {
      setRows(getInitialRows(user, interactions));
      setCustomerRows(getInitialCustomerRows(user, interactions));
    }
  }, [user, interactions])

  useEffect(() => {
    setInitialLoad(false);
  }, [setInitialLoad]);

  const isEditing = editingRows.some((editing) => editing) || customerEditingRows.some((editing) => editing);
  const managerRecipientsDisplay = isEmployeeView
    ? <RecipientsListView editingRows={editingRows} setEditingRows={setEditingRows} rows={rows} setRows={setRows} submit={submit} initialRows={initialRows} />
    : <CustomersListView editingRows={customerEditingRows} setEditingRows={setCustomerEditingRows} rows={customerRows} setRows={setCustomerRows} submit={submit} initialRows={initialCustomerRows} increasePageCount={increasePageCount} loading={loading} />;

  const searchComponent = openSearch
    ? <SearchBar additionalClassNames={SEARCH_ADDITIONAL_CLASSNAMES} searchText={searchText} search={search} placeholder={'Search'} setOpenSearch={closeSearchBar} />
    : <SearchButton additionalClassNames={SEARCH_BUTTON_ADDITIONAL_CLASSNAME} setOpenSearch={setOpenSearch} searchText={searchText} />;
  return (
    <ManagerRecipientsPageWrapper>
      <article className={'manager-recipients-page'} onKeyUp={enterSubmit}>
        <h2 className={'page-title'}>{'Recipients'}</h2>
        <section className={'recipients-table'}>
          <section className={'header-section'}>
            <section className={'left-section'}>
              <p className={'active-recipients'}>
                {'You have '}
                <span className={'bold'}>{`${numActive} active `}</span>
                {headerText}
              </p>
              <DoubleToggleButton
                leftButtonText={'Customer'}
                rightButtonText={'Employee'}
                isLeftSelected={!isEmployeeView}
                selectLeft={selectLeft}
                selectRight={selectRight}
              />
              {searchComponent}
            </section>
            <section className={'right-section'}>
              { isEditing && (
                  <section className={'buttons'}>
                    <button className={'reset-button'} onClick={reset}>{'Reset'}</button>
                    <button className={'confirm-button'} onClick={submit}>{'Confirm'}</button>
                  </section>
                )
              }
              <button className={'import-recipients-button'} onClick={() => setOpen(true)}>{importText}</button>
            </section>
          </section>
          {managerRecipientsDisplay}
          { open && <FlatfilePortal employeeView={isEmployeeView} closeFlatFileModal={closeFlatFileModal} setEmployeeRows={setRows} setCustomerRows={setCustomerRows} pageCount={customersPageCount} /> }
        </section>
      </article>
    </ManagerRecipientsPageWrapper>
  );
}

ManagerRecipientsPage.displayName = 'ManagerRecipientsPage';
ManagerRecipientsPage.propTypes = propTypes;
export default ManagerRecipientsPage;