import './TeamPicker.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PlusButton from './PlusButton';
import TeamMemberSearchModal from '../../modals/TeamMemberSearchModal';
import { DUMMY_TEAM_MEMBERS } from '../../EngagementConstants';
import DeleteButton from '../../../../common/DeleteButton';

const propTypes = {};

function TeamPicker({ setSelectedTeamMemberIds, teamMembers = DUMMY_TEAM_MEMBERS, selectedTeamMemberIds = [] }) {
  const [modalOpen, setModalOpen] = useState(false);
  const teamMemberList = teamMembers
    .filter(({ _id }) => selectedTeamMemberIds.includes(_id))
    .map(({ firstName, lastName, _id }) => {
      const firstNameDisplay = firstName ? firstName : '';
      const lastNameDisplay = lastName ? ` ${lastName}` : '';
      const firstNameInitial = firstName ? firstName.charAt(0) : '';
      const lastNameInitial = lastName ? lastName.charAt(0) : '';
      const initials = `${firstNameInitial}${lastNameInitial}`;
      const deleteClick = () => {
        setSelectedTeamMemberIds(selectedTeamMemberIds.filter((id) => id !== _id));
      };
      return (
        <li className={'team-member'} key={_id}>
          <span className={'initials'}>{initials}</span>
          <span className={'name'}>{`${firstNameDisplay}${lastNameDisplay}`}</span>
          <DeleteButton onClick={deleteClick} />
        </li>
      )
    });
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);
  return (
    <section className={'team-picker-container'}>
      { modalOpen &&
        <TeamMemberSearchModal
          close={close}
          teamMembers={teamMembers}
          selectedTeamMemberIds={selectedTeamMemberIds}
          setSelectedTeamMemberIds={setSelectedTeamMemberIds}
        />
      }
      <PlusButton buttonText={'Assign team members'} onClick={open}/>
      <ul className={'team-list'}>
        {teamMemberList}
      </ul>
    </section>
  );
}

TeamPicker.displayName = 'TeamPicker';
TeamPicker.propTypes = propTypes;
export default TeamPicker;