import './ReputationSelection.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AssistantSelection from '../common/AssistantSelection';
import AssistantSelectionSection from '../common/AssistantSelectionSection';
import PlusButton from '../common/PlusButton';
import AssistantDropdown from '../common/AssistantDropdown';
import AssistantTextInput from '../common/AssistantTextInput';
import TeamPicker from '../common/TeamPicker';
import ToggleButton from '../common/ToggleButton';
import { APPOINTMENT_DATE_FIELDS, DUMMY_TEAM_MEMBERS, WEEKLY_FREQUENCY_OPTIONS } from '../../EngagementConstants';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../../../../context/AuthContext';
import { postActivateAssistant } from '../../../../../../../apis/AssistantApi';

const propTypes = {};

function ReputationSelection({ context, active, setAssistantKey, assistantKey }) {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const teamMembers = user && user.recipients ? user.recipients : DUMMY_TEAM_MEMBERS;
  const campaignOptions = user && user.savedTemplates ? user.savedTemplates.map(({ _id, title }) => ({ key: _id, displayText: title })) : [];
  const navigate = useNavigate();
  const [assistantContext, setAssistantContext] = useState(context);
  const cancel = () => setAssistantKey(null);
  const { autopilot, campaign, frequency, contactFrequency, appointmentDateFieldName, teamMemberIds } = assistantContext;
  const [selectedTeamMemberIds, setSelectedTeamMemberIds] = useState(teamMemberIds);
  const setAppointmentDateFieldName = (appointmentDateFieldName) => setAssistantContext({ ...assistantContext, appointmentDateFieldName });
  const setAutopilot = (autopilot) => setAssistantContext({ ...assistantContext, autopilot });
  const setCampaign = (campaign) => setAssistantContext({ ...assistantContext, campaign });
  const setFrequency = (frequency) => setAssistantContext({ ...assistantContext, frequency });
  const setContactFrequency = (contactFrequency) => setAssistantContext({ ...assistantContext, contactFrequency });
  const autopilotDescription = autopilot 
    ? 'Rex will draft and send a campaign out to your recipients automatically.'
    : 'Rex will create a draft campaign for you, and you import recipients manually.';
  const frequencyDescription = 'How often would you like this campaign launched?';
  const campaignDescription = 'Select and review a campaign template for Rex to use.';
  const maxContactDescription = 'Enter the maximum number of review requests per recipient each year (recommended is 1).';
  const alertsDescription = 'Assign which team members will be alerted for low feedback scores.';

  // TODO: Implement is integrated check
  const isIntegrated = false;
  const integrationDescription = isIntegrated
    ? 'Select the field that represents ‘appointment date’.'
    : 'Find your CRM from available integrations and click on ‘request’.';
  const integrationText = isIntegrated ? 'Integrated' : 'Not integrated';
  const integrationTextDisplay = <span className={'integration-text'}>{integrationText}</span>;
  const integrationButton = isIntegrated 
    ? <AssistantDropdown
      defaultButtonText={'Select field'}
      options={APPOINTMENT_DATE_FIELDS}
      select={setAppointmentDateFieldName}
      selected={appointmentDateFieldName}
    />
    : <PlusButton onClick={() => navigate('/integrations')} buttonText={'Integrate CRM'} />;

  const activate = (callback) => {
    if (loading) {
      return;
    }
    setLoading(true);
    postActivateAssistant(assistantKey, { ...assistantContext, teamMemberIds: selectedTeamMemberIds })
      .then((res) => {
        setUser(res.user);
        setLoading(false);
        callback();
      });
  }
  const autopilotDisabled = true;
  return (
    <AssistantSelection title={'Reputation Assistant'} additionalClassNames={['reputation-assistant']} cancel={cancel} activateAssistant={activate} assistantKey={assistantKey}>
      <AssistantSelectionSection
        title={'Auto pilot'}
        description={autopilotDescription}
        toggleButton={<ToggleButton active={autopilot} setActive={setAutopilot} />}
        hidden={true}
      />
      { autopilot && !autopilotDisabled && (
        <AssistantSelectionSection title={'CRM Integration'} description={integrationDescription} toggleButton={integrationTextDisplay}>
          {integrationButton}
        </AssistantSelectionSection>
      )}
      <AssistantSelectionSection title={'Select frequency'} description={frequencyDescription}>
        <AssistantDropdown
          defaultButtonText={'Frequency'}
          selected={frequency}
          select={setFrequency}
          options={WEEKLY_FREQUENCY_OPTIONS}
        />
      </AssistantSelectionSection>
      <AssistantSelectionSection title={'Select campaign template'} description={campaignDescription}>
        <AssistantDropdown
          defaultButtonText={'Campaign'}
          selected={campaign}
          select={setCampaign}
          options={campaignOptions}
        />
      </AssistantSelectionSection>
      <AssistantSelectionSection title={'Max contact frequency'} description={maxContactDescription}>
        <AssistantTextInput placeholder={'Type a number'} text={contactFrequency} setText={setContactFrequency} />
      </AssistantSelectionSection>
      <AssistantSelectionSection title={'Assign team member'} description={alertsDescription}>
        <TeamPicker
          selectedTeamMemberIds={selectedTeamMemberIds}
          setSelectedTeamMemberIds={setSelectedTeamMemberIds}
          teamMembers={teamMembers}
        /> 
      </AssistantSelectionSection>
    </AssistantSelection>
  );
}

ReputationSelection.displayName = 'ReputationSelection';
ReputationSelection.propTypes = propTypes;
export default ReputationSelection;