import './AddOfferModal.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WorkRexModal from '../../../../common/WorkRexModal';
import CampaignTextInput from '../common/CampaignTextInput';

const propTypes = {};

function AddOfferModal({ close, confirm }) {
  const [text, setText] = useState('');
  const confirmOffer = () => {
    confirm(text);
    setText('');
    close();
  }
  const disabled = text.length === 0;
  return (
    <WorkRexModal close={close} additionalClassNames={['add-offer-modal']}>
      <h4 className={'title'}>{'Add campaign offer'}</h4>
      <p className={'description'}>
        {'Add a promotional offer or incentive with your campaign. E.g. take 20% off'}
      </p>
      <CampaignTextInput text={text} setText={setText} placeholder={'Type your offer here...'} />
      <button
        className={`confirm-button ${disabled ? 'disabled' : ''}`}
        onClick={confirmOffer}
        disabled={disabled}
      >
        {'Add offer'}
      </button>
    </WorkRexModal>
  );
}

AddOfferModal.displayName = 'AddOfferModal';
AddOfferModal.propTypes = propTypes;
export default AddOfferModal;