import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getContentFile } from '../../../../../apis/ContentApi';

import { WORKREX_CONTENT_LIBRARY } from './library/ContentLibrary';

import DotBricksLoading from '../../../../common/DotBricksLoading';

const propTypes = {};

function ContentPage() {
  const { attachmentCode } = useParams();
  useEffect(() => {
    const libraryFile = WORKREX_CONTENT_LIBRARY.find(({ code }) => code === attachmentCode);
    if (libraryFile) {
      window.location.href = libraryFile.url;
      return;
    }
    getContentFile(attachmentCode)
      .then((response) => {
        window.location.href = response.url;
      })
      .catch(() => {
        // window.location.href = 'https://www.workrex.io';
      });
  }, []);
  return <DotBricksLoading />;
}

ContentPage.displayName = 'ContentPage';
ContentPage.propTypes = propTypes;
export default ContentPage;