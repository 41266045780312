import './ChatHistory.scss';

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import EditModalDropdown from '../../../list/views/tasks/EditModalDropdown';

import { ASSET_CDN_URL } from '../../../../../../../../Constants';
import { useOutsideClick } from '../../../../../../../../hooks/ClickHooks';
import { updateMessageReadStatus } from '../../../../../../../../apis/Api';

const editIcon = `${ASSET_CDN_URL}/icons/workrex_edit_triple_dot_selection.svg`;

const propTypes = {};

const editMenuWidthPx = 160;
const SECONDS_IN_A_MINUTE = 60;
const SECONDS_IN_AN_HOUR = 60 * SECONDS_IN_A_MINUTE;
const SECONDS_IN_A_DAY = 24 * SECONDS_IN_AN_HOUR;
const SECONDS_IN_A_WEEK = 7 * SECONDS_IN_A_DAY;

const isSameYear = (date1, date2) => date1.getFullYear() === date2.getFullYear();
const isSameDay = (date1, date2) => {
  return isSameYear(date1, date2) && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}
const isYesterday = (date1, date2) => {
  return date1.toDateString() === date2.toDateString();
}

const getDatestampDisplay = (timestamp) => {
  const currentDate = new Date();
  const timestampDate = new Date(timestamp);
  const currentTime = currentDate.getTime();
  if (isSameDay(currentDate, timestampDate)) {
    return 'Today';
  }
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (isYesterday(yesterday, timestampDate)) {
    return 'Yesterday';
  }
  const timeDiff = currentTime - timestamp;
  if (timeDiff < SECONDS_IN_A_WEEK * 1000) {
    return timestampDate.toLocaleString('en-AU', { weekday: 'short'});
  }
  else if (isSameYear(currentDate, timestampDate)) {
    return timestampDate.toLocaleString('en-AU', { month: 'short', day: 'numeric'});
  }
  else {
    return timestampDate.toLocaleString('en-AU', { year: 'numeric', month: 'short', day: 'numeric'});
  }
}

const getTimestampDisplay = (timestamp) => {
  const timestampDate = new Date(timestamp);
  return timestampDate.toLocaleString('en-AU', { hour: 'numeric', hour12: true, minute: 'numeric' });
}

const ChatMessage = ({ chatMessage, senderId, markChatAsUnread }) => {
  const [additionalClassNames, setAdditionalClassNames] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsEditing(false));
  const toggleEdit = (e) => {
    e.preventDefault();
    setIsEditing(!isEditing);
    if (dropdownRef !== null && dropdownRef.current !== null) {
      const leftAlign = dropdownRef.current.offsetParent.clientWidth - dropdownRef.current.offsetLeft < editMenuWidthPx;
      setAdditionalClassNames(leftAlign ? ['left-align', 'chat-message-modal'] : ['chat-message-modal']);
    }
  }

  const { _id, receiverId, message, creationDate, read } = chatMessage;
  const updateReadStatus = (newStatus) => () => {
    updateMessageReadStatus(_id, newStatus)
      .then(response => {
        markChatAsUnread(_id);
      })
  }

  const onMouseLeave = () => {
    if (isEditing) {
      setIsEditing(false);
    }
  }

  return (
    <div className={'chat-msg-container'} onMouseLeave={onMouseLeave}>
      <div className={'chat-msg-body'}>
        <span className={`chat-message ${receiverId === senderId ? 'receiver' : 'sender'}`}>
          {message}
        </span>
        <ChatTimestamp 
          timestamp={creationDate} isReceiver={receiverId === senderId} key={`timestamp-${creationDate}`}
        />
      </div>
      <button className={`edit-selected-button ${receiverId === senderId ? 'receiver' : 'sender'}`} ref={dropdownRef} onClick={toggleEdit} type={'button'}>
        <img alt={'edit-icon'} className={'edit-icon'} src={editIcon} />
        {isEditing && 
          <EditModalDropdown additionalClassNames={additionalClassNames}>
            {read && <button className={'edit-button'} onClick={updateReadStatus(false)} type={'button'}>{'Mark as unread'}</button>}
            {/* {!read && <button className={'edit-button'} onClick={updateReadStatus(true)} type={'button'}>{'Mark as read'}</button>} */}
          </EditModalDropdown>
        }
      </button>
    </div>
  );
}

const ChatReceiverName = ({ firstName }) => {
  return <span className={'chat-receiver-name'} >{firstName}</span>
}

const ChatTimestamp = ({ timestamp, isReceiver }) => {
  const timestampDisplay = getTimestampDisplay(timestamp);
  return <span className={`chat-timestamp ${isReceiver ? 'receiver' : 'sender'}`}>{timestampDisplay}</span>;
}

const ChatDatestamp = ({ timestamp }) => {
  const timestampDisplay = getDatestampDisplay(timestamp);
  return (
    <section className={'chat-datestamp-container'}>
      <hr className={'horizontal-divider'} />
      <span className={'chat-datestamp'}>{timestampDisplay}</span>
    </section>
  );
}

const ChatAttachment = ({ receiverId, attachment, senderId, timestamp }) => {
  const { size, url, fileName } = attachment;
  let sizeDisplay = `${size} B`;
  if (size >= 1000 && size < 1000 * 1000) {
    sizeDisplay = `${(size / 1000).toFixed(1)} KB`;
  }
  else {
    sizeDisplay = `${(size / (1000 * 1000)).toFixed(1)} MB`;
  }
  return (
    <section className={'chat-msg-container'}>
      <section className={'chat-msg-body'}>
        <span className={`chat-attachment ${receiverId === senderId ? 'receiver' : 'sender'}`}>
          <a href={url} className={`${url === null ? 'loading' : ''}`}>
            {fileName}
          </a>
          <span className={'size'}>
            {sizeDisplay}
          </span>
        </span>
        <ChatTimestamp 
          timestamp={timestamp} isReceiver={receiverId === senderId} key={`timestamp-${timestamp}`}
        />
      </section>
    </section>
  );
}

function ChatHistory({ chatHistory, senderId, firstName, markChatAsUnread }) {
  const chatHistoryRef = useRef(null);
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scroll(0, chatHistoryRef.current.scrollHeight);
    }
  });
  if (chatHistory.length > 0) {
    const history = [];
    const chatMessage = chatHistory[0];
    const chatMessageKey =`key-${chatMessage.senderId}-${chatMessage.businessId}-${chatMessage.receiverId}-${chatMessage.creationDate}`;
    history.push(<ChatDatestamp timestamp={chatMessage.creationDate} key={`timestamp-${chatMessage.creationDate}`}/>);
    if (chatMessage.receiverId === senderId) {
      history.push(<ChatReceiverName firstName={firstName} />)
    }
    if (chatMessage.attachments && chatMessage.attachments.length > 0) {
      chatMessage.attachments.forEach(attachment => {
        history.push(
          <ChatAttachment
            receiverId={chatMessage.receiverId}
            attachment={attachment}
            timestamp={chatMessage.creationDate}
            senderId={senderId}
            key={`message-${attachment._id}`}
          />
        );
      }); 
    }
    if (chatMessage.message.length > 0 && chatMessage.message.replace(/\s/g, '').length > 0) {
      history.push(<ChatMessage chatMessage={chatMessage} senderId={senderId} key={chatMessageKey} markChatAsUnread={markChatAsUnread} />);
    }
    for (let i = 1; i < chatHistory.length; i++) {
      const chatMessage = chatHistory[i];
      const prevChatMessage = chatHistory[i - 1];
      const chatMessageKey =`key-${chatMessage.senderId}-${chatMessage.businessId}-${chatMessage.receiverId}-${chatMessage.creationDate}`;
      if (isSameDay(new Date(chatMessage.creationDate), new Date(prevChatMessage.creationDate))) {
        if (prevChatMessage.receiverId !== senderId && chatMessage.receiverId === senderId) {
          history.push(<ChatReceiverName firstName={firstName} />)
        }
        if (chatMessage.attachments && chatMessage.attachments.length > 0) {
          chatMessage.attachments.forEach(attachment => {
            history.push(
              <ChatAttachment
                receiverId={chatMessage.receiverId}
                attachment={attachment}
                timestamp={chatMessage.creationDate}
                senderId={senderId}
                key={`message-${attachment._id}`}
              />
            );
          }); 
        }
        if (chatMessage.message.length > 0 && chatMessage.message.replace(/\s/g, '').length > 0) {
          history.push(<ChatMessage chatMessage={chatMessage} senderId={senderId} key={chatMessageKey} markChatAsUnread={markChatAsUnread} />);
        }
      }
      else {
        history.push(<ChatDatestamp timestamp={chatMessage.creationDate} key={`timestamp-${chatMessage.creationDate}`} />);
        // if (chatMessage.receiverId === senderId) {
        //   history.push(<ChatReceiverName firstName={firstName} />)
        // }
        if (chatMessage.attachments && chatMessage.attachments.length > 0) {
          chatMessage.attachments.forEach(attachment => {
            history.push(
              <ChatAttachment
                receiverId={chatMessage.receiverId}
                attachment={attachment}
                timestamp={chatMessage.creationDate}
                senderId={senderId}
                key={`message-${attachment._id}`}
              />
            );
          }); 
        }
        if (chatMessage.message.length > 0 && chatMessage.message.replace(/\s/g, '').length > 0) {
          history.push(<ChatMessage chatMessage={chatMessage} senderId={senderId} key={chatMessageKey} markChatAsUnread={markChatAsUnread} />);
        }
      }
    }
    return <section className={'chat-history'} ref={chatHistoryRef}>{history}</section>;
  } 
  else {
    return (
      <section className={'unavailable-chat-history'}>
        <p className={'unavailable-chat-history-text'}>{'Message history is not available yet for this contact at the moment.'}</p>
      </section>
    )
  }
}

ChatHistory.displayName = 'ChatHistory';
ChatHistory.propTypes = propTypes;
export default ChatHistory;