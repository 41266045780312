import './ConfirmIntegrationRemovalModal.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IntegrationModal from './IntegrationModal';

import { postIntegrationRemoval } from '../../../../../../apis/IntegrationApi';
import useAuth from '../../../../../../context/AuthContext';

const propTypes = {};

function ConfirmIntegrationRemovalModal({ close, type }) {
  const { setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const confirmRemoval = () => {
    setLoading(true);
    setError(null);
    postIntegrationRemoval(type)
      .then((response) => {
        const { user } = response;
        setUser(user);
        setLoading(false);
        close();
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }
  return (
    <IntegrationModal additionalClassNames={['confirm-removal']} close={close}>
      <h3 className={'modal-title'}>{'Are you sure you want to remove this integration?'}</h3>
      { error && <span className={'error-text'}>{error}</span>}
      <section className={'buttons'}>
        <button className={'cancel-button'} onClick={close} disabled={loading}>{'Cancel'}</button>
        <button className={'confirm-button'} onClick={confirmRemoval} disabled={loading}>{'Confirm'}</button>
      </section>
    </IntegrationModal>
  );
}

ConfirmIntegrationRemovalModal.displayName = 'ConfirmIntegrationRemovalModal';
ConfirmIntegrationRemovalModal.propTypes = propTypes;
export default ConfirmIntegrationRemovalModal;