import './EditAutomationInput.scss';

import React, { useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { SPECIAL_KEYWORDS } from '../../../CommunicationsConstants';

import UNSELECTED_EDITING_BUTTON_ICON from '../../assets/unselected-editing-icon.svg';
import SELECTED_EDITING_BUTTON_ICON from '../../assets/selected-editing-icon.svg';

const getTemplateText = (displayText, type) => {
  return `<span class="container" contenteditable="false"><span class="${type}" contenteditable="false">${displayText}</span></span>`;
}

const propTypes = {};

function EditAutomationInput({ initialDescription, placeholder = 'Write your automation description here', editable = true, shouldUseVariables = false, save }) {
  const [editing, setEditing] = useState(false);
  const [description, setDescription] = useState(initialDescription);
  const [offset, setOffset] = useState(null);
  const processText = (text) => {
    let processedText = text;
    if (shouldUseVariables) {
      for (const specialKeyword of Object.values(SPECIAL_KEYWORDS)) {
        const { keyword, displayText, type } = specialKeyword;
        processedText = processedText.replaceAll(`@${keyword}`, getTemplateText(displayText, type));
      }
      const matches = processedText.match('@[0-9]+');
      if (matches) {
        for (const match of [...matches]) {
          const number = match.replaceAll('@', '');
          processedText = processedText.replaceAll(`@${number}`, getTemplateText(number, 'numeric'));
        }
      }
    }
    return processedText;
  }
  const unprocessText = (text) => {
    let processedText = text;
    if (shouldUseVariables) {
      for (const specialKeyword of Object.values(SPECIAL_KEYWORDS)) {
        const { keyword, displayText, type } = specialKeyword;
        processedText = processedText.replaceAll(getTemplateText(displayText, type), `@${keyword}`);
      }
      const matches = processedText.match('>[0-9]+<');
      if (matches) {
        for (const match of [...matches]) {
          const number = match.replaceAll('>', '').replaceAll('<', '');
          processedText = processedText.replaceAll(getTemplateText(number, 'numeric'), `@${number}`);
        }
      }
    }
    // processedText = processedText.replaceAll('&nbsp;', ' ');
    return processedText;
  }
  const textInputRef = useRef(null);
  const onChange = ({ target }) => {
    const unprocessedText = unprocessText(target.innerHTML);
    // todo catch paste and remove the span from it
    const range = document.getSelection().getRangeAt(0);
    setOffset(range.startOffset);
    setDescription(unprocessedText);
  };
  const onClickEdit = () => {
    if (editing) {
      // Save edits
      save(description);
      setEditing(false);
    }
    else {
      setEditing(true);
    }
  }
  useLayoutEffect(() => {
    if (offset !== null && textInputRef.current !== null && textInputRef.current.childNodes.length > 0) {
      const newRange = document.createRange();
      const currentChildNode = textInputRef.current.childNodes[0];
      try {
        if (currentChildNode && currentChildNode.nodeType === Node.TEXT_NODE) {
          newRange.setStart(currentChildNode, offset);
          const selection = document.getSelection();
          selection.removeAllRanges();
          selection.addRange(newRange);
        }
        else if (currentChildNode === null) {

        }
      }
      catch (err) {
        console.error(err);
      }
    }
  });
  const displayText = editing ? description : processText(description);
  const editingIcon = editing ? SELECTED_EDITING_BUTTON_ICON : UNSELECTED_EDITING_BUTTON_ICON;
  return (
    <section className={'edit-automation-input-container'}>
      <span className={'text'}>
        <p
          className={`description ${editing ? 'editing' : ''}`}
          onInput={onChange}
          contentEditable={editing}
          suppressContentEditableWarning={true}
          ref={textInputRef}
          dangerouslySetInnerHTML={{ __html: displayText }}
          data-placeholder={placeholder}
        />
      </span>
      { editable && 
        <button className={'editing-button'} onClick={onClickEdit}>
          <img className={'editing-button-icon'} src={editingIcon} />
        </button>
      }
    </section>
  )
}

EditAutomationInput.displayName = 'EditAutomationInput';
EditAutomationInput.propTypes = propTypes;
export default EditAutomationInput;
