import './AutomationFlowDisplay.scss';

import React from 'react';
import PropTypes from 'prop-types';
import AutomationFlowUnit from './AutomationFlowUnit';
import { AUTOMATION_TYPES } from '../../AutomationsConstants';

import SELECTED_TICK_ICON from '../../assets/selected-tick-icon.svg';
import UNSELECTED_TICK_ICON from '../../assets/unselected-tick-icon.svg';
import SELECTED_EXCLAMATION_ICON from '../../assets/selected-exclamation-icon.svg';
import UNSELECTED_EXCLAMATION_ICON from '../../assets/unselected-exclamation-icon.svg';

const { AVAILABLE, REQUESTED } = AUTOMATION_TYPES;

const propTypes = {};

function AutomationFlowDisplay({ type, active }) {
  return (
    <section className={'automation-flow-display'}>
      <AutomationFlowUnit
        selectedIcon={SELECTED_TICK_ICON}
        unselectedIcon={UNSELECTED_TICK_ICON}
        selected={type === REQUESTED.key}
        text={'Automation requested'}
      />
      <span className={'divider'} />
      <AutomationFlowUnit
        selectedIcon={SELECTED_TICK_ICON}
        unselectedIcon={UNSELECTED_TICK_ICON}
        selected={type === AVAILABLE.key && active === null}
        text={'Automation ready for activation'}
      />
      <span className={'divider'} />
      <AutomationFlowUnit
        selectedIcon={SELECTED_TICK_ICON}
        unselectedIcon={UNSELECTED_TICK_ICON}
        selected={type === AVAILABLE.key && active}
        text={'Automation is active'}
      />
      <span className={'divider'} />
      <AutomationFlowUnit
        selectedIcon={SELECTED_EXCLAMATION_ICON}
        unselectedIcon={UNSELECTED_EXCLAMATION_ICON}
        selected={type === AVAILABLE.key && active === false}
        text={'Automation has been paused'}
      />
    </section>
  );
}

AutomationFlowDisplay.displayName = 'AutomationFlowDisplay';
AutomationFlowDisplay.propTypes = propTypes;
export default AutomationFlowDisplay;