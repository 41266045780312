import './FileCard.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import EllipsisDropdown from '../../../common/EllipsisDropdown';
import { postDeleteFile } from '../../../../../../apis/ContentApi';
import useAuth from '../../../../../../context/AuthContext';

const propTypes = {};

const limitDisplayTagCharacters = (tag) => {
  if (tag) {
    return tag.length > 10 ? `${tag.substring(0, 10)}...` : tag;
  }
  return null;
}

function FileCard({ fileId, title, description, fileType, code, openLinkCopied, tags = [], additionalClassNames = [] }) {
  const { setUser, user } = useAuth();
  const navigate = useNavigate();
  const view = () => navigate(`/file/${code}`);
  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/content/${code}`);
      openLinkCopied();
    }
    catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  const options = [
    {
      displayText: 'Share',
      onClick: copyLink
    },
    {
      displayText: 'Edit',
      onClick: view,
    },
    {
      displayText: 'Delete',
      onClick: () => {
        const newUser = { ...user };
        newUser.files = newUser.files.filter(({ _id }) => _id !== fileId);
        setUser(newUser);
        postDeleteFile(fileId)
          .then((res) => setUser(res.user));
      },
    }
  ];
  const displayTag = tags.length > 0 ? limitDisplayTagCharacters(tags[0]) : null;
  return (
    <section className={`file-card ${additionalClassNames.join(' ')}`}>
      <section className={'card-header'}>
        <span className={'file-label'}>{fileType}</span>
        <EllipsisDropdown options={options} />
      </section>
      <section className={'file-details'}>
        <h5 className={'file-title'}>{title}</h5>
        <p className={'file-description'}>{description}</p>
      </section>
      <section className={'card-buttons'}>
        { displayTag && <span className={'tag-label'}>{displayTag}</span> }
        <button className={'view-more-button'} onClick={view}>{'View'}</button>
      </section>
    </section>
  );
}

FileCard.displayName = 'FileCard';
FileCard.propTypes = propTypes;
export default FileCard;