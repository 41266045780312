import { MONTHS } from "./FilterConstants";

/**
 * Checks a is equal to b, case insensitive.
 * @param {String} a 
 * @param {String} b 
 * @returns 
 */
export const stringEquals = (a, b) => {
  return !!a && !!b && a.toLowerCase() === b.toLowerCase();
}

/**
 * Checks a is not equal to b, case insensitive.
 * @param {String} a 
 * @param {String} b 
 * @returns 
 */
export const stringNotEquals = (a, b) => {
  return !a || !b || a.toLowerCase() !== b.toLowerCase();
}

/**
 * Checks a is equal to b.
 * @param {Integer} a 
 * @param {Integer} b 
 * @returns 
 */
export const numberEquals = (a, b) => {
  return parseInt(a) === parseInt(b);
}

/**
 * Checks a is not equal to b.
 * @param {Integer} a 
 * @param {Integer} b 
 * @returns 
 */
export const numberNotEquals = (a, b) => {
  return parseInt(a) !== parseInt(b);
}

/**
 * Checks a is equal to b.
 * @param {Integer} a 
 * @param {Integer} b 
 * @returns 
 */
export const dateEquals = (a, b) => {
  if (a && b) {
    const recordDate = new Date(a);
    const filterDate = new Date(b);
    return recordDate.setHours(0,0,0,0) === filterDate.setHours(0,0,0,0);
  }
  return false;
}

/**
 * Checks a is not equal to b.
 * @param {Integer} a 
 * @param {Integer} b 
 * @returns 
 */
export const dateNotEquals = (a, b) => {
  if (a && b) {
    const recordDate = new Date(a);
    const filterDate = new Date(b);
    return recordDate.setHours(0,0,0,0) !== filterDate.setHours(0,0,0,0);
  }
  return false;
}

/**
 * Checks a is less than b.
 * @param {Integer} a 
 * @param {Integer} b 
 * @returns 
 */
export const isLessThan = (a, b) => {
  return parseInt(a) < parseInt(b);
}

/**
 * Checks a is greater than b.
 * @param {Integer} a 
 * @param {Integer} b 
 * @returns 
 */
export const isMoreThan = (a, b) => {
  return parseInt(a) > parseInt(b);
}

/**
 * Checks if b is a substring of a.
 * @param {String} a 
 * @param {String} b 
 * @returns 
 */
export const contains = (a, b) => {
  return !!a && !!b && a.toLowerCase().includes(b.toLowerCase());
}

/**
 * Checks if b is a substring of a.
 * @param {String} a 
 * @param {String} b 
 * @returns 
 */
export const isOneOf = (a, b) => {
  console.log(a)
  console.log(b)
  return !!a && !!b && b.key === a;
}

/**
 * Checks if any value in array a exists in array b and returns true if it does.
 * @param {Array} a 
 * @param {Array} b 
 * @returns 
 */
export const arrayContains = (a, b) => {
  return !!a && !!b && b.some(bVal => a.some(aVal => aVal.includes(bVal)));
}

/**
 * Checks if b is not a substring of a.
 * @param {String} a 
 * @param {String} b 
 * @returns 
 */
export const notContains = (a, b) => {
  return !a || !b || !a.toLowerCase().includes(b.toLowerCase());
}

/**
 * Checks if any value in array b exists in array a and returns false if it does.
 * @param {Array} a 
 * @param {Array} b 
 * @returns 
 */
export const arrayNotContains = (a, b) => {
  return !a || !b || b.every(bVal => a.every(aVal => !aVal.includes(bVal)));
}

/**
 * Checks if a equates to false or has length === 0.
 * @param {*} a 
 * @returns 
 */
export const isEmpty = (a) => {
  console.log('isEmpty');
  console.log(a);
  return !!a === false || (a && a.length === 0);
}

/**
 * Checks if a equates to true or has length > 0.
 * @param {*} a 
 * @returns 
 */
export const isNotEmpty = (a) => {
  console.log('isNotEmpty');
  console.log(a);
  return !!a === true || (a && a.length > 0);
}

/**
 * Checks if b exists in a where a is an array.
 * @param {Array} a 
 * @param {*} b 
 * @returns 
 */
export const inMonth = (a, b) => {
  return a === b;
}