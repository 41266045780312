import './ActionViewManager.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { MESSAGING_VIEWS, RECORD_TYPES } from '../../MessagingConstants';

import BroadcastPage from './broadcast/BroadcastPage';
import NewBroadcastPage from './broadcast/NewBroadcastPage';
import ContactActionDashboard from './contacts/ContactActionDashboard';
import TaskDetailContainer from './contacts/tasks/TaskDetailContainer';

const { SEARCH, BROADCAST, TASKS, INBOX, CUSTOMERS, EMPLOYEES } = MESSAGING_VIEWS;

const propTypes = {};

function ActionViewManager({ view, viewFilters, selectedRecord, broadcastList, setSelected, setRecord, setSelectedRecord, setViewFilters }) {
  let actionView = null;

  if ((view === CUSTOMERS.key || view === EMPLOYEES.key || view === SEARCH.key) && selectedRecord !== null) {
    actionView = <ContactActionDashboard selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} />;
  } else if (view === BROADCAST.key && viewFilters === BROADCAST.filters.NEW.key) {
    actionView = <NewBroadcastPage broadcastList={broadcastList} setSelected={setSelected} setViewFilters={setViewFilters} setRecord={setRecord(RECORD_TYPES.BROADCAST.key)} />;
  } else if (view === BROADCAST.key && viewFilters === BROADCAST.filters.UPCOMING.key && selectedRecord !== null) {
    actionView = <BroadcastPage selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} />;
  } else if (view === TASKS.key) {
    actionView = <TaskDetailContainer selectedRecord={selectedRecord} setSelectedRecord={setRecord(RECORD_TYPES.TASK.key)} viewFilters={viewFilters} />;
  } else if (view === INBOX.key && selectedRecord !== null) {
    // actionView = <MessagesPage selectedRecord={selectedRecord} />;
    actionView = <ContactActionDashboard selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} />;
  }

  return (
    <section className={'action-manager-container'}>
      {actionView}
    </section>
  )
}

ActionViewManager.displayName = 'ActionViewManager';
ActionViewManager.propTypes = propTypes;
export default ActionViewManager;