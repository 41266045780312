import './TimeOption.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function TimeOption({ selectedTime, time, setTime }) {
  const selected = selectedTime === time.key;
  return (
    <button className={`time-option ${selected ? 'selected' : ''}`} onClick={() => setTime(time.key)} type={'button'}>
      {time.text}
    </button>
  )
}

TimeOption.displayName = 'TimeOption';
TimeOption.propTypes = propTypes;
export default TimeOption;