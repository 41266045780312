import './BroadcastContactsFilter.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../../../Constants';

import FilterDashboard from './FilterDashboard';

const FILTER_ICON = `${ASSET_CDN_URL}/icons/workrex_filter_icon.svg`;
const UNFILLED_FILTER_ICON = `${ASSET_CDN_URL}/icons/workrex_filter_icon_unfilled.svg`;

const propTypes = {};

function BroadcastContactsFilter({ filters, setFilters, applyFilters }) {
  const [open, setOpen] = useState(false);
  const timeoutRef = useRef(null);
  const filterIcon = filters.length > 0 ? FILTER_ICON : UNFILLED_FILTER_ICON;

  const toggle = useCallback((select) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    const wrapper = document.getElementById(`broadcasts-dropdown-wrapper`);
    const dropdownText = document.getElementById(`broadcasts-dropdown-list`);
    const header = document.getElementById('broadcasts-dropdown-header');

    if (open !== null) {
      setOpen(!select);
    }

    // + 2 for border
    wrapper.style.height = select ? '0px' : `${dropdownText.clientHeight + 2}px`;
    wrapper.style.maxHeight = select ? '0px' : `${dropdownText.clientHeight + 2}px`;
    wrapper.style.top = `${header.clientHeight + 20}px`;
    wrapper.style.left = `-${header.clientWidth}px`

    if (select) {
      wrapper.style.overflow = 'hidden';
    }
    else {
      timeoutRef.current = setTimeout(() => {
        wrapper.style.overflow = 'unset';
      }, 200);
    }
  }, [open])

  useEffect(() => {
    if (open) {
      toggle(null)
    }
  }, [open, toggle, filters])

  return (
    <section className={'broadcasts-dropdown-container'}>
      <button id={'broadcasts-dropdown-header'} className={'dropdown-header'} onClick={() => toggle(open)}>
        <img alt={'filter-icon'} src={filterIcon} className={'filter-icon'} />
      </button>
      <section id={`broadcasts-dropdown-wrapper`} className={'dropdown-content'}>
        <section id={`broadcasts-dropdown-list`} className={'dropdown-answer'}>
          <FilterDashboard filters={filters} setFilters={setFilters} containerToggle={toggle} applyFilters={applyFilters} />
        </section>
      </section>
    </section>
  )
}

BroadcastContactsFilter.displayText = 'BroadcastContactsFilter';
BroadcastContactsFilter.propTypes = propTypes;
export default BroadcastContactsFilter;
