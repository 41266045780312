import './ChannelTabList.scss';

import React from 'react';
import PropTypes from 'prop-types';

import ChannelTab from './ChannelTab';

import { CHANNELS } from '../../CampaignConstants';

const propTypes = {};

function ChannelTabList({ channels, selectedTab, selectChannel }) {
  const channelTabs = channels.map((key, index) => {
    const channel = CHANNELS[key];
    const { displayText } = channel;
    const onClick = () => selectChannel(key);
    const style = { left: `${index * -2}px`};
    const selected = selectedTab === key;
    return (
      <ChannelTab selected={selected} displayText={displayText} style={style} key={key} onClick={onClick} />
    );
  });
  return (
    <ul className={'channel-tab-list'}>
      {channelTabs}
    </ul>
  );
}

ChannelTabList.displayName = 'ChannelTabList';
ChannelTabList.propTypes = propTypes;
export default ChannelTabList;