import './ManagerPage.scss';
import DotBricksLoading from '../../common/DotBricksLoading';

import React, { useEffect } from 'react';

const propTypes = {};

function ManagerPage() {
  useEffect(() => {
    window.location.href = 'https://www.workrex.io';
  });
  return <DotBricksLoading />;
}

ManagerPage.displayName = 'ManagerPage';
ManagerPage.propTypes = propTypes;
export default ManagerPage;