export const AUTOMATIONS_TABS = {
  AVAILABLE_AUTOMATIONS: 'AVAILABLE_AUTOMATIONS',
  AUTOMATION_LIBRARY: 'AUTOMATION_LIBRARY',
  REQUEST_AUTOMATION: 'REQUEST_AUTOMATION',
};

export const AUTOMATION_TYPES = {
  AVAILABLE: {
    key: 'AVAILABLE',
    className: 'available',
    displayTextActive: 'On',
    displayTextInactive: 'Off',
  },
  REQUESTED: {
    key: 'REQUESTED',
    className: 'requested',
    displayTextActive: 'Requested',
    displayTextInactive: 'Requested',
  },
  MODULE: {
    key: 'MODULE',
    className: 'module',
    displayTextActive: 'Request',
    displayTextInactive: 'Request',
  },
};

export const AUTOMATION_LABELS = {
  ALL_INDUSTRIES: 'All Industries',
  OPTICAL_PRACTICES: 'Optical Practices',
}

const { ALL_INDUSTRIES, OPTICAL_PRACTICES } = AUTOMATION_LABELS;

export const AUTOMATION_MODULES = {
  EMPLOYEE_PULSE_CHECK_SYSTEM: {
    key: 'EMPLOYEE_PULSE_CHECK_SYSTEM',
    title: 'Employee pulse check system',
    label: ALL_INDUSTRIES,
    objective: 'Improve employee experience',
    recommendedUrl: 'https://www.workrex.io/templates/simple-guide-to-setting-up-an-employee-pulse-check-system',
    description: 'Automatically gather and track employee pulse checks, proactively surfacing issues and encouraging check-ins from their managers',
    active: false,
    type: AUTOMATION_TYPES.MODULE.key,
  },
  EMPLOYEE_PERFORMANCE_SELF_ASSESSMENT_SYSTEM: {
    key: 'EMPLOYEE_PERFORMANCE_SELF_ASSESSMENT_SYSTEM',
    title: 'Employee performance self-assessment system',
    label: ALL_INDUSTRIES,
    objective: 'Improve employee experience',
    recommendedUrl: null,
    description: 'Automatically gather a self assessment from your employees against performance areas, and remind managers to have performance / goal setting discussions with their staff',
    active: false,
    type: AUTOMATION_TYPES.MODULE.key,
  },
  PATIENT_RECALL_SYSTEM: {
    key: 'PATIENT_RECALL_SYSTEM',
    title: 'Patient recall system',
    label: OPTICAL_PRACTICES,
    objective: 'Increase booking volume',
    recommendedUrl: 'https://www.workrex.io/templates/simple-guide-to-setting-up-a-patient-recall-system',
    description: 'Generate appointment bookings and patient loyalty by sending a personalised recall reminder to your patients that visited 12 & 24 months ago',
    active: false,
    type: AUTOMATION_TYPES.MODULE.key,
  },
  PATIENT_FEEDBACK_SYSTEM: {
    key: 'PATIENT_FEEDBACK_SYSTEM',
    title: 'Patient feedback system',
    label: OPTICAL_PRACTICES,
    objective: 'Improve patient experience',
    recommendedUrl: 'https://www.workrex.io/templates/simple-guide-to-setting-up-a-patient-feedback-review-system-for-optical-practices',
    description: 'Automatically gather patient feedback post appointment and build your reputation through positive Google reviews',
    active: false,
    type: AUTOMATION_TYPES.MODULE.key,
  },
  WELCOME_NEW_PATIENTS_SYSTEM: {
    key: 'WELCOME_NEW_PATIENTS_SYSTEM',
    title: 'Welcome new patients system',
    label: OPTICAL_PRACTICES,
    objective: 'Improve patient experience',
    recommendedUrl: 'https://www.workrex.io/templates/simple-guide-to-setting-up-an-appointment-no-show-cancellation-rescheduling-system',
    description: 'Send new patients booked into your practice a welcome message ahead of their visit to highlight your unique brand',
    active: false,
    type: AUTOMATION_TYPES.MODULE.key,
  },
  RESCHEDULE_PATIENT_NO_SHOWS: {
    key: 'RESCHEDULE_PATIENT_NO_SHOWS',
    title: 'Reschedule patient no-shows',
    label: OPTICAL_PRACTICES,
    objective: 'Increasing booking volume',
    recommendedUrl: 'https://www.workrex.io/templates/simple-guide-to-setting-up-an-appointment-no-show-cancellation-rescheduling-system',
    description: 'For patients that didn\'t show up, follow up with them to reschedule into another appointment',
    active: false,
    type: AUTOMATION_TYPES.MODULE.key,
  },
  NEW_PATIENT_REFERRAL_SYSTEM: {
    key: 'NEW_PATIENT_REFERRAL_SYSTEM',
    title: 'New patient referral system',
    label: OPTICAL_PRACTICES,
    objective: 'Acquire new patients',
    recommendedUrl: 'https://www.workrex.io/templates/simple-guide-to-setting-up-a-new-patient-referral-system',
    description: 'Automatically ask your new patients for word of mouth referrals if they had a good experience',
    active: false,
    type: AUTOMATION_TYPES.MODULE.key,
  },
};

export const AUTOMATION_ACTIVITY_EVENT_TYPES = {
  AUTOMATION_RUN: 'AUTOMATION_RUN',
};

const { AUTOMATION_RUN } = AUTOMATION_ACTIVITY_EVENT_TYPES;

export const DUMMY_AUTOMATION = {
  _id: 1,
  title: 'Dummy 1',
  objective: ALL_INDUSTRIES,
  description: 'Automatically gather a self assessment from your employees against performance areas, and remind managers to have performance / goal setting discussions with their staff',
  type: AUTOMATION_TYPES.AVAILABLE.key,
  actions: [
    {
      title: 'Alert patients who do not make a same day patient',
      targetDescription: 'Patients who do not make a purchase on the same day',
      triggerDescription: 'Triggered @2 days @after the appointment. Recurring @daily.',
      outputDescription: `Send customer this message: “Hi @customerName, @optometristName from @storeName here. Thanks for visiting! If you’re interested in coming back to the store, mention this message to any of our lovely team for a 5% discount. Works for the next 5 days.”`
    },
    {
      title: 'Notify you to act on the customer response',
      targetDescription: 'Send yourself a reminder to act on the customer response',
      triggerDescription: 'Triggered when a customer responds to the alert message',
      outputDescription: `Creates a task and the following message to yourself: "Hi @firstName, send a message back to @customerName at @customerPhoneNumber"`,
    },
    {
      title: 'Follow up with patient if patient makes a purchase',
      targetDescription: 'Patients who end up making a purchase',
      triggerDescription: 'Triggered if patient follows through and makes a purchase',
      outputDescription: `Send customer this message: “Hi @customerName, @optometristName from @storeName</span> here. Thanks for making a purchase and supporting our business. We appreciate you!”`
    },
  ],
  active: null,
  automationHistory: [
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 1 },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 2 },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 3 },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 4 },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 4 },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 4 },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 4 },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 4 },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 4 },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 4 },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 4 },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 4 },
    },
    {
      eventType: AUTOMATION_RUN,
      context: { number: 15, date: Date.now() + 4 },
    },
    
  ],
}