import './ViewAutomationHeader.scss';

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../../../../context/AuthContext';

import AutomationDropdown from '../../common/AutomationDropdown';
import AutomationActiveToggle from './AutomationActiveToggle';
import ArchiveAutomationModal from '../../modals/ArchiveAutomationModal';
import SupportRequestAutomationModal from '../../modals/SupportRequestAutomationModal';

import EDITING_BUTTON_ICON from '../../assets/unselected-editing-icon.svg';
import { postAutomationArchive, postAutomationSupport } from '../../../../../../../apis/Api';

const propTypes = {};

function ViewAutomationHeader({ title, initialDescription, automationId, type, active, back, setActive }) {
  const navigate = useNavigate();
  const descriptionRef = useRef(null);
  const { setUser } = useAuth();
  const [descriptionHeight, setDescriptionHeight] = useState(null);
  const [editing, setEditing] = useState(false);
  const [description, setDescription] = useState(initialDescription);
  const [archiveModalId, setArchiveModalId] = useState(null);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const closeArchiveModal = () => setArchiveModalId(null);
  const closeSupportModal = () => setSupportModalOpen(false);
  const archive = (automationId) => {
    setArchiveModalId(automationId);
  }
  const archiveAutomation = () => {
    postAutomationArchive(automationId)
      .then(res => {
        if (res.user) {
          setUser(res.user);
          back();
        }
      });
  }
  const requestSupport = (automationId) => {
    // TODO: Add backend logic to send requested support
    postAutomationSupport(automationId);
    setSupportModalOpen(true);
  }
  const onChange = ({ target }) => setDescription(target.value);
  useEffect(() => {
    if (!editing && descriptionRef.current) {
      setDescriptionHeight(descriptionRef.current.clientHeight - 10);
    }
  }, [descriptionRef.current, editing]);

  const textInput = editing
    ? (
      <textarea
        className={`description ${editing ? '' : 'disabled'}`}
        onChange={onChange}
        value={description}
        style={{ height: descriptionHeight }}
        placeholder={'Write your automation description here'}
      />
    )
    : (
      <span className={'description disabled'} ref={descriptionRef}>
        {description}
      </span>
    );
  const options = [
    { displayText: 'Archive', onClick: () => archive(automationId) },
    { displayText: 'Edit', onClick: () => navigate('/automations/edit', { state: { automationId } }) },
    { displayText: 'Ask for support', onClick: () => requestSupport(automationId) },
  ];
  return (
    <header className={'view-automation-header'}>
      { archiveModalId !== null && <ArchiveAutomationModal type={type} close={closeArchiveModal} archive={archiveAutomation} /> }
      { supportModalOpen && <SupportRequestAutomationModal close={closeSupportModal} /> }
      <section className={'header-container'}>
        <h4 className={'automation-title'}>{title}</h4>
        <p className={'description-container'}>
          {textInput}
          {/**  
            <button className={'editing-button'} onClick={() => setEditing(!editing)}>
              <img className={'editing-button-icon'} src={EDITING_BUTTON_ICON} />
            </button>
          */}
        </p>
      </section>
      <section className={'setting-buttons'}>
        <AutomationActiveToggle active={active} setActive={setActive} />
        <AutomationDropdown options={options} />
      </section>
    </header>
  );
}

ViewAutomationHeader.displayName = 'ViewAutomationHeader';
ViewAutomationHeader.propTypes = propTypes;
export default ViewAutomationHeader;