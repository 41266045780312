import { ASSET_CDN_URL } from "../../../../../Constants";

const PUBLISHED_ICON = `${ASSET_CDN_URL}/icons/workrex_active_engagements.svg`;
const UNPUBLISHED_ICON = `${ASSET_CDN_URL}/icons/workrex_draft_engagements.svg`;
const LIBRARY_ICON = `${ASSET_CDN_URL}/icons/workrex_engagement_library.svg`;

export const CONTENT_TABS = {
  LIBRARY: 'LIBRARY',
  UNPUBLISHED: 'UNPUBLISHED',
  PUBLISHED: 'PUBLISHED',
}

const { LIBRARY, UNPUBLISHED, PUBLISHED } = CONTENT_TABS;

export const CONTENT_TAB_LIST = [
  {
    key: PUBLISHED,
    displayText: 'Published content',
    url: '/content/published',
    unselectedIcon: PUBLISHED_ICON,
    selectedIcon: null,
  },
  {
    key: UNPUBLISHED,
    displayText: 'Unpublished content',
    url: '/content/unpublished',
    unselectedIcon: UNPUBLISHED_ICON,
    selectedIcon: null,
  },
  {
    key: LIBRARY,
    displayText: 'WorkRex content library',
    url: '/content/library',
    unselectedIcon: LIBRARY_ICON,
    selectedIcon: null,
  },
];

export const FILE_TYPES = {
  JPEG: 'JPEG',
  PNG: 'PNG',
  PDF: 'PDF',
  MP4: 'MP4',
  CSV: 'CSV',
  SVG: 'SVG',
}

const { JPEG, PNG, PDF, MP4, CSV, SVG } = FILE_TYPES;

export const FILE_TYPE_TO_SUFFIX = {
  [JPEG]: '.jpg',
  [PNG]: '.png',
  [PDF]: '.pdf',
  [MP4]: '.mp4',
  [CSV]: '.csv',
  [SVG]: '.svg',
};