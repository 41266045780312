import './CampaignRow.scss';

import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PropTypes from 'prop-types';

import DeleteCampaignModal from '../modals/DeleteCampaignModal';
import { CAMPAIGN_PAGE_TYPES, CHANNELS } from '../CampaignConstants';
import EllipsisDropdown from '../../../common/EllipsisDropdown';
import { postDeleteCampaign } from '../../../../../../apis/CampaignApi';

const { LIBRARY, DRAFT, PUBLISHED } = CAMPAIGN_PAGE_TYPES;

const propTypes = {};

const NAME_MAX_LENGTH = 50;

const truncateText = (name, maxLength) => {
  if (name.length >= maxLength) {
    return `${name.slice(0, maxLength - 3)}...`;
  }
  return name;
}

function CampaignRow({ name, creationDate = null, description, channels, numVariants = null, type, campaign, campaignId }) {
  const { setCampaign } = useOutletContext();
  const navigate = useNavigate();
  const [openDeletionModal, setOpenDeletionModal] = useState(false);
  const additionalClassNames = [];
  let dateCreatedSection = null;
  let variantCountSection = null;
  let viewButtonText = '';
  let ellipsisButton = null;
  const closeDeletionModal = () => setOpenDeletionModal(false);
  const openCampaignDeletionModal = () => setOpenDeletionModal(true);
  let viewOnClick = () => {};
  if (type === DRAFT) {
    additionalClassNames.push('draft');
    const dateText = creationDate.toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' });
    dateCreatedSection = <section className={'row-date'}>{dateText}</section>;
    const variantText = numVariants === 1 ? 'variant' : 'variants';
    variantCountSection = <section className={'row-variant-count'}>{`${numVariants} ${variantText}`}</section>
    viewButtonText = 'Resume';
    viewOnClick = () => {
      setCampaign(campaign);
      window.localStorage.setItem('campaign', JSON.stringify(campaign));
      navigate('/campaigns/create/new');
    }
    const options = [
      {
        displayText: 'Edit campaign',
        onClick: () => {
          setCampaign(campaign);
          window.localStorage.setItem('campaign', JSON.stringify(campaign));
          navigate('/campaigns/create/new');
        },
      },
      {
        displayText: 'Delete campaign',
        onClick: () => {
          openCampaignDeletionModal();
        },
      },
      {
        displayText: 'View campaign',
        
        onClick: () => {
          setCampaign(campaign);
          window.localStorage.setItem('campaign', JSON.stringify(campaign));
          navigate('/campaigns/view');
        },
      }
    ];
    ellipsisButton = (
      <section className={'row-ellipsis-button'}>
        <EllipsisDropdown options={options} />
      </section>
    );
  }
  else if (type === PUBLISHED) {
    additionalClassNames.push('published');
    const dateText = creationDate.toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' });
    dateCreatedSection = <section className={'row-date'}>{dateText}</section>;
    const variantText = numVariants === 1 ? 'variant' : 'variants';
    variantCountSection = <section className={'row-variant-count'}>{`${numVariants} ${variantText}`}</section>
    viewButtonText = 'View';
    const options = [
      {
        displayText: 'Duplicate campaign',
        onClick: () => {
          setCampaign(campaign);
          window.localStorage.setItem('campaign', JSON.stringify(campaign));
          navigate('/campaigns/create');
        },
      },
      {
        displayText: 'Delete campaign',
        onClick: () => {
          openCampaignDeletionModal();
        },
      },
      {
        displayText: 'View campaign',
        onClick: () => {
          setCampaign(campaign);
          window.localStorage.setItem('campaign', JSON.stringify(campaign));
          navigate('/campaigns/view');
        }
      }
    ];
    ellipsisButton = (
      <section className={'row-ellipsis-button'}>
        <EllipsisDropdown options={options} />
      </section>
    );
    viewOnClick = () => {
      setCampaign(campaign);
      window.localStorage.setItem('campaign', JSON.stringify(campaign));
      navigate('/campaigns/view');
    }
  }
  else if (type === LIBRARY) {
    viewButtonText = 'Use';
  }
  const channelIcons = channels.map((channel) => {
    return <img className={'channel'} src={CHANNELS[channel].selectedIcon} alt={channel} key={channel} />
  })
  return (
    <section className={`campaign-row ${additionalClassNames.join(' ')}`}>
      { openDeletionModal && <DeleteCampaignModal close={closeDeletionModal} campaignId={campaignId} /> }
      <section className={'row-name'}>{truncateText(name, NAME_MAX_LENGTH)}</section>
      {dateCreatedSection}
      <p className={'row-description'}>{description}</p>
      <section className={'channels'}>
        {channelIcons}
      </section>
      {variantCountSection}
      <section className={'row-view-button'}>
        <button className={'view-button'} onClick={viewOnClick}>{viewButtonText}</button>
      </section>
      {ellipsisButton}
    </section>
  );
}

CampaignRow.displayName = 'CampaignRow';
CampaignRow.propTypes = propTypes;
export default CampaignRow;