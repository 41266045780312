import './ManagerWaitlistPage.scss';

import React, { useState, useEffect } from 'react';
import IframeResizer from 'iframe-resizer-react';
import DotBricksLoading from '../../common/DotBricksLoading';

const propTypes = {};

function ManagerWaitlistPage() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  return (
    <article className={`iframe-container manager-waitlist-page ${loading ? 'loading' : ''}`}>
      { loading && <DotBricksLoading /> }
      <IframeResizer
        src={'https://z2m4caj4dl7.typeform.com/to/VpVZ56jv'}
        scrolling={false}
        sizeWidth={true}
      />
    </article>
  );
}

ManagerWaitlistPage.displayName = 'ManagerWaitlistPage';
ManagerWaitlistPage.propTypes = propTypes;
export default ManagerWaitlistPage;