import './WorkRexPageWrapper.scss';

import React from 'react';
import Breadcrumb from './Breadcrumb';
import PropTypes from 'prop-types';

const propTypes = {};

function WorkRexPageWrapper({ additionalClassNames, additionalOptions, children }) {
  const options = [{ displayName: 'Navigation', href: null }, ...additionalOptions];
  return (
    <article className={`workrex-page ${additionalClassNames.join(' ')}`}>
      <Breadcrumb options={options} />
      {children}
    </article>
  );
}

WorkRexPageWrapper.displayName = 'WorkRexPageWrapper';
WorkRexPageWrapper.propTypes = propTypes;
export default WorkRexPageWrapper;