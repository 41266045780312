import './TemplatesPageWrapper.scss';

import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import CommunicationsPageWrapper from '../CommunicationsPageWrapper';
import { COMMUNICATION_TABS } from '../CommunicationsConstants';

const { TEMPLATES } = COMMUNICATION_TABS;

const propTypes = {};

function TemplatesPageWrapper({ children, showTabs = true, additionalClassNames = [] }) {
  const location = useLocation();
  const options = [{ displayName: 'Templates', href: '/templates' }];
  const { pathname } = location;
  if (pathname.includes('draft')) {
    options.push({ displayName: 'Your draft templates', href: '/templates/draft' });
    if (pathname.includes('new')) {
      options.push({ displayName: 'Create a new template', href: '/templates/draft/new' });
    }
    if (pathname.includes('view')) {
      options.push({ displayName: 'View template', href: '/templates/draft/view' });
    }
  }
  else if (pathname.includes('library')) {
    options.push({ displayName: 'The WorkRex template library', href: '/templates/library' });
    if (pathname.includes('new')) {
      options.push({ displayName: 'Create a new template', href: '/templates/library/new' });
    }
    if (pathname.includes('view')) {
      options.push({ displayName: 'View template', href: '/templates/library/view' });
    }
  }
  else if (pathname.includes('published')) {
    options.push({ displayName: 'Your published templates', href: '/templates/published' });
    if (pathname.includes('new')) {
      options.push({ displayName: 'Create a new template', href: '/templates/published/new' });
    }
    if (pathname.includes('view')) {
      options.push({ displayName: 'View template', href: '/templates/published/view' });
    }
  }
  return (
    <CommunicationsPageWrapper
      additionalClassNames={['templates-page', ...additionalClassNames]}
      additionalOptions={options}
      selectedTab={TEMPLATES}
      showNavigationTabs={showTabs}
    >
      { children }
    </CommunicationsPageWrapper>
  );
}

TemplatesPageWrapper.displayName = 'TemplatesPageWrapper';
TemplatesPageWrapper.propTypes = propTypes;
export default TemplatesPageWrapper;