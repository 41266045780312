import './AccountPageWrapper.scss';

import React from 'react';
import PropTypes from 'prop-types';
import WorkRexPageWrapper from '../common/WorkRexPageWrapper';

const propTypes = {};

function AccountPageWrapper({ additionalClassNames = [], additionalOptions = [], children }) {
  const classNames = ['account-page', ...additionalClassNames];
  const options = [{ displayName: 'My account', href: '/account' }, ...additionalOptions];
  return (
    <WorkRexPageWrapper additionalClassNames={classNames} additionalOptions={options}>
      <section className={'account-page-container'}>
        {children}
      </section>
    </WorkRexPageWrapper>
  )
}

AccountPageWrapper.displayName = 'AccountPageWrapper';
AccountPageWrapper.propTypes = propTypes;
export default AccountPageWrapper;