import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const IGNORED_PATHNAMES = [];

/**
 * Component that provides functionality to autoscroll to top of page.
 * Add pathnames that you want to exclude from this behavior in IGNORED_PATHNAMES.
 */
const ScrollToTopHook = () => {
  const location = useLocation();
  useEffect(() => {
    if (!IGNORED_PATHNAMES.includes(location.pathname)) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (null);
}

export default ScrollToTopHook;