import './CustomersListView.scss';

import React, { useState } from 'react';
import { uniqueId } from 'lodash';

import ManagerTable from '../common/ManagerTable';
import { RECIPIENT_ACTIVE_STATUSES, DELIVERABILITY, EMPTY_CUSTOMER, SALES_STAGE, CUSTOMER_STATUS_HEADERS, CUSTOMER_TEXT_HEADERS } from './RecipientsConstants';
import useAuth from '../../../../context/AuthContext';
import { splitDates } from '../../../../utils/DataUtils';

import TableDropdown from '../common/TableDropdown';
import TextInput from '../common/TextInput';
import DotBricksLoading from '../../../common/DotBricksLoading';
import GroupsDropdown from './modals/GroupsDropdown';
import AddGroupsModal from './modals/AddGroupsModal';
import SearchGroupsModal from './modals/SearchGroupsModal';
import { DATE_FIELDS, DERIVED_FIELDS } from '../ManagerConstants';

const { LEAD } = SALES_STAGE;
const { ACTIVE, DELETE } = RECIPIENT_ACTIVE_STATUSES;
const { SUBSCRIBED, OPTED_OUT, UNDELIVERABLE } = DELIVERABILITY;

const propTypes = {};

function CustomersListView({ editingRows, setEditingRows, rows, setRows, increasePageCount, loading }) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [openCreateGroupModal, setOpenCreateGroupModal] = useState(false);
  const openCreateModal = (index) => () => {
    setOpenCreateGroupModal(true);
    setSelectedIndex(index);
  }
  const closeCreateModal = () => setOpenCreateGroupModal(false);
  const [openSearchGroupModal, setOpenSearchGroupModal] = useState(false);
  const openSearchModal = (index) => () => {
    setOpenSearchGroupModal(true);
    setSelectedIndex(index);
  }
  const closeSearchModal = () => setOpenSearchGroupModal(false);
  const { user } = useAuth();
  const groupNameMap = user.groups.filter(group => group._id !== 'ALL_GROUPS').reduce((groupNames, group) => ({ ...groupNames, [group._id]: { key: group._id, displayText: group.name } }), {});

  const selectGroups = (index) => (newGroup) => {
    if (!editingRows[index]) {
      const newEditingRows = [...editingRows];
      newEditingRows[index] = true;
      setEditingRows(newEditingRows);
    }
    const newRows = [...rows];
    let groupList = newRows[index].groups;
    if (groupList.includes(newGroup)) {
      groupList = groupList.filter(group => group !== newGroup);
    }
    else {
      groupList.push(newGroup);
    }
    newRows[index] = { ...newRows[index], groups: groupList };
    setRows(newRows);
  }

  const generatedRows = rows
    .filter(({ status }, index) => status !== DELETE || editingRows[index])
    .map((row, index) => {
      const {
        firstName,
        lastName,
        groups,
        mobileNumber,
        emailAddress,
        address = '',
        formattedBirthday,
        source,
        favouriteBrands,
        salesStage = LEAD.key,
        status = ACTIVE.key,
        deliverability = SUBSCRIBED.key,
        undeliverable = false,
        customerSegmentCategory = ''
      } = row;
      const setText = (fieldName) => (value) => {
        const newRows = [...rows];
        newRows[index] = { ...newRows[index], [fieldName]: value };
        setRows(newRows);
      };

    const openEditing = () => {
      const newEditingRows = [...editingRows];
      newEditingRows[index] = true;
      setEditingRows(newEditingRows);
    }

    const formattedBirthdayNode = editingRows[index] ? <TextInput text={formattedBirthday} setText={setText('formattedBirthday')} processText={(text) => text.toLowerCase()} /> : <button className={'cell-text'} onClick={openEditing}>{formattedBirthday}</button>;

    const groupNames = groups.map(group => groupNameMap[group].displayText).join(', ');

    const firstNameNode = editingRows[index] ? <TextInput text={firstName} setText={setText('firstName')} /> : <button className={'cell-text'} onClick={openEditing}>{firstName}</button>;
    const lastNameNode = editingRows[index] ? <TextInput text={lastName} setText={setText('lastName')} /> : <button className={'cell-text'} onClick={openEditing}>{lastName}</button>;
    const mobileNumberNode = editingRows[index] ? <TextInput text={mobileNumber} setText={setText('mobileNumber')} /> : <button className={'cell-text'} onClick={openEditing}>{mobileNumber}</button>;
    const emailAddressNode = editingRows[index] ? <TextInput text={emailAddress} setText={setText('emailAddress')} processText={(text) => text.toLowerCase()} /> : <button className={'cell-text'} onClick={openEditing}>{emailAddress}</button>;
    const addressNode = editingRows[index] ? <TextInput text={address} setText={setText('address')} processText={(text) => text.toLowerCase()} /> : <button className={'cell-text'} onClick={openEditing}>{address}</button>;
    const sourceNode = editingRows[index] ? <TextInput text={source} setText={setText('source')} processText={(text) => text.toLowerCase()} /> : <button className={'cell-text'} onClick={openEditing}>{source}</button>;
    const favouriteBrandsNode = editingRows[index] ? <TextInput text={favouriteBrands} setText={setText('favouriteBrands')} processText={(text) => text.toLowerCase()} /> : <button className={'cell-text'} onClick={openEditing}>{favouriteBrands}</button>;
    const customerSegmentCategoryNode = editingRows[index] ? <TextInput text={customerSegmentCategory} setText={setText('customerSegmentCategory')} processText={(text) => text.toLowerCase()} /> : <button className={'cell-text'} onClick={openEditing}>{customerSegmentCategory}</button>;


    // FORMULA FIELDS - NO EDITING ALLOWED.
    const formulaFields = DERIVED_FIELDS
      .reduce((formulaFieldNodes, derivedField) => {
        let fieldValue = row[derivedField.fieldName];
        if (DATE_FIELDS.includes(derivedField.fieldName)) {
          fieldValue = fieldValue
            ? new Date(row[derivedField.fieldName]).toLocaleString('en-au', { day: 'numeric', month: 'numeric', year: 'numeric' })
            : '';
        }
        return {
          ...formulaFieldNodes,
          [derivedField.fieldName]: editingRows[index]
            ? <TextInput disabled={true} text={fieldValue} setText={setText(derivedField.fieldName)} processText={(text) => text.toLowerCase()} />
            : <button className={'cell-text'} onClick={openEditing}>{fieldValue}</button>
        };
      }, {});
    
    const selectSalesStage = (salesStage) => {
      if (!editingRows[index]) {
        openEditing();
      }
      const newRows = [...rows];
      newRows[index] = { ...newRows[index], salesStage };
      setRows(newRows);
    }

    const salesStageText = SALES_STAGE[salesStage].displayText;
    const salesStageTextNode = <span className={'button-text sales-stage'}>{salesStageText}</span>;
    const salesStageNode = <TableDropdown buttonText={salesStageTextNode} options={Object.values(SALES_STAGE)} select={selectSalesStage} selected={salesStage} />;

    const groupsTextNode = <span className={`group-cell-text`}>{groupNames}</span>;
    const groupsNode = <GroupsDropdown buttonText={groupsTextNode} options={Object.values(groupNameMap)} select={selectGroups(index)} selected={groups} openCreateGroupModal={openCreateModal(index)} openSearchGroupModal={openSearchModal(index)} />;

    const selectStatus = (newStatus) => {
      if (!editingRows[index]) {
        openEditing();
      }
      const newRows = [...rows];
      newRows[index] = { ...newRows[index], status: newStatus };
      setRows(newRows);
    }
    const statusClass = `${status === ACTIVE.key ? 'active-button' : 'inactive-button'} ${status === DELETE.key ? 'delete' : ''}`;
    const statusText = Object.values(RECIPIENT_ACTIVE_STATUSES).find(({ key }) => key === status).displayText;
    const statusTextNode = <span className={`button-text ${statusClass}`}>{statusText}</span>;
    const statusNode = <TableDropdown buttonText={statusTextNode} options={Object.values(RECIPIENT_ACTIVE_STATUSES)} select={selectStatus} selected={status} />;
    
    const deliverabilityStatus = undeliverable ? UNDELIVERABLE.key : deliverability;
    const isSubscribed = deliverabilityStatus === SUBSCRIBED.key;
    const isOptedOut = deliverabilityStatus === OPTED_OUT.key;
    const selectDeliverability = (newDeliverablityStatus) => {
      if (!editingRows[index]) {
        openEditing();
      }
      const newRows = [...rows];
      newRows[index] = { ...newRows[index], deliverability: newDeliverablityStatus };
      setRows(newRows);
    }

    const deliverabilityClass = `${undeliverable ? 'undeliverable' : ''} ${isSubscribed ? 'subscribed' : ''} ${isOptedOut ? 'opted-out' : ''}`;
    const deliverabilityTextNode = <span className={`button-text ${deliverabilityClass}`}>{deliverabilityStatus}</span>;
    const deliverabilityOptions = Object.values(DELIVERABILITY).filter(({ key }) => key !== UNDELIVERABLE.key);
    const deliverabilityNode = <TableDropdown buttonText={deliverabilityTextNode} options={deliverabilityOptions} select={selectDeliverability} selected={deliverabilityStatus} disabled={undeliverable} />;
    return {
      firstName: firstNameNode,
      lastName: lastNameNode,
      groups: groupsNode,
      mobileNumber: mobileNumberNode,
      emailAddress: emailAddressNode,
      address: addressNode,
      formattedBirthday: formattedBirthdayNode,
      source: sourceNode,
      favouriteBrands: favouriteBrandsNode,
      customerSegmentCategory: customerSegmentCategoryNode,
      // salesStage: salesStageNode,
      ...formulaFields,
      status: statusNode,
      deliverability: deliverabilityNode,
    };
  })
  const addCustomer = () => {
    const newRows = [...rows, { ...EMPTY_CUSTOMER, _id: uniqueId(), isNew: true }];
    const newEditingRows = [...editingRows, true];
    setRows(newRows);
    setEditingRows(newEditingRows);
  }

  const selectedGroups = selectedIndex !== null && rows[selectedIndex] && rows[selectedIndex].groups ? rows[selectedIndex].groups.map(group => groupNameMap[group]) : [];
  const derivedFields = DERIVED_FIELDS.map(({displayName, fieldName})=> ({ display: displayName, key: fieldName }));
  const customerTableHeaders = [ ...CUSTOMER_TEXT_HEADERS, ...derivedFields, ...CUSTOMER_STATUS_HEADERS ]
  return (
    <ManagerTable headers={customerTableHeaders} rows={generatedRows} noRowsText={'No customers have been added yet. Import your customers CSV or add customers manually below.'} loading={loading}>
      <section className={'customers-table-button-container'}>
        {!loading && !user.queriedAllCustomers && <button className={'view-more-customers-button'} onClick={increasePageCount}>{'View More Customers'}</button>}
        {loading &&
          <section className={'loading-container'}>
            <DotBricksLoading isFixed={false} tableSize={true} />
          </section>
        }
      </section>
      <button className={'add-recipient-button'} onClick={addCustomer}>{'+ Add customers'}</button>
      {openCreateGroupModal && <AddGroupsModal closeModal={closeCreateModal} selectGroup={selectGroups(selectedIndex)} selectedGroups={selectedGroups} /> }
      {openSearchGroupModal && <SearchGroupsModal closeModal={closeSearchModal} selectGroup={selectGroups(selectedIndex)} selectedGroups={selectedGroups} /> }
    </ManagerTable>
  )
}

CustomersListView.displayName = 'CustomersListView';
CustomersListView.propTypes = propTypes;
export default CustomersListView;
