import React from 'react';
import { Route, Navigate } from 'react-router-dom';

import WorkrexPage from '../workrex/wrappers/WorkrexPage';
import ContentPage from '../workrex/components/managers/communications/content/ContentPage';
import ContentManagementPage from '../workrex/components/managers/communications/content/ContentManagementPage';
import ContentLibrary from '../workrex/components/managers/communications/content/common/ContentLibrary';
import { WORKREX_CONTENT_LIBRARY } from '../workrex/components/managers/communications/content/library/ContentLibrary';
import { CONTENT_TABS, FILE_TYPES } from '../workrex/components/managers/communications/content/ContentConstants';
import ContentFilePage from '../workrex/components/managers/communications/content/file/ContentFilePage';

const { PUBLISHED, UNPUBLISHED, LIBRARY } = CONTENT_TABS;
const { JPEG, PNG, PDF } = FILE_TYPES;

/**
 * Routes relating to content.
 */
function getContentRoutes(files) {
  const publishedFiles = files.filter(({ status }) => status === PUBLISHED);
  const unpublishedFiles = files.filter(({ status }) => status === UNPUBLISHED);
  const counts = {
    [PUBLISHED]: publishedFiles.length,
    [UNPUBLISHED]: unpublishedFiles.length,
    [LIBRARY]: WORKREX_CONTENT_LIBRARY.length,
  }
  return (
    <Route path={'/'} element={<WorkrexPage />}>
      { /* TODO: apply some form of authorization to this route */ }
      <Route path={'content/:attachmentCode'} element={<ContentPage />} />
      <Route path={'content'} element={<ContentManagementPage counts={counts} />}>
        <Route index element={<Navigate to={'/content/published'} />} replace />
        <Route path={'published'} element={<ContentLibrary files={publishedFiles} />} />
        <Route path={'unpublished'} element={<ContentLibrary files={unpublishedFiles} />} />
        <Route path={'library'} element={<ContentLibrary files={WORKREX_CONTENT_LIBRARY} />} />
      </Route>
      <Route path={'file/:fileCode'} element={<ContentFilePage files={files} />} />
    </Route>
  );
}

export default getContentRoutes;