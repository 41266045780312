import './ContentFilePage.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';

import ContentPageWrapper from '../ContentPageWrapper';
import { Navigate } from 'react-router-dom';
import ContentFilePreview from './ContentFilePreview';
import ContentFileDescription from './ContentFileDescription';
import { CONTENT_TABS } from '../ContentConstants';
import { postFileTitle, postPublishFile } from '../../../../../../apis/ContentApi';
import { WORKREX_CONTENT_LIBRARY } from '../library/ContentLibrary';
import useAuth from '../../../../../../context/AuthContext';

const { UNPUBLISHED, LIBRARY } = CONTENT_TABS

const propTypes = {};

const AUTOSAVE_TIMEOUT = 1000;

function ContentFilePage({ files }) {
  const { setUser } = useAuth();
  const titleTimeoutIdRef = useRef(null);
  const { fileCode } = useParams();
  const navigate = useNavigate();
  let file = WORKREX_CONTENT_LIBRARY.find(({ code }) => code === fileCode);
  if (!file) { 
    file = files.find(({ code }) => code === fileCode);
  }
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(file && file.title);
  const [description, setDescription] = useState(file && file.description);
  const [tags, setTags] = useState(file && file.tags ? file.tags : []);
  if (!file) {
    return <Navigate to={'/content'} />;
  }
  const { _id, code, fileType, url, status } = file;
  const options = [{ displayName: title, href: `/file/${code}` }];
  const isLibrary = status === LIBRARY;

  const onTitleChange = ({ target }) => {
    if (titleTimeoutIdRef.current) {
      clearTimeout(titleTimeoutIdRef.current);
    }
    titleTimeoutIdRef.current = setTimeout(() => {
      postFileTitle(_id, target.value)
        .then((res) => setUser(res.user));
    }, AUTOSAVE_TIMEOUT);
    setTitle(target.value);
  };

  const publish = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    postPublishFile(_id)
      .then((res) => {
        setLoading(false);
        setUser(res.user);
        navigate('/content/published');
      });
  }
  const back = () => {
    if (status === LIBRARY) {
      navigate('/content/library');
    }
    else if (status === UNPUBLISHED) {
      navigate('/content/unpublished');;
    }
    else {
      navigate('/content/published');
    }
  }
  return (
    <ContentPageWrapper showTabs={false} additionalOptions={options}>
      <article className={'content-file-page'}>
        <header className={'file-header'}>
          <h3 className={'title'}>
            <input type={'text'} className={'title-input'} value={title} onChange={onTitleChange} disabled={isLibrary} />
          </h3>
          <section className={'buttons'}>
            <button className={'back-button'} onClick={back}>{'Back'}</button>
            { status === UNPUBLISHED && <button className={'publish-button'} onClick={publish} disabled={loading}>{'Publish content'}</button> }
          </section>
        </header>
        <section className={'file-metadata'}>
          <h4 className={'file-type'}>
            <span className={'label'}>{'File type'}</span>
            <span className={'type'}>{fileType}</span>
          </h4>
        </section>
        <section className={'file-details'}>
          <ContentFilePreview title={title} fileType={fileType} description={description} url={url} />
          <ContentFileDescription
            fileId={file._id}
            description={description}
            setDescription={setDescription}
            fileType={fileType}
            url={url}
            title={title}
            code={code}
            tags={tags}
            setTags={setTags}
            disabled={isLibrary}
          />
        </section>
      </article>
    </ContentPageWrapper>
  );
}

ContentFilePage.displayName = 'ContentFilePage';
ContentFilePage.propTypes = propTypes;
export default ContentFilePage;