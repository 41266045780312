import './GroupsDropdownOption.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {};

function GroupsDropdownOption({ option, selected, setSelected, useSearch }) {
  const answerSelected = selected._id === option._id;
  const selectedStyle = useSearch ? 'search-selected' : 'selected';

  return (
    <button className={`groups-dropdown-button ${answerSelected ? selectedStyle : ''}`} onClick={() => setSelected(option)} type={'button'}>
      <span className={'dropdown-button-text'}>{option.name}</span>
    </button>
  )
}

GroupsDropdownOption.defaultProps = { useSearch: false };
GroupsDropdownOption.displayText = 'GroupsDropdownOption';
GroupsDropdownOption.propTypes = propTypes;
export default GroupsDropdownOption;