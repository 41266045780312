import './OnboardingButton.scss';

import React, { useState } from 'react';
import { RECIPIENT_ONBOARDING } from '../RecipientsConstants';
import SendOnboardingModal from '../modals/SendOnboardingModal';

const { SENT } = RECIPIENT_ONBOARDING;

function OnboardingButton({ onboarding, userAccess, firstName, lastName, hasLoggedIn, userId, setOnboarding, emailAddress }) {
  const [open, setOpen] = useState(false);
  let buttonText = '';
  let className = '';
  if (onboarding === SENT) {
    if (hasLoggedIn) {
      buttonText = 'Onboarded';
      className = 'onboarded';
    }
    else {
      buttonText = 'Sent';
      className = 'sent';
    }
  }
  else {
    buttonText = 'Onboard';
    className = 'onboard';
  }
  const openOnboarding = () => {
    if (onboarding !== SENT) {
      setOpen(true);
    }
  }
  const close = () => setOpen(false);
  return (
    <>
      { open &&
        <SendOnboardingModal
          close={close}
          userId={userId}
          userAccess={userAccess}
          firstName={firstName}
          setOnboarding={setOnboarding}
          emailAddress={emailAddress}
        />
      }
      <button className={`onboarding-button ${className}`} onClick={openOnboarding} disabled={onboarding === SENT}>
        {buttonText}
      </button>
    </>
  );
};

OnboardingButton.displayName = 'OnboardingButton';
export default OnboardingButton;