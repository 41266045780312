import './AutomationsPage.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Outlet, useLocation } from 'react-router-dom';
import AutomationsPageWrapper from './AutomationsPageWrapper';
import AutomationTabs from './common/AutomationTabs';
import AutomationRequestContainer from './common/automation-request/AutomationRequestContainer';
import { AUTOMATIONS_TABS, AUTOMATION_TYPES, DUMMY_AUTOMATION } from './AutomationsConstants';

const { AVAILABLE_AUTOMATIONS, REQUEST_AUTOMATION, AUTOMATION_LIBRARY } = AUTOMATIONS_TABS;
const { AVAILABLE } = AUTOMATION_TYPES;

const propTypes = {};

function AutomationsPage() {
  const { pathname } = useLocation();
  let selectedTab = null;

  let hideAll = false;
  if (pathname.includes('available')) {
    selectedTab = AVAILABLE_AUTOMATIONS;
  }
  else if (pathname.includes('library')) {
    selectedTab = AUTOMATION_LIBRARY;
  }
  else if (pathname.includes('request')) {
    selectedTab = REQUEST_AUTOMATION;
  }
  else if (pathname.includes('view')) {
    selectedTab = AVAILABLE_AUTOMATIONS;
  }
  else if (pathname.includes('edit')) {
    hideAll = true;
  }
  return (
    <AutomationsPageWrapper showTabs={!hideAll}>
      { !hideAll && <AutomationTabs selectedTab={selectedTab} /> }
      <Outlet />
    </AutomationsPageWrapper>
  );
}

AutomationsPage.displayName = 'AutomationsPage';
AutomationsPage.propTypes = propTypes;
export default AutomationsPage;