import './TemplateLabelContainer.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function TemplateLabelContainer({ label, children }) {
  return (
    <section className={'template-label-container'}>
      <span className={'label'}>{label}</span>
      <section className={'template-label-children'}>
        {children}
      </section>
    </section>
  )
}

TemplateLabelContainer.displayName = 'TemplateLabelContainer';
TemplateLabelContainer.propTypes = propTypes;
export default TemplateLabelContainer;