import React from 'react';
import { Route, Navigate } from 'react-router-dom';

import { ENGAGEMENT_TABS, OUTREACH_OPPORTUNITIES } from '../workrex/components/managers/communications/engagements/EngagementConstants';

import WorkrexPage from '../workrex/wrappers/WorkrexPage';
import EngagementsPage from '../workrex/components/managers/communications/engagements/EngagementsPage';
import ActiveEngagements from '../workrex/components/managers/communications/engagements/active/ActiveEngagements';
import AllEngagements from '../workrex/components/managers/communications/engagements/all/AllEngagements';
import DraftEngagements from '../workrex/components/managers/communications/engagements/draft/DraftEngagement';

const { ENGAGEMENT_LIBRARY, ACTIVE_ENGAGEMENTS, DRAFT_ENGAGEMENTS } = ENGAGEMENT_TABS;

/**
 * Routes relating to engagements.
 */
function getEngagementRoutes(engagements) {
  const undeletedEngagements = engagements.filter(engagement => !engagement.deletionDate);
  const activeEngagements = undeletedEngagements.filter(({ state }) => ACTIVE_ENGAGEMENTS.filterState.includes(state));
  const draftEngagements = undeletedEngagements.filter(({ state }) => DRAFT_ENGAGEMENTS.filterState.includes(state));
  const counts = {
    [ACTIVE_ENGAGEMENTS.key]: activeEngagements.length,
    [DRAFT_ENGAGEMENTS.key]: draftEngagements.length,
    [ENGAGEMENT_LIBRARY.key]: OUTREACH_OPPORTUNITIES.length,
  };
  console.log(counts);
  return (
    <Route path={'/'} element={<WorkrexPage />}>
      { /* TODO: apply some form of authorization to this route */ }
      <Route path={'engagements'} element={<EngagementsPage counts={counts} />}>
        <Route index element={<Navigate to={'/engagements/library'} replace />} />
        <Route path={'library'} element={<AllEngagements engagements={undeletedEngagements} />} />
        <Route path={'active'} element={<ActiveEngagements engagements={undeletedEngagements} />} />
        <Route path={'draft'} element={<DraftEngagements engagements={undeletedEngagements} />} />
      </Route>
    </Route>
  );
}

export default getEngagementRoutes;