import './EditableTextInput.scss';

import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

const MIN_TEXTAREA_HEIGHT = 32;

function EditableTextInput({ text, setText, minTextAreaHeight = MIN_TEXTAREA_HEIGHT, disableResize = false, disabled = false }) {
  const textareaRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const onChange = ({ target }) => setText(target.value);
  useLayoutEffect(() => {
    if (!textareaRef.current || disableResize) {
      return;
    }
    // Reset height - important to shrink on deletee
    if (textareaRef.current.scrollHeight < minTextAreaHeight) {
      return;
    }
    textareaRef.current.style.height = "inherit";
    // Set height
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      minTextAreaHeight
    )}px`;
  }, [text, minTextAreaHeight, disableResize]);
  const inputComponent = editing ? (
    <textarea
      className={'editable-text-input'}
      value={text}
      onChange={onChange}
      ref={textareaRef}
    />
  ) : (
    <button className={`editable-text-button ${disabled ? 'disabled' : ''}`} onClick={() => setEditing(true)} disabled={disabled}>
      {text}
    </button>
  );
  useEffect(() => { 
    if (text.length === 0 && !editing) {
      setEditing(true);
    }
  }, [text, editing]);
  return (
    <section className={'editable-text-input-container'}>
      {inputComponent}
    </section>
  );
};

EditableTextInput.displayName = 'EditableTextInput';
export default EditableTextInput;