import './SortOption.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {};

function SortOption({ option, selected, setSelected }) {
  const answerSelected = selected === option.key;

  return (
    <button className={`sort-option-button ${answerSelected ? 'selected' : ''}`} onClick={() => setSelected(option.key)} type={'button'}>
      <span className={'sort-option-button-text'}>{option.displayText}</span>
    </button>
  )
}

SortOption.defaultProps = { useSearch: false };
SortOption.displayText = 'SortOption';
SortOption.propTypes = propTypes;
export default SortOption;