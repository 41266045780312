import './DropdownField.scss';

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../Constants';

import DropdownFieldOption from './DropdownFieldOption';

const DropdownArrow = `${ASSET_CDN_URL}/icons/solid-filled-dropdown-arrow.svg`;

const propTypes = {};

function DropdownField({ dropdownSelected, dropdownOptions, text, setSelectedData }) {
  const [open, setOpen] = useState(false);

  const toggle = useCallback((select) => {
    const wrapper = document.getElementById(`${text}-dropdown-wrapper`);
    const dropdownText = document.getElementById(`${text}-dropdown-list`);
    const header = document.getElementById('actions-dropdown-header');

    if (open !== null) {
      setOpen(!select);
    }

    wrapper.style.height = select ? '0px' : `${dropdownText.clientHeight}px`;
    wrapper.style.maxHeight = select ? '0px' : `${dropdownText.clientHeight}px`;
    wrapper.style.top = `${header.clientHeight}px`;
  }, [open, text])

  const setSelected = (option) => {
    setSelectedData(option);
    toggle(open);
  }

  useEffect(() => {
    if (open) {
      toggle(null)
    }
  }, [open, toggle])

  return (
    <section className={'dropdown-field-container'}>
      <section id={'actions-dropdown-header'} className={'dropdown-header'} onClick={() => toggle(open)}>
        {text}
        <img src={DropdownArrow} alt={''} className={`rec-header-icon ${open ? 'open' : ''}`} />
      </section>
      <section id={`${text}-dropdown-wrapper`} className={'dropdown-content'}>
        <section id={`${text}-dropdown-list`} className={'dropdown-answer'}>
          {Object.keys(dropdownOptions).map((option) => <DropdownFieldOption option={option} selected={dropdownSelected} setSelected={setSelected} key={option} dataOptions={dropdownOptions} />)}
        </section>
      </section>
    </section>
  )
}

DropdownField.displayText = 'DropdownField';
DropdownField.propTypes = propTypes;
export default DropdownField;
