import './FilterRow.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FILTER_ATTRIBUTES, FILTER_CONDITIONS, VALUE_TYPES, FILTER_FIELDS } from './FilterConstants';
import { ASSET_CDN_URL } from '../../../../../../../../../Constants';

import FilterDropdown from './FilterDropdown';
import FilterDropdownInput from './filterInputs/FilterDropdownInput';
import FilterInput from './filterInputs/FilterInput';

const { DATE, DROPDOWN, STRING } = VALUE_TYPES;
const { ATTRIBUTE_FIELD, CONDITION_FIELD, VALUE_FIELD } = FILTER_FIELDS;
const RUBBISH_BIN_ICON = `${ASSET_CDN_URL}/icons/workrex_rubbish_bin_icon.svg`;

const propTypes = {};

const defaultOpenState = { attribute: false, condition: false, value: false };

function FilterRow({ filter, deleteFilter, containerToggle, updateFilter }) {
  const [open, setOpen] = useState(defaultOpenState);
  const openDropdown = (dropdownField) => (openState) => {
    const updatedOpenState = {
      ...defaultOpenState,
      [dropdownField]: openState
    }
    setOpen(updatedOpenState);
  }
  const { filterId, attribute, condition, value } = filter;

  let valueTypeNode = (
    <FilterInput
      value={value}
      updateFilter={updateFilter(VALUE_FIELD)(filterId)}
    />
  );
  if (condition) {
    if (condition.key === 'empty' || condition.key === 'notEmpty') {
      valueTypeNode = null;
    }
    else if (condition.valueType === DROPDOWN) {
      valueTypeNode = (
        <FilterDropdownInput
          open={open[VALUE_FIELD]}
          setOpen={openDropdown(VALUE_FIELD)}
          title={condition.valueTypeTitle}
          filterKey={`${filterId}-${VALUE_FIELD}`}
          selected={value}
          containerToggle={containerToggle}
          dropdownAttributes={condition.valueTypeValues}
          updateFilter={updateFilter(VALUE_FIELD)(filterId)}
        />
      );
    }
    else if (condition.valueType === DATE) {
      valueTypeNode = (
        <DatePicker
          selected={value ? value : new Date()}
          onChange={updateFilter(VALUE_FIELD)(filterId)}
          dateFormat={'dd/MM/yyyy'}
          formatWeekDay={nameOfDay => nameOfDay.substring(0, 3)}
          placeholderText={'DD/MM/YYYY'}
          showPopperArrow={false}
        />
      );
    }
  }
    
  return (
    <section className={'filter-row'}>
      <section className={'filter-sections'}>
        <FilterDropdown
          open={open[ATTRIBUTE_FIELD]}
          setOpen={openDropdown(ATTRIBUTE_FIELD)}
          title={'Select attribute'}
          filterKey={`${filterId}-${ATTRIBUTE_FIELD}`}
          selected={attribute}
          containerToggle={containerToggle}
          dropdownAttributes={FILTER_ATTRIBUTES}
          updateFilter={updateFilter(ATTRIBUTE_FIELD)(filterId)}
        />
        {attribute &&
          <FilterDropdown
            open={open[CONDITION_FIELD]}
            setOpen={openDropdown(CONDITION_FIELD)}
            title={'Select conditions'}
            filterKey={`${filterId}-${CONDITION_FIELD}`}
            selected={condition}
            containerToggle={containerToggle}
            dropdownAttributes={FILTER_CONDITIONS[attribute.type]}
            updateFilter={updateFilter(CONDITION_FIELD)(filterId)}
          />
        }
        {condition && valueTypeNode}
      </section>
      <button className={'delete-filter-button'} onClick={deleteFilter} type={'button'}>
        <img alt={'delete filter button'} className={'delete-filter-icon'} src={RUBBISH_BIN_ICON} />
      </button>
    </section>
  )
}

FilterRow.displayText = 'FilterRow';
FilterRow.propTypes = propTypes;
export default FilterRow;
