import './ContactDetail.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { CONTACT_TYPES, INTERACTION_TYPE_MAPPING, CONTACT_DETAIL_DISPLAY_FIELDS } from '../../../MessagingConstants';

import GenericLabel from './GenericLabel';

const propTypes = {};

function ContactDetail({ contact, setRecord }) {

  const {
    firstName = '',
    lastName = '',
    emailAddress,
    address = null,
    birthday,
    mobileNumber,
    contactType,
    interactions
  } = contact;
  const iconInitial = firstName
    ? firstName.charAt(0)
    : (lastName ? lastName.charAt(0) : 'U');
  const fullName = `${firstName} ${lastName}`;
  const additionalClassNames = contactType === 'Employee' ? ['employee-contact'] : ['customer-contact'];

  const today = Date.now();
  const lastInteraction = interactions.filter(interaction => interaction.startDateTime < today).sort((a, b) => b.startDateTime - a.startDateTime)[0];

  const lastInteractionFormatted = lastInteraction ? `${INTERACTION_TYPE_MAPPING[lastInteraction.type].text} on ${new Date(lastInteraction.startDateTime).toLocaleDateString('en-au', { month: 'short', day: 'numeric', year: 'numeric'})}` : 'None';

  const contactDisplayFields = Object.keys(CONTACT_DETAIL_DISPLAY_FIELDS)
    .filter(fieldName => !!contact[fieldName] || contact[fieldName] === 0)
    .map(fieldName => {
      const fieldValue = CONTACT_DETAIL_DISPLAY_FIELDS[fieldName].dataType === 'DATE'
        ? new Date(contact[fieldName]).toLocaleString('en-au', { day: 'numeric', month: 'numeric', year: 'numeric' })
        : contact[fieldName];
      return (
        <section className={'body-detail'}>
          <p className={'detail-heading'}>{CONTACT_DETAIL_DISPLAY_FIELDS[fieldName].displayText}</p>
          <p className={'detail-value'}>{fieldValue}</p>
        </section>
      );
    });

  return (
    <section className={'contact-detail-container'}>
      <section className={'contact-detail'}>
        {/* Header */}
        <section className={'contact-detail-header'}>
          <section className={'header-icon-container'}>
            <section className={'header-icon'}>
              <span className={'header-text'}>{iconInitial}</span>
            </section>
          </section>
          <section className={'header-details'}>
            <section className={'header-contact-name'}>
              <span>{fullName}</span>
              <GenericLabel additionalClassNames={additionalClassNames} text={CONTACT_TYPES[contactType].text} />
            </section>
            <section className={'header-last-interaction-title'}>
              <p className={'header-interaction-last'}>{'Last interaction:'}</p>
              <p className={'header-interaction'}>{lastInteractionFormatted}</p>
            </section>
          </section>
        </section>
        {/* Body */}
        <section className={'contact-detail-body'}>
          {contactDisplayFields}
          {/* <section className={'body-detail'}>
            <p className={'detail-heading'}>{'First name'}</p>
            <p className={'detail-value'}>{firstName}</p>
          </section>
          <section className={'body-detail'}>
            <p className={'detail-heading'}>{'Last name'}</p>
            <p className={'detail-value'}>{lastName}</p>
          </section>
          {birthday && <section className={'body-detail'}>
            <p className={'detail-heading'}>{'Birthday'}</p>
            <p className={'detail-value'}>{formattedBirthday}</p>
          </section>}
          {emailAddress && <section className={'body-detail'}>
            <p className={'detail-heading'}>{'Email'}</p>
            <p className={'detail-value'}>{emailAddress}</p>
          </section>}
          {mobileNumber && <section className={'body-detail'}>
            <p className={'detail-heading'}>{'Contact number'}</p>
            <p className={'detail-value'}>{mobileNumber}</p>
          </section>} */}
        </section>
      </section>
      <section className={'contact-detail-footer'}>
        <button className={'close-detail-view'} onClick={() => setRecord(null)} type={'button'}>
          {'Close'}
        </button>
      </section>
    </section>
  )
}

ContactDetail.displayName = 'ContactDetail';
ContactDetail.propTypes = propTypes;
export default ContactDetail;