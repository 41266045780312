import './UploadRecipientsModal.scss';

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import WorkRexModal from '../../../common/WorkRexModal';
import Attachment from '../../templates/common/Attachment';

const propTypes = {};

function UploadRecipientsModal({ close, setAttachment, initialFile = null }) {
  const [file, setFile] = useState(initialFile);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const attachmentRef = useRef(null);
  const inputRef = useRef(null);
  const [draggingOver, setDraggingOver] = useState(false);
  const extractFile = (newFile) => {
    const { name,  size, type } = newFile;
    setError(null);
    const newFileObject = { fileName: name, url: '', size, type, file: newFile };
    return newFileObject;
  }
  const handleFile = ({ target })  => {
    const newFile = target.files[0];
    if (!newFile) {
      return;
    }
    const fileWithoutUrl = extractFile(newFile);
    setFile(fileWithoutUrl);
  }

  const onDragOver = (event) => {
    event.preventDefault();
    setDraggingOver(true);
  }

  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    const newFile = files.length > 0 ? files[0] : file;
    const fileWithoutUrl = extractFile(newFile);
    setFile(fileWithoutUrl);
    setError(null);
    setDraggingOver(false);
  }

  const onClick = () => {
    if (file === null) {
      setAttachment(null);
      close();
      return;
    }
    else if (file.url) {
      setAttachment(file);
      close();
      return;
    }
    setLoading(true);
    // TODO: Some upload endpoint call here
  }

  const deleteAttachment = () => setFile(null);
  const clearValue = () => inputRef.current.value = '';

  return (
    <WorkRexModal close={close} additionalClassNames={['upload-recipients-modal']}>
      <section className={'modal-description'}>
        <h4 className={'attachment-modal-title'}>{`Add recipients`}</h4>
        <p className={'description'}>{'Upload your list of recipients you want the campaign to go out to'}</p>
      </section>
      <section className={`attachment-container ${draggingOver ? 'dragging' : ''}`} ref={attachmentRef} onDragOver={onDragOver} onDrop={onDrop}>
        { error && <span className={'error-text'}>{error}</span>}
        <input ref={inputRef} type={'file'} id={'attachment-file-input'} className={'attachment-input'} onChange={handleFile} />
        <label htmlFor={'attachment-file-input'} className={'attachment-input-label'} onClick={clearValue}>{draggingOver ? 'Drop here' :  'Choose file'}</label>
        <p className={'input-tip'}>{'or drag and drop file here'}</p>
        { file && <Attachment attachment={file} disabled={loading} deleteAttachment={deleteAttachment} />}
      </section>
      <button className={`upload-button ${loading ? 'loading' : ''}`} onClick={onClick} disabled={loading}>
        {loading ? 'Uploading...' : 'Upload'}
      </button>
    </WorkRexModal>
  );
}

UploadRecipientsModal.displayName = 'UploadRecipientsModal';
UploadRecipientsModal.propTypes = propTypes;
export default UploadRecipientsModal;