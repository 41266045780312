import './Modal.scss';

import React, { useRef } from 'react';

import { ASSET_CDN_URL } from '../../Constants';

import { useOutsideClick } from '../../hooks/ClickHooks';

const EXIT_ICON = `${ASSET_CDN_URL}/icons/delete-icon.svg`;

function Modal({ children, closeModal, additionalClassNames = [] }) {
  const modalRef = useRef(null);
  useOutsideClick(modalRef, () => closeModal());

  return (
    <section className={'modal-container-wrapper'}>
      <section className={`modal-container ${additionalClassNames.join(' ')}`} ref={modalRef}>
        <section className={'close-modal-button-container'}>
          <button
            className={'close-modal-button very-dark'}
            onClick={closeModal}
            type={'button'}
          >
            <img alt={'delete-icon'} className={'close-modal-icon'} src={EXIT_ICON} />
            {/* {'×'} */}
          </button>
        </section>
        {children}
      </section>
    </section>
  )
}

export default Modal;
