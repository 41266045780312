import './MessagingBody.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import MessagingConsumer from '../../../../../../context/MessagingContext';
import useAuth from '../../../../../../context/AuthContext';
import { matchObjectReferences } from '../../../../../../utils/DataUtils';
import { MESSAGING_VIEWS, VIEW_PATHNAMES } from '../../MessagingConstants';
import { calculateRoute } from '../MessagingHelpers';

import ActionViewManager from '../actions/ActionViewManager';
import MessagingNavigation from '../navigation/MessagingNavigation';
import ListViewManager from '../list/ListViewManager';

const propTypes = {};

function MessagingBody() {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const viewAndFilter = calculateRoute(pathname);
  
  const [navigationView, setNavigationView] = useState(viewAndFilter.view);
  const [viewFilters, setViewFilters] = useState(viewAndFilter.filter);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [broadcastList, setBroadcastList] = useState([]);

  const { customers, employees, integrationUser, interactions, tasks, setInitialLoad } = MessagingConsumer();

  const setRecord = (recordType) => (record) => {
    const populatedRecord = matchObjectReferences(customers, employees, user.groups, integrationUser, interactions, tasks, recordType, record);
    if (record === null || !selectedRecord || (record && selectedRecord && selectedRecord._id !== record._id)) {
      setSelectedRecord(populatedRecord);
    }
  }

  const setSelected = (id) => {
    const broadcastSet = new Set(broadcastList);
    if (broadcastSet.has(id)) {
      broadcastSet.delete(id);
    } else {
      broadcastSet.add(id);
    }
    setBroadcastList([...broadcastSet]);
  }

  useEffect(() => {
    setInitialLoad(false);
  }, [setInitialLoad]);

  return (
    <section className={'messaging-body-container'}>
      <section className={'messaging-body-navigation-container'}>
        <MessagingNavigation
          navigationView={navigationView}
          setNavigationView={setNavigationView}
          viewFilters={viewFilters}
          setBroadcastList={setBroadcastList}
          setViewFilters={setViewFilters}
          setSelectedRecord={setRecord(null)}
        />
      </section>
      <section className={'messaging-body-list-container'}>
        <ListViewManager
          view={navigationView}
          selectedRecord={selectedRecord}
          setRecord={setRecord}
          setSelectedRecord={setSelectedRecord}
          viewFilters={viewFilters}
          broadcastList={broadcastList}
          setBroadcastList={setBroadcastList}
          setSelected={setSelected}
        />
      </section>
      <section className={'messaging-body-detail-container'}>
        <ActionViewManager
          view={navigationView}
          viewFilters={viewFilters}
          selectedRecord={selectedRecord}
          broadcastList={broadcastList}
          setSelected={setSelected}
          setRecord={setRecord}
          setSelectedRecord={setSelectedRecord}
          setViewFilters={setViewFilters}
        />
      </section>
    </section>
  )
}

MessagingBody.displayName = 'MessagingBody';
MessagingBody.propTypes = propTypes;
export default MessagingBody;