import { MESSAGING_VIEWS } from "../MessagingConstants";
import { SPECIAL_KEYWORDS } from "../CommunicationsConstants";
import { VALUE_TYPES } from "./list/views/broadcasts/filterDropdown/FilterConstants";

const { ARRAY, STRING, DATE, NUMBER, DROPDOWN } = VALUE_TYPES;
const { SEARCH, BROADCAST, TASKS, INBOX, CUSTOMERS, EMPLOYEES } = MESSAGING_VIEWS;

export const calculateRoute = (pathname) => {
  const viewAndFilter = {
    view: null,
    filter: 'ALL'
  };
  if (pathname.includes('search')) {
    viewAndFilter.view = SEARCH.key;
  }
  else if (pathname.includes('broadcasts')) {
    viewAndFilter.view = BROADCAST.key;
    if (pathname.includes('new')) {
      viewAndFilter.filter = BROADCAST.filters.NEW.key;
    }
    if (pathname.includes('upcoming')) {
      viewAndFilter.filter = BROADCAST.filters.UPCOMING.key;
    }
  }
  else if (pathname.includes('tasks')) {
    viewAndFilter.view = TASKS.key;
    if (pathname.includes('outstanding')) {
      viewAndFilter.filter = TASKS.filters.OUTSTANDING.key;
    }
    if (pathname.includes('completed')) {
      viewAndFilter.filter = TASKS.filters.COMPLETED.key;
    }
    if (pathname.includes('all')) {
      viewAndFilter.filter = TASKS.filters.ALL.key;
    }
  }
  else if (pathname.includes('inbox')) {
    viewAndFilter.view = INBOX.key;
    if (pathname.includes('unread')) {
      viewAndFilter.filter = INBOX.filters.UNREAD.key;
    }
    if (pathname.includes('all')) {
      viewAndFilter.filter = INBOX.filters.ALL.key;
    }
  }
  else if (pathname.includes('customers')) {
    viewAndFilter.view = CUSTOMERS.key;
    if (pathname.includes('all')) {
      viewAndFilter.filter = CUSTOMERS.filters.ALL.key;
    }
    if (pathname.includes('actions')) {
      viewAndFilter.filter = CUSTOMERS.filters.ACTIONS_REQUIRED.key;
    }
  }
  else if (pathname.includes('employees')) {
    viewAndFilter.view = EMPLOYEES.key;
    if (pathname.includes('all')) {
      viewAndFilter.filter = EMPLOYEES.filters.ALL.key;
    }
    if (pathname.includes('actions')) {
      viewAndFilter.filter = EMPLOYEES.filters.ACTIONS_REQUIRED.key;
    }
  }
  return viewAndFilter;
}

export const populateMessage = (message, recipient, user, employees) => {
  const managers = recipient && Object.hasOwn(recipient, 'managers') ? employees.filter(({ _id}) => recipient.managers.includes(_id)) : [];
  const group = Object.hasOwn(recipient, 'groupId') ? user.groups.find(group => recipient.groupId === group._id) : null;
  const groupDisplay = group ? group.name : user.business.name;
  const keywordMatching = {
    customerFirstName:  `||@${recipient.firstName}||`,
    customerLastName:  `||@${recipient.lastName}||`,
    customerFullName: `||@${recipient.firstName} ${recipient.lastName}||`,
    customerPhoneNumber: recipient.mobileNumber ? `||@${recipient.mobileNumber}||` : '||[@customerPhoneNumber]||',
    customerEmailAddress: recipient.emailAddress ? `||@${recipient.emailAddress}||` : '||[@customerEmailAddress]||',
    employeeFirstName: `||@${recipient.firstName}||`,
    employeeLastName: `||@${recipient.lastName}||`,
    employeeFullName: `||@${recipient.firstName} ${recipient.lastName}||`,
    employeePhoneNumber: recipient.mobileNumber ? `||@${recipient.mobileNumber}||` : '||[@employeePhoneNumber]||',
    employeeEmailAddress: recipient.emailAddress ? `||@${recipient.emailAddress}||` : '||[@employeeEmailAddress]||',
    employeeManagerFirstName: managers && managers.length > 0 ? `||@${managers[0].firstName}||` : '||[@employeeManagerFirstName]||',
    employeeManagerLastName: managers && managers.length > 0 ? `||@${managers[0].lastName}||` : '||[@employeeManagerLastName]||',
    employeeManagerFullName: managers && managers.length > 0  ? `||@${managers[0].firstName} ${managers[0].lastName}||` : '||[@employeeManagerFullName]||',
    groupName: groupDisplay,
  };

  let missingVariableInputs = false;
  for (const keyword of Object.keys(SPECIAL_KEYWORDS)) {
    if (message.includes(`@${keyword}`)) {
      if (keywordMatching[keyword].includes(`@${keyword}`)) {
        missingVariableInputs = true;
      }
      message = message.replaceAll(`@${keyword}`, keywordMatching[keyword]);
    }
  }

  return { message, missingVariableInputs };
};

export const filterContacts = (contacts, filters, groupMap) => {
  return contacts.filter(contact => {
    const populatedFilters = filters.filter(filter => filter.condition);
    let validContact = true;
    for (const filter of populatedFilters) {
      const { attribute, condition, value } = filter;
      let contactVal = contact[attribute.key];

      if (attribute.key === 'lastInteractionDate') {
        contactVal = contact.interactions && contact.interactions.length > 0
          ? contact.interactions.sort((a, b) => b.createdDate - a.createdDate)[0].createdDate
          : null;
      }

      if (attribute.key === 'lastCampaignDate') {
        const campaignInteractions = contact.interactions
          ? contact.interactions.filter(interaction => interaction.broadcastId || interaction.campaignId)
          : [];
        contactVal = campaignInteractions.length > 0
          ? contact.interactions.sort((a, b) => b.createdDate - a.createdDate)[0].createdDate
          : null;
      }

      let filterResult = false;
      if (attribute.type === DATE) {
        if (condition.key === 'inMonth' && value) {
          filterResult = !!contactVal ? condition.filterFunction(value.value, new Date(contactVal).getMonth()) : false;
        }
        else {
          filterResult = condition.filterFunction.length === 2
            ? condition.filterFunction(contactVal, value ? value.getTime() : Date.now())
            : condition.filterFunction(contactVal);
        }
      }
      else if (attribute.type === ARRAY && contactVal) {
        // Expected input value example: 'WorkRex (Melbourne), WorkRex (Sydney)'.
        const valueArray = value.split(', ').map(val => val.toLowerCase().trim());
        const contactGroups = contactVal.map(groupId => groupMap[groupId]);
        filterResult = condition.filterFunction(contactGroups, valueArray);
      }
      else if (attribute.type === STRING) {
        filterResult = condition.filterFunction.length === 2
          ? condition.filterFunction(contactVal, value)
          : condition.filterFunction(contactVal);
      }
      else if (attribute.type === NUMBER) {
        filterResult = condition.filterFunction.length === 2
          ? condition.filterFunction(contactVal, value)
          : condition.filterFunction(contactVal);
      }
      else if (attribute.type === DROPDOWN) {
        filterResult = condition.filterFunction.length === 2
          ? condition.filterFunction(contactVal, value)
          : condition.filterFunction(contactVal);
      }
      if (!filterResult) {
        return false;
      }
    }

    return validContact;
  })
}

export const searchFilterContacts = (contacts, searchText, groupMap) => {
  const lowerCaseSearchText = searchText.toLowerCase();
  const lowerCaseSearchTextList = lowerCaseSearchText.split(' ');
  return contacts.filter(filteredContact => {
    let match = false;
    const { firstName = '', lastName = '', mobileNumber = '', emailAddress = '', groupId = '', groups = [], role = '' } = filteredContact;
    let lowerCaseFirstName = firstName ? firstName.toLowerCase() : '';
    let lowerCaseLastName = lastName ? lastName.toLowerCase() : '';
    let lowerCaseName = `${lowerCaseFirstName} ${lowerCaseLastName}`;

    // Check for matches via name.
    if (lowerCaseName.includes(lowerCaseSearchText)) {
      match = true;
    }
    lowerCaseSearchTextList.forEach(searchParam => {
      if (lowerCaseFirstName.includes(searchParam) || lowerCaseLastName.includes(searchParam)) {
        match = true;
      }
    })

    // Check for matches via mobile number.
    const mobileNumberAltFormat = mobileNumber && mobileNumber.startsWith('61') ? `0${mobileNumber.substring(2)}` : mobileNumber;
    if (mobileNumber && (mobileNumber.includes(lowerCaseSearchText) || mobileNumberAltFormat.includes(lowerCaseSearchText))) {
      match = true;
    }

    // Check for matches via email address.
    const lowerCaseEmailAddress = emailAddress ? emailAddress.toLowerCase() : emailAddress;
    if (emailAddress && lowerCaseEmailAddress.includes(lowerCaseSearchText)) {
      match = true;
    }

    const lowerCaseRoleType = role ? role.toLowerCase() : role;
    if (role && lowerCaseRoleType.includes(lowerCaseSearchText)) {
      match = true;
    }

    // Check for matches via group name.
    for (const groupId of groups) {
      const group = groupMap[groupId];
      const lowerCaseGroupName = group ? group.toLowerCase() : group;
      if (group && lowerCaseGroupName.includes(lowerCaseSearchText)) {
        match = true;
      }
    }

    return match;
  });
}