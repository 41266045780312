export const postData = async (url, data) => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
  .then(response => response.json());
  return response;
};

export const postFormData = async (url, formData) => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    body: formData,
  }).then(response => response.json());
  return response;
}

export const getData = async (url, signal = null) => {
  const response = await fetch(url, { method: 'GET', mode: 'cors', cache: 'default', signal })
    .then(response => response.json())
    .catch(err => console.error(err));
  return response;
};

// CRM GET REQUESTS

const TASK_ARCHIVE_URL = '/crm/tasks/archive';
const TASK_CREATION_URL = '/crm/task/create';
const TASK_EDIT_URL = '/crm/task/edit';
const TASK_COMPLETION_URL = '/crm/task/completion/update';
const TASK_COMPLETION_MANY_URL = '/crm/tasks/completion/update';
const TASK_REMINDER_MANY_URL = '/crm/tasks/reminders';

// CRM POST REQUESTS
const BROADCASTS_CREATE_URL = '/crm/broadcasts/new';
const BROADCASTS_DELETE_URL = '/crm/broadcasts/delete';
const BROADCASTS_PAUSE_URL = '/crm/broadcasts/pause';
const MESSAGE_MARK_INBOX_READ_URL = '/crm/inbox/read';
const MESSAGE_UPDATE_STATUS_URL = '/crm/inbox/message/status';

// TODO: Interaction routers
const INTERACTION_ARCHIVE_URL = '/crm/interactions/archive';
const INTERACTION_CREATION_URL = '/crm/interaction/create';
const INTERACTION_EDIT_URL = '/crm/interaction/edit';

const POST_BULK_INTERACTION_SYNC = '/crm/interactions/bulk/sync';
const POST_BULK_INTERACTION = '/crm/interactions/bulk/upload';

// TODO: Task routes

// GET REQUESTS
// TODO: Rename with GET in the name
// TODO: Group this by type of data we are fetching
// TODO: rename backend urls

// TODO: Form broadcast grouping
const USER_BROADCASTS_URL = '/crm/broadcasts/user/';
const GET_BROADCAST_ANALYTICS = '/api/user/work-rex/manager/broadcast/analytics/pagination/';

// TODO: Form group grouping
const USER_CRM_GROUP_DATA_URL = '/crm/data/group/';
const USER_CRM_GROUP_DATA_INITIAL_LOAD_URL = '/crm/data/group/initial/';

// TODO: Form template grouping
const USER_TEMPLATES_URL = '/crm/templates/user/';

// TODO: Form user grouping
const GET_MANAGER_USER = '/api/user/work-rex/manager';
const USER_RAW_CRM_DATA_URL = '/crm/rawdata/user/';
const USER_RAW_CRM_DATA_INITIAL_URL = '/crm/rawdata/initial/user/';

// TODO: Form customer grouping
const GET_CUSTOMERS_PAGINATION = '/api/user/work-rex/manager/customers/pagination/';

// POST REQUESTS
const POST_GROUP_CREATION_URL = '/crm/group/add';
const POST_MANY_GROUPS_CREATION_URL = '/crm/groups/add';
const USER_CRM_GROUPS = '/crm/groups/all';

// TODO: Group under account routes
const POST_MANAGER_ACCOUNT_URL = '/api/user/work-rex/managers/account/edit';
const POST_RECIPIENT_ROWS_URL = '/api/user/work-rex/managers/recipients/add';

// TODO: Group under integration routes
const POST_MANAGER_INTEGRATION_NEW = '/api/user/work-rex/managers/integrations/new';
const POST_MANAGER_INTEGRATION_REMOVE = '/api/user/work-rex/managers/integrations/remove';
const POST_REQUEST_INTEGRATION_URL = '/api/integration/request';

// TODO: Group under automation routes
const POST_AUTOMATION_REQUEST = '/api/automation/request';
const POST_AUTOMATION_ARCHIVE = '/api/automation/archive';
const POST_AUTOMATION_SUPPORT = '/api/automation/support';
const POST_AUTOMATION_TOGGLE = '/api/automation/toggle';
const POST_AUTOMATION_EDIT = '/api/automation/edit';

const ONBOARD_NEW_RECIPIENT_URL = '/api/user/onboard';

export const postBulkInteractions = async (interactions) => {
  return postData(POST_BULK_INTERACTION, { interactions });
}

export const postSyncCustomers = async (customers) => {
  return postData(POST_BULK_INTERACTION_SYNC, { customers });
}

// GET Requests
// TODO: Group under analytics routes
export const getMoreBroadcastAnalyticsUser = async (analyticsPageCount) => {
  return getData(GET_BROADCAST_ANALYTICS + analyticsPageCount);
}

// TODO: Group under customer routes
export const getMoreCustomersToCustomersTable = async (customersPageCount) => {
  return getData(GET_CUSTOMERS_PAGINATION + customersPageCount);
}

// TODO: Group under user routes
export const getManagerUser = async () => {
  return getData(GET_MANAGER_USER);
}

// TODO: Document and minimize data payload
export const getCRMDataByGroup = async (groupId, signal) => {
  return getData(USER_CRM_GROUP_DATA_URL + groupId, signal);
}

// TODO: Document and minimize data payload
export const getCRMDataByGroupInitialLoad = async (groupId, signal) => {
  return getData(USER_CRM_GROUP_DATA_INITIAL_LOAD_URL + groupId, signal);
}

// TODO: Document and minimize data payload
export const getGroupsList = async () => {
  return getData(USER_CRM_GROUPS);
}

// TODO: Document and minimize data payload
export const getUserVisibleRawData = async (groupId) => {
  return getData(USER_RAW_CRM_DATA_URL + groupId);
}

// TODO: Document and minimize data payload
export const getUserVisibleRawDataInitialLoad = async (groupId) => {
  return getData(USER_RAW_CRM_DATA_INITIAL_URL + groupId);
}

// TODO: Group under broadcasts routes
export const getInitialBroadcastsViaFirstGroup = async (groupId) => {
  return getData(USER_BROADCASTS_URL + groupId);
}

// TODO: Group under all templates routes
export const getAllTemplates = async (userId) => {
  return getData(USER_TEMPLATES_URL + userId);
}

// TODO: Group under group routes
export const postGroupCreation = (group) => {
  return postData(POST_GROUP_CREATION_URL, { group });
}

export const postManyGroups = (groups) => {
  return postData(POST_MANY_GROUPS_CREATION_URL, { groups });
}

// TODO: Group under account routes
export const submitRecipientRows = (rows, customerRows, customersPageCount) => {
  return postData(POST_RECIPIENT_ROWS_URL, { rows, customerRows, customersPageCount });
}
export const postManagerAccount = async (accountDetails) => {
  return postData(POST_MANAGER_ACCOUNT_URL, accountDetails);
}

// TODO: Group under automation routes
export const postAutomationInputRequest = (request) => {
  return postData(POST_AUTOMATION_REQUEST, { type: 'INPUT', request });
}
export const postAutomationTemplateRequest = ({ key, title, description }) => {
  return postData(POST_AUTOMATION_REQUEST, { type: 'TEMPLATE', key, title, description });
}
export const postAutomationArchive = (automationId) => {
  return postData(POST_AUTOMATION_ARCHIVE, { automationId });
}
export const postAutomationActiveToggle = (automationId, active) => {
  return postData(POST_AUTOMATION_TOGGLE, { automationId, active });
}
export const postAutomationSupport = (automationId, type) => {
  return postData(POST_AUTOMATION_SUPPORT, { automationId, type });
}
export const postAutomationEdit = (automationId, editDetails) => {
  return postData(POST_AUTOMATION_EDIT, { automationId, editDetails });
} 

// TODO: Group under integration routes
export const postIntegration = (type, credentials) => {
  return postData(POST_MANAGER_INTEGRATION_NEW, { type, credentials });
}
export const postIntegrationRemoval = (type) => {
  return postData(POST_MANAGER_INTEGRATION_REMOVE, { type });
}
export const postIntegrationRequest = (type) => {
  return postData(POST_REQUEST_INTEGRATION_URL, { type });
}


// TODO: Group under interaction routes
// TODO: What is an interaction?
export const postInteractionCreation = async (interaction, userId) => {
  return postData(INTERACTION_CREATION_URL, { interaction, userId });
}
export const postInteractionEdit = async (modifiedInteraction, userId) => {
  return postData(INTERACTION_EDIT_URL, { modifiedInteraction, userId });
}
export const postManyInteractionArchive = async (interactions, userId) => {
  return postData(INTERACTION_ARCHIVE_URL, { interactions, userId });
}

// TODO: Group under broadcast routes
// TODO: What is a broadcast
export const postBroadcastCreate = async (newBroadcast, userId, groupId) => {
  return postData(BROADCASTS_CREATE_URL, { newBroadcast, userId, groupId });
}
export const postBroadcastDelete = async (broadcastId) => {
  return postData(BROADCASTS_DELETE_URL, { broadcastId });
}
export const postBroadcastPause = async (broadcastId, paused) => {
  return postData(BROADCASTS_PAUSE_URL, { broadcastId, paused });
}
export const postMarkChatAsRead = async (contactId) => {
  return postData(MESSAGE_MARK_INBOX_READ_URL, { contactId });
}
export const updateMessageReadStatus = async (messageId, readStatus) => {
  return postData(MESSAGE_UPDATE_STATUS_URL, { messageId, readStatus });
}

export const postOnboardUser = async (recipientId) => {
  return postData(ONBOARD_NEW_RECIPIENT_URL, { recipientId });
}

// TODO: Group under tasks routes
export const postManyTaskArchive = async (tasks, userId) => {
  return postData(TASK_ARCHIVE_URL, { tasks, userId });
}
export const postTaskCreation = async (task) => {
  return postData(TASK_CREATION_URL, { task });
}
export const postTaskEdit = async (modifiedTask, taskHistory, userId) => {
  return postData(TASK_EDIT_URL, { modifiedTask, taskHistory, userId });
}
export const postManyTaskCompletion = async (tasks, userId) => {
  return postData(TASK_COMPLETION_MANY_URL, { tasks, userId });
}
export const postManyTaskReminders = async (tasks, userId) => {
  return postData(TASK_REMINDER_MANY_URL, { tasks, userId });
}
export const postTaskCompletion = async (task, userId) => {
  return postData(TASK_COMPLETION_URL, { task, userId });
}
