import './FilterButton.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function FilterButton({ selected, onClick, icon, buttonText, additionalComponent }) {
  const selectedClass = selected ? 'selected' : '';
  return (
    <button className={`filter-button ${selectedClass}`} onClick={onClick}>
      <section className={'button-content'}>
        <img className={'icon'} src={icon} alt={''} />
        <span className={'button-text'}>{buttonText}</span>
      </section>
      {additionalComponent}
    </button>
  )
}

FilterButton.displayName = 'FilterButton';
FilterButton.propTypes = propTypes;
export default FilterButton;