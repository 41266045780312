import './AutomationActiveToggle.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function AutomationActiveToggle({ active, setActive }) {
  const onChange = () => setActive(!active);
  return (
    <section className={'automation-active-toggle'}>
      { active && (
        <section className={'text-container active'}>
          <span className={'text'}>{'Active'}</span>
        </section>
      )}
      <input type={'checkbox'} id={'switch'} className={`active-toggle ${active ? 'active' : ''}`} value={!!active} onChange={onChange} checked={!!active} />
      <label htmlFor={'switch'} className={active ? 'active' : ''}>{'Toggle active'}</label>
      { !active && (
          <section className={'text-container inactive'}>
            <span className={'text'}>{'Not active'}</span>
          </section>
      )}
    </section>
  )
}

AutomationActiveToggle.displayName = 'AutomationActiveToggle';
AutomationActiveToggle.propTypes = propTypes;
export default AutomationActiveToggle;