import './ScheduleSelectionModal.scss';
import 'react-datepicker/dist/react-datepicker.css';

import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import TimeSelector from '../TimeSelector';
import WorkRexModal from '../../../../../common/WorkRexModal';
import { HOUR_MAP } from '../../../../MessagingConstants';

const propTypes = {};

function ScheduleSelectionModal({ close, nextBroadcastDate, setNextBroadcastDate }) {
  const [time, setTime] = useState({ hour: 9, minute: 0, am: true });
  const [currentNextBroadcastDate, setCurrentNextBroadcastDate] = useState(nextBroadcastDate);

  const toggleAmPm = () => {
    const scheduledTime = new Date(currentNextBroadcastDate);
    const formattedHour = !time.am ? HOUR_MAP[time.hour].value : HOUR_MAP[time.hour].value + 12;
    scheduledTime.setHours(formattedHour, time.minute, 0, 0);
    setCurrentNextBroadcastDate(scheduledTime.getTime());
    setTime({ ...time, am: !time.am });
  };

  const selectDate = (date) => {
    const formattedHour = time.am ? HOUR_MAP[time.hour].value : HOUR_MAP[time.hour].value + 12;
    date.setHours(formattedHour, time.minute, 0, 0);
    setCurrentNextBroadcastDate(date.getTime());
  };

  const selectHour = (hour) => {
    const scheduledTime = new Date(currentNextBroadcastDate);
    const formattedHour = time.am ? HOUR_MAP[hour].value : HOUR_MAP[hour].value + 12;
    scheduledTime.setHours(formattedHour, time.minute, 0, 0);
    setCurrentNextBroadcastDate(scheduledTime.getTime());
    setTime({ ...time, hour });
  }

  const selectMinute = (minute) => {
    const scheduledTime = new Date(currentNextBroadcastDate);
    const formattedHour = time.am ? HOUR_MAP[time.hour].value : HOUR_MAP[time.hour].value + 12;
    scheduledTime.setHours(formattedHour, minute, 0, 0);
    setCurrentNextBroadcastDate(scheduledTime.getTime());
    setTime({ ...time, minute });
  }
  const confirm = () => {
    setNextBroadcastDate(currentNextBroadcastDate);
    close();
  }
  return (
    <WorkRexModal close={close} additionalClassNames={['schedule-selection-modal']}>
      <section className={'selection-option'}>
        <h4 className={'selection-title'}>{'Schedule date to send out broadcast'}</h4>
        <section className={'time-period-picker'}>
          <DatePicker
            minDate={new Date()}
            selected={currentNextBroadcastDate}
            onChange={selectDate}
            dateFormat={'dd/MM/yyyy'}
            formatWeekDay={nameOfDay => nameOfDay.substring(0, 3)}
            placeholderText={'DD/MM/YYYY'}
            showPopperArrow={false}
          />
        </section>
      </section>
      <section className={'selection-option'}>
        <h4 className={'selection-title'}>{'Schedule time to send out broadcast'}</h4>
          <TimeSelector time={time} selectHour={selectHour} selectMinute={selectMinute} toggleAmPm={toggleAmPm} />
      </section>
      <section className={'confirm-buttons'}>
        <button className={'back-button'} onClick={close}>{'Back'}</button>
        <button className={'confirm-button'} onClick={confirm}>{'Confirm'}</button>
      </section>
    </WorkRexModal>
  );
}

ScheduleSelectionModal.displayName = 'ScheduleSelectionModal';
ScheduleSelectionModal.propTypes = propTypes;
export default ScheduleSelectionModal;
