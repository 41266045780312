import './InboxListView.scss';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { MESSAGING_VIEWS } from '../../../../MessagingConstants';
import useAuth from '../../../../../../../../context/AuthContext';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';
import { VALUE_TYPES } from '../broadcasts/filterDropdown/FilterConstants';
import { filterContacts, searchFilterContacts } from '../../../MessagingHelpers';

import BroadcastContactCard from '../broadcasts/BroadcastContactCard';
import BroadcastContactsFilter from '../broadcasts/filterDropdown/BroadcastContactsFilter';
import DotBricksLoading from '../../../../../../../common/DotBricksLoading';
import ButtonWithBorder from '../../../../../../../common/ButtonWithBorder';
import SearchBar from '../search/SearchBar';
import SearchButton from '../search/SearchButton';
import SortDropdown from '../broadcasts/sortDropdown/SortDropdown';
import { sortContacts } from '../broadcasts/sortDropdown/SortHelpers';

const { INBOX } = MESSAGING_VIEWS;
const { ARRAY, STRING, DATE } = VALUE_TYPES;
const SEARCH_ADDITIONAL_CLASSNAMES = ['new-broadcast', 'max-width'];

const propTypes = {};

function InboxListView({ broadcastList, setSelected, setBroadcastList, setRecord, viewFilters }) {
  const { state } = useLocation();
  console.log(state);
  const { user } = useAuth();
  const groupMap = user.groups.reduce((groupMapping, group) => ({ ...groupMapping, [group._id]: group.name.toLowerCase() }), {});
  const { contacts } = MessagingConsumer();
  const initialContact = state && state.recipientId ? { _id: state.recipientId } : null;
  const [highlightedContact, setHighlightContact] = useState(initialContact);
  const [selectAll, setSelectAll] = useState(true);
  const selectAllText = selectAll ? 'Select all' : 'Deselect all';
  const [openSearch, setOpenSearch] = useState(false);
  const [filteredContacts, setFilteredContacts] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState('');

  const setSelectedContact = (contact) => (selectedContact) => {
    setHighlightContact(selectedContact);
    setRecord(contact.contactType)(selectedContact);
  }

  const [sortBy, setSortBy] = useState(null);
  const [filters, setFilters] = useState([]);

  const applyFilters = () => {
    const unreadMessagePopulatedContacts = contacts.map(contact => {
      const hasUnreadMessage = !!contact.chatHistory.find(chat => !chat.read);
      return { ...contact, hasUnreadAction: hasUnreadMessage };
    })

    const navFilteredContacts = unreadMessagePopulatedContacts.filter(contact => {
      if (viewFilters === INBOX.filters.UNREAD.key) {
        return contact.hasUnreadAction;
      }
      return true;
    });

    const dropdownFilteredContacts = filterContacts(navFilteredContacts, filters, groupMap);
    const searchFilteredContacts = searchFilterContacts(dropdownFilteredContacts, searchText, groupMap);
    return sortContacts(sortBy, searchFilteredContacts);
  }

  const increasePageCount = () => setPageCount(pageCount + 1);

  const search = (e) => {
    setSearchText(e.target.value);
  }

  const closeSearchBar = () => {
    setOpenSearch(false);
    filterAndSetContacts();
  }

  const filterAndSetContacts = () => {
    setSearchText('');
    const dropdownFilteredContacts = applyFilters();
    setFilteredContacts(dropdownFilteredContacts);
  }

  const updateSelectedContacts = () => {
    setSelectAll(!selectAll)
    if (selectAll) {
      const contactIds = filteredContacts.map(contact => contact._id);
      setBroadcastList(contactIds);
    } else {
      setBroadcastList([]);
    }
  }

  useEffect(() => {
    if (contacts !== null) {
      const inboxFilteredContacts = applyFilters();
      setFilteredContacts(inboxFilteredContacts);
      if (highlightedContact) {
        console.log('setting record');
        const foundContact = contacts.find(contact => contact._id === highlightedContact._id)
        setRecord(foundContact.contactType)(foundContact);
      }
    }
  }, [contacts, viewFilters, sortBy, searchText, highlightedContact])

  if (contacts === null || filteredContacts === null) {
    return <DotBricksLoading isFixed={false} />
  }

  const notShowingAll = 100 * pageCount < filteredContacts.length;
  const searchComponent = openSearch
    ? <SearchBar additionalClassNames={SEARCH_ADDITIONAL_CLASSNAMES} searchText={searchText} search={search} placeholder={'Search'} setOpenSearch={closeSearchBar} />
    : <SearchButton setOpenSearch={setOpenSearch} searchText={searchText} />;

  return (
    <section className={'inbox-list-view'}>
      <section className={'inbox-control-container'}>
        <section className={'inbox-filter-container'}>
          <BroadcastContactsFilter filters={filters} setFilters={setFilters} applyFilters={filterAndSetContacts} />
          <SortDropdown sortBy={sortBy} setSortBy={setSortBy} />
          {searchComponent}
        </section>
        <button className={'inbox-select-all-button'} onClick={updateSelectedContacts} type={'button'}>{selectAllText}</button>
      </section>
      <section className={'contact-rows'}>
        {filteredContacts.slice(0, 100 * pageCount).map(filteredContact => <BroadcastContactCard key={filteredContact._id} contact={filteredContact} selectedContact={highlightedContact} broadcastList={broadcastList} setSelected={setSelected} setRecord={setSelectedContact(filteredContact)} />)}
        { notShowingAll && 
          <section className={'show-more-container'}>
            <ButtonWithBorder additionalClassNames={['white-bg']} onClick={increasePageCount} buttonText={'Show more'} />
          </section>
        }
      </section>
    </section>
  )
}

InboxListView.displayName = 'InboxListView';
InboxListView.propTypes = propTypes;
export default InboxListView;