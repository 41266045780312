import './SocialMediaPost.scss';

import React from 'react';

import PreviewToolButtons from './PreviewToolButtons';
import TemplateTextInput from '../../../templates/common/TemplateTextInput';

function SocialMediaPost({ preview, setPreview, proceed }) {
  const { caption } = preview;
  const setCaption = (text) => setPreview({ ...preview, caption: text });
  return (
    <section className={'social-media-post'}>
      <section className={'caption-container'}>
        <label className={'caption-label'} htmlFor={'social-media-caption'}>{'Edit caption'}</label>

        <TemplateTextInput
          editable={true}
          text={caption}
          setText={setCaption}
          placeholder={'Type your caption here...'}
          shouldUseVariables={false}
          showCharCount={true}
          maxCharCount={400}
        />
        <PreviewToolButtons text={caption} proceed={proceed} uploadText={'Upload a photo or video'} />
      </section>
    </section>
  );
};

SocialMediaPost.displayName = 'SocialMediaPost';
export default SocialMediaPost;