import './CampaignSource.scss';

import React from 'react';

function CampaignSource({ tagComponent, additionalClassNames = [], title, description, onClick, buttonText }) {
  return (
    <section className={`campaign-source ${additionalClassNames.join(' ')}`}>
      {tagComponent}
      <section className={'source-details'}>
        <h4 className={'source-title'}>{title}</h4>
        <p className={'source-description'}>{description}</p>
      </section>
      <button className={'source-button'} onClick={onClick}>{buttonText}</button>
    </section>
  );
};

CampaignSource.displayName = 'CampaignSource';
export default CampaignSource;