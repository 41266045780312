import './IntegrationsList.scss';

import React from 'react';
import PropTypes from 'prop-types';

import IntegrationCard from './IntegrationCard';

const propTypes = {};

function IntegrationsList({ integrations, allIntegrations }) {
  const integrationsList = allIntegrations.map(
    ({ key, icon, comingSoon, displayText }) => 
      <IntegrationCard
        key={key}
        type={key} 
        icon={icon}
        comingSoon={comingSoon}
        displayText={displayText}
        active={integrations.includes(key)}
      />
    );

  return (
    <ul className={'integrations-list'}>
      {integrationsList}
    </ul>
  );
}

IntegrationsList.displayName = 'IntegrationsList';
IntegrationsList.propTypes = propTypes;
export default IntegrationsList;