import './AutomationTabs.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import NavigationTab from '../../../common/NavigationTab';
import SELECTED_AVAILABLE_AUTOMATIONS_ICON from '../assets/selected-available-automations-icon.svg';
import UNSELECTED_AVAILABLE_AUTOMATIONS_ICON from '../assets/unselected-available-automations-icon.svg';
import SELECTED_REQUEST_AUTOMATION_ICON from '../assets/selected-request-automation-icon.svg';
import UNSELECTED_REQUEST_AUTOMATION_ICON from '../assets/unselected-request-automation-icon.svg';
import SELECTED_AUTOMATION_LIBRARY_ICON from '../assets/selected-automation-library-icon.svg';
import UNSELECTED_AUTOMATION_LIBRARY_ICON from '../assets/unselected-automation-library-icon.svg';

import { AUTOMATIONS_TABS } from '../AutomationsConstants';

const { AVAILABLE_AUTOMATIONS, AUTOMATION_LIBRARY, REQUEST_AUTOMATION } = AUTOMATIONS_TABS;

const propTypes = {};

function AutomationTabs({ selectedTab }) {
  const navigate = useNavigate();
  const onAvailableClick = () => navigate('/automations/available');
  const onRequestClick = () => navigate('/automations/request');
  const onLibraryClick = () => navigate('/automations/library');
  return (
    <ul className={'automation-tabs'}>
      <NavigationTab
        unselectedIcon={UNSELECTED_AVAILABLE_AUTOMATIONS_ICON}
        selectedIcon={SELECTED_AVAILABLE_AUTOMATIONS_ICON}
        displayText={'Your available automations'}
        onClick={onAvailableClick}
        selected={selectedTab === AVAILABLE_AUTOMATIONS}
      />
      <NavigationTab
        unselectedIcon={UNSELECTED_AUTOMATION_LIBRARY_ICON}
        selectedIcon={SELECTED_AUTOMATION_LIBRARY_ICON}
        displayText={'The WorkRex automation library'}
        onClick={onLibraryClick}
        selected={selectedTab === AUTOMATION_LIBRARY}
      />
      <NavigationTab
        unselectedIcon={UNSELECTED_REQUEST_AUTOMATION_ICON}
        selectedIcon={SELECTED_REQUEST_AUTOMATION_ICON}
        displayText={'Request a custom automation'}
        onClick={onRequestClick}
        selected={selectedTab === REQUEST_AUTOMATION}
      />
    </ul>
  );
}

AutomationTabs.displayName = 'AutomationTabs';
AutomationTabs.propTypes = propTypes;
export default AutomationTabs;