import './FilterInput.scss';

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function FilterInput({ value, updateFilter }) {
  const textAreaRef = useRef(null);
  useEffect(() => {
    if (value === null && textAreaRef.current) {
      textAreaRef.current.value = '';
    }
  }, [value])
  return (
    <textarea rows={1} className={'filter-input-text'} ref={textAreaRef} onChange={(e) => updateFilter(e.target.value)} defaultValue={value} value={value} />
  )
}

FilterInput.displayText = 'FilterInput';
FilterInput.propTypes = propTypes;
export default FilterInput;
