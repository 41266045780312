import './WebManagerNavbar.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../../context/AuthContext';
import PropTypes from 'prop-types';

import WORKREX_LOGO from '../../assets/workrex-logo.svg';

import { ASSET_CDN_URL } from '../../../../../Constants';

import WebManagerAuthNavButtons from './WebManagerAuthNavButtons';
import WebManagerNavButtons from './WebManagerNavButtons';

const logo = `${ASSET_CDN_URL}/logo/Rex_Yellow_White_Logo.svg`;

const propTypes = {};

function WebManagerNavbar() {
  const { authed, user } = useAuth();
  const navigate = useNavigate();
  const navigateHome = () => authed ? navigate('/dashboard') : navigate('/');
  return (
    <nav className={'navbar'}>
      <section className={'navbar-container'}>
        <button className={'logo-container'} onClick={navigateHome} type={'button'}>
          <img alt={'Logo'} className={'workrex-logo'} src={WORKREX_LOGO} />
        </button>
        { authed ? <WebManagerAuthNavButtons /> : <WebManagerNavButtons /> }
      </section>
    </nav>
  );
}

WebManagerNavbar.displayName = 'WebManagerNavbar';
WebManagerNavbar.propTypes = propTypes;
export default WebManagerNavbar;