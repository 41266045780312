import './FilterOption.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {};

function FilterOption({ option, selected, setSelected }) {
  const answerSelected = selected === option.key;

  return (
    <button className={`filter-option-button ${answerSelected ? 'selected' : ''}`} onClick={() => setSelected(option)} type={'button'}>
      <span className={'filter-option-button-text'}>{option.displayText}</span>
    </button>
  )
}

FilterOption.displayText = 'FilterOption';
FilterOption.propTypes = propTypes;
export default FilterOption;