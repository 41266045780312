import './ContentLibraryList.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FileCard from './FileCard';

const propTypes = {};

function ContentLibraryList({ files, setLinkCopied }) {
  const fileList = files.map(({ _id, title, description, fileType, code, tags }) => {
    return (
      <FileCard
        key={_id}
        fileId={_id}
        title={title}
        description={description}
        fileType={fileType}
        code={code}
        openLinkCopied={() => setLinkCopied(true)}
        tags={tags}
      />
    );
  })
  return (
    <ul className={'content-library-list'}>
      {fileList}
    </ul>
  );
}

ContentLibraryList.displayName = 'ContentLibraryList';
ContentLibraryList.propTypes = propTypes;
export default ContentLibraryList;