import './BroadcastListWrapper.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MessagingConsumer from '../../../../../../../../context/MessagingContext';

import DotBricksLoading from '../../../../../../../common/DotBricksLoading';
import ButtonWithBorder from '../../../../../../../common/ButtonWithBorder';

const propTypes = {};

function BroadcastListWrapper({ children, broadcastView, notShowingAll, increasePageCount }) {
  const { broadcasts, contacts } = MessagingConsumer();

  if (broadcasts === null || contacts === null || broadcastView === null) {
    return <DotBricksLoading isFixed={false} />
  }
  return (
    <section className={'broadcast-list-view'}>
      {children}
      <section className={'contact-rows'}>
        {broadcastView}
        { notShowingAll && 
          <section className={'show-more-container'}>
            <ButtonWithBorder additionalClassNames={['white-bg']} onClick={increasePageCount} buttonText={'Show more'} />
          </section>
        }
      </section>
    </section>
  )
}

BroadcastListWrapper.displayName = 'BroadcastListWrapper';
BroadcastListWrapper.propTypes = propTypes;
export default BroadcastListWrapper;