import './EmptyChatHistory.scss';

import React from 'react';

function EmptyChatHistory() {
  return (
    <section className={'unavailable-chat-history'}>
      <p className={'unavailable-chat-history-text'}>{'Message history is not available yet for this contact at the moment.'}</p>
    </section>
  )
}

EmptyChatHistory.displayName = 'EmptyChatHistory';
export default EmptyChatHistory;