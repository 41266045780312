import { useEffect } from 'react';
import Cookies from 'js-cookie';

import { getInitialBroadcastsViaFirstGroup, getAllTemplates, getManagerUser } from '../apis/Api';
import useAuth from '../context/AuthContext';

/**
 * TODO: Add documentation
 * @param {*} broadcasts 
 * @param {*} setBroadcasts 
 */
export const useGetAllBroadcasts = (broadcasts, setBroadcasts, selectedGroup) => {
  const { authed } = useAuth();
  useEffect(() => {
    if (authed && broadcasts === null && selectedGroup) {
      getInitialBroadcastsViaFirstGroup(selectedGroup._id).then((response) => {
        setBroadcasts(response.broadcasts);
      })
      .catch(() => setBroadcasts([]));
    }
  }, [authed, broadcasts, setBroadcasts, selectedGroup]);
}

/**
 * TODO: Add documentation
 * @param {*} templates 
 * @param {*} setTemplates 
 */
export const useGetAllTemplates = (templates, setTemplates) => {
  const { authed, user } = useAuth();
  useEffect(() => {
    if (authed && templates === null) {
      const { _id } = user;
      getAllTemplates(_id).then((response) => {
        setTemplates(response.templates);
      })
      .catch(() => setTemplates([]));
    }
  }, [authed, user, templates, setTemplates]);
}

/**
 * TODO: add documentation
 * @param {*} firstLoad 
 * @param {*} setFirstLoad 
 */
export const useInitialUserFetch = (firstLoad, setFirstLoad) => {
  const { authed, setAuthed, setUser } = useAuth();
  useEffect(() => {
    const token = Cookies.get('token');
    if (!authed && token) {
      getManagerUser().then(({ user }) => {
        setUser(user);
        setAuthed(true);
        setFirstLoad(true);
      })
      .catch(() => setFirstLoad(true));
    }
    else {
      setFirstLoad(true);
    }
  }, [firstLoad, setFirstLoad, authed, setAuthed, setUser]);
}