import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import EngagementsPageWrapper from './EngagementsPageWrapper';
import NavigationTabList from '../../common/NavigationTabList';

import { ENGAGEMENT_TAB_LIST, ENGAGEMENT_TABS } from './EngagementConstants';

const { ENGAGEMENT_LIBRARY, ACTIVE_ENGAGEMENTS, DRAFT_ENGAGEMENTS } = ENGAGEMENT_TABS;

const propTypes = {};

function EngagementsPage({ showTabs = true, counts = null }) {
  const { pathname } = useLocation();
  let additionalOptions = [];
  let selectedTab = null;
  if (pathname.includes('active')) {
    selectedTab = ACTIVE_ENGAGEMENTS.key;
    additionalOptions = [{ displayName: 'Active engagements', href: '/engagements/active' }];
  }
  if (pathname.includes('draft')) {
    selectedTab = DRAFT_ENGAGEMENTS.key;
    additionalOptions = [{ displayName: 'Draft engagements', href: '/engagements/draft' }];
  }
  if (pathname.includes('library')) {
    selectedTab = ENGAGEMENT_LIBRARY.key;
    additionalOptions = [{ displayName: 'WorkRex engagement library', href: '/engagements/library' }];
  }
  return (
    <EngagementsPageWrapper additionalOptions={additionalOptions}>
      { showTabs && <NavigationTabList selectedTab={selectedTab} tabs={ENGAGEMENT_TAB_LIST} counts={counts} additionalClassNames={['assistants-navigation']} /> }
      <Outlet />
    </EngagementsPageWrapper>
  );
}

EngagementsPage.displayName = 'EngagementsPage';
EngagementsPage.propTypes = propTypes;
export default EngagementsPage;