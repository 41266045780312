import './SelectionDropdown.scss';

import React, { useState, useRef } from 'react';

import { useOutsideClick } from '../../../../../../../../hooks/ClickHooks';

import DROPDOWN_ARROW from '../assets/dropdown-arrow.svg';

function SelectionDropdown({ options, selected, select, defaultText = '', disabled = false }) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  let optionList = options
    .map(({ key, displayText }) => {
      const onClick = () => {
        select(key);
        setOpen(false);
      }
      return (
        <li key={key} className={`selection-item ${selected === key ? 'selected' : ''}`}>
          <button className={'selection-item-button'} onClick={onClick}>
            {displayText}
          </button>
        </li>
      )
    });
  const closeDropdown = () => setOpen(false);
  useOutsideClick(dropdownRef, closeDropdown);
  const selectedOption = options.find(({ key }) => key === selected);
  const buttonText = selectedOption ? selectedOption.displayText : defaultText;
  return (
    <section className={'selection-dropdown-container'} ref={dropdownRef}>
      <button
        className={`selection-dropdown-button ${open ? 'open' : ''} ${disabled ? 'disabled' : ''}`}
        onClick={() => setOpen(!open)}
        disabled={disabled}
      >
        {buttonText}
        <img className={'dropdown-arrow'} src={DROPDOWN_ARROW} alt={''} />
      </button>
      { open &&
        <section className={'dropdown-container'}>
          <ul className={'selection-dropdown-list'}>
            {optionList}
          </ul>
        </section>
      }
    </section>
  )
}

export default SelectionDropdown;