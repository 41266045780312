import './AssistantDropdown.scss';

import React, { useState, useRef } from 'react';
import { useOutsideClick } from '../../../../../../../hooks/ClickHooks';
import PropTypes from 'prop-types';

import DOWNWARDS_CARET_ICON from '../../assets/dropdown-arrow.svg';

const propTypes = {};

function AssistantDropdown({ defaultButtonText, select, selected, options, onlyShowDefault = false, additionalClassNames = [], showDisplayTextOnSide = false, disabled = false }) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const selectedOption = options.find(({ key }) => key === selected);
  const displayText = selectedOption ? selectedOption.displayText : '';
  const optionList = options.map(({ displayText, key }) => {
    const isSelected = selected === key;
    const selectItem = () => {
      select(key);
      setOpen(false);
    };
    return (
      <li className={`assistant-dropdown-item ${isSelected ? 'selected' : ''}`} key={key}>
        <button className={'assistant-dropdown-item-button'} onClick={selectItem}>{displayText}</button>
      </li>
    )
  });
  useOutsideClick(dropdownRef, () => setOpen(false));
  const buttonText = (selected === null || selected === '' || onlyShowDefault) ? defaultButtonText : displayText;
  return (
    <section className={`assistant-dropdown-container ${additionalClassNames.join(' ')} ${open ? 'open' : ''}`} ref={dropdownRef}>
      <section className={`assistant-dropdown-button-container ${selected !== null && showDisplayTextOnSide ? 'displaying-selected' : ''}`}>
        <button className={`assistant-dropdown-button ${disabled ? 'disabled' : ''}`} onClick={() => setOpen(!open)} disabled={disabled}>
          <span className={'text'}>{buttonText}</span>
          <img className={'downwards-caret-icon'} src={DOWNWARDS_CARET_ICON} alt={''} />
        </button>
        { selected !== null && showDisplayTextOnSide &&
          <span className={'assistant-dropdown-button-selection'}>
            {displayText}
          </span>
        }
      </section>
      {
        open && (
          <section className={'assistant-dropdown-list-container'}>
            <ul className={'assistant-dropdown-list'}>
              {optionList}
            </ul>
          </section>
        )
      }
    </section>
  )
}

AssistantDropdown.displayName = 'AssistantDropdown';
AssistantDropdown.propTypes = propTypes;
export default AssistantDropdown;