import './SearchPlaceholder.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../../Constants';

const listStyleIcon = `${ASSET_CDN_URL}/icons/rex-messaging-navigation-sub-category-icon.svg`;

const propTypes = {};

function SearchPlaceholder() {

  return (
    <section className={'search-placeholder'}>
      <p className={'search-placeholder-title'}>{'Search using name, mobile, group, email or message history'}</p>
      <section className={'placeholder-list-item'}>
        <img alt={'list-item'} className={'no-search-placeholder-icon'} src={listStyleIcon} />
        <span className={'placeholder-list-text'}>{'Customers'}</span>
      </section>
      <section className={'placeholder-list-item'}>
        <img alt={'list-item'} className={'no-search-placeholder-icon'} src={listStyleIcon} />
        <span className={'placeholder-list-text'}>{'Employees'}</span>
      </section>
    </section>
  )
}

SearchPlaceholder.displayName = 'SearchPlaceholder';
SearchPlaceholder.propTypes = propTypes;
export default SearchPlaceholder;