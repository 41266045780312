import './AttachmentModal.scss';

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import WorkRexModal from '../../../common/WorkRexModal';
import { postTemplateAttachment } from '../../../../../../apis/TemplateApi';
import Attachment from '../common/Attachment';

const propTypes = {};

const FILE_TYPES = {
  JPEG: 'JPEG',
  PNG: 'PNG',
  MP4: 'MP4',
  PDF: 'PDF',
};

const { JPEG, PNG, PDF, MP4 } = FILE_TYPES;

function AttachmentModal({ close, setAttachment, initialFile = null }) {
  const [file, setFile] = useState(initialFile);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const attachmentRef = useRef(null);
  const inputRef = useRef(null);
  const [draggingOver, setDraggingOver] = useState(false);
  const extractFile = (newFile) => {
    const { name,  size, type } = newFile;
    let attachmentType;
    if (type.startsWith('image/png')) {
      attachmentType = PNG;
    }
    else if (type.startsWith('image/jpeg')) {
      attachmentType = JPEG;
    }
    else if (type.startsWith('video/mp4')) {
      attachmentType = MP4;
    }
    else if (type.startsWith('application/pdf')) {
      attachmentType = PDF;
    }
    else {
      setError('Invalid file type (only .jpeg, .png, .pdf, or .mp4 supported)');
      return;
    }
    setError(null);
    const newFileObject = { fileName: name, url: '', size, type, attachmentType, file: newFile };
    return newFileObject;
  }
  const handleFile = ({ target })  => {
    const newFile = target.files[0];
    if (!newFile) {
      return;
    }
    const fileWithoutUrl = extractFile(newFile);
    setFile(fileWithoutUrl);
  }

  const onDragOver = (event) => {
    event.preventDefault();
    setDraggingOver(true);
  }

  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    const newFile = files.length > 0 ? files[0] : file;
    const fileWithoutUrl = extractFile(newFile);
    setFile(fileWithoutUrl);
    setError(null);
    setDraggingOver(false);
  }

  const onClick = () => {
    if (file === null) {
      setAttachment(null);
      close();
      return;
    }
    else if (file.url) {
      setAttachment(file);
      close();
      return;
    }
    setLoading(true);
    postTemplateAttachment(file)
      .then((response) => {
        setAttachment(response.attachment);
        setLoading(false);
        close();
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }

  const deleteAttachment = () => setFile(null);
  const clearValue = () => inputRef.current.value = '';

  return (
    <WorkRexModal close={close} additionalClassNames={['attachment-modal']}>
      <section className={'modal-description'}>
        <h4 className={'attachment-modal-title'}>{`Add attachments`}</h4>
        <p className={'description'}>{'Files must be in JPEG, PNG, PDF, or MP4 format less than 60 MB'}</p>
      </section>
      <section className={`attachment-container ${draggingOver ? 'dragging' : ''}`} ref={attachmentRef} onDragOver={onDragOver} onDrop={onDrop}>
        { error && <span className={'error-text'}>{error}</span>}
        <input ref={inputRef} type={'file'} id={'attachment-file-input'} className={'attachment-input'} onChange={handleFile} />
        <label htmlFor={'attachment-file-input'} className={'attachment-input-label'} onClick={clearValue}>{draggingOver ? 'Drop here' :  'Choose file'}</label>
        <p className={'input-tip'}>{'or drag and drop file here'}</p>
        { file && <Attachment attachment={file} disabled={loading} deleteAttachment={deleteAttachment} />}
      </section>
      <button className={`upload-button ${loading ? 'loading' : ''}`} onClick={onClick} disabled={loading}>
        {loading ? 'Uploading...' : 'Upload'}
      </button>
    </WorkRexModal>
  );
}

AttachmentModal.displayName = 'AttachmentModal';
AttachmentModal.propTypes = propTypes;
export default AttachmentModal;
