import './Voicemail.scss';

import React, { useState } from 'react';

import UPLOAD_ICON from '../assets/upload-icon.svg';
import VOICEMAIL_ICON from '../assets/voicemail-icon.svg';
import PLAY_ICON from '../assets/play-icon.svg';

function Voicemail({ preview, setPreview, proceed }) {
  return (
    <section className={'voicemail'}>
      <button className={'upload-button'}>
        <img className={'upload-icon'} src={UPLOAD_ICON} alt={'Upload icon'} />
        {'Upload a recording'}
      </button>
      <section className={'voicemail-message-input'}>
        <img className={'voicemail-icon'} src={VOICEMAIL_ICON} alt={'Voicemail icon'} />
        <section className={'play-button-container'}>
          <button className={'play-button'}>
            <img className={'play-icon'} src={PLAY_ICON} alt={'Play icon'} />
          </button>
        </section>
      </section>
      <button className={'submit-button'} onClick={proceed}>{'Proceed'}</button>
    </section>
  );
};

Voicemail.displayName = 'Voicemail';
export default Voicemail;