import './AssistantSelection.scss';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ActivateAssistantModal from '../../modals/ActivateAssistantModal';

const propTypes = {};

function AssistantSelection({ title, children, cancel, active, activateAssistant, additionalClassNames = [], assistantKey }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const activateText = active ? 'Update' : 'Activate';
  const activate = () => {
    setLoading(true);
    const callback = () => {
      setLoading(false);
      setModalOpen(true);
    }
    activateAssistant(callback);
  }
  const confirm = () => {
    navigate('/assistants/active', { state: { assistantKey } });
  }
  return (
    <section className={`assistant-selection ${additionalClassNames.join(' ')}`}>
      { modalOpen && <ActivateAssistantModal close={() => setModalOpen(false)} active={active} displayText={title} confirm={confirm} /> }
      <h3 className={'selection-title'}>
        {title}
      </h3>
      <section className={'selection-content'}>{children}</section>
      <section className={'buttons'}>
        <button className={'cancel-button'} onClick={cancel}>{'Cancel'}</button>
        <button className={`activate-button ${loading ? 'loading' : ''}`} onClick={activate} disabled={loading}>{loading ? 'Loading...' : activateText}</button>
      </section>
    </section>
  );
}

AssistantSelection.displayName = 'AssistantSelection';
AssistantSelection.propTypes = propTypes;
export default AssistantSelection;