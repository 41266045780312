import './CreateRecordModal.scss';

import React from 'react';
import PropTypes from 'prop-types';

import FloatingModal from '../../../../../../../common/FloatingModal';

const propTypes = {};

function CreateRecordModal({ children, closeModal, modalType, createRecord, createMode }) {
  const createModeText = createMode ? 'Add' : 'Edit';
  
  return (
    <FloatingModal additionalClassNames={['interaction-task-modal', 'fixed']} closeModal={closeModal}>
      <section className={'create-modal'}>
        <section className={'create-modal-header'}>
          <p>{modalType.text}</p>
        </section>
        <section className={'create-modal-body'}>
          {children}
        </section>
        <section className={'create-modal-footer'}>
          <button className={'footer-button'} onClick={closeModal} type={'button'}>{'Cancel'}</button>
          <button className={'footer-button create-record'} onClick={createRecord} type={'button'}>{`${createModeText} ${modalType.buttonText}`}</button>
        </section>
      </section>
    </FloatingModal>
  )
}

CreateRecordModal.displayName = 'CreateRecordModal';
CreateRecordModal.propTypes = propTypes;
export default CreateRecordModal;