import './InteractionsList.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Interaction from './Interaction';
import DotBricksLoading from '../../../../../../../common/DotBricksLoading';

const propTypes = {};

function InteractionsList({ interactions, subtitle, createEditInteraction, setSelectedRecord }) {
  if (interactions === null) {
    return <DotBricksLoading isFixed={false} />
  }
  return (
    <section className={'interactions-list-container'}>
      <section className={'interaction-list-status'}>
        <p className={'interaction-status-subtitle'}>{`${subtitle} (${interactions.length})`}</p>
      </section>
      {interactions.length > 0 && interactions.map(interaction => <Interaction interaction={interaction} createEditInteraction={createEditInteraction(false)} setSelectedRecord={setSelectedRecord} />)}
    </section>
  )
}

InteractionsList.displayName = 'InteractionsList';
InteractionsList.propTypes = propTypes;
export default InteractionsList;