import './GroupsDropdown.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useOutsideClick } from '../../../../../hooks/ClickHooks';

const propTypes = {};

function GroupsDropdown({ buttonText, options, select, selected, disabled, openCreateGroupModal, openSearchGroupModal }) {
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!open);

  const dropdownList = options.map(({ key, displayText }) => {
    const selectKey = () => {
      select(key);
      setOpen(false);
    };
    const selectedClass = selected.includes(key) ? 'selected' : ''
    return (
      <li className={'option-item'} key={key}>
        <button className={`option-selection ${selectedClass}`} onClick={selectKey}>
          <span className={'option-text'}>{displayText}</span>
        </button>
      </li>
    )
  });
  useOutsideClick(dropdownRef, () => setOpen(false));
  const isOpen = open && options.length > 0;
  return (
    <section className={`groups-dropdown-container ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
      <button className={`groups-dropdown-selector ${disabled ? 'disabled' : ''}`} onClick={toggleDropdown} disabled={disabled}>
        {buttonText}
      </button>
      {
        isOpen && (
          <section className={'groups-dropdown-options'}>
            <ul className={'groups-dropdown-list'}>
              <li className={'option-item'} key={'addGroup'}>
                <button className={`option-selection selected`} onClick={openCreateGroupModal}>
                  <span className={'option-text'}>{'Add Group'}</span>
                </button>
              </li>
              <li className={'option-item'} key={'searchGroup'}>
                <button className={`option-selection selected`} onClick={openSearchGroupModal}>
                  <span className={'option-text'}>{'Search Groups'}</span>
                </button>
              </li>
              {dropdownList}
            </ul>
          </section>
        )
      }
      
    </section>
  );
}

GroupsDropdown.displayName = 'GroupsDropdown';
GroupsDropdown.propTypes = propTypes;
export default GroupsDropdown;