import './AnalysisDisplay.scss';

import React, { useState} from 'react';
import PropTypes from 'prop-types';

import QUANTITATIVE from './Quantitative_Insights.svg';
import QUALITATIVE from './Qualitative_Insights.svg';
import ButtonWithBorder from '../../../../common/ButtonWithBorder';

const propTypes = {};

function AnalysisDisplay() {
  const [hoverImage, setHoverImage] = useState(false);
  const onMouseOver = () => setHoverImage(true);
  const onMouseOut = () => setHoverImage(false);
  return (
    <section className={'analysis-display'}>
      <section className={'analysis-container'}>
        <section className={'insight-header'}>
          <span className={'insight-title'}>{'Quantitative Insights'}</span>
        </section>
        <section className={'image-container'}>
          <img className={'placeholder-svg'} src={QUANTITATIVE} onMouseOver={onMouseOver} alt={'analysis'} />
          {hoverImage && 
            <section className={'cover-image'} onMouseOut={onMouseOut}>
              <ButtonWithBorder additionalClassNames={['white-bg']} buttonText={'Coming soon'} />
            </section>
          }
        </section>
      </section>
      <section className={'analysis-container'}>
        <section className={'insight-header'}>
          <span className={'insight-title'}>{'Qualitative Insights'}</span>
        </section>
        <section className={'image-container'}>
          <img className={'placeholder-svg'} src={QUALITATIVE} onMouseOver={onMouseOver} alt={'analysis'} />
          {hoverImage && 
            <section className={'cover-image'} onMouseOut={onMouseOut}>
              <ButtonWithBorder additionalClassNames={['white-bg']} buttonText={'Coming soon'} />
            </section>
          }
        </section>
      </section>
    </section>
  )
}

AnalysisDisplay.displayName = 'AnalysisDisplay';
AnalysisDisplay.propTypes = propTypes;
export default AnalysisDisplay;