import './ToggleButton.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GREEN_ICON from '../../assets/green-icon.svg';
import YELLOW_ICON from '../../assets/yellow-icon.svg';

const propTypes = {};

function ToggleButton({ active, setActive, disabled }) {
  const onClick = () => setActive(!active);
  const buttonText = active ? 'On' : 'Off';
  const buttonIcon = active ? GREEN_ICON : YELLOW_ICON;
  return (
    <button className={`toggle-button ${active ? 'active' : ''}`} onClick={onClick} disabled={disabled}>
      <img className={'icon'} src={buttonIcon} alt={buttonText} />
      <span className={'button-text'}>{buttonText}</span>
    </button>
  );
}

ToggleButton.displayName = 'ToggleButton';
ToggleButton.propTypes = propTypes;
export default ToggleButton;