import './ReorderSelection.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AssistantSelection from '../common/AssistantSelection';
import AssistantSelectionSection from '../common/AssistantSelectionSection';
import AssistantTextInput from '../common/AssistantTextInput';
import ToggleButton from '../common/ToggleButton';
import DeleteButton from '../../../../common/DeleteButton';
import PlusButton from '../common/PlusButton';
import { postActivateAssistant } from '../../../../../../../apis/AssistantApi';
import { WEEKLY_FREQUENCY_OPTIONS } from '../../EngagementConstants';
import useAuth from '../../../../../../../context/AuthContext';
import AssistantDropdown from '../common/AssistantDropdown';

const propTypes = {};

function ReorderSelection({ context, setAssistantKey, assistantKey }) {
  const [assistantContext, setAssistantContext] = useState(context);
  const { setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const cancel = () => setAssistantKey(null);
  const { product, checkInMessage, offers, autopilot, frequency } = assistantContext;
  const setProduct = (product) => setAssistantContext({ ...assistantContext, product });
  const setCheckInMessage = (checkInMessage) => setAssistantContext({ ...assistantContext, checkInMessage });
  const setAutopilot = (autopilot) => setAssistantContext({ ...assistantContext, autopilot });
  const setFrequency = (frequency) => setAssistantContext({ ...assistantContext, frequency });
  const promotionDescription = 'Add up to 10';
  const autopilotDescription = autopilot 
    ? 'Rex will automatically select recipients and send out messages on your behalf.'
    : 'Rex will create draft campaigns for you. You manually import recipients to send it off.';
  const offerList = offers.map((offer, index) => {
    const setOffer = (newOffer) => {
      const newOffers = [...offers];
      newOffers[index] = newOffer;
      setAssistantContext({ ...assistantContext, offers: newOffers });
    }
    const deleteOffer = () => setAssistantContext({
      ...assistantContext, offers: offers.filter((_, i) => i !== index) });
    return (
      <AssistantTextInput
        text={offer}
        setText={setOffer}
        placeholder={'Add your promotional offer here'}
        additionalClassNames={['offer-input-container']}
        additionalButton={<DeleteButton onClick={deleteOffer} />}
        key={`offer-${index}`}
      />
    );
  });
  const addOffer = () => {
    if (offers.length >= 10) {
      return;
    }
    const newOffers = [...offers, ''];
    setAssistantContext({ ...assistantContext, offers: newOffers });
  }
  const activate = (callback) => {
    if (loading) {
      return;
    }
    setLoading(true);
    postActivateAssistant(assistantKey, { ...assistantContext })
      .then((res) => {
        setUser(res.user);
        setLoading(false);
        callback();
      });
  }
  return (
    <AssistantSelection
      title={'Reorder Assistant'}
      additionalClassNames={['reorder-assistant']}
      cancel={cancel}
      activateAssistant={activate}
      assistantKey={assistantKey}
    >
      <AssistantSelectionSection title={`Define what product you’d like customers to re-order`} description={'We recommend those frequently purchased such as contact lenses'}>
        <AssistantTextInput text={product} setText={setProduct} placeholder={`Insert the product you want customers to re-order here`} />
      </AssistantSelectionSection>
      <AssistantSelectionSection title={`Describe what you’d like in the message content`} description={'We recommend a simple, check-in message'}>
        <AssistantTextInput text={checkInMessage} setText={setCheckInMessage} placeholder={'Insert your check-in message here'} />
      </AssistantSelectionSection>
      <AssistantSelectionSection
        title={'Add promotional offer'}
        description={promotionDescription}
      >
        <ul className={'offer-list'}>{offerList}</ul>
        { offers.length < 10 && <PlusButton onClick={addOffer} buttonText={'Add offer'} /> }
      </AssistantSelectionSection>
      <AssistantSelectionSection title={'Select frequency'} description={'How often would you like this campaign launched?'}>
        <AssistantDropdown
          defaultButtonText={'Frequency'}
          options={WEEKLY_FREQUENCY_OPTIONS}
          selected={frequency}
          select={setFrequency}
        />
      </AssistantSelectionSection>
      <AssistantSelectionSection
        title={'Auto pilot'}
        description={autopilotDescription} 
        toggleButton={<ToggleButton active={autopilot} setActive={setAutopilot} />}
      >
      </AssistantSelectionSection>
    </AssistantSelection>
  );
}

ReorderSelection.displayName = 'ReorderSelection';
ReorderSelection.propTypes = propTypes;
export default ReorderSelection;