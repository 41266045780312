import './FloatingModal.scss';

import React, { useRef } from 'react';

import { useOutsideClick } from '../../hooks/ClickHooks';

function FloatingModal({ children, closeModal, additionalClassNames = [] }) {
  const modalRef = useRef(null);
  useOutsideClick(modalRef, () => closeModal());

  return (
    <section className={`absolute-modal-container-wrapper ${additionalClassNames.join(' ')}`}>
      <section className={`modal-container ${additionalClassNames.join(' ')}`} ref={modalRef}>
        {children}
      </section>
    </section>
  )
}

export default FloatingModal;
