import './PreviewToolButtons.scss';

import React, { useState } from 'react';
import { convert } from 'html-to-text';

import UPLOAD_ICON from '../assets/upload-icon.svg';

import Attachment from './Attachment';
import AttachmentModal from '../../modals/AttachmentModal';

function PreviewToolButtons({ proceed = null, uploadText, attachment, setAttachment, viewOnly = false }) {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const deleteAttachment = () => setAttachment(null);
  const hasAttachment = !!attachment;
  const send = async () => {
    setLoading(true);
    await proceed();
    setLoading(false);
  }
  return (
    <section className={'preview-tool-buttons'}>
      { modalOpen && <AttachmentModal close={() => setModalOpen(false)} setAttachment={setAttachment} initialFile={attachment} /> }
      <section className={'buttons'}>
        <section className={'upload-button-container'}>
          <button className={`upload-button ${hasAttachment ? 'has-attachment' : ''} ${viewOnly ? 'disabled' : ''}`} onClick={() => setModalOpen(true)} disabled={viewOnly}>
            <img className={'upload-icon'} src={UPLOAD_ICON} alt={'Upload icon'} />
            {uploadText}
          </button>
          { attachment && (
            <section className={'attachment-container'}>
              <Attachment attachment={attachment} deleteAttachment={deleteAttachment} disabled={viewOnly} />
            </section>
          )}
        </section>
        <button className={'submit-button'} onClick={send} disabled={loading}>{loading ? 'Sending...' : 'Send'}</button>
      </section>
    </section>
  );
};

PreviewToolButtons.displayName = 'PreviewToolButtons';
export default PreviewToolButtons;