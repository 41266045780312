import './EditAutomationDropdown.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DOWN_CARET from '../../assets/downward-caret-icon.svg';

const propTypes = {};

function EditAutomationDropdown({ title, children }) {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  return (
    <section className={`edit-automation-dropdown-container ${open ? 'open' : ''}`}>
      <button className={'dropdown-button'} onClick={toggle}>
        <span className={'text'}>{title}</span>
        <img className={'caret-icon'} src={DOWN_CARET} />
      </button>
      { open && children }
    </section>
  );
}

EditAutomationDropdown.displayName = 'EditAutomationDropdown';
EditAutomationDropdown.propTypes = propTypes;
export default EditAutomationDropdown;