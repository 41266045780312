import './CommunicationsPageWrapper.scss';

import React from 'react';
import PropTypes from 'prop-types';
import CommunicationsNavigationTabs from './navigation/CommunicationsNavigationTabs';
import WorkRexPageWrapper from '../common/WorkRexPageWrapper';

const propTypes = {};

function CommunicationsPageWrapper({ additionalClassNames = [], additionalOptions = [], selectedTab, showNavigationTabs = true, children }) {
  const classNames = ['communications-page', ...additionalClassNames];
  return (
    <WorkRexPageWrapper additionalClassNames={classNames} additionalOptions={additionalOptions}>
      { showNavigationTabs && <CommunicationsNavigationTabs selectedTab={selectedTab} /> }
      <section className={'communications-container'}>
        {children}
      </section>
    </WorkRexPageWrapper>
  )
}

CommunicationsPageWrapper.displayName = 'CommunicationsPageWrapper';
CommunicationsPageWrapper.propTypes = propTypes;
export default CommunicationsPageWrapper;