import './CreateTaskModal.scss';

import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from 'prop-types';

import { DEFAULT_TASK, REPEAT_MAP } from '../../../../MessagingConstants';
import useAuth from '../../../../../../../../context/AuthContext';
import { postTaskCreation, postTaskEdit } from '../../../../../../../../apis/Api';

import CreateRecordModal from './CreateRecordModal';
import DropdownField from '../../dropdown/DropdownField';
import FieldSection from './FieldSection';
import SearchContact from './SearchContact';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';

const propTypes = {};

function CreateTaskModal({ closeModal, modalType, selectedRecord, setSelectedRecord, existingTask = DEFAULT_TASK, createMode = true }) {
  const { user } = useAuth();
  const { employees, setTasks } = MessagingConsumer();

  const [task, setTask] = useState(existingTask);
  const { assignedTo } = task;
  const [searchText, setSearchText] = useState(createMode ? '' : `${existingTask.assignedTo.firstName} ${existingTask.assignedTo.lastName}`);
  const [filteredContacts, setFilteredContacts] = useState(employees);
  const [error, setError] = useState('');
  
  const datePickerRef = useRef(null);
  const updateLabel = (e) => {
    setError('');
    setTask({...task, label: e.target.value});
  }
  const selectDate = (date) => {
    setError('');
    date.setHours(0, 0, 0, 0);
    setTask({ ...task, dueDate: date.getTime() });
  };

  const setSearch = (e) => {
    setSearchText(e.target.value);
    // setTask({ ...task, assignedTo: '' });
    const result = employees.filter((employee) => {
      const employeeName = `${employee.firstName} ${employee.lastName}`;
      return employeeName.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setFilteredContacts(result);
  }

  const setAssignedTo = (assignedToUser) => {
    setError('');
    setTask({ ...task, assignedTo: assignedToUser });
    setSearchText(`${assignedToUser.firstName} ${assignedToUser.lastName}`);
  }

  const createRecord = () => {
    if (!task.label) {
      setError('Please enter a label for this task.');
      return;
    } else if (!task.assignedTo) {
      setError('Please select a user to assign this task to.');
      return;
    } else if (!task.dueDate) {
      setError('Please select a due date for this task.');
      return;
    }
    const taggedToId = selectedRecord && selectedRecord.contactType === 'CUSTOMER' ? selectedRecord._id : '';
    const taggedTo = selectedRecord && selectedRecord.contactType === 'CUSTOMER' ? selectedRecord : '';
    
    const newTask = {
      ...task,
      assignedTo: task.assignedTo ? task.assignedTo._id : '',
      createdBy: user._id,
      taggedTo: taggedToId,
    }
    postTaskCreation(newTask, user._id).then(response => {
      let newTaskList = [];
      setTasks(prevState => {
        newTaskList = [ ...prevState, { ...response.task, assignedTo: task.assignedTo, createdBy: user, taggedTo: taggedTo } ];
        return newTaskList;
      });
      if (selectedRecord && Object.hasOwn(selectedRecord, 'contactType')) {
        setSelectedRecord(newTaskList);
      }
      closeModal();
    })
  }

  const editRecord = () => {
    if (!task.label) {
      setError('Please enter a label for this task.');
      return;
    } else if (!task.assignedTo) {
      setError('Please select a user to assign this task to.');
      return;
    } else if (!task.dueDate) {
      setError('Please select a due date for this task.');
      return;
    }
    const changes = [];
    if (task.label !== existingTask.label) {
      changes.push(`Label changed from ${existingTask.label} to ${task.label}`);
    }
    if (!!task.assignedTo && !!existingTask.assignedTo && task.assignedTo._id !== existingTask.assignedTo._id) {
      changes.push(`Task reassigned from ${existingTask.assignedTo.firstName} ${existingTask.assignedTo.lastName} to ${task.assignedTo.firstName} ${task.assignedTo.lastName}`);
    }
    if (task.dueDate !== existingTask.dueDate) {
      const newDueDate = new Date(task.dueDate);
      const oldDueDate = new Date(existingTask.dueDate);
      const newDueDateString = `${newDueDate.getDate()}/${newDueDate.getMonth() + 1}/${newDueDate.getFullYear()}`
      const oldDueDateString = `${oldDueDate.getDate()}/${oldDueDate.getMonth() + 1}/${oldDueDate.getFullYear()}`
      changes.push(`Due date changed from ${oldDueDateString} to ${newDueDateString}`);
    }
    if (task.frequency !== existingTask.frequency) {
      changes.push(`Task frequency changed from ${existingTask.frequency} to ${task.frequency}`);
    }
    const updatedTask = {
      _id: task._id,
      schedulerId: task.schedulerId,
      label: task.label,
      dueDate: task.dueDate,
      frequency: task.frequency,
      assignedTo: task.assignedTo._id,
    };
    postTaskEdit(updatedTask, changes, user._id)
      .then(response => {
        let newTaskList = [];
        setTasks(prevState => {
          const filteredTasks = prevState.filter(prevTasks => prevTasks._id !== task._id);
          newTaskList = [ ...filteredTasks, { ...task, ...response.task} ];
          return newTaskList;
        });
        setSelectedRecord({ ...task, ...response.task});
        closeModal();
      })
  }

  const modalSubmit = createMode ? createRecord : editRecord;

  const setDueDate = dateType => count => () => {
    let date = new Date();
    if (dateType === 'month') {
      date = date.setMonth(date.getMonth() + count);
    } else if (dateType === 'day') {
      date = date.setDate(date.getDate() + count);
    }
    setTask({ ...task, dueDate: date });
  }

  const setFrequency = (option) => {
    setTask(prevState => ({...prevState, frequency: option}));
  }

  return (
   <CreateRecordModal closeModal={closeModal} modalType={modalType} createRecord={modalSubmit} createMode={createMode}>
    <p className={'confirm-error-text'}>{error}</p>
    <section className={'modal-details'}>
      <FieldSection inputLabel={'Label'}>
        <input className={'create-record-input'} onChange={updateLabel} type={'text'} value={task.label} />
      </FieldSection>
      <FieldSection inputLabel={'Assign the task to who?'}>
        <section className={'search-contact-container'}>
          <input className={'create-record-input'} onChange={setSearch} value={searchText} placeholder={'Send to...'} type={'text'} />
          {searchText.length > 0 && <SearchContact assignedTo={assignedTo} options={filteredContacts} setAssignedTo={setAssignedTo} />}
        </section>
      </FieldSection>
      <FieldSection inputLabel={'When is it due?'} ref={datePickerRef}>
        <section className={'time-period-picker'}>
          <DatePicker
            minDate={new Date()}
            selected={task.dueDate}
            onChange={selectDate}
            dateFormat={'dd/MM/yyyy'}
            formatWeekDay={nameOfDay => nameOfDay.substring(0, 3)}
            placeholderText={'DD/MM/YYYY'}
            showPopperArrow={false}
          />
        </section>
        <section className={'set-due-date-container'}>
          <button className={'set-due-date'} onClick={setDueDate('day')(7)} type={'button'}>{'In a week'}</button>
          <button className={'set-due-date'} onClick={setDueDate('day')(14)} type={'button'}>{'In a fortnight'}</button>
          <button className={'set-due-date'} onClick={setDueDate('month')(1)} type={'button'}>{'In a month'}</button>
          <button className={'set-due-date'} onClick={setDueDate('month')(6)} type={'button'}>{'In 6 months'}</button>
        </section>
      </FieldSection>
      <FieldSection inputLabel={'Repeat'}>
        <DropdownField dropdownSelected={task.frequency} dropdownOptions={REPEAT_MAP} text={REPEAT_MAP[task.frequency].text} setSelectedData={setFrequency} />
      </FieldSection>
    </section>
   </CreateRecordModal>
  )
}

CreateTaskModal.displayName = 'CreateTaskModal';
CreateTaskModal.propTypes = propTypes;
export default CreateTaskModal;