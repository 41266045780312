import './InstagramPreview.scss';

import React from 'react';
import PreviewWrapper from './PreviewWrapper';

import INSTAGRAM_PREVIEW_IMAGE from './assets/instagram-preview-image.png';
import SocialMediaPost from './common/SocialMediaPost';

function InstagramPreview({ preview, setPreview, proceed }) {
  return (
    <PreviewWrapper title={'Instagram'} previewImage={INSTAGRAM_PREVIEW_IMAGE}>
      <SocialMediaPost preview={preview} setPreview={setPreview} proceed={proceed} />
    </PreviewWrapper>
  );
};

InstagramPreview.displayName = 'InstagramPreview';
export default InstagramPreview;