import './BroadcastPage.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MessagingConsumer from '../../../../../../../context/MessagingContext';
import { postPauseBroadcast, postDeleteBroadcast } from '../../../../../../../apis/BroadcastApi';
import { postBroadcastDelete, postBroadcastPause } from '../../../../../../../apis/Api';

import GenericLabel from '../../list/views/GenericLabel';
import DotBricksLoading from '../../../../../../common/DotBricksLoading';
import Modal from '../../../../../../common/Modal';
import useAuth from '../../../../../../../context/AuthContext';

const modalViews = {
  DELETE: {
    key: 'DELETE',
    description: 'Confirm deletion of this broadcast. Recipients will not receive further messages.',
    buttonText: 'Delete',
  },
  PAUSE: {
    key: 'PAUSE',
    description: 'Confirm pausing of this broadcast. Recipients will not receive further messages until restarted.',
    buttonText: 'Pause',
  },
  RESUME: {
    key: 'RESUME',
    description: 'Confirm that you would like to resume this broadcast. Recipients will receive messages on the next scheduled date.',
    buttonText: 'Resume',
  },
}

const propTypes = {};

const shortTime = new Intl.DateTimeFormat("en", {
  timeStyle: "short",
});

function BroadcastPage({ selectedRecord, setSelectedRecord }) {
  const { user, setUser } = useAuth();
  const { campaigns } = user;
  const { message, recipients, paused, scheduledSendDate, campaignId, _id } = selectedRecord;
  const [modalMode, setModalMode] = useState(modalViews.DELETE.key);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const openModal = (modalMode) => () => {
    setShowModal(true)
    setModalMode(modalMode);
  };

  const { setBroadcasts, templates } = MessagingConsumer();
  const additionalClassNames = ['broadcast-recipients']

  const PAUSE_DISPLAY = paused ? modalViews.RESUME : modalViews.PAUSE;
  const scheduledTimeDisplay = `${shortTime.format(scheduledSendDate)} on ${new Date(scheduledSendDate).toLocaleDateString('en-au', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
  })}`

  const deleteBroadcast = () => {
    postDeleteBroadcast(selectedRecord._id)
      .then(response => {
        setUser({ ...user, broadcasts: user.broadcasts.filter(broadcast => broadcast._id !== selectedRecord._id) });
        setSelectedRecord(null);
      })
      .finally(closeModal)
  }

  const pauseBroadcast = () => {
    postPauseBroadcast(selectedRecord._id, !selectedRecord.paused)
      .then(_ => {
        const updatedBroadcast = { ...selectedRecord, paused: !selectedRecord.paused };
        setSelectedRecord(updatedBroadcast);
        setUser({ ...user, broadcasts: user.broadcasts.map(broadcast => broadcast._id === selectedRecord._id ? updatedBroadcast : broadcast) })
      })
      .finally(closeModal)
  }

  if (templates === null) {
    return <DotBricksLoading isFixed={false} />
  }

  const campaign = campaignId ? campaigns.find(campaign => campaign._id === campaignId) : null;
  const messageDisplay = campaign ? campaign.name : message;
  const onSubmit = modalMode === modalViews.DELETE.key ? deleteBroadcast : pauseBroadcast;

  return (
    <section className={'broadcast-page'}>
      <p className={'broadcast-title'}>{'Broadcast message'}</p>
      <section className={'broadcast-field-container'}>
        <span className={'broadcast-field-label'}>{'Sent to:'}</span>
        <section className={'broadcast-input-box'}>
          {recipients.slice(0, 7).map(recipient => <GenericLabel additionalClassNames={additionalClassNames} text={`${recipient.firstName} ${recipient.lastName}`} />)}
          {recipients.length > 7 && 
            <GenericLabel additionalClassNames={['broadcast-recipients']} text={`+${recipients.length - 7} ${recipients.length - 7 <= 1 ? 'contact' : 'contacts'}`} />
          }
        </section>
      </section>
      <section className={'broadcast-field-container'}>
        <span className={'broadcast-field-label'}>{campaign ? 'Campaign': 'Message:'}</span>
        <p className={'new-broadcast-template'} dangerouslySetInnerHTML={{ __html: messageDisplay }} />
      </section>
      <section className={'broadcast-field-container'}>
        <span className={'broadcast-field-label'}>{'Time scheduled:'}</span>
        <span className={'broadcast-field-value'}>{scheduledTimeDisplay}</span>
      </section>
      <section className={'broadcast-button-container'}>
        <button className={'broadcast-button delete-broadcast'} onClick={openModal(modalViews.DELETE.key)} type={'button'}>
          <span>{'Delete broadcast'}</span>
        </button>
        <button className={'broadcast-button pause-broadcast'} onClick={openModal(PAUSE_DISPLAY.key)} type={'button'}>
          <span>{`${PAUSE_DISPLAY.buttonText} broadcast`}</span>
        </button>
      </section>
      {showModal &&
        <Modal closeModal={closeModal} additionalClassNames={['archive']}>
          <section className={'broadcast-modal'}>
            <p className={'broadcast-modal-text'}>{modalViews[modalMode].description}</p>
            <button className={'broadcast-modal-button'} onClick={onSubmit} type={'button'}>{modalViews[modalMode].buttonText}</button>
          </section>
        </Modal>
      }
    </section>
  )
}

BroadcastPage.displayName = 'BroadcastPage';
BroadcastPage.propTypes = propTypes;
export default BroadcastPage;