import './SettingsDropdownOption.scss';

import PropTypes from 'prop-types';
import React from 'react';

import SELECTED_PURPLE_TICK from '../assets/selected-purple-tick.svg';

const propTypes = {};

function SettingsDropdownOption({ option, selected, setSelected, useSearch }) {
  const answerSelected = selected.includes(option.key);
  const selectedStyle = useSearch ? 'search-selected' : 'selected';

  return (
    <button className={`settings-dropdown-button ${answerSelected ? selectedStyle : ''}`} onClick={() => setSelected(option.key)} type={'button'}>
      <span className={'settings-dropdown-button-text'}>{option.displayText}</span>
      {answerSelected && <img alt={'selected setting'} className={'selected-setting'} src={SELECTED_PURPLE_TICK} />}
    </button>
  )
};

SettingsDropdownOption.defaultProps = { useSearch: false };
SettingsDropdownOption.displayText = 'SettingsDropdownOption';
SettingsDropdownOption.propTypes = propTypes;
export default SettingsDropdownOption;