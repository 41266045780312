import './AssistantSelectionSection.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function AssistantSelectionSection({ title, toggleButton = null, description = null, children = null, hidden = false }) {
  if (hidden) {
    return null;
  }
  return (
    <section className={'assistant-selection-section'}>
      <header className={'section-header'}>
        <h6 className={'section-title'}>{title}</h6>
        {toggleButton}   
      </header>
      {description && <p className={'section-description'}>{description}</p>}
      {children}
    </section>
  );
}

AssistantSelectionSection.displayName = 'AssistantSelectionSection';
AssistantSelectionSection.propTypes = propTypes;
export default AssistantSelectionSection;