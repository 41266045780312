import './DeleteButton.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = { };

function DeleteButton({ disabled = false, onClick }) {
  return (
    <section className={'delete-button-container'}>
      <button className={`delete-button ${disabled ? 'disabled' : ''}`} onClick={onClick} disabled={disabled}>
        <span className={'delete-sign'}>{'×'}</span>
      </button>
    </section>
  );
}

DeleteButton.displayName = 'DeleteButton';
DeleteButton.propTypes = propTypes;
export default DeleteButton;