import { useEffect } from 'react';

/**
 * Hook to set up a keypress listener in order to trigger a callback upon an 'Enter' key press.
 * @param {node} ref - component that is expecting to be in focus when an enter event is fired. 
 * @param {func} callback - resulting callback upon receiving an 'Enter' press 
 */
export const useEnterClick = (ref, callback = () => {}) => {
  useEffect(() => {
    const buttonListener = (event) => {
      if (event.key === 'Enter' && !event.shiftKey && ref.current) {
        ref.current.click();
        callback();
      }
    };
    window.addEventListener('keypress', buttonListener);
    return () => window.removeEventListener('keypress', buttonListener);
  }, [ref, callback]);
};

/**
 * Hook to set up a mousedown/touchstart listener to fire a callback when user mouse clicks/touches outside a component.
 * @param {node} ref - component that is used to define "inside". Outside refers to everything outside this component. 
 * @param {*} callback - callback fired when a mouse click or touch is fired from outside the component.
 */
export const useOutsideClick = (ref, callback = () => {}) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [ref, callback]);
};