import './TaskCard.scss';

import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import INCOMPLETE_ICON from './assets/incomplete-icon.svg';
import COMPLETED_ICON from './assets/completed-icon.svg';

dayjs.extend(advancedFormat);

const propTypes = {};

function TaskCard({ task, toggleComplete }) {
  const { assignedTo, label, completed, dueDate, creationDate, taggedTo } = task;

  const assignedDateText = dayjs(new Date(creationDate)).format('Do MMM YYYY');
  const dueDateText = dayjs(new Date(dueDate)).format('Do MMM YYYY');
  const completedIcon = completed ? COMPLETED_ICON : INCOMPLETE_ICON;

  return (
    <section className={'task-card'}>
      <button className={'complete-button'} onClick={() => toggleComplete(!completed)}>
        <img alt={'completed-icon'} className={completed ? 'complete-icon' : 'incomplete-icon'} src={completedIcon} />
      </button>
      <section className={'task-details'}>
        <span className={'task-name'}>{label}</span>
        <span className={'assigned-date'}>{`Assigned on: ${assignedDateText}`}</span>
        <span className={'due-date'}>{`Due on ${dueDateText}`}</span>
        { assignedTo && <span className={'tagged-name'}>{`Assigned to ${assignedTo.firstName} ${assignedTo.lastName}`}</span> }
        { taggedTo && <span className={'tagged-name'}>{`Tagged to ${taggedTo.firstName} ${taggedTo.lastName}`}</span> }
      </section>
    </section>
  );
}

TaskCard.displayName = 'TaskCard';
TaskCard.propTypes = propTypes;
export default TaskCard;