import './AutomationSidebar.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddButton from '../../../common/AddButton';
import SearchInput from '../../../common/SearchInput';
import FilterButtonList from '../../../common/FilterButtonList';

const propTypes = {};

function AutomationSidebar({ searchText, setSearchText, filters }) {
  const navigate = useNavigate();
  return (
    <aside className={'automation-sidebar'}>
      <AddButton buttonText={'Request new automation'} onClick={() => navigate(`/automations/request`)} />
      <span className={'divider'} />
      <SearchInput searchText={searchText} setSearchText={setSearchText} />
      <FilterButtonList filters={filters} />
    </aside>
  );
}

AutomationSidebar.displayName = 'AutomationSidebar';
AutomationSidebar.propTypes = propTypes;
export default AutomationSidebar;