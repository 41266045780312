import './CommunicationHours.scss';

import React from 'react';
import TimeDropdown from './TimeDropdown';


const propTypes = {};

const TWELVE_HOURS_IN_MILLISECONDS = 12 * 60 * 60 * 1000;

function CommunicationHours({ communicationHours, setHours }) {
  const { startTime, endTime, sendWithinHours } = communicationHours;
  const toggleWithinHours = (sendWithinHours) => setHours({ ...communicationHours, sendWithinHours });
  const isStartAM = startTime < TWELVE_HOURS_IN_MILLISECONDS;
  const isEndAM = endTime < TWELVE_HOURS_IN_MILLISECONDS;
  const setTime = (fieldName, isAM) => (value) => {
    const timeValue = isAM ? value : value + TWELVE_HOURS_IN_MILLISECONDS;
    setHours({ ...communicationHours, [fieldName]: timeValue })
  };
  const toggleAM = (time) => {
    if (time < TWELVE_HOURS_IN_MILLISECONDS) {
      return time + TWELVE_HOURS_IN_MILLISECONDS;
    }
    return time - TWELVE_HOURS_IN_MILLISECONDS;
  }
  const toggleStartAM = () => {
    const newStartTime = toggleAM(startTime);
    setHours({ ...communicationHours, startTime: newStartTime });
  }
  const toggleEndAM = () => {
    const newEndTime = toggleAM(endTime);
    setHours({ ...communicationHours, endTime: newEndTime });
  }

  return (
    <section className={'communication-hours'}>
      <section className={'time-selection-container'}>
        <section className={'time-selection'}>
          <TimeDropdown selectedTime={startTime % TWELVE_HOURS_IN_MILLISECONDS} setTime={setTime('startTime', isStartAM)} />
          <button className={'am-toggle-button'} onClick={toggleStartAM}>{isStartAM ? 'AM' : 'PM'}</button>
        </section>
        <section className={'range-container'}>
          <input
            type={'checkbox'}
            id={'within-hours'}
            className={'range-checkbox'}
            checked={sendWithinHours}
            onChange={() => toggleWithinHours(!sendWithinHours)}
          />
          <label className={'range-label'} htmlFor={'within-hours'}>{'Send within these hours'}</label>
        </section>
      </section>
      <section className={'time-selection-container'}>
        <section className={'time-selection'}>
          <TimeDropdown selectedTime={endTime % TWELVE_HOURS_IN_MILLISECONDS} setTime={setTime('endTime', isEndAM)} />
          <button className={'am-toggle-button'} onClick={toggleEndAM}>{isEndAM ? 'AM' : 'PM'}</button>
        </section>
        <section className={'range-container'}>
          <input
            type={'checkbox'}
            id={'within-hours'}
            className={'range-checkbox'}
            checked={!sendWithinHours}
            onChange={() => toggleWithinHours(!sendWithinHours)}
          />
          <label className={'range-label'} htmlFor={'within-hours'}>{'Send outside these hours'}</label>
        </section>
      </section>
    </section>
  )
}

CommunicationHours.displayName = 'CommunicationHours';
CommunicationHours.propTypes = propTypes;
export default CommunicationHours;