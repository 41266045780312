import './ManagerTable.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function ManagerTable({ additionalClassNames = [], headers = [], rows = [], noRowsText, children = null, loading = false }) {
  const header = (
    <thead className={`table-header ${additionalClassNames.join(' ')}`}>
      <tr className={'table-row'}>
        {headers.map(({ display }, index) => <th className={'table-header-cell'} key={`header-${index}`}><span className={'table-item'}>{display}</span></th>)}
      </tr>
    </thead>
  );
  const tableRows = rows.map((row, x) => (
    <tr className={'table-row'} key={`row-${x}`}>
      {headers.map(({ key }, y) => <td className={`table-cell ${key}`} key={`cell-${x}-${y}`}>{<span className={'table-item'}>{row[key]}</span>}</td>)}
    </tr>
  ));
  return (
    <section className={'overflow-container'}>
      <section className={'manager-table-container'}>
        <table className={`manager-table ${additionalClassNames.join(' ')}`}>
          {header}
          <tbody>
            {tableRows}
          </tbody>
        </table>
        {!loading && rows.length === 0 && <span className={'no-rows-text'}>{noRowsText}</span>}
        {children}
      </section>
    </section>
  );
}

ManagerTable.displayName = 'ManagerTable';
ManagerTable.propTypes = propTypes;
export default ManagerTable;