import './Hamburger.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  /* True if open. */
  open: PropTypes.bool.isRequired,
};

function Hamburger({ open }) {
  return (
    <section className={`hamburger ${open ? 'open' : ''}`}>
      <span className={'line'} />
      <span className={'line'} />
      <span className={'line'} />
    </section>
  );
}

Hamburger.propTypes = propTypes;
Hamburger.displayName = 'Hamburger';
export default Hamburger;