import './TagWithDelete.scss';

import React from 'react';

import { ASSET_CDN_URL } from '../../Constants';

const exitIcon = `${ASSET_CDN_URL}/icons/workrex_purple_x_icon.svg`;

function TagWithDelete({ tagText, tagClick }) {
  
  return (
    <section className={'tag-with-delete'}>
      <span className={'tag-with-delete-text'}>{tagText}</span>
      <button className={'tag-button'} onClick={tagClick} type={'button'}>
        <img alt={'tag-button'} className={'tag-img'} src={exitIcon} />
      </button>
    </section>
  );
};

TagWithDelete.displayName = 'TagWithDelete';
export default TagWithDelete;