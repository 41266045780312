import { DERIVED_FIELDS } from '../workrex/components/managers/ManagerConstants';
import { RECORD_TYPES } from '../workrex/components/managers/communications/MessagingConstants';
import { OPPORTUNITY_SCORE } from '../workrex/components/managers/communications/engagements/EngagementConstants';

const { NO_DATA, QUARTER_DATA, HALF_DATA, THREE_QUARTER_DATA, FULL_DATA } = OPPORTUNITY_SCORE;

export const getRelatedInteractions = (user, interactions) => {
  if (user && interactions) {
    const relatedInteractions = interactions
      .filter(interaction => (interaction.sender.toString() === user._id.toString() || interaction.receiver.toString() === user._id.toString()));
    return relatedInteractions;
  }
  return [];
};

export const getRelatedBroadcasts = (user, broadcasts) => {
  if (user && broadcasts) {
    const relatedBroadcasts = broadcasts
      .filter(({ recipientIds }) => recipientIds.includes(user._id.toString()))
      .map(({ lastSendDate }) => ({ lastSendDate }));
    return relatedBroadcasts;
  }
  return [];
};

export const calculateOpportunityScoreKey = (opportunityScore) => {
  if (!opportunityScore) {
    return NO_DATA.key;
  }
  else if (opportunityScore <= 25) {
    return QUARTER_DATA.key;
  }
  else if (opportunityScore <= 50) {
    return HALF_DATA.key;
  }
  else if (opportunityScore <= 75) {
    return THREE_QUARTER_DATA.key;
  }
  else if (opportunityScore <= 100) {
    return FULL_DATA.key;
  }
};

export const matchObjectReferences = (customers, employees, groups, integrationUser, interactions, tasks, recordType, record) => {
  switch (recordType) {
    case RECORD_TYPES.CUSTOMER.key: {
      const { groupId } = record;
      const group = groups.find(group => group._id.toString() === groupId);
      const employeesWithIntUser = [ ...employees, integrationUser ];
      const customerInteractions = getRelatedInteractions(record, interactions);
      const labelledCustomerInteractions = customerInteractions.map(interaction => ({ ...interaction, createdBy: employeesWithIntUser.find(employee => employee._id === interaction.createdBy), sender: employeesWithIntUser.find(employee => employee._id === interaction.sender), receiver: record }));
      const customerTasks = tasks.filter(task => task.taggedTo && task.taggedTo._id.toString() === record._id.toString());
      //TODO: Can this be removed?
      // const labelledCustomerTasks = customerTasks.map(task => ({ ...task, assignedTo: employees.find(employee => employee._id === task.assignedTo), taggedTo: record }));

      return { ...record, group, interactions: labelledCustomerInteractions, tasks: customerTasks };
    }
    case RECORD_TYPES.ADMIN.key:
    case RECORD_TYPES.EMPLOYEE.key: {
      const employeesWithIntUser = [ ...employees, integrationUser ];

      const employeeInteractions = getRelatedInteractions(record, interactions);
      const labelledEmployeeInteractions = employeeInteractions.map(interaction => ({ ...interaction, createdBy: employeesWithIntUser.find(employee => employee._id === interaction.createdBy), sender: record, receiver: customers.find(customer => customer._id === interaction.receiver) }));

      const employeeTasks = tasks.filter(task => task.assignedTo && task.assignedTo._id.toString() === record._id.toString());
      // TODO: Can this be removed?
      // const labelledEmployeeTasks = employeeTasks.map(task => ({ ...task, assignedTo: record, taggedTo: customers.find(customer => customer._id === task.taggedTo) }));

      return { ...record, interactions: labelledEmployeeInteractions, tasks: employeeTasks };
    }
    case RECORD_TYPES.INTERACTION.key: {
      const { createdBy, receiver, sender } = record;
      const employeesWithIntUser = [ ...employees, integrationUser ];
      const createdByEmployee = employeesWithIntUser.find(employee => employee._id.toString() === createdBy.toString());
      const senderEmployee = employees.find(employee => employee._id.toString() === sender.toString());
      const receiverCustomer = customers.find(customer => customer._id.toString() === receiver.toString());

      return { ...record, createdBy: createdByEmployee, sender: senderEmployee, receiver: receiverCustomer };
    }
    case RECORD_TYPES.TASK.key: {
      return record;
      // TODO: What is does this commented code mean?
      // const { createdBy, assignedTo, taggedTo } = record;
      // const createdByEmployee = employees.find(employee => employee._id.toString() === createdBy.toString());
      // const assignedToEmployee = employees.find(employee => employee._id.toString() === assignedTo.toString());
      // const taggedToCustomer = customers.find(customer => customer._id.toString() === taggedTo.toString());

      // return { ...record, createdBy: createdByEmployee, assignedTo: assignedToEmployee, taggedTo: taggedToCustomer };
    }
    case RECORD_TYPES.BROADCAST.key: {
      return record;
    }
    default:
      return null;
    }
}

/** Sorts an array of objects by a particular field name. */
export const sortArrayFn = (fieldName, reverse = false) => (a, b) => {
  if (typeof a[fieldName] === 'number' && typeof b[fieldName] === 'number') {
    let fieldA = a[fieldName];
    let fieldB = b[fieldName];
    if (reverse) {
      fieldA = b[fieldName];
      fieldB = a[fieldName];
    }
    return (fieldA < fieldB) ? -1 : (fieldA > fieldB) ? 1 : 0;
  }
  let fieldA = a[fieldName] ? a[fieldName].toUpperCase() : '';
  let fieldB = b[fieldName] ? b[fieldName].toUpperCase() : '';
  if (reverse) {
    fieldA = b[fieldName] ? b[fieldName].toUpperCase() : '';
    fieldB = a[fieldName] ? a[fieldName].toUpperCase() : '';
  }
  return (fieldA < fieldB) ? -1 : (fieldA > fieldB) ? 1 : 0;
}

/** Sorts an array of objects in descending order by a particular field name where that field is a date. */
export const sortArrayOfObjByDateFn = (fieldName) => (sortOrder) => (a, b) => {
  const fieldA = a[fieldName] ? a[fieldName] : '';
  const fieldB = b[fieldName] ? b[fieldName] : '';
  if (sortOrder === 'desc') {
    return (fieldA > fieldB) ? -1 : (fieldA < fieldB) ? 1 : 0;
  }
  else if (sortOrder === 'asc') {
    return (fieldA < fieldB) ? -1 : (fieldA > fieldB) ? 1 : 0;
  }
  return 0;
}

/************************************ FILTER FUNCTIONS ************************************/
/** Filter array of objects where value equals specific value. */
export const filterArrayOfObjByFieldValue = (fieldName) => (fieldValue) => (obj) => {
  return obj[fieldName] === fieldValue;
}

/** Filter array of objects where value in array of values. */
export const filterArrayOfObjByFieldValueArray = (fieldName) => (fieldValueArray) => (obj) => {
  return fieldValueArray.includes(obj[fieldName]);
}

/**
 * Gets difference in years between two dates Can be used to get age of person or years since date. 
 * Takes into consideration months.
 * @param {*} fromDate E.g. 1626707495478
 * @param {*} toDate E.g. 1716707495478
 * @returns 2
 */
export const getYearsBetweenDates = (fromDate, toDate) => {
  const from = new Date(fromDate);
  const to = new Date(toDate);
  let yearsBetween = to.getFullYear() - from.getFullYear();
  const monthsBetween = to.getMonth() - from.getMonth();
  if (monthsBetween < 0 || (monthsBetween === 0 && to.getDate() < from.getDate())) {
      yearsBetween--;
  }
  return yearsBetween;
}

/**
 * Takes in a date in format DD/MM/YYYY where separators can be ['/', '-', '.'] and returns the date components separately.
 * @param {*} date 
 * @returns 
 */
export const splitDates = (date) => {
  let splitDate;
  if (date.includes('/')) {
    splitDate = date.split('/');
  }
  else if (date.includes('-')) {
    splitDate = date.split('-');
  }
  else if (date.includes('.')) {
    splitDate = date.split('.');
  }
  return splitDate;
}

export const calculateDerivedFields = (customerOrEmployee, initialCalculation = true, updatedFieldNames = []) => {
  for (const fieldWithDependency of DERIVED_FIELDS) {
    const { fieldName, formulaFunction, dependencies } = fieldWithDependency;
    const fieldRequiresUpdate = initialCalculation || dependencies.some(dependency => updatedFieldNames.includes(dependency));
    if (fieldRequiresUpdate) {
      if (fieldName === 'firstTransactionDate' || fieldName === 'lastTransactionDate') {
      }
      customerOrEmployee[fieldName] = formulaFunction(customerOrEmployee);
    }
  }
  return { ...customerOrEmployee, formulaFieldsCalculated: true };
};