import React from 'react';
import { Route } from 'react-router-dom';

import WorkrexPage from '../workrex/wrappers/WorkrexPage';
import LoginViewManager from '../workrex/components/login/LoginViewManager';
import { RequireNoAuth } from '../context/AuthContext';
import ManagerWaitlistPage from '../workrex/components/managers/ManagerWaitlistPage';

/**
 * All login and authentication routes for WorkRex.
 */
function getLoginRoutes() {
  return (
    <Route path={'/'} element={<WorkrexPage />}>
      <Route path={'login'} element={<RequireNoAuth><LoginViewManager /></RequireNoAuth>} />
      <Route path={'signup'} element={<RequireNoAuth><ManagerWaitlistPage /></RequireNoAuth>} />
    </Route>
  );
}

export default getLoginRoutes;
