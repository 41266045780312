import './PlusButton.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PLUS_ICON from '../../assets/plus-icon.svg';

const propTypes = {};

function PlusButton({ buttonText, onClick }) {
  return (
    <button className={'plus-button'} onClick={onClick}>
      <section className={'button-text'}>{buttonText}</section>
      <img className={'plus-icon'} src={PLUS_ICON} alt={'plus'} />
    </button>
  );
}

PlusButton.displayName = 'PlusButton';
PlusButton.propTypes = propTypes;
export default PlusButton;