import './PreviewToolbar.scss';

import React, { useState } from 'react';
import { convert } from 'html-to-text';

import LinkCopiedModal from '../../../content/modals/LinkCopiedModal';
import ButtonWithTooltip from './ButtonWithTooltip';

import PREV_ARROW_ICON from '../assets/prev-arrow-icon.svg';
import NEXT_ARROW_ICON from '../assets/next-arrow-icon.svg';
import PLUS_ICON from '../assets/plus-icon.svg';
import COPY_ICON from '../assets/copy-icon.svg';
import REMOVE_ICON from '../assets/remove-icon.svg';

function PreviewToolbar({ text, hasMultipleVariants, goToNextVariant, goToPreviousVariant, addVariant, removeVariant, viewOnly = false }) {
  const [modalOpen, setModalOpen] = useState(false);
  const copyMessage = async () => {
    try {
      await navigator.clipboard.writeText(convert(text));
      setModalOpen(true);
    }
    catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  return (
    <section className={'preview-toolbar'}>
      { modalOpen && <LinkCopiedModal close={() => setModalOpen(false)}/> }
      { hasMultipleVariants &&
        <ButtonWithTooltip tooltipText={'View the previous variant'} onClick={goToPreviousVariant}>
          <img src={PREV_ARROW_ICON} alt={'Previous variant'} />
        </ButtonWithTooltip>
      }
      { hasMultipleVariants &&
        <ButtonWithTooltip tooltipText={'View the next variant'} onClick={goToNextVariant}>
          <img src={NEXT_ARROW_ICON} alt={'Next variant'} />
        </ButtonWithTooltip>
      }
      { !viewOnly && 
        <ButtonWithTooltip tooltipText={'Add a new variant'} onClick={addVariant}>
          <img src={PLUS_ICON} alt={'Add variant'} />
        </ButtonWithTooltip>
      }
      {
        !viewOnly && hasMultipleVariants &&
          <ButtonWithTooltip tooltipText={'Remove this variant'} onClick={removeVariant}>
            <img src={REMOVE_ICON} className={'remove-icon'} alt={'Remove variant'} />
          </ButtonWithTooltip>
      }
      <ButtonWithTooltip tooltipText={'Copy this message'} onClick={copyMessage}>
        <img src={COPY_ICON} alt={'Copy message'} />
      </ButtonWithTooltip>
    </section>
  );
};

PreviewToolbar.displayName = 'PreviewToolbar';
export default PreviewToolbar;