import './TaskCard.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../../Constants';
import { REPEAT_MAP } from '../../../../MessagingConstants';
import { postManyTaskCompletion } from '../../../../../../../../apis/Api';

import GenericLabel from '../GenericLabel'
import MessagingConsumer from '../../../../../../../../context/MessagingContext';

const { NONE } = REPEAT_MAP;

const propTypes = {};

// TODO: Migrate from using ASSET_CDN_URL here - faster to just store icons in assets directory
const uncheckedCompleteIcon = `${ASSET_CDN_URL}/icons/rex-unchecked-complete-icon.svg`;
const checkedCompleteIcon = `${ASSET_CDN_URL}/icons/rex-checked-complete-icon.svg`;
const uncheckedSelectIcon = `${ASSET_CDN_URL}/icons/rex-unchecked-select-icon.svg`;
const checkedSelectIcon = `${ASSET_CDN_URL}/icons/rex-checked-select-icon.svg`;

function TaskCard({ task, setRecord, selectedTaskIds, setSelectedTaskIds, selectedRecord, setSelectedRecord }) {
  const { setTasks } = MessagingConsumer();
  const { _id, label, assignedTo, completed, taggedTo, frequency } = task;
  const isSelected = selectedTaskIds.includes(task._id);
  const selectedIcon = isSelected ? checkedSelectIcon : uncheckedSelectIcon;
  const completedIcon = completed ? checkedCompleteIcon : uncheckedCompleteIcon;

  const completeTask = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // TODO: Why are we passing back the entire task rather than just a task id?
    postManyTaskCompletion([ { ...task, completed: !completed } ])
      .then(response => {
        if (selectedRecord && _id === selectedRecord._id && response.tasks.length > 0) {
          setSelectedRecord(response.tasks[0])
        }
        setTasks(prevState => {
          const filteredTasks = prevState.filter(prevTasks => prevTasks._id !== _id);
          return [ ...filteredTasks, ...response.tasks ];
        });
      })
      .catch(err => {
        // TODO: Handle error and revert the tasks lists
      })
  }

  const setSelected = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const taskIdSet = new Set(selectedTaskIds);
    if (taskIdSet.has(task._id)) {
      taskIdSet.delete(task._id)
    }
    else {
      taskIdSet.add(task._id);
    }
    setSelectedTaskIds([ ...taskIdSet ]);
  }
  const assignedToText = assignedTo ? `Assigned to ${assignedTo.firstName} ${assignedTo.lastName}` : 'Unassigned';

  return (
    <button className={'task-list-view-card'} onClick={() => setRecord(task)} type={'button'}>
      <button className={'task-icon-container task-completion'} onClick={completeTask} type={'button'}>
        <img alt={'task-type'} className={'task-type-icon'} src={completedIcon} />
      </button>
      <section className={'task-details'}>
        <section className={'task-heading'}>
          <p className={'task-label'}>{label}</p>
        </section>
        <section className={'task-assigned-details'}>
          <GenericLabel additionalClassNames={['task-assigned']} text={assignedToText} />
          {taggedTo && <GenericLabel additionalClassNames={['task-tagged']} text={`${taggedTo.firstName} ${taggedTo.lastName}`} />}
          {frequency !== NONE.key && <GenericLabel additionalClassNames={[]} text={REPEAT_MAP[frequency].text} />}
        </section>
      </section>
      <button className={`task-icon-container ${isSelected ? '' : 'not-selected'}`} onClick={setSelected} type={'button'}>
        <img alt={'task-type'} className={'task-type-icon'} src={selectedIcon} />
      </button>
    </button>
  )
}

TaskCard.displayName = 'TaskCard';
TaskCard.propTypes = propTypes;
export default TaskCard;