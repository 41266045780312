import './IntegrationsPage.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { INTEGRATIONS, INTEGRATION_TAGS } from './IntegrationConstants';

import IntegrationsSidebar from './common/IntegrationsSidebar';
import IntegrationsList from './common/IntegrationsList';

import useAuth from '../../../../../context/AuthContext';

import STAR_ICON from './assets/star-icon.svg';

const { OPTOMATE_TOUCH, DEPUTY, TANDA, SUNIX } = INTEGRATIONS;
const INTEGRATIONS_LIST = [OPTOMATE_TOUCH, SUNIX, DEPUTY, TANDA];

const { PRACTICE_MANAGEMENT, HR_AND_ROSTERING, CRM } = INTEGRATION_TAGS;

const propTypes = {};

function IntegrationsPage() {
  const { user } = useAuth();
  const [searchText, setSearchText] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [activeIntegrationsOnly, setActiveIntegrationsOnly] = useState(false);
  const { integrations } = user ? user : { integrations: [] };
  const allIntegrations = INTEGRATIONS_LIST
    .filter(({ displayText }) => displayText.toLowerCase().includes(searchText))
    .filter(({ key }) => !activeIntegrationsOnly || integrations.includes(key))
    .filter(({ tags }) => selectedTags.length === 0 || tags.filter(t => selectedTags.includes(t)).length > 0);
  const integrationText = integrations.length === 1 ? 'integration' : 'integrations';
  const selectTag = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(t => t !== tag));
    }
    else {
      setSelectedTags([...selectedTags, tag]);
    }
  }
  const filters = [
    {
      buttonText: 'Active integrations',
      icon: STAR_ICON,
      selected: activeIntegrationsOnly,
      onClick: () => setActiveIntegrationsOnly(!activeIntegrationsOnly),
    },
    {
      buttonText: 'Practice management',
      icon: STAR_ICON,
      selected: selectedTags.includes(PRACTICE_MANAGEMENT),
      onClick: () => selectTag(PRACTICE_MANAGEMENT),
    },
    {
      buttonText: 'HR & rostering',
      icon: STAR_ICON,
      selected: selectedTags.includes(HR_AND_ROSTERING),
      onClick: () => selectTag(HR_AND_ROSTERING),
    },
    {
      buttonText: 'CRM',
      icon: STAR_ICON,
      selected: selectedTags.includes(CRM),
      onClick: () => selectTag(CRM),
    }
  ];
  return (
    <section className={'integrations-page'}>
      <section className={'integrations-header'}>
        <h3 className={'title'}>{`You have ${integrations.length} active ${integrationText}`}</h3>
      </section>
      <section className={'integrations'}>
        <IntegrationsSidebar searchText={searchText} setSearchText={setSearchText} filters={filters} />
        <IntegrationsList integrations={integrations} allIntegrations={allIntegrations}/>
      </section>
    </section>
  )
}

IntegrationsPage.displayName = 'IntegrationsPage';
IntegrationsPage.propTypes = propTypes;
export default IntegrationsPage;