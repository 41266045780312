import './TextInput.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function TextInput({ text, setText, placeholder, additionalClassNames = [], disabled, processText }) {
  const onChange = ({ target }) => {
    const value = processText ? processText(target.value) : target.value;
    setText(value);
  }
  return (
    <input type={'text'} className={`text-input ${additionalClassNames.join(' ')} ${disabled ? 'disabled' : ''}`} onChange={onChange} value={text} placeholder={placeholder} disabled={disabled} />
  );
}

TextInput.displayName = 'TextInput';
TextInput.propTypes = propTypes;
export default TextInput;