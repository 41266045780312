import './CampaignListContainer.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CampaignList from './CampaignList';
import CampaignSidebar from './CampaignSidebar';
import { CAMPAIGN_PAGE_TYPES } from '../CampaignConstants';

const { LIBRARY } = CAMPAIGN_PAGE_TYPES;

const propTypes = {};

function CampaignListContainer({ campaigns = [], pageType, title }) {
  const [searchText, setSearchText] = useState('');
  const isLibrary = pageType === LIBRARY;
  const [selectedTag, setTag] = useState(null);
  const tags = [...new Set(campaigns.map(({ tag }) => tag))].sort();
  const selectTag = (tag) => {
    setTag(tag === selectedTag ? null : tag);
  }
  return (
    <section className={'campaign-list-container'}>
      <CampaignSidebar
        searchText={searchText}
        setSearchText={setSearchText}
        isLibrary={isLibrary}
        selectedTag={selectedTag}
        selectTag={selectTag}
        tags={tags}
      />
      <section className={'campaigns'}>
        <h5 className={'campaigns-title'}>{title}</h5>
        <CampaignList
          campaigns={campaigns}
          searchText={searchText}
          selectedTag={isLibrary ? selectedTag : null}
        />
      </section>
    </section>
  );
}

CampaignListContainer.displayName = 'CampaignListContainer';
CampaignListContainer.propTypes = propTypes;
export default CampaignListContainer;