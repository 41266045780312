import './EllipsisDropdown.scss';

import React, { useState, useRef } from 'react';

import { useOutsideClick } from '../../../../hooks/ClickHooks';
import PropTypes from 'prop-types';

const propTypes = {};

function EllipsisDropdown({ disabled, options, children, buttonDisplay = '•••', additionalClassNames = [] }) {
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const optionList = options.map(({ displayText, onClick, selected = false }) => {
    const select = () => {
      setOpen(false);
      onClick();
    }
    return (
      <li className={`dropdown-option ${selected ? 'selected' : ''}`} key={displayText}>
        <button className={'dropdown-selection'} onClick={select}>
          {displayText}
        </button>
      </li>
    )
  });
  useOutsideClick(dropdownRef, () => setOpen(false));
  return (
    <section className={`ellipsis-dropdown-container ${additionalClassNames.join(' ')} ${open ? 'open' : ''}`} ref={dropdownRef}>
      {children}
      <button
        className={`ellipsis-button ${disabled ? 'disabled' : ''}`} 
        disabled={disabled}
        onClick={() => setOpen(!open)}
      >
        {buttonDisplay}
      </button>
      { open && (
        <section className={'ellipsis-dropdown'}>
          <ul className={'dropdown-list'}>
            {optionList}
          </ul>
        </section>
      )}
    </section>
  );
}

EllipsisDropdown.displayName = 'EllipsisDropdown';
EllipsisDropdown.propTypes = propTypes;
export default EllipsisDropdown;