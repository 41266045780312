import './SearchContact.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function SearchContact({ assignedTo, options, setAssignedTo }) {

  return (
    <section className={'search-contacts'}>
      <p className={'search-label'}>{'Suggested recipients'}</p>
      <section className={'recipient-list'}>
        {options.map(option => <button className={`searched-contact ${option._id === assignedTo._id ? 'selected' : ''}`} onClick={() => setAssignedTo(option)} type={'button'}>{`${option.firstName} ${option.lastName}`}</button>)}
      </section>
    </section>
  )
}

SearchContact.displayName = 'SearchContact';
SearchContact.propTypes = propTypes;
export default SearchContact;