import './ChatInput.scss';

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../../Constants';
import { CHANNELS, DIRECT_MESSAGE_SETTINGS, DEFAULT_BROADCAST } from '../../../../MessagingConstants';
import { DELIVERABILITY } from '../../../../../recipients/RecipientsConstants';
import useAuth from '../../../../../../../../context/AuthContext';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';
import { postBroadcastCreate } from '../../../../../../../../apis/Api';
import ChannelDropdown from './dropdowns/ChannelDropdown';
import SettingsDropdown from './dropdowns/SettingsDropdown';

const propTypes = {};

const { OPTED_OUT } = DELIVERABILITY;
const { SMS } = CHANNELS;

const SendMessageIcon = `${ASSET_CDN_URL}/icons/rex-send-message-icon.svg`;
const InactiveSendMessageIcon = `${ASSET_CDN_URL}/icons/rex-inactive-send-message-icon.svg`;
const OPTED_OUT_TEXT = 'This user has opted out of communications. To resume sending messages, change the status back to subscribed in recipients.';

const Attachment = ({ file, deleteAttachment }) => {
  return (
    <span className={'attachment'}>
      {file.name}
      <span className={'delete-container'}>
        <button className={'delete'} onClick={deleteAttachment}>{'×'}</button>
      </span>
    </span>
  );
}

function ChatInput ({ isHidden, selectedRecord, setSelectedRecord }) {
  const { user } = useAuth();
  const IS_BIRD_USER = !!user.business.workspaceId;
  const { selectedGroup, setCustomers, setEmployees } = MessagingConsumer();
  const [message, setMessage] = useState('');
  const [channels, setChannels] = useState([SMS.key]);
  const [settings, setSettings] = useState(Object.keys(DIRECT_MESSAGE_SETTINGS));
  const [attachments, setAttachments] = useState([]);
  const optedOut = selectedRecord.deliverability === OPTED_OUT.key;
  const hasMobile = selectedRecord.mobileNumber;
  const disableChatInput = optedOut || !hasMobile;
  const sendMessageIcon = disableChatInput ? InactiveSendMessageIcon : SendMessageIcon;

  const placeholderText = optedOut
    ? OPTED_OUT_TEXT
    : (
      !hasMobile
        ? 'You cannot send a message to a user without a mobile number.'
        : 'Write something...'
    )
  
  const messageRef = useRef(null);
  const messageListener = (e) => {
    return hasMobile && e.key === 'Enter' && !e.shiftKey && messageRef.current.click()
  };
  const onKeyDownChat = (e) => {
    if (hasMobile && e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      messageRef.current.click();
    }
  }
  const onChangeChat = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };
  const onChangeAttachment = (e) => {
    const file = e.target.files[0];
    setAttachments([...attachments, file]);
  };

  const selectChannel = (channel) => {
    setChannels([channel]);
  }
  const selectSettings = (setting) => {
    const settingsSet = new Set(settings);
    if (settingsSet.has(setting)) {
      settingsSet.delete(setting);
    }
    else {
      settingsSet.add(setting);
    }
    setSettings([ ...settingsSet ]);
  }

  const sendMessage = () => {
    if (!disableChatInput && message && message.length > 0) {
      const chatHistory = {
        _id: `tempId-${Date.now}`,
        businessId: user.businessId,
        creationDate: Date.now(),
        message: message,
        channels: IS_BIRD_USER ? channels : [],
        read: true,
        attachments: [],
        receiverId: selectedRecord._id,
        senderId: user.businessId,
        settings,
        recipientId: selectedRecord._id,
        createdById: user.businessId,
        sendMethod: channels[0],
        source: 'MESSAGE',
      };
      const DIRECT_MESSAGE = {
        ...DEFAULT_BROADCAST,
        broadcastName: 'DIRECT MESSAGE',
        message,
        channels: IS_BIRD_USER ? channels : [],
        settings,
        createMethod: 'MESSAGE',
        recipientIds: [selectedRecord._id],
        nextBroadcastDate: Date.now()
      };
      postBroadcastCreate(DIRECT_MESSAGE, user._id, selectedGroup._id)
        .then(response => {
          const updatedSelectedRecord = { ...selectedRecord, chatHistory: [ ...selectedRecord.chatHistory, chatHistory ]};
          setSelectedRecord(updatedSelectedRecord);
          if (selectedRecord.contactType === 'CUSTOMER') {
            setCustomers(prevCustomers => {
              const customerIndex = prevCustomers.findIndex(customer => customer._id === selectedRecord._id);
              prevCustomers[customerIndex] = updatedSelectedRecord;
              return prevCustomers;
            })
          } else {
            setEmployees(prevEmployees => {
              const employeeIndex = prevEmployees.findIndex(employee => employee._id === selectedRecord._id);
              prevEmployees[employeeIndex] = updatedSelectedRecord;
              return prevEmployees;
            })
          }
        })
        .finally(() => {
          setMessage('');
        })
    }
  }

  const removeAttachment = (index) => setAttachments(attachments.filter((_, i) => i !== index));
  const attachmentsList = attachments.map((file, index) => <Attachment file={file} deleteAttachment={() => removeAttachment(index)} key={`key-${file.name}`} />);
  useEffect(() => {
    window.addEventListener('keypress', messageListener);
    return () => window.removeEventListener('keypress', messageListener);
  }, []);
  return (
    <section className={`chat-input-container ${isHidden ? 'hidden' : ''}`}>
      <section className={'chat-bar'}>
        {/* <button className={'attachment-button'}>
          <label for={'upload-file'} className={'add-attachment'}>{'+'}</label>
          <input type={'file'} id={'upload-file'} name={'upload-file'} accept={'*'} onChange={onChangeAttachment} />
        </button> */}
        <section className={'inputs'}>
          <section className={'attachments-container'}>
            { attachments.length > 0 && <section className={'attachments'} style={{ bottom: `${25 + 28 * (attachments.length - 1)}px` }}>{attachmentsList}</section>}
          </section>
          <textarea
            class={message.length ? 'chat-input chat-input-content' : 'chat-input'}
            placeholder={placeholderText}
            disabled={disableChatInput}
            value={message}
            onChange={onChangeChat}
            onKeyDown={onKeyDownChat}
          />
          {
            IS_BIRD_USER && 
            (
              <section className={'input-menu-controls'}>
                <ChannelDropdown dropdownSelected={channels} dropdownOptions={CHANNELS} text={channels} setSelectedData={selectChannel} />
                <SettingsDropdown dropdownSelected={settings} dropdownOptions={DIRECT_MESSAGE_SETTINGS} text={settings} setSelectedData={selectSettings} />
              </section>
            )
          }
        </section>
        <button className={`send-button ${disableChatInput ? 'disable-chat' : ''}`} disabled={disableChatInput} onClick={sendMessage} ref={messageRef}>
          <img className={'send-message-icon'} src={sendMessageIcon} alt={'Send Message Icon'} />
        </button>
      </section>
    </section>
  );
};

ChatInput.displayName = 'ChatInput';
ChatInput.propTypes = propTypes;
export default ChatInput;
