import './BroadcastListView.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import MessagingConsumer from '../../../../../../../../context/MessagingContext';

import DotBricksLoading from '../../../../../../../common/DotBricksLoading';
import BroadcastCard from './BroadcastCard';
import SearchBar from '../search/SearchBar';
import BroadcastListWrapper from './BroadcastListWrapper';
import SearchButton from '../search/SearchButton';
import useAuth from '../../../../../../../../context/AuthContext';

const SEARCH_ADDITIONAL_CLASSNAMES = ['new-broadcast', 'max-width'];

const propTypes = {};

function BroadcastListView({ setRecord }) {
  const { user } = useAuth();
  const { broadcasts } = user;
  const { contacts, templates } = MessagingConsumer();
  const [searching, setSearching] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const increasePageCount = () => setPageCount(pageCount + 1);

  const closeSearchBar = () => {
    setOpenSearch(false);
    setSearchText('');
    setSearching(false);
  }

  const search = (e) => {
    console.log(e.target.value);
    if (!searching) {
      setSearching(true);
    }
    setSearchText(e.target.value);
  }

  const filteredBroadcasts = broadcasts.filter(({ name, sentDate }) => name.includes(searchText.toLowerCase()) && sentDate === null);
  console.log(filteredBroadcasts);
  if (broadcasts === null || contacts === null || filteredBroadcasts === null) {
    return <DotBricksLoading isFixed={false} />
  }

  const notShowingAll = 100 * pageCount < filteredBroadcasts.length;
  const broadcastView = filteredBroadcasts.slice(0, 100 * pageCount).map(broadcast => <BroadcastCard key={broadcast._id} broadcast={broadcast} setRecord={setRecord} />);

  const searchComponent = openSearch
    ? <SearchBar additionalClassNames={SEARCH_ADDITIONAL_CLASSNAMES} searchText={searchText} search={search} placeholder={'Search'} setOpenSearch={closeSearchBar} />
    : <SearchButton setOpenSearch={setOpenSearch} searchText={searchText} />
    
  return (
    <BroadcastListWrapper broadcastView={broadcastView} notShowingAll={notShowingAll} increasePageCount={increasePageCount}>
      <section className={'broadcast-header'}>
        {searchComponent}
        <span className={'broadcast-count'}>{`${broadcasts.length} broadcasts`}</span>
      </section>
    </BroadcastListWrapper>
  )
}

BroadcastListView.displayName = 'BroadcastListView';
BroadcastListView.propTypes = propTypes;
export default BroadcastListView;