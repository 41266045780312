import './BirthdaySelection.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AssistantSelection from '../common/AssistantSelection';
import AssistantSelectionSection from '../common/AssistantSelectionSection';
import AssistantTextInput from '../common/AssistantTextInput';
import ToggleButton from '../common/ToggleButton';
import DeleteButton from '../../../../common/DeleteButton';
import PlusButton from '../common/PlusButton';
import { postActivateAssistant } from '../../../../../../../apis/AssistantApi';
import useAuth from '../../../../../../../context/AuthContext';


const propTypes = {};

function BirthdaySelection({ context, setAssistantKey, assistantKey }) {
  const [assistantContext, setAssistantContext] = useState(context);
  const { setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const cancel = () => setAssistantKey(null);
  const { message, offers, isOfferOn, autopilot } = assistantContext;
  const setMessage = (message) => setAssistantContext({ ...assistantContext, message });
  const setOfferOn = (isOfferOn) => setAssistantContext({ ...assistantContext, isOfferOn });
  const setAutopilot = (autopilot) => setAssistantContext({ ...assistantContext, autopilot });
  const promotionDescription = isOfferOn
    ? 'Include a promotional offer to further incentivise customers. Add multiple and Rex can test them to find the best offer.'
    : 'Do not include any additional offers to incentivise customers.';
  const autopilotDescription = autopilot 
    ? 'Rex will automatically send out messages on your behalf.'
    : 'Rex will create draft campaigns for you to approve and select recipients before sending.';
  const offerList = offers.map((offer, index) => {
    const setOffer = (newOffer) => {
      const newOffers = [...offers];
      newOffers[index] = newOffer;
      setAssistantContext({ ...assistantContext, offers: newOffers });
    }
    const deleteOffer = () => setAssistantContext({
      ...assistantContext, offers: offers.filter((_, i) => i !== index) });
    return (
      <AssistantTextInput
        text={offer}
        setText={setOffer}
        placeholder={'Add your promotional offer here'}
        additionalClassNames={['offer-input-container']}
        additionalButton={<DeleteButton onClick={deleteOffer} />}
        key={`offer-${index}`}
      />
    );
  });
  const addOffer = () => {
    const newOffers = [...offers, ''];
    setAssistantContext({ ...assistantContext, offers: newOffers });
  }
  const activate = (callback) => {
    if (loading) {
      return;
    }
    setLoading(true);
    postActivateAssistant(assistantKey, { ...assistantContext, offers: isOfferOn ? offers : [] })
      .then((res) => {
        setUser(res.user);
        setLoading(false);
        callback();
      });
  }
  return (
    <AssistantSelection
      title={'Birthday Assistant'}
      additionalClassNames={['birthday-assistant']}
      cancel={cancel}
      activateAssistant={activate}
      assistantKey={assistantKey}
    >
      <AssistantSelectionSection title={'Customise your birthday message'}>
        <AssistantTextInput text={message} setText={setMessage} placeholder={'Insert your birthday message here'} />
      </AssistantSelectionSection>
      <AssistantSelectionSection
        title={'Add promotional offer'}
        description={promotionDescription}
        toggleButton={<ToggleButton active={isOfferOn} setActive={setOfferOn} />}
      >
        { isOfferOn && (
          <>
            <ul className={'offer-list'}>{offerList}</ul>
            <PlusButton onClick={addOffer} buttonText={'Add offer'} />
          </>
        )}
      </AssistantSelectionSection>
      <AssistantSelectionSection
        title={'Auto pilot'}
        description={autopilotDescription} 
        toggleButton={<ToggleButton active={autopilot} setActive={setAutopilot} />}
      >
      </AssistantSelectionSection>
    </AssistantSelection>
  );
}

BirthdaySelection.displayName = 'BirthdaySelection';
BirthdaySelection.propTypes = propTypes;
export default BirthdaySelection;