import './DeleteSavedTemplateModal.scss';

import React from 'react';
import PropTypes from 'prop-types';

import WorkRexModal from '../../../common/WorkRexModal';

const propTypes = {};

function DeleteSavedTemplateModal({ close, confirm }) {
  return (
    <WorkRexModal close={close} additionalClassNames={['delete-saved-template']}>
      <p className={'description'}>
        {`Confirm deletion of this saved template. You will not be able to reverse this action.`}
      </p>
      <button className={'confirm-button'} onClick={confirm}>{'Delete'}</button>
    </WorkRexModal>
  );
}

DeleteSavedTemplateModal.displayName = 'DeleteSavedTemplateModal';
DeleteSavedTemplateModal.propTypes = propTypes;
export default DeleteSavedTemplateModal;