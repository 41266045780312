export const DASHBOARD_STATES = {
  TEMPLATES: 'TEMPLATES',
  RECIPIENTS: 'RECIPIENTS',
  MANAGERS: 'MANAGERS',
  STORES: 'STORES',
  ANALYSIS: 'ANALYSIS',
};

export const MESSAGE_STATUS_MAP = {
  sent: 'Sent',
  delivered: 'Delivered',
  read: 'Read',
  replied: 'Replied',
}