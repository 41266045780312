import './TemplateNavigationTabs.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { createNewEmptySection } from '../TemplatesHelpers';
import { MAXIMUM_NUMBER_OF_SECTIONS } from '../TemplatesConstants';

import DELETE_ICON from '../assets/delete-icon.svg';

const propTypes = {};

function TemplateNavigationTabs({ editable, setIndex, selectedIndex, addSection, sections, deleteSection }) {
  const welcomeTabText = editable ? 'Create template' : 'Overview';
  const sectionTabs = sections.map(({ title }, index) => {
    const goToIndex = () => setIndex(index);
    const selected = selectedIndex === index;
    const deleteSectionTab = (e) => {
      e.preventDefault();
      e.stopPropagation();
      deleteSection(index);
      if (sections.length === 1) {
        setIndex(-1);
      }
    }
    return (
      <button
        className={`template-nav-tab section ${selected ? 'selected' : ''}`}
        onClick={goToIndex}
        key={`title-${index}`}
        style={{ left: `-${2 * index + 2}px`}}
      >
        {title}
        { editable && <img className={'delete-icon'} src={DELETE_ICON} onClick={deleteSectionTab} /> }
      </button>
    )
  });
  const goToWelcomePage = () => setIndex(-1);
  const isWelcomeSelected = selectedIndex === -1;
  const numSections = sections.length;
  const cannotAddMoreSections = numSections === MAXIMUM_NUMBER_OF_SECTIONS;
  return (
    <section className={'template-navigation-tabs'}>
      <ul className={'template-navigation-tab-list'}>
        <button className={`template-nav-tab ${isWelcomeSelected ? 'selected' : ''}`} onClick={goToWelcomePage}>
          {welcomeTabText}
        </button>
        {sectionTabs}
        {
          (!cannotAddMoreSections && editable) &&
            <button
              className={'template-nav-tab add-section'}
              onClick={addSection}
              style={{ left: `-${2 * numSections + 2}px`}}
            >
              <span className={'text'}>{'Add section'}</span>
              <span className={'icon'}>{'+'}</span>
            </button>

        }
      </ul>
    </section>
  );
}

TemplateNavigationTabs.displayName = 'TemplateNavigationTabs';
TemplateNavigationTabs.propTypes = propTypes;
export default TemplateNavigationTabs;