import './CampaignTypeNavigationTabs.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import NavigationTab from '../../../common/NavigationTab';

import { CAMPAIGN_PAGE_TYPES } from '../CampaignConstants';
import { ASSET_CDN_URL } from '../../../../../../Constants';

const PUBLISHED_CAMPAIGNS_ICON = `${ASSET_CDN_URL}/icons/workrex_active_engagements.svg`;
const DRAFT_CAMPAIGNS_ICON = `${ASSET_CDN_URL}/icons/workrex_draft_engagements.svg`;
const LIBRARY_CAMPAIGNS_ICON = `${ASSET_CDN_URL}/icons/workrex_engagement_library.svg`;

const { SAVED, EMPLOYEE, LIBRARY, PUBLISHED, DRAFT } = CAMPAIGN_PAGE_TYPES;

const propTypes = {};

function CampaignTypeNavigationTabs({ selectedTab = null, campaignCounts }) {
  const navigate = useNavigate();
  const onPublishedClick = () => navigate('/campaigns/published');
  const onDraftClick = () => navigate('/campaigns/draft');
  const onLibraryClick = () => navigate('/campaigns/library');
  return (
    <ul className={'campaign-type-navigation-tabs'}>
      <NavigationTab
        unselectedIcon={PUBLISHED_CAMPAIGNS_ICON}
        displayText={'Your published campaigns'}
        onClick={onPublishedClick}
        selected={selectedTab === PUBLISHED}
        count={campaignCounts[PUBLISHED]}
      />
      <NavigationTab
        unselectedIcon={DRAFT_CAMPAIGNS_ICON}
        displayText={'Your draft campaigns'}
        onClick={onDraftClick}
        selected={selectedTab === DRAFT}
        count={campaignCounts[DRAFT]}
      />
      <NavigationTab
        unselectedIcon={LIBRARY_CAMPAIGNS_ICON}
        displayText={'WorkRex campaign library'}
        onClick={onLibraryClick}
        selected={selectedTab === LIBRARY}
        count={campaignCounts[LIBRARY]}
      />
    </ul>
  )
}

CampaignTypeNavigationTabs.displayName = 'CampaignTypeNavigationTabs';
CampaignTypeNavigationTabs.propTypes = propTypes;
export default CampaignTypeNavigationTabs;