import { postData, postFormData } from './Api';

const BROADCAST_ROUTES = {
  CREATE_BROADCAST_SCHEDULER_URL: '/api/broadcast/scheduler/create',
  PAUSE_BROADCAST_URL: '/api/broadcast/pause',
  DELETE_BROADCAST_URL: '/api/broadcast/delete',
};

const { CREATE_BROADCAST_SCHEDULER_URL, PAUSE_BROADCAST_URL, DELETE_BROADCAST_URL } = BROADCAST_ROUTES;

export const postDeleteBroadcast = (broadcastId) => {
  return postData(DELETE_BROADCAST_URL, { broadcastId });
}

export const postPauseBroadcast = (broadcastId, paused) => {
  return postData(PAUSE_BROADCAST_URL, { broadcastId, paused });
}

export const postCreateBroadcastScheduler = (broadcastScheduler) => {
  return postData(CREATE_BROADCAST_SCHEDULER_URL, { broadcastScheduler });
}