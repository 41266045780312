import './App.scss';

import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { useAdjustMobileViewHeight } from './hooks/MediaHooks';
import { MessagingProvider } from './context/MessagingContext';
import { useInitialUserFetch } from './hooks/DataHooks';

import AppRoutes from './routes/AppRoutes';
import DotBricksLoading from './workrex/common/DotBricksLoading';

const App = () => {
  // TODO: See if we can use something like React.suspense to achieve similar behavior
  const [firstLoad, setFirstLoad] = useState(false);
  useInitialUserFetch(firstLoad, setFirstLoad);
  useAdjustMobileViewHeight();
  if (!firstLoad) {
    return <DotBricksLoading />;
  }
  return (
    <Router>
      <MessagingProvider>
        <AppRoutes />
      </MessagingProvider>
    </Router>
  );
};

export default App;
