import './DraftTemplateConfirmationModal.scss';

import React from 'react';
import PropTypes from 'prop-types';
import WorkRexModal from '../../../common/WorkRexModal';

const propTypes = {};

function DraftTemplateConfirmationModal({ close, confirm, loading }) {
  return (
    <WorkRexModal close={close} additionalClassNames={['draft-template-confirmation']}>
      <p className={'description'}>
        {`Confirm you’d like to draft this template and save it to 'Your draft templates'`}
      </p>
      <button className={'confirm-button'} onClick={confirm} disabled={loading}>{'Confirm'}</button>
    </WorkRexModal>
  );
}

DraftTemplateConfirmationModal.displayName = 'DraftTemplateConfirmationModal';
DraftTemplateConfirmationModal.propTypes = propTypes;
export default DraftTemplateConfirmationModal;