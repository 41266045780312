import './TemplateAttachmentButton.scss';

import React from 'react';
import PropTypes from 'prop-types';

import ATTACHMENT_ICON from '../assets/attachment-icon.svg';

import Attachment from './Attachment';

const propTypes = {};

function TemplateAttachmentButton({ onClick, attachment, deleteAttachment, disabled }) {
  return (
    <section className={'template-attachment-button-container'}>
      <button className={`attachment-button ${disabled ? 'disabled' : ''}`} onClick={onClick} disabled={disabled}>
        <img className={'button-icon'} src={ATTACHMENT_ICON} />
        <span className={'button-text'}>{'Include attachment'}</span>
      </button>
      { attachment && (
        <section className={'attachment-container'}>
          <Attachment attachment={attachment} deleteAttachment={deleteAttachment} />
        </section>
      )}
    </section>
  );
}

TemplateAttachmentButton.displayName = 'TemplateAttachmentButton';
TemplateAttachmentButton.propTypes = propTypes;
export default TemplateAttachmentButton;