import './WebManagerAuthNavButtons.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';
import useAuth from '../../../../../context/AuthContext';

import PropTypes from 'prop-types';
import AccountMenu from './AccountMenu';

const propTypes = {};

function WebManagerAuthNavButtons({ onClick = () => {} }) {
  const { logout, hasAdminAccess } = useAuth();
  return (
    <>
      <ul className={'navbar-list'}>
        { hasAdminAccess &&
          <li className={'nav-option-container'}>
            <NavLink className={'nav-option'} exact={'true'} onClick={onClick} to={'/dashboard'}>
              {'Dashboard'}
            </NavLink>
          </li>
        }
        { hasAdminAccess &&
          <li className={'nav-option-container'}>
            <NavLink className={'nav-option'} exact={'true'} onClick={onClick} to={'/recipients'}>
              {'Recipients'}
            </NavLink>
          </li>
        }
        <li className={'nav-option-container'}>
          <NavLink className={'nav-option'} exact={'true'} onClick={onClick} to={'/messaging'}>
            {'Communications'}
          </NavLink>
        </li>
      </ul>
      <section className={'web-nav-buttons'}>
        <AccountMenu />
        <button className={'web-nav-button dark'} onClick={logout}>
          {'Log out'}
        </button>
      </section>
    </>
  );
}

WebManagerAuthNavButtons.displayName = 'WebManagerAuthNavButtons';
WebManagerAuthNavButtons.propTypes = propTypes;
export default WebManagerAuthNavButtons;