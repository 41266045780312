import './Interaction.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../../Constants';
import { INTERACTION_TYPE_MAPPING } from '../../../../MessagingConstants';
import { useOutsideClick } from '../../../../../../../../hooks/ClickHooks';
import useAuth from '../../../../../../../../context/AuthContext';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';

import EditModalDropdown from '../../../list/views/tasks/EditModalDropdown';
import GenericLabel from '../../../list/views/GenericLabel';
import { postManyInteractionArchive } from '../../../../../../../../apis/Api';
import Modal from '../../../../../../../common/Modal';

const dayNames = ['SUN', 'MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT'];
const editMenuWidthPx = 160;

const editIcon = `${ASSET_CDN_URL}/icons/workrex_edit_triple_dot_selection.svg`;

const propTypes = {};

function Interaction({ interaction, createEditInteraction, setSelectedRecord }) {
  const { user } = useAuth();
  const { setInteractions } = MessagingConsumer();
  const { createdBy, type, createdDate, name, description, startDateTime } = interaction;
  const [showSeeMore, setShowSeeMore] = useState(description.length > 80);
  const seeEntireDescription = () => setShowSeeMore(!showSeeMore);
  const startDate = new Date(startDateTime);
  const startTimeString = `${dayNames[startDate.getDay()]} ${startDate.getDate()}`
  const createdDateType = new Date(createdDate);
  const createdDateString = `${createdDateType.getDate()}/${createdDateType.getMonth() + 1}/${createdDateType.getFullYear()}`
  const shouldTrim = showSeeMore && description.length > 80;
  const concatDescription = shouldTrim ? `${description.substring(0, 65).trim()}... ` : description;

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const closeArchiveModal = () => setShowArchiveModal(false);
  const openArchiveModal = () => setShowArchiveModal(true);
  const [additionalClassNames, setAdditionalClassNames] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsEditing(false));
  const toggleEdit = (e) => {
    e.preventDefault();
    setIsEditing(!isEditing);
    if (dropdownRef !== null && dropdownRef.current !== null) {
      const leftAlign = dropdownRef.current.offsetParent.clientWidth - dropdownRef.current.offsetLeft < editMenuWidthPx;
      setAdditionalClassNames(leftAlign ? ['left-align'] : ['']);
    }
  }

  const onMouseLeave = () => {
    if (isEditing) {
      setIsEditing(false);
    }
  }

  const archiveInteraction = (e) => {
    postManyInteractionArchive([interaction._id], user._id)
      .then(_ => {
        setInteractions(prevState => prevState.filter(prevInteractions => prevInteractions._id !== interaction._id));
        setSelectedRecord(prevState => {
          const filteredInteractions = prevState.interactions.filter(prevInteraction => prevInteraction._id !== interaction._id);
          return { ...prevState, interactions: filteredInteractions };
        });
      })
      .finally(closeArchiveModal);
  }

  return (
    <button className={'interaction-card'} type={'button'} onMouseLeave={onMouseLeave} >
      <section className={'interaction-icon-container'}>
        <img alt={'interaction-type'} className={'interaction-type-icon'} src={INTERACTION_TYPE_MAPPING[type].icon} />
      </section>
      <section className={'interaction-details'}>
        <section className={'interaction-heading'}>
          <section className={'interaction-header-container'}>
            <p className={'interaction-label'}>{name}</p>
            <button className={'edit-selected-button'} ref={dropdownRef} onClick={toggleEdit} type={'button'}>
              <img alt={'edit-icon'} className={'edit-icon'} src={editIcon} />
              {isEditing && 
                <EditModalDropdown additionalClassNames={additionalClassNames}>
                  <button className={'edit-button'} onClick={createEditInteraction(interaction)} type={'button'}>{'Edit'}</button>
                  <button className={'edit-button'} onClick={openArchiveModal} type={'button'}>{'Archive interaction'}</button>
                </EditModalDropdown>
              }
            </button>
          </section>
          <span className={'interaction-start'}>{startTimeString}</span>
        </section>
        <section className={'interaction-creation-details'}>
          <GenericLabel additionalClassNames={[]} text={`Created by ${createdBy.firstName} ${createdBy.lastName}`} />
          <span className={'interaction-created-date'}>{`created on ${createdDateString}`}</span>
        </section>
        <section className={'interaction-label-container'}>
          <button className={'interaction-label'} onClick={seeEntireDescription} type={'button'}>
            <span className={'interaction-label-text'}>{concatDescription}</span>
            {shouldTrim && <span className={'see-more-button'}>{'SEE MORE'}</span>}
          </button>
        </section>
      </section>
      {showArchiveModal &&
        <Modal closeModal={closeArchiveModal} additionalClassNames={['archive']}>
          <section className={'archive-interaction-modal'}>
            <p className={'archive-text'}>{'Confirm archiving of this interaction which will remove this from your interaction list.'}</p>
            <button className={'confirm-archive'} onClick={archiveInteraction} type={'button'}>{'Archive'}</button>
          </section>
        </Modal>
      }
    </button>
  )
}

Interaction.displayName = 'Interaction';
Interaction.propTypes = propTypes;
export default Interaction;