import './ActivateAssistantModal.scss';

import React from 'react';
import WorkRexModal from '../../../common/WorkRexModal';

const propTypes = {};

function ActivateAssistantModal({ close, active, displayText, confirm }) {
  const title = active ? `${displayText} has been updated!` : `${displayText} is now active!`
  return (
    <WorkRexModal close={close} additionalClassNames={['activate-assistant-modal']}>
      <h2 className={'modal-title'}>{title}</h2>
      <button className={'close-button'} onClick={confirm}>{'Close'}</button>
    </WorkRexModal>
  );
};

ActivateAssistantModal.propTypes = propTypes;
ActivateAssistantModal.displayName = 'ActivateAssistantModal';
export default ActivateAssistantModal;