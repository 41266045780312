import EmptyRecipients from './EmptyRecipients';
import './RecipientsTable.scss';

import React from 'react';

import SELECTED_ICON from './assets/selected-icon.svg';
import UNSELECTED_ICON from './assets/unselected-icon.svg';

const RecipientsTable = ({ recipients, selectedRecipients, selectRecipient }) => {

  const recipientList = recipients.length === 0
    ? <span className={'no-recipients'}>{'No recipients found.'}</span>
    : recipients.map(({ _id, firstName, lastName, emailAddress, mobileNumber, birthday }) => {
      const displayLastName = lastName ? ` ${lastName}` : '';
      const fullName = `${firstName}${displayLastName}`;
      const emailAddressDisplay = emailAddress ? emailAddress : '';
      const mobileNumberDisplay = mobileNumber ? mobileNumber : '';
      const birthdayDisplay = birthday
        ? new Date(birthday).toLocaleString('en-AU', { day: 'numeric', month: 'numeric', year: 'numeric' })
        : '';
      const selected = selectedRecipients.includes(_id);
      const icon = selected ? SELECTED_ICON : UNSELECTED_ICON;
      return (
        <tr className={'recipient-row'}>
          <td className={'selected-cell'}>
            <button className={'select-recipient-button'} onClick={() => selectRecipient(_id)}>
              <img className={'icon'} src={icon} alt={'selected icon'} />
            </button>
          </td>
          <td className={'full-name-cell'}>{fullName}</td>
          <td className={'birthday-cell'}>{birthdayDisplay}</td>
          <td className={'mobile-cell'}>{mobileNumberDisplay}</td>
          <td className={'email-cell'}>{emailAddressDisplay}</td>
        </tr>
      )
    });
  return (
    <section className={'recipients-table-container'}>
      <table className={'recipients-table'}>
        <thead className={'table-header'}>
          <tr className={'table-row'}>
            <th className={'selected-cell'}>{' '}</th>
            <th className={'full-name-cell'}>{'Full Name'}</th>
            <th className={'birthday-cell'}>{'Birthday'}</th>
            <th className={'mobile-cell'}>{'Mobile Number'}</th>
            <th className={'email-cell'}>{'Email Address'}</th>
          </tr>
        </thead>
        <tbody className={'table-body'}>
          {recipientList}
        </tbody>
      </table>
    </section>
  );
};

export default RecipientsTable;