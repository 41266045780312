import './IntegrationsSidebar.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddButton from '../../../common/AddButton';
import SearchInput from '../../../common/SearchInput';
import RequestIntegrationModal from '../modals/RequestIntegrationModal';
import FilterButtonList from '../../../common/FilterButtonList';


const propTypes = {};

function IntegrationsSidebar({ searchText, setSearchText, filters }) {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  return (
    <aside className={'integrations-sidebar'}>
      { open && <RequestIntegrationModal close={close} />}
      <AddButton buttonText={'Request integration'} onClick={() => setOpen(true)} />
      <span className={'divider'} />
      <SearchInput searchText={searchText} setSearchText={setSearchText} />
      <FilterButtonList filters={filters} />
    </aside>
  );
}

IntegrationsSidebar.displayName = 'IntegrationsSidebar';
IntegrationsSidebar.propTypes = propTypes;
export default IntegrationsSidebar;