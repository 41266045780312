import '../communications/navigation/CommunicationsNavigationTabs.scss';

import React from 'react';
import PropTypes from 'prop-types';
import CommunicationsNavigationTab from '../communications/navigation/CommunicationsNavigationTab';
import { PAGES } from './AccountConstants';
import useAuth from '../../../../context/AuthContext';

const { EDIT_PAGE, GROUPS_PAGE, INTEGRATIONS_PAGE } = PAGES;

const propTypes = {};

// TODO: Turn this into a more reusable component that just reused the CommunicationsNavigationTabs component
function AccountNavigationTabs({ selectedTab }) {
  const { hasAdminAccess } = useAuth();
  return (
    <ul className={'communication-navigation-tabs account-navigation-tabs'}>
      <nav className={'nav-tab'}>
        <CommunicationsNavigationTab
          href={'/account/edit'}
          selected={EDIT_PAGE === selectedTab}
          displayText={'My details'}
        />
      </nav>
      {/** 
      <nav className={'nav-tab'}>
        <CommunicationsNavigationTab
          href={'/account/groups'}
          selected={GROUPS_PAGE === selectedTab}
          displayText={'Groups'}
          style={{ left: '-2px' }}
        />
      </nav>
      */}
      { hasAdminAccess &&
        <nav className={'nav-tab'}>
          <CommunicationsNavigationTab
            href={'/account/integrations'}
            selected={INTEGRATIONS_PAGE === selectedTab}
            displayText={'Integrations'}
            style={{ left: '-2px' }}
          />
        </nav>
      }
    </ul>
  );
}

AccountNavigationTabs.displayName = 'AccountNavigationTabs';
AccountNavigationTabs.propTypes = propTypes;
export default AccountNavigationTabs;