import './GroupsPage.scss';

import React from 'react';
import PropTypes from 'prop-types';
import AccountPageWrapper from '../AccountPageWrapper';

import { PAGES } from '../AccountConstants';

const { EDIT_PAGE } = PAGES;

const propTypes = {};

function GroupsPage({ }) {
  return (
    <section className={'groups-page'}>
      
    </section>
  )
}

GroupsPage.displayName = 'GroupsPage';
GroupsPage.propTypes = propTypes;
export default GroupsPage;