import './MessagesPage.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import useAuth from '../../../../../../../../context/AuthContext';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';
import { postMarkChatAsRead } from '../../../../../../../../apis/Api';
import EmptyChatHistory from './EmptyChatHistory';

import ChatHistory from './ChatHistory';
import ChatInput from './ChatInput';

const propTypes = {};

function MessagesPage({ selectedRecord, setSelectedRecord }) {
  const { user } = useAuth();
  const { setCustomers, setEmployees } = MessagingConsumer();
  const { _id, firstName, chatHistory } = selectedRecord;

  const markChatAsUnread = (messageId = null) => {
    const updatedChatHistory = chatHistory.map(chat => {
      if (chat._id === messageId) {
        return { ...chat, read: false };
      }
      return { ...chat, read: true };
    });
    const updatedSelectedRecord = { ...selectedRecord, chatHistory: updatedChatHistory, hasUnreadAction: messageId ? true : false };
    // setSelectedRecord(updatedSelectedRecord);
    if (selectedRecord.contactType === 'CUSTOMER') {
      setCustomers(prevCustomers => {
        const newFilteredCustomers = prevCustomers.filter(prevCustomer => prevCustomer._id !== selectedRecord._id);
        return [ ...newFilteredCustomers, updatedSelectedRecord ].sort((a, b) => a.firstName - b.firstName);
      })
    } else {
      setEmployees(prevEmployees => {
        const newFilteredEmployees = prevEmployees.filter(prevEmployee => prevEmployee._id !== selectedRecord._id);
        return [ ...newFilteredEmployees, updatedSelectedRecord ].sort((a, b) => a.firstName - b.firstName);
      })
    }
  }

  useEffect(() => {
    if (chatHistory.length > 0) {
      const hasUnreadMessage = !!chatHistory.find(chat => !chat.read);
      if (hasUnreadMessage) {
        postMarkChatAsRead(_id)
          .then(response => {
            markChatAsUnread();
          })
      }
    }
  }, [selectedRecord]);

  const sortedChatHistory = chatHistory.sort((a, b) => a.creationDate - b.creationDate);

  return (
    <section className={'chat-room'}>
      {!!chatHistory && chatHistory.length > 0 && <ChatHistory chatHistory={sortedChatHistory} senderId={user.businessId} firstName={firstName} markChatAsUnread={markChatAsUnread} />}
      {chatHistory && chatHistory.length === 0 && <EmptyChatHistory />}
      {!!chatHistory && <ChatInput senderId={user.businessId} isHidden={false} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} />}
    </section>
  )
}

MessagesPage.displayName = 'MessagesPage';
MessagesPage.propTypes = propTypes;
export default MessagesPage;