import './AutomationCard.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { AUTOMATION_TYPES } from '../AutomationsConstants';
import ACTIVE_ICON from '../assets/active-automation-icon.svg';
import INACTIVE_ICON from '../assets/inactive-automation-icon.svg';
import AutomationDropdown from './AutomationDropdown';

const { AVAILABLE, REQUESTED, MODULE } = AUTOMATION_TYPES;

const propTypes = {};

function AutomationCard(
  { type, active, description, automationId, archive, requestSupport, request, label = null, title, recommendedUrl = null, objective }
) {
  const navigate = useNavigate();
  let className = '';
  let cardButton = '';
  let onClick;
  let disabledDropdown = false;
  let dropdownOptions = [];
  let displayTitle = title;
  if (type === AVAILABLE.key) {
    className = AVAILABLE.className;
    cardButton = (
      <button className={`automation-card-button toggle ${active ? 'active' : 'inactive'}`} disabled={true}>
        <span className={'text'}>
          {active ? AVAILABLE.displayTextActive : AVAILABLE.displayTextInactive}
        </span>
        <img className={'icon'} src={active ? ACTIVE_ICON : INACTIVE_ICON} alt={''} />
      </button>
    );
    disabledDropdown = true;
    onClick = () => navigate('/automations/view', { state: { automationId }});
  }
  else if (type === REQUESTED.key) {
    className = REQUESTED.className;
    cardButton = <button className={'automation-card-button'} disabled={true}>{REQUESTED.displayTextActive}</button>;
    dropdownOptions = [
      { displayText: 'Archive', onClick: () => archive(automationId) },
      { displayText: 'Ask for support', onClick: () => requestSupport(automationId, type) },
    ];
    displayTitle = 'Requested automation';
  }
  else if (type === MODULE.key) {
    className = MODULE.className;
    const requestAutomation = () => request(automationId, title, description);
    cardButton = (
      <section className={`card-buttons ${ recommendedUrl ? '' : 'no-recommended'}`}>
        { recommendedUrl && <a href={recommendedUrl} className={'recommendation-url'} target={'_blank'} rel={'noreferrer'}>{'Best used for ...'}</a> }
        <button className={'automation-card-button request'} onClick={requestAutomation}>
          {MODULE.displayTextActive}
        </button>
      </section>
    );
    dropdownOptions = [
      { displayText: 'Ask for support', onClick: () => requestSupport(automationId, type) },
    ];
  }
  return (
    <section
      className={`automation-card ${className} ${active ? 'active' : ''}`}
      onClick={type === AVAILABLE.key ? onClick : null}
    >
      <section className={`card-header ${label ? '' : 'no-label'}`}>
        { label && <span className={'label'}>{label}</span> }
        <AutomationDropdown disabled={disabledDropdown} options={dropdownOptions} />
      </section>
      <section className={'automation-details'}>
        <h3 className={'automation-title'}>{displayTitle}</h3>
        <h4 className={'automation-description'}>{description}</h4>
        { objective && type === MODULE.key && <h5 className={'automation-objective'}>{objective}</h5> }
      </section>
      {cardButton}
    </section>
  )
}

AutomationCard.displayName = 'AutomationCard';
AutomationCard.propTypes = propTypes;
export default AutomationCard;