import './BatchSelectionModal.scss';

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import WorkRexModal from '../../../../../common/WorkRexModal';
import SearchDropdown from '../common/SearchDropdown';
import SelectionDropdown from '../common/SelectionDropdown';

import { BATCH_FREQUENCY, BATCH_SIZE, DEFAULT_BATCH_OPTIONS, RECURRENCE_OPTIONS } from '../BroadcastConstants';

const propTypes = {};

function BatchSelectionModal({ close, batchOptions = DEFAULT_BATCH_OPTIONS, setBatchOptions }) {
  const [currentBatchOptions, setCurrentBatchOptions] = useState(batchOptions);
  const setBatchSize = (batchSize) => {
    if (batchSize === 1) {
      setCurrentBatchOptions({ ...currentBatchOptions, batchSize, batchFrequency: null });
    }
    else {
      setCurrentBatchOptions({ ...currentBatchOptions, batchSize });
    }
  };
  const setBatchFrequency = (batchFrequency) => setCurrentBatchOptions({ ...currentBatchOptions, batchFrequency });
  const setRecurrence = (recurrence) => setCurrentBatchOptions({ ...currentBatchOptions, recurrence });
  const confirm = () => {
    setBatchOptions(currentBatchOptions);
    close();
  }
  const { batchSize, batchFrequency, recurrence } = currentBatchOptions;
  const disabled = batchSize > 1 && batchFrequency === null;
  return (
    <WorkRexModal close={close} additionalClassNames={['batch-selection-modal']}>
      <section className={'selection-option'}>
        <h4 className={'selection-title'}>{'Send in batches'}</h4>
        <span className={'selection-subtitle'}>{'Distribute this campaign over batches'}</span>
        <SelectionDropdown options={Object.values(BATCH_SIZE)} selected={batchSize} select={setBatchSize} />
      </section>
      <section className={'selection-option'}>
        <h4 className={'selection-title'}>{'Batch frequency'}</h4>
        <span className={'selection-subtitle'}>{'Select batch send frequency'}</span>
        <SelectionDropdown
          options={Object.values(BATCH_FREQUENCY)}
          selected={batchFrequency}
          select={setBatchFrequency}
          disabled={batchSize === 1}
          defaultText={'NA'}
        />
      </section>
      <section className={'selection-option'}>
        <h4 className={'selection-title'}>{'Send recurring'}</h4>
        <span className={'selection-subtitle'}>{'Select whether you\'d like to repeat sends'}</span>
        <SelectionDropdown options={Object.values(RECURRENCE_OPTIONS)} selected={recurrence} select={setRecurrence} />
      </section>
      <section className={'confirm-buttons'}>
        <button className={'back-button'} onClick={close}>{'Back'}</button>
        <button className={`confirm-button ${disabled ? 'disabled' : ''}`} onClick={confirm} disabled={disabled}>{'Confirm'}</button>
      </section>
    </WorkRexModal>
  );
}

BatchSelectionModal.displayName = 'BatchSelectionModal';
BatchSelectionModal.propTypes = propTypes;
export default BatchSelectionModal;
