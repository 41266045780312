import './CampaignRecipientsDisplay.scss';

import React, { useState, useEffect } from 'react';
import { useOutletContext, Navigate, useNavigate, useLocation } from 'react-router-dom';
import RecipientsSearch from '../recipients/RecipientsSearch';
import RecipientsView from '../recipients/RecipientsView';
import { getUserVisibleRawData } from '../../../../../../apis/Api';
import ConfirmCampaignModal from '../modals/ConfirmCampaignModal';
import { postPublishCampaign } from '../../../../../../apis/CampaignApi';
import useAuth from '../../../../../../context/AuthContext';

function CampaignRecipientsDisplay() {
  const navigate = useNavigate();
  const { setUser, user } = useAuth();
  const { campaigns } = user;
  const { state } = useLocation();
  console.log({ state, location: 'CampaignRecipientsDisplay' });
  const stateAllRecipients = state && state.allRecipients ? state.allRecipients : null;
  const stateRecipients = state && state.recipients ? state.recipients : [];
  const stateRecipientsToView = stateAllRecipients
    ? stateAllRecipients.filter(({ _id }) => stateRecipients.includes(_id))
    : [];
  const stateCampaignId = state && state.campaignId ? state.campaignId : null;
  const campaign = campaigns.find(({ _id }) => _id === stateCampaignId);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [allRecipients, setAllRecipients] = useState(stateAllRecipients);
  const [selectedRecipients, setSelectedRecipients] = useState(stateRecipients);
  const [selectedRecipientsToView, setSelectedRecipientsToView] = useState(stateRecipientsToView);
  console.log({ state, allRecipients, stateAllRecipients });
  useEffect(() => {
    if (allRecipients === null || firstLoad) {
      getUserVisibleRawData('ALL_GROUPS')
        .then((data) => {
          setFirstLoad(false);
          setAllRecipients([...data.customers].sort((a, b) => a.firstName - b.firstName));
        })
        .catch(() => setAllRecipients([]));
    }
  }, [allRecipients])
  const selectRecipient = (recipientId) => {
    if (selectedRecipients.includes(recipientId)) {
      setSelectedRecipients(selectedRecipients.filter(id => id !== recipientId));
    }
    else {
      setSelectedRecipients([...selectedRecipients, recipientId]);
    }
  }
  const confirm = () => {
    navigate('/messaging/broadcasts/new', { state: { ...state, recipientIds: selectedRecipients }})
  };
  
  const disabled = selectedRecipients.length === 0;
  const campaignTitle = campaign ? campaign.name : 'Custom broadcast';
  return (
    <section className={'campaign-recipients-display'}>
      { modalOpen && <ConfirmCampaignModal close={() => setModalOpen(false)} confirm={confirm} loading={loading} /> }
      <h3 className={'title'}>{campaignTitle}</h3>
      <section className={'campaign-recipients'}>
        <RecipientsSearch
          recipients={allRecipients}
          selectedRecipients={selectedRecipients}
          setSelectedRecipients={setSelectedRecipients}
          setSelectedRecipientsToView={setSelectedRecipientsToView}
        />
        <RecipientsView
          recipients={selectedRecipientsToView}
          selectedRecipients={selectedRecipients}
          selectRecipient={selectRecipient}
        />
      </section>
      <section className={'buttons'}>
        <span className={'selection-number'}>{`(${selectedRecipients.length} selected)`}</span>
        <button
          className={`broadcast-button ${disabled ? 'disabled' : ''}`}
          onClick={confirm}
          disabled={disabled}
        >
          {'Confirm'}
        </button>
      </section>
    </section>
  );
};

CampaignRecipientsDisplay.displayName = 'CampaignRecipientsDisplay';
export default CampaignRecipientsDisplay;