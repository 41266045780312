import './EditModalDropdown.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function EditModalDropdown({ children, additionalClassNames = [] }) {
  return (
    <section className={`editing-dropdown ${additionalClassNames.join(' ')}`}>
      {children}
    </section>
  )
}

EditModalDropdown.displayName = 'EditModalDropdown';
EditModalDropdown.propTypes = propTypes;
export default EditModalDropdown;