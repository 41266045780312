import './TaskListView.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../../Constants';
import { MESSAGING_VIEWS } from '../../../../MessagingConstants';
import useAuth from '../../../../../../../../context/AuthContext';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';
import { postManyTaskArchive, postManyTaskCompletion, postManyTaskReminders } from '../../../../../../../../apis/Api';

import TaskCard from './TaskCard';
import DotBricksLoading from '../../../../../../../common/DotBricksLoading';
import EditModalDropdown from './EditModalDropdown';
import { useOutsideClick } from '../../../../../../../../hooks/ClickHooks';
import Modal from '../../../../../../../common/Modal';

const { TASKS } = MESSAGING_VIEWS;
const editIcon = `${ASSET_CDN_URL}/icons/workrex_edit_triple_dot_selection.svg`;

const additionalClassNames = ['left-align'];

const propTypes = {};

function TaskListView({ tasks, setRecord, viewFilters, selectedRecord, setSelectedRecord }) {
  const { user } = useAuth();
  const { setTasks } = MessagingConsumer();
  const [selectAll, setSelectAll] = useState(true);
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const selectAllText = selectAll ? 'Select all' : 'Deselect all';

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const closeArchiveModal = () => setShowArchiveModal(false);
  const openArchiveModal = () => setShowArchiveModal(true);

  const [showReminderModal, setShowReminderModal] = useState(false);
  const closeReminderModal = () => setShowReminderModal(false);
  const openReminderModal = () => setShowReminderModal(true);

  const [isEditing, setIsEditing] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsEditing(false));
  const toggleEdit = (e) => {
    e.preventDefault();
    setIsEditing(!isEditing);
  }
  const showCompleteEditOptions = viewFilters === TASKS.filters.ALL.key || viewFilters === TASKS.filters.COMPLETED.key;
  const showOutstandingEditOptions = viewFilters === TASKS.filters.ALL.key || viewFilters === TASKS.filters.OUTSTANDING.key;

  if (tasks === null) {
    return <DotBricksLoading isFixed={false} />
  }

  const filteredTasks = tasks.filter(task => {
    if (!task.completed && viewFilters === TASKS.filters.OUTSTANDING.key) {
      return true;
    } else if (task.completed && viewFilters === TASKS.filters.COMPLETED.key) {
      return true;
    } else if (viewFilters === TASKS.filters.ALL.key) {
      return true;
    }
    return false;
  }).sort((a, b) => a.completed - b.completed);

  const updateSelectedTasks = () => {
    setSelectAll(!selectAll)
    if (selectAll) {
      const taskIds = filteredTasks.map(filteredTask => filteredTask._id);
      setSelectedTaskIds(taskIds);
    } else {
      setSelectedTaskIds([]);
    }
  }

  const updatePostComplete = (completedStatus) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const tasksToUpdate = filteredTasks.filter(filteredTask => selectedTaskIds.includes(filteredTask._id));
    const updatedTasks = tasksToUpdate.map(taskToUpdate => ({ ...taskToUpdate, completed: completedStatus }));
    postManyTaskCompletion(updatedTasks)
      .then(response => {
        if (viewFilters === 'ALL') {
          setSelectedRecord(prevState => {
            if (prevState) {
              return response.tasks.find(task => task._id === prevState._id)
            }
            return prevState;
          });
        } else {
          setRecord(null);
        }
        setTasks(prevState => {
          const filteredTasks = prevState.filter(prevTasks => !selectedTaskIds.includes(prevTasks._id));
          return [ ...filteredTasks, ...response.tasks ];
        });
      })
      .finally(() => {
        setSelectedTaskIds([]);
        setIsEditing(false);
      })
  }

  const sendReminders = () => {
    postManyTaskReminders(selectedTaskIds, user._id)
      .then(response => {
        const historyUpdatedTasks = filteredTasks.map(filteredTask => {
          const newTaskHistory = { taskId: filteredTask._id, creationDate: Date.now(), createdById: user._id, createdBy: user, description: 'Reminder sent' };
          if (selectedTaskIds.includes(filteredTask._id)) {
            return { ...filteredTask, taskHistory: [ newTaskHistory, ...filteredTask.taskHistory ] }
          }
          return filteredTask;
        });
        setTasks(historyUpdatedTasks);
      })
      .finally(() => {
        closeReminderModal();
      })
  }

  const archiveTask = () => {
    const tasksToUpdate = filteredTasks.filter(filteredTask => selectedTaskIds.includes(filteredTask._id));
    postManyTaskArchive(tasksToUpdate, user._id)
      .then(response => {
        setTasks(prevState => {
          return prevState.filter(prevTasks => !selectedTaskIds.includes(prevTasks._id));
        });
        setRecord(null);
      })
      .finally(() => {
        setSelectedTaskIds([]);
        setIsEditing(false);
        closeArchiveModal();
      })
  }

  return (
    <section className={'task-list-view'}>
      <section className={'task-header'}>
        <button className={'select-all-button'} onClick={() => updateSelectedTasks()} type={'button'}>{selectAllText}</button>
        <button className={'edit-selected-button'} ref={dropdownRef} onClick={toggleEdit} type={'button'}>
          <img alt={'edit-icon'} className={'edit-icon'} src={editIcon} />
          {isEditing && 
            <EditModalDropdown additionalClassNames={additionalClassNames}>
              {showOutstandingEditOptions && <button className={'edit-button'} onClick={updatePostComplete(true)} type={'button'}>{'Mark as complete'}</button>}
              {showCompleteEditOptions && <button className={'edit-button'} onClick={updatePostComplete(false)} type={'button'}>{'Mark as incomplete'}</button>}
              {showOutstandingEditOptions && <button className={'edit-button'} onClick={openReminderModal} type={'button'}>{'Send reminder'}</button>}
              {showCompleteEditOptions && <button className={'edit-button'} onClick={openArchiveModal} type={'button'}>{'Archive tasks'}</button>}
            </EditModalDropdown>
          }
        </button>
      </section>
      <section className={'task-rows'}>
        {filteredTasks.map(task =>
          <TaskCard
            key={task._id}
            task={task}
            viewFilters={viewFilters}
            setRecord={setRecord}
            selectedTaskIds={selectedTaskIds}
            setSelectedTaskIds={setSelectedTaskIds}
            selectedRecord={selectedRecord}
            setSelectedRecord={setSelectedRecord}
          />
        )}
      </section>
      {showArchiveModal &&
        <Modal closeModal={closeArchiveModal} additionalClassNames={['archive']}>
          <section className={'archive-task-modal'}>
            <p className={'archive-text'}>{'Confirm archiving of this task which will remove this from your task list.'}</p>
            <button className={'confirm-archive'} onClick={archiveTask} type={'button'}>{'Archive'}</button>
          </section>
        </Modal>
      }
      {showReminderModal &&
        <Modal closeModal={closeReminderModal} additionalClassNames={['archive']}>
          <section className={'archive-task-modal'}>
            <p className={'archive-text'}>{'Are you sure you want to send a reminder for this task?'}</p>
            <button className={'confirm-archive'} onClick={sendReminders} type={'button'}>{'Send Reminder'}</button>
          </section>
        </Modal>
      }
    </section>
  )
}

TaskListView.displayName = 'TaskListView';
TaskListView.propTypes = propTypes;
export default TaskListView;