import { getData, postData, postFormData } from './Api';

const CONTENT_ROUTES = {
  GET_FILE_URL: '/api/content/',
  UPLOAD_FILE_URL: '/api/content/upload',
  UNPUBLISH_FILE_URL: '/api/content/unpublish',
  PUBLISH_FILE_URL: '/api/content/publish',
  UPDATE_FILE_DESCRIPTION_URL: '/api/content/description/update',
  UPDATE_FILE_TITLE_URL: '/api/content/title/update',
  UPDATE_FILE_TAGS_URL: '/api/content/tags/update',
  REMOVE_FILE_TAG_URL: '/api/content/tags/remove',
  DELETE_FILE_URL: '/api/content/delete',
};

const {
  GET_FILE_URL,
  UPLOAD_FILE_URL,
  UNPUBLISH_FILE_URL,
  PUBLISH_FILE_URL,
  UPDATE_FILE_DESCRIPTION_URL,
  UPDATE_FILE_TITLE_URL,
  UPDATE_FILE_TAGS_URL,
  REMOVE_FILE_TAG_URL,
  DELETE_FILE_URL,
} = CONTENT_ROUTES;

export const getContentFile = (code) => {
  return getData(`${GET_FILE_URL}${code}`);
}

export const postContentFile = (contentFile) => {
  const { type, size, fileName, file, fileType } = contentFile;
  const formData = new FormData();
  formData.append('fileName', fileName);
  formData.append('size', size);
  formData.append('type', type);
  formData.append('file', file);
  formData.append('fileType', fileType);
  return postFormData(UPLOAD_FILE_URL, formData);
}

export const postUnpublishFile = (fileId) => {
  return postData(UNPUBLISH_FILE_URL, { fileId });
}

export const postPublishFile = (fileId) => {
  return postData(PUBLISH_FILE_URL, { fileId });
}

export const postDeleteFile = (fileId) => { 
  return postData(DELETE_FILE_URL, { fileId });
}

export const postFileDescription = (fileId, description) => {
  return postData(UPDATE_FILE_DESCRIPTION_URL, { fileId, description });
}

export const postFileTitle = (fileId, title) => {
  return postData(UPDATE_FILE_TITLE_URL, { fileId, title });
}

export const postFileTags = (fileId, tags) => {
  return postData(UPDATE_FILE_TAGS_URL, { fileId, tags });
}

export const postRemoveFileTag = (tag) => {
  return postData(REMOVE_FILE_TAG_URL, { tag });
}