import { usePortal } from "@flatfile/react";
import { interactions_workbook } from "./workbooks";
import { listener } from "./listener";
import { prodFlatFileAuth } from '../../../../../Constants';

const { environmentId, publishableKey } = prodFlatFileAuth;

function InteractionsFlatFilePortal() {
  const workbook = interactions_workbook;
  return usePortal({
    environmentId,
    publishableKey,
    spaceBody: {
      namespace: "portal",
    },
    listener,
    workbook,
    closeSpace: {
      operation: "closeFlatFile",
      onClose: () => {},
    },
  });
}

export default InteractionsFlatFilePortal;