import { Routes } from 'react-router-dom';
import React from 'react';

import getManagerRoutes from './ManagerRoutes';
import getLoginRoutes from './LoginRoutes';
import useAuth from '../context/AuthContext';
import getContentRoutes from './ContentRoutes';
import getCampaignRoutes from './CampaignRoutes';
import getEngagementRoutes from './EngagementRoutes';


/**
 * Parent container that holds all the app routes.
 * TODO: Split up the different routes here.
 */
function AppRoutes() {
  const { user } = useAuth();
  const userAutomations = user && user.automations ? user.automations : [];
  const userTemplates = user && user.savedTemplates ? user.savedTemplates : [];
  const userCampaigns = user && user.campaigns ? user.campaigns : [];
  // assistants renamed to engagements, but mongo collection still called assistants.
  const userEngagements = user && user.engagements ? user.engagements : [];
  const userFiles = user && user.files ? user.files : [];
  return (
    <Routes>
      {getManagerRoutes(userAutomations, userTemplates)}
      {getLoginRoutes()}
      {getContentRoutes(userFiles)}
      {getCampaignRoutes(userCampaigns)}
      {getEngagementRoutes(userEngagements)}
    </Routes>
  );
}

export default AppRoutes;