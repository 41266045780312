import { postData, postFormData } from './Api';

const TEMPLATE_ROUTES = {
  ATTACH_TEMPLATE_URL: '/api/template/attach',
  ARCHIVE_TEMPLATE_URL: '/api/template/archive',
  CREATE_TEMPLATE_URL: '/api/template/create',
};

const { ATTACH_TEMPLATE_URL, ARCHIVE_TEMPLATE_URL, CREATE_TEMPLATE_URL } = TEMPLATE_ROUTES;

export const postTemplateAttachment = (attachment) => {
  const { attachmentType, type, size, fileName, file } = attachment;
  const formData = new FormData();
  formData.append('fileName', fileName);
  formData.append('size', size);
  formData.append('attachmentType', attachmentType);
  formData.append('type', type);
  formData.append('file', file);
  return postFormData(ATTACH_TEMPLATE_URL, formData);
}
export const postArchiveTemplate = (templateId) => {
  return postData(ARCHIVE_TEMPLATE_URL, { templateId });
}
export const postCreateTemplate = (template, templateId) => {
  return postData(CREATE_TEMPLATE_URL, { template, templateId });
}