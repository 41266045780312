import './FilterDropdownInput.scss';

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import RIGHT_CARET_ICON from '../../../../../../../common/assets/right-caret-icon.svg';
import FilterOption from '../FilterOption';

const propTypes = {};

function FilterDropdownInput({ open, setOpen, title, filterKey, selected, containerToggle, dropdownAttributes, updateFilter }) {

  const selectFilter = (option) => {
    updateFilter(option);
    toggle(open);
  }

  const toggle = useCallback((select, skipSetOpen = false) => {
    const wrapper = document.getElementById(`filter-dropdown-input-wrapper-${filterKey}`);
    const dropdownText = document.getElementById(`filter-dropdown-input-list-${filterKey}`);
    const header = document.getElementById(`filter-dropdown-input-header-${filterKey}`);
    
    if (!skipSetOpen && open !== null) {
      setOpen(!select);
    }

    // + 2 for border
    wrapper.style.height = select ? '0px' : `${dropdownText.clientHeight + 2}px`;
    wrapper.style.maxHeight = select ? '0px' : `${dropdownText.clientHeight + 2}px`;
    wrapper.style.top = `${header.clientHeight + 10}px`;
  }, [open])

  useEffect(() => {
    if (open) {
      toggle(null)
      containerToggle(null);
    } else {
      toggle(true, true);
    }
  }, [open, toggle])

  console.log(dropdownAttributes);

  return (
    <section className={'filter-dropdown-input-container'}>
      <button id={`filter-dropdown-input-header-${filterKey}`} className={'filter-dropdown-input-header'} onClick={() => toggle(open)}>
        <span className={'dropdown-display-text'}>{selected ? selected.displayText : title}</span>
        <img src={RIGHT_CARET_ICON} alt={''} className={`filter-header-input-icon ${open ? 'open' : ''}`} />
      </button>
      <section id={`filter-dropdown-input-wrapper-${filterKey}`} className={'filter-dropdown-input-content'}>
        <section id={`filter-dropdown-input-list-${filterKey}`} className={'filter-dropdown-input-answer'}>
          {Object.keys(dropdownAttributes).map((option) => <FilterOption option={dropdownAttributes[option]} selected={selected} setSelected={selectFilter} key={dropdownAttributes[option].key} />)}
        </section>
      </section>
    </section>
  )
}

FilterDropdownInput.displayText = 'FilterDropdownInput';
FilterDropdownInput.propTypes = propTypes;
export default FilterDropdownInput;
