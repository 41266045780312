import './ViewOffersModal.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WorkRexModal from '../../../../common/WorkRexModal';
import PlusButton from '../../../engagements/available-old/common/PlusButton';

const propTypes = {};

function ViewOffersModal({ close, addOffer, offers, setOffers, viewOnly = false }) {
  const offerList = offers.map((offer, index) => {
    const removeOffer = () => {
      setOffers(offers.filter((_, i) => i !== index));
      if (offers.length === 1) {
        close();
      }
    };
    return (
      <li key={`offer-${index}`} className={'offer'}>
        <span className={'offer-text'}>{offer}</span>
        { !viewOnly && <button className={'remove-offer-button'} onClick={removeOffer}>{'×'}</button> }
      </li>
    );
  });
  const offerText = offers.length === 1 ? 'offer' : 'offers';
  return (
    <WorkRexModal close={close} additionalClassNames={['view-offers-modal']}>
      <h4 className={'title'}>{'Campaign offers'}</h4>
      <p className={'description'}>
        {`You have added ${offers.length} ${offerText}`}
      </p>
      <ul className={'offers'}>
        {offerList}
      </ul>
      { !viewOnly && <PlusButton onClick={addOffer} buttonText={'Add offer'} /> }
      { viewOnly && <button className={'plus-button'} onClick={close}>{'Close'}</button> }
    </WorkRexModal>
  );
}

ViewOffersModal.displayName = 'ViewOffersModal';
ViewOffersModal.propTypes = propTypes;
export default ViewOffersModal;