import './SearchGroupsModal.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import useAuth from '../../../../../context/AuthContext';

import GroupsModalWrapper from './GroupsModalWrapper';
import SearchBar from '../../communications/messaging/list/views/search/SearchBar';
import GroupOption from './dropdown/GroupOption';
import TagWithDelete from '../../../../common/TagWithDelete';

const SEARCH_GROUP_CLASS_NAME = ['no-padding'];
const OPEN_SEARCH_GROUP_CLASS_NAME = ['no-padding', 'group-search'];
const propTypes = {};

function SearchGroupsModal({ closeModal, selectGroup, selectedGroups }) {
  const { user } = useAuth();
  const [searchText, setSearchText] = useState('');
  const [searching, setSearching] = useState(false);

  const search = ({ target }) => {
    if (!searching && searchText.length > 0) {
      setSearching(true);
    }
    if (searchText.length === 0) {
      setSearching(false);
    }
    setSearchText(target.value);
  }

  const addGroup = (group) => {
    selectGroup(group);
    setSearching(false);
  }

  const filteredGroups = user.groups.filter(group => group.name.toLowerCase().includes(searchText.toLowerCase()));
  const searchResults = filteredGroups.length > 0
    ? filteredGroups.map(group => <GroupOption selectedGroups={selectedGroups} group={group} setGroup={addGroup} />)
    : <button disabled className={'no-groups-button'} type={'button'}>
        <span>{'No groups found with this name.'}</span>
      </button>

  const groupTags = selectedGroups.map(group => <TagWithDelete tagText={group.displayText} tagClick={() => selectGroup(group.key)} />)
  const searchBarClassName = searching ? OPEN_SEARCH_GROUP_CLASS_NAME : SEARCH_GROUP_CLASS_NAME;

  return (
    <GroupsModalWrapper closeModal={closeModal} confirmModal={closeModal} modalTitle={'Search your groups'} confirmButtonText={'Confirm'}>
      <section className={'search-groups-container'}>
        <section className={'search-groups'}>
          <SearchBar additionalClassNames={searchBarClassName} placeholder={'Search groups'} searchText={searchText} search={search} exitIconType={false} />
          {searching && 
            <section className={'search-groups-dropdown'}>
              {searchResults}
            </section>
          }
        </section>
        <section className={'group-tags-container'}>
          {groupTags}
        </section>
      </section>
    </GroupsModalWrapper>
  )
}

SearchGroupsModal.displayName = 'SearchGroupsModal';
SearchGroupsModal.propTypes = propTypes;
export default SearchGroupsModal;