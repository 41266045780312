import OPTOMATE_TOUCH_ICON from './assets/optomate-touch-icon.png';
import DEPUTY_ICON from './assets/deputy-icon.svg';
import TANDA_ICON from './assets/tanda-icon.svg';
import SUNIX_ICON from './assets/sunix-icon.svg';

export const INTEGRATION_TAGS = {
  PRACTICE_MANAGEMENT: 'Practice managements',
  HR_AND_ROSTERING: 'HR & rostering',
  CRM: 'CRM',
}

const { PRACTICE_MANAGEMENT, HR_AND_ROSTERING, CRM } = INTEGRATION_TAGS;

export const INTEGRATIONS = {
  OPTOMATE_TOUCH: {
    key: 'OPTOMATE_TOUCH',
    icon: OPTOMATE_TOUCH_ICON,
    displayText: 'Optomate Touch',
    comingSoon: false,
    hasCredentials: true,
    tags: [PRACTICE_MANAGEMENT, CRM],
  },
  DEPUTY: {
    key: 'DEPUTY',
    icon: DEPUTY_ICON,
    comingSoon: true,
    displayText: 'Deputy',
    hasCredentials: true,
    tags: [PRACTICE_MANAGEMENT, HR_AND_ROSTERING]
  },
  TANDA: {
    key: 'TANDA',
    icon: TANDA_ICON,
    comingSoon: true,
    displayText: 'Tanda',
    hasCredentials: true,
    tags: [HR_AND_ROSTERING],
  },
  SUNIX: {
    key: 'SUNIX',
    icon: SUNIX_ICON,
    comingSoon: true,
    displayText: 'SUNIX',
    hasCredentials: true,
    tags: [PRACTICE_MANAGEMENT, CRM],
  }
};