import './ReceptionistSelection.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import AssistantSelection from '../common/AssistantSelection';
import AssistantSelectionSection from '../common/AssistantSelectionSection';
import TeamPicker from '../common/TeamPicker';
import ToggleButton from '../common/ToggleButton';
import TaskTypeSelections from '../common/TaskTypeSelections';
import { DUMMY_TEAM_MEMBERS } from '../../EngagementConstants';
import useAuth from '../../../../../../../context/AuthContext';

import { postActivateAssistant } from '../../../../../../../apis/AssistantApi';

const propTypes = {};

function ReceptionistSelection({ context, active, setAssistantKey, assistantKey }) {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const teamMembers = user && user.recipients ? user.recipients : DUMMY_TEAM_MEMBERS;
  const [assistantContext, setAssistantContext] = useState(context);
  const cancel = () => setAssistantKey(null);
  const { shouldSendSumaryReports, autoAssignment, tasks } = assistantContext;
  const [selectedTasks, setSelectedTasks] = useState(tasks);
  const [selectedTeamMemberIds, setSelectedTeamMemberIds] = useState([]);
  const setShouldSendSummaryReports = (shouldSendSumaryReports) => setAssistantContext({ ...assistantContext, shouldSendSumaryReports });
  const setAutoAssignment = (autoAssignment) => setAssistantContext({ ...assistantContext, autoAssignment });
  const summaryReportsDescription = shouldSendSumaryReports
    ? 'Rex will automatically categorise un-read messages and send you a weekly summary.'
    : 'Rex will not send you any summary reports.';
  const recipientsDescription = 'Select which team members will receive these reports.';
  const assignDescription = autoAssignment
    ? 'Rex will automatically create and assign tasks for messages that require action from your team.'
    : 'Rex will automatically create unassigned tasks for messages that require action from your team.';

  const activate = (callback) => {
    if (loading) {
      return;
    }
    setLoading(true);
    postActivateAssistant(assistantKey, { ...assistantContext, teamMemberIds: selectedTeamMemberIds, tasks })
      .then((res) => {
        setUser(res.user);
        setLoading(false);
        callback();
      });
  }
  return (
    <AssistantSelection title={'Receptionist'} additionalClassNames={['receptionist']} cancel={cancel} activateAssistant={activate} assistantKey={assistantKey}>
      <AssistantSelectionSection
        title={'Summary reports'}
        description={summaryReportsDescription}
        toggleButton={<ToggleButton active={shouldSendSumaryReports} setActive={setShouldSendSummaryReports} />}
      >
      </AssistantSelectionSection>
      {
        shouldSendSumaryReports &&
          <AssistantSelectionSection title={'Select report recipients'} description={recipientsDescription}>
            <TeamPicker
              selectedTeamMemberIds={selectedTeamMemberIds}
              setSelectedTeamMemberIds={setSelectedTeamMemberIds}
              teamMembers={teamMembers}
            /> 
          </AssistantSelectionSection>
      }
      <AssistantSelectionSection
        title={'Auto assign tasks'}
        description={assignDescription}
        toggleButton={<ToggleButton active={autoAssignment} setActive={setAutoAssignment} />}
      >
        { autoAssignment && <TaskTypeSelections setTasks={setSelectedTasks} tasks={selectedTasks} teamMembers={teamMembers} /> }
      </AssistantSelectionSection>
    </AssistantSelection>
  );
}

ReceptionistSelection.displayName = 'ReceptionistSelection';
ReceptionistSelection.propTypes = propTypes;
export default ReceptionistSelection;