import './AddGroupsModal.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import useAuth from '../../../../../context/AuthContext';
import { postGroupCreation } from '../../../../../apis/Api';

import GroupsModalWrapper from './GroupsModalWrapper';
import TagWithDelete from '../../../../common/TagWithDelete';

const ADD_GROUPS_TEXT = 'Add group';
const GROUPS_PLACEHOLDER = 'Group name';
const propTypes = {};

function AddGroupsModal({ closeModal, selectGroup, selectedGroups }) {
  const { user, setUser } = useAuth();
  const existingGroupNames = user.groups.map(group => group.name.toLowerCase());
  const [newGroup, setNewGroup] = useState('');
  const [errorText, setErrorText] = useState('');

  const updateNewGroup = ({ target }) => {
    setNewGroup(target.value);
    setErrorText('');
  }

  const createGroup = () => {
    if (existingGroupNames.includes(newGroup.toLowerCase())) {
      setErrorText('There is already a group with this name.');
    }
    else {
      postGroupCreation(newGroup)
        .then(response => {
          console.log(response);
          if (response.error) {
            setErrorText(response.error);
            return;
          }
          const newlyCreatedGroup = response.user.groups.find(group => group.name === newGroup);
          setUser(prevState => ({ ...prevState, ...response.user }));
          selectGroup(newlyCreatedGroup._id);
          closeModal();
        })
    }
  }

  const groupTags = selectedGroups.map(group => <TagWithDelete tagText={group.displayText} tagClick={() => selectGroup(group.key)} />)

  return (
    <GroupsModalWrapper closeModal={closeModal} confirmModal={createGroup} modalTitle={ADD_GROUPS_TEXT} confirmButtonText={ADD_GROUPS_TEXT}>
      <section className={'add-group-modal-body'}>
        <input className={`add-group-input ${newGroup.length === 0 ? 'show-placeholder' : ''}`} onChange={updateNewGroup} placeholder={GROUPS_PLACEHOLDER} value={newGroup} type={'text'} />
        <span className={'error-text'}>{errorText}</span>
        <section className={'group-tags-container'}>
          {groupTags}
        </section>
      </section>
    </GroupsModalWrapper>
  )
}

AddGroupsModal.displayName = 'AddGroupsModal';
AddGroupsModal.propTypes = propTypes;
export default AddGroupsModal;