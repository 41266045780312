import './ArchiveAutomationModal.scss';

import React from 'react';
import PropTypes from 'prop-types';
import WorkRexModal from '../../../common/WorkRexModal';

import { AUTOMATION_TYPES } from '../AutomationsConstants';

const { AVAILABLE } = AUTOMATION_TYPES;

const propTypes = {};

function ArchiveAutomationModal({ close, type, archive }) {
  const confirm = () => {
    archive();
  }
  const description = type === AVAILABLE.key
    ? 'Please confirm you would like to stop this automation and remove it from your account'
    : 'Please confirm you no longer require this automation and the request will be removed';
  return (
    <WorkRexModal close={close} additionalClassNames={['archive-automation-modal']}>
      <p className={'description'}>{description}</p>
      <button className={'confirm-button'} onClick={confirm}>{'Archive'}</button>
    </WorkRexModal>
  );
}

ArchiveAutomationModal.displayName = 'ArchiveAutomationModal';
ArchiveAutomationModal.propTypes = propTypes;
export default ArchiveAutomationModal;