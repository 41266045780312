import './ContentFilePreview.scss';

import React from 'react';
import PropTypes from 'prop-types';

import FILE_ICON from './assets/file-icon.svg';
import { FILE_TYPES } from '../ContentConstants';

const { JPEG, PNG, SVG } = FILE_TYPES;

const propTypes = {};

function ContentFilePreview({ title, fileType, url = FILE_ICON }) {
  const isImage = fileType === JPEG || fileType === PNG || fileType === SVG;
  const imageSrc = isImage ? url : FILE_ICON;
  const isDefault = FILE_ICON === imageSrc;
  const previewImage = <img className={`preview-image ${isDefault ? 'default' : ''}`} src={imageSrc} alt={'Preview'} />;
  return (
    <section className={'file-preview'}>
      <section className={'preview'}>
        {previewImage}
        <span className={'preview-title'}>{title}</span>
      </section>
    </section>
  );
}

ContentFilePreview.displayName = 'ContentFilePreview';
ContentFilePreview.propTypes = propTypes;
export default ContentFilePreview;