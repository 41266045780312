import './AutomationRunLogEntry.scss';

import dayjs from 'dayjs';
import React from 'react';
import PropTypes from 'prop-types';

import advancedFormat from 'dayjs/plugin/advancedFormat';

const propTypes = {};

function AutomationRunLogEntry({ context }) {
  const { number, date } = context;
  const automationText = number === 1 ? 'automation' : 'automations';
  const dateText = dayjs(new Date(date)).format('MMM dddd Do YYYY');
  return (
    <span className={'log-entry automation-run'}>
      <b>{`${number}`}</b> 
      {`${automationText} run on ${dateText}`}
    </span>
  );
};

AutomationRunLogEntry.displayName = 'AutomationRunLogEntry';
AutomationRunLogEntry.propTypes = propTypes;
export default AutomationRunLogEntry;