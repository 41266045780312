import './TemplateTypeNavigationTabs.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import NavigationTab from '../../../common/NavigationTab';

import { TEMPLATE_PAGE_TYPES } from '../TemplatesConstants';
import { ASSET_CDN_URL } from '../../../../../../Constants';

const PUBLISHED_TEMPLATES_ICON = `${ASSET_CDN_URL}/icons/workrex_active_engagements.svg`;
const DRAFT_TEMPLATES_ICON = `${ASSET_CDN_URL}/icons/workrex_draft_engagements.svg`;
const LIBRARY_TEMPLATES_ICON = `${ASSET_CDN_URL}/icons/workrex_engagement_library.svg`;

const { SAVED, EMPLOYEE, LIBRARY, PUBLISHED, DRAFT } = TEMPLATE_PAGE_TYPES;

const propTypes = {};

function TemplateTypeNavigationTabs({ selectedTab = null, templateCounts }) {
  const navigate = useNavigate();
  const onPublishedClick = () => navigate('/templates/published');
  const onDraftClick = () => navigate('/templates/draft');
  const onLibraryClick = () => navigate('/templates/library');
  return (
    <ul className={'template-type-navigation-tabs'}>
      <NavigationTab
        unselectedIcon={PUBLISHED_TEMPLATES_ICON}
        displayText={'Your published templates'}
        onClick={onPublishedClick}
        selected={selectedTab === PUBLISHED}
        count={templateCounts[PUBLISHED]}
      />
      <NavigationTab
        unselectedIcon={DRAFT_TEMPLATES_ICON}
        displayText={'Your draft templates'}
        onClick={onDraftClick}
        selected={selectedTab === DRAFT}
        count={templateCounts[DRAFT]}
      />
      <NavigationTab
        unselectedIcon={LIBRARY_TEMPLATES_ICON}
        displayText={'The WorkRex template library'}
        onClick={onLibraryClick}
        selected={selectedTab === LIBRARY}
        count={templateCounts[LIBRARY]}
      />
    </ul>
  )
}

TemplateTypeNavigationTabs.displayName = 'TemplateTypeNavigationTabs';
TemplateTypeNavigationTabs.propTypes = propTypes;
export default TemplateTypeNavigationTabs;