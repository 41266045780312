import './Engagement.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { postEngagementRequest, postActivateEngagement, postDeleteEngagement } from '../../../../../../../apis/EngagementApi';
import { ENGAGEMENT_TABS } from '../../EngagementConstants';
import { ASSET_CDN_URL } from '../../../../../../../Constants';
import useAuth from '../../../../../../../context/AuthContext';

import EngagementDataField from './EngagementDataField';
import EngagementComponentWrapper from './EngagementComponentWrapper';
import OpportunityScore from '../../common/OpportunityScore';
import { useOutsideClick } from '../../../../../../../hooks/ClickHooks';
import EditModalDropdown from '../../../messaging/list/views/tasks/EditModalDropdown';

const EDIT_ICON = `${ASSET_CDN_URL}/icons/workrex_edit_triple_dot_selection.svg`;
const { ENGAGEMENT_LIBRARY } = ENGAGEMENT_TABS;
const additionalClassNames = ['engagement-item-dropdown'];
const opportunityScoreHoverData = {
  hoverEnabled: true,
  hoverClassNames: ['engagement-hover'],
  hoverText: 'This is our estimate of the size of impact this opportunity can have. It is a proprietary score based on your industry, business, data quality, and past performance',
};
const requestEngagementHoverData = {
  hoverEnabled: true,
  hoverClassNames: ['engagement-hover'],
  hoverText: 'You don\'t have the required data fields to activate this opportunity. You can request for WorkRex support to come up with an alternative',
};
const dataFieldHoverData = {
  hoverEnabled: true,
  hoverClassNames: ['engagement-hover'],
  hoverText: '',
};
const missingDataHoverTextStart = 'This opportunity requires';
const missingDataHoverTextEnd = 'which we have not detected in your database';
const propTypes = {};

function Engagement({ customerRows, engagement, tab, firstEngagement = false }) {
  const { setUser } = useAuth();
  const showEditBar = tab !== ENGAGEMENT_LIBRARY.key;
  const { customersAvailable = [], title, description, dataRequired, state, key } = engagement;
  const [isEditing, setIsEditing] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsEditing(false));
  const toggleEdit = (e) => {
    e.preventDefault();
    setIsEditing(!isEditing);
  }
  const availableDataFields = dataRequired
    .filter(dataField => customerRows.find(customerRow => customerRow[dataField]));
  const hasMissingData = dataRequired.length === 0 || availableDataFields.length !== dataRequired.length;
  const missingData = dataRequired.filter(dataField => !availableDataFields.includes(dataField));
  const engagementDataHoverText = `${missingDataHoverTextStart} ${missingData.join(', ')} ${missingDataHoverTextEnd}`

  const opportunityScorePercentage = (customersAvailable.length / customerRows.length) * 100;
  const opportunityScoreNode = <OpportunityScore opportunityScorePercentage={opportunityScorePercentage} />

  const requestEngagementActivation = () => {
    const engagementContext = {
      key,
    }
    postActivateEngagement(engagementContext)
      .then(response => {
        setUser(response.user);
      });
  }

  const requestEngagementDeletion = () => {
    postDeleteEngagement(key)
      .then(response => {
        setUser(response.user);
      });
  }
  
  const requestEngagementSupport = () => {
    const engagementContext = {
      key,
    }
    postEngagementRequest(engagementContext)
      .then(response => {
        setUser(response.user);
      });
  }

  const existingEngagementText = state === 'draft' || state === 'requested'
    ? 'Requested'
    : state === 'active'
      ? 'Active'
      : customersAvailable.length === 0
        ? 'Request'
        : 'Activate';
  
  const engagementClass = state
    ? state
    : customersAvailable.length === 0
      ? 'requested'
      : 'activate';
  
  const engagementMethod = state === 'draft' || state === 'active'
    ? () => {}
    : customersAvailable.length === 0
      ? requestEngagementSupport
      : requestEngagementActivation;

  const activateNode = (
    <button
    className={`activate-button ${engagementClass}`}
    onClick={engagementMethod}>
      {existingEngagementText}
    </button>
  );

  const editNode = (
    <button className={'edit-engagement-button'} onClick={toggleEdit} ref={dropdownRef}>
      <img alt={'edit-icon'} className={'edit-engagement-icon'} src={EDIT_ICON} />
      {isEditing && 
        <EditModalDropdown additionalClassNames={additionalClassNames}>
          <button className={'edit-button'} onClick={requestEngagementDeletion} type={'button'}>{'Delete'}</button>
        </EditModalDropdown>
      }
    </button>
  );

  return (
    <section className={`engagement-item `}>
      <EngagementComponentWrapper additionalClassNames={['title']} engagementComponent={title} />
      <EngagementComponentWrapper additionalClassNames={['description']} engagementComponent={description} />
      <EngagementDataField availableDataFields={availableDataFields} dataRequired={dataRequired} hoverData={{ ...dataFieldHoverData, hoverEnabled: hasMissingData, hoverText: engagementDataHoverText }} firstEngagement={firstEngagement} />
      <EngagementComponentWrapper additionalClassNames={['opportunity-score']} engagementComponent={opportunityScoreNode} hoverData={opportunityScoreHoverData} firstEngagement={firstEngagement} />
      <EngagementComponentWrapper additionalClassNames={['action-button']} engagementComponent={activateNode} hoverData={{ ...requestEngagementHoverData, hoverEnabled: existingEngagementText === 'Request' }} firstEngagement={firstEngagement} />
      {showEditBar && <EngagementComponentWrapper additionalClassNames={['edit-button']} engagementComponent={editNode} />}
    </section>
  );
}

Engagement.displayName = 'Engagement';
Engagement.propTypes = propTypes;
export default Engagement;