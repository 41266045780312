import './VerificationCodeInput.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useMediaQuery } from '../../../hooks/MediaHooks';
import useAuth from '../../../context/AuthContext';
import { postLoginVerificationCode } from '../../../apis/LoginApi';

const CODE_LENGTH = new Array(6).fill(0);
const MOBILE_INPUT_INCREMENTS = 42;
const WEB_INPUT_INCREMENTS = 66;

const MOBILE_WIDTH = '24px';
const WEB_WIDTH = '32px';

const INVALID_VERIFICATION_CODE_ERROR = 'Invalid verification code. Please try again.';

function VerificationCodeInput({ loginDetails, loginMethod, setError, verificationCode, setVerificationCode }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const isMobile = useMediaQuery('(max-width: 480px)');

  // TODO: Add a loading UI state
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  const digitRef = useRef(null);
  const [focused, setFocused] = useState(false);
  const codeDigits = verificationCode.split('');
  const hideInput = !(codeDigits.length < CODE_LENGTH.length);

  const handleClick = () => {
    digitRef.current.focus();
  };
  const handleFocus = () => {
    setFocused(true);
  };
  const handleBlur = () => {
    setFocused(false)
  };

  useEffect(() => {
    if (verificationCode.length === CODE_LENGTH.length) {
      setLoading(true);
      postLoginVerificationCode(loginDetails[loginMethod], loginMethod, verificationCode)
        .then((response) => {
          setVerificationCode('');
          if (response.success) {
            const { user, token } = response;
            login(user, token);
            if (state && state.path) {
              navigate(state.path);
            }
            else {
              navigate('/messaging');
            }
          }
          else {
            setError(INVALID_VERIFICATION_CODE_ERROR);
            setLoading(false);
          }
        })
        .catch(() => {
          setError(INVALID_VERIFICATION_CODE_ERROR);
          setLoading(false);
        });
    }
  }, [verificationCode, loginDetails, loginMethod, setError, navigate, state, login, setVerificationCode]);

  const handleChange = (e) => {
    const value = e.target.value;
  
    setVerificationCode(prevState => {
      if (prevState.length >= CODE_LENGTH.length) {
        return prevState;
      }
      setError('');
      return (prevState + value).slice(0, CODE_LENGTH.length);
    })
  };

  const handleKeyUp = (e) => {
    if (e.key === "Backspace") {
      setVerificationCode(prevState => prevState.slice(0, prevState.length - 1))
    }
  };

  // TODO: Add a loading state

  const selectedIndex = codeDigits.length < CODE_LENGTH.length ? codeDigits.length : CODE_LENGTH.length - 1;

  return (
    <section className={'verification-code-container'} onClick={handleClick}>
      {CODE_LENGTH.map((vDigit, index) => {
        const selected = codeDigits.length === index;
        const filled = codeDigits.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;
        return (
          <section className={'verification-digit'} key={`${index}`}>
            {codeDigits[index]}
            {(selected || filled) && focused && <div className="shadows" />}
          </section>
        )
      })}
      <input
        value={''}
        ref={digitRef}
        className={'code-input'}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        style={{
          width: (isMobile ? MOBILE_WIDTH : WEB_WIDTH),
          top: '0px',
          bottom: '0px',
          left: `${selectedIndex * (isMobile ? MOBILE_INPUT_INCREMENTS : WEB_INPUT_INCREMENTS)}px`,
          opacity: hideInput ? 0 : 1,
        }}
      />
    </section>
  )
}

export default VerificationCodeInput;
