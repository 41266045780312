import './ContactActionDashboard.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ACTION_VIEWS, DEFAULT_INTERACTION } from '../../../MessagingConstants';

import MessagesPage from './messages/MessagesPage';
import InteractionsPage from './interactions/InteractionsPage';
import TasksPage from './tasks/TasksPage';
import CreateInteractionModal from './modal/CreateInteractionModal';
import CreateTaskModal from './modal/CreateTaskModal';

const { MESSAGES, INTERACTIONS, TASKS } = ACTION_VIEWS;

const propTypes = {};

function ContactActionDashboard({ selectedRecord, setSelectedRecord }) {
  const [actionView, setActionView] = useState(MESSAGES.key);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);
  const { _id, contactType } = selectedRecord;

  const [createMode, setCreateMode] = useState(true);
  const [interactionToEdit, setInteractionToEdit] = useState(null);

  const createEditInteraction = (mode) => (interactionToEdit) => () => {
    setCreateMode(mode);
    if (!mode) {
      setInteractionToEdit(interactionToEdit);
    }
    openModal();
  }

  const resetUpdatedContactTasks = (tasks) => {
    const filteredTasks = tasks.filter(task => {
      if (contactType === 'CUSTOMER') {
        return task.taggedTo._id === _id;
      }
      return task.assignedTo._id === _id;
    })
    setSelectedRecord({ ...selectedRecord, tasks: filteredTasks });
  }

  const resetUpdatedContactInteractions = (create) => (interactions) => {
    if (create) {
      setSelectedRecord(prevState => ({ ...prevState, interactions: [ ...prevState.interactions, interactions ] }));
    } else {
      setSelectedRecord(prevState => ({ ...prevState, interactions }));
    }
  }

  let detailView = null;
  if (actionView === MESSAGES.key) {
    detailView = <MessagesPage selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} />
  } else if (actionView === INTERACTIONS.key) {
    detailView = <InteractionsPage selectedRecord={selectedRecord} createEditInteraction={createEditInteraction} setSelectedRecord={setSelectedRecord}  />
  } else if (actionView === TASKS.key) {
    detailView = <TasksPage selectedRecord={selectedRecord} openModal={openModal} setSelectedRecord={resetUpdatedContactTasks} />
  }

  const modalDisplay = actionView === TASKS.key
    ? <CreateTaskModal closeModal={closeModal} modalType={ACTION_VIEWS[actionView]} selectedRecord={selectedRecord} setSelectedRecord={resetUpdatedContactTasks} />
    : <CreateInteractionModal closeModal={closeModal} modalType={ACTION_VIEWS[actionView]} selectedRecord={selectedRecord} setSelectedRecord={resetUpdatedContactInteractions} existingInteraction={createMode ? DEFAULT_INTERACTION : interactionToEdit} createMode={createMode} />

  return (
    <section className={'contact-action-dashboard'}>
      <section className={'contact-action-nav'}>
        <button className={`action-nav-button ${actionView === MESSAGES.key ? 'selected' : ''}`} onClick={() => setActionView(MESSAGES.key)} type={'button'}>
          <span>{'Messages'}</span>
        </button>
        <button className={`action-nav-button ${actionView === INTERACTIONS.key ? 'selected' : ''}`} onClick={() => setActionView(INTERACTIONS.key)} type={'button'}>
          <span>{'Interactions'}</span>
        </button>
        <button className={`action-nav-button ${actionView === TASKS.key ? 'selected' : ''}`} onClick={() => setActionView(TASKS.key)} type={'button'}>
          <span>{'Tasks'}</span>
        </button>
      </section>
      {detailView}
      {showModal && modalDisplay}
    </section>
  )
}

ContactActionDashboard.displayName = 'ContactActionDashboard';
ContactActionDashboard.propTypes = propTypes;
export default ContactActionDashboard;