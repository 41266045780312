import './EngagementComponentWrapper.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import EditModalDropdown from '../../../messaging/list/views/tasks/EditModalDropdown';

const additionalHoverClassNames = ['engagement-hover'];
const propTypes = {};

function EngagementComponentWrapper({ additionalClassNames = [], engagementComponent, hoverData = {}, firstEngagement = false }) {
  const { hoverEnabled = false, hoverText = '' } = hoverData;
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => hoverEnabled
    ? setIsHovering(true)
    : () => {};
  const handleMouseOut = () => hoverEnabled
    ? setIsHovering(false)
    : () => {};
  return (
    <section className={`engagement-component ${additionalClassNames.join(' ')}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
      {engagementComponent}
      {isHovering &&
        <EditModalDropdown additionalClassNames={firstEngagement ? [ ...additionalHoverClassNames, 'first-engagement' ] : additionalHoverClassNames}>
          {hoverText}
        </EditModalDropdown>
      }
    </section>
  );
}

EngagementComponentWrapper.displayName = 'EngagementComponentWrapper';
EngagementComponentWrapper.propTypes = propTypes;
export default EngagementComponentWrapper;