import './CreateInteractionModal.scss';

import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from 'prop-types';

import { DEFAULT_INTERACTION, INTERACTION_TYPE_MAPPING } from '../../../../MessagingConstants';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';
import useAuth from '../../../../../../../../context/AuthContext';
import { postInteractionCreation, postInteractionEdit } from '../../../../../../../../apis/Api';

import CreateRecordModal from './CreateRecordModal';
import DropdownField from '../../dropdown/DropdownField';
import FieldSection from './FieldSection';
import SearchContact from './SearchContact';

const propTypes = {};

function CreateInteractionModal({ closeModal, modalType, selectedRecord, setSelectedRecord, existingInteraction = DEFAULT_INTERACTION, createMode = true }) {
  const { setInteractions } = MessagingConsumer();
  const { user } = useAuth();

  const [interaction, setInteraction] = useState(existingInteraction);
  const [error, setError] = useState('');
  const { type } = interaction;
  const appointmentType = type === '' ? 'Interaction type' : INTERACTION_TYPE_MAPPING[type].text;
  
  const datePickerRef = useRef(null);
  const updateLabel = (e) => {
    setInteraction({...interaction, name: e.target.value});
    setError('');
  }

  const updateDescription = (e) => {
    setInteraction({...interaction, description: e.target.value});
    setError('');
  }

  const selectDate = (date) => {
    date.setHours(0, 0, 0, 0);
    setInteraction({ ...interaction, startDateTime: date.getTime(), endDateTime: date.getTime() });
    setError('');
  };

  const createRecord = () => {
    if (!interaction.type) {
      setError('Please select an Interaction Type');
      return;
    } else if (!interaction.name) {
      setError('Please enter a label for this Interaction.');
      return;
    }
    const newInteraction = {
      ...interaction,
      createdBy: user._id,
      sender: user._id,
      receiver: selectedRecord._id,
    }
    postInteractionCreation(newInteraction, user._id).then(response => {
      setInteractions(prevState => [ ...prevState, { ...response.interaction, ...interaction, createdBy: user, sender: user, receiver: selectedRecord } ]);
      setSelectedRecord(true)({ ...response.interaction, ...interaction, createdBy: user, sender: user, receiver: selectedRecord });
      closeModal();
    })
  }

  const editRecord = () => {
    if (!interaction.type) {
      setError('Please select an Interaction Type');
      return;
    } else if (!interaction.name) {
      setError('Please enter a label for this Interaction.');
      return;
    }
    const updatedInteraction = {
      _id: interaction._id,
      type: interaction.type,
      name: interaction.name,
      description: interaction.description,
      startDateTime: interaction.startDateTime,
      endDateTime: interaction.endDateTime,
    }
    postInteractionEdit(updatedInteraction, user._id)
      .then(response => {
        let newInteractionList = [];
        setInteractions(prevState => {
          const filteredInteractions = selectedRecord.interactions.filter(prevInteractions => prevInteractions._id !== interaction._id);
          newInteractionList = [ ...filteredInteractions, interaction ];
          return newInteractionList;
        });
        setSelectedRecord(false)(newInteractionList);
        closeModal();
      })
  }

  const modalSubmit = createMode ? createRecord : editRecord;

  const setSelectedData = (option) => {
    setInteraction(prevState => ({...prevState, type: option}));
    setError('');
  }

  return (
   <CreateRecordModal closeModal={closeModal} modalType={modalType} createRecord={modalSubmit} createMode={createMode}>
    <p className={'confirm-error-text'}>{error}</p>
    <section className={'modal-details'}>
      <FieldSection inputLabel={''}>
        <DropdownField dropdownSelected={interaction.type} dropdownOptions={INTERACTION_TYPE_MAPPING} text={appointmentType} setSelectedData={setSelectedData} />
      </FieldSection>
      <FieldSection inputLabel={'Add label'}>
        <input className={'create-record-input'} placeholder={'i.e. Reminder sent for upcoming appointment'} value={interaction.name} onChange={updateLabel} type={'text'} />
      </FieldSection>
      <FieldSection inputLabel={'Date'} ref={datePickerRef}>
        <section className={'time-period-picker'}>
          <DatePicker
            minDate={null}
            selected={interaction.startDateTime}
            onChange={selectDate}
            dateFormat={'dd/MM/yyyy'}
            formatWeekDay={nameOfDay => nameOfDay.substring(0, 3)}
            placeholderText={'DD/MM/YYYY'}
            showPopperArrow={false}
          />
        </section>
      </FieldSection>
      <FieldSection inputLabel={'Additional notes'}>
        <textarea className={'create-record-textarea'} placeholder={'Add a note'} value={interaction.description} onChange={updateDescription} type={'text'} />
      </FieldSection>
    </section>
   </CreateRecordModal>
  )
}

CreateInteractionModal.displayName = 'CreateInteractionModal';
CreateInteractionModal.propTypes = propTypes;
export default CreateInteractionModal;