export const BATCH_SIZE = {
  2: {
    key: 2,
    displayText: 'Distribute across 2 batches',
  },
  3: {
    key: 3,
    displayText: 'Distribute across 3 batches',
  },
  4: {
    key: 4,
    displayText: 'Distribute across 4 batches',
  },
  5: {
    key: 5,
    displayText: 'Distribute across 5 batches',
  },
  6: {
    key: 6,
    displayText: 'Distribute across 6 batches',
  },
  7: {
    key: 7,
    displayText: 'Distribute across 7 batches',
  },
  8: {
    key: 8,
    displayText: 'Distribute across 8 batches',
  },
  9: {
    key: 9,
    displayText: 'Distribute across 9 batches',
  },
  10: {
    key: 10,
    displayText: 'Distribute across 10 batches',
  },
  1: {
    key: 1,
    displayText: 'Do not send in batches',
  },
};

export const BATCH_FREQUENCY = {
  DAILY: {
    key: 'DAILY',
    displayText: 'Batch send daily',
  },
  THREE_DAYS: {
    key: 'THREE_DAYS',
    displayText: 'Batch send every 3 days',
  },
  WEEKLY: {
    key: 'WEEKLY',
    displayText: 'Batch send weekly',
  },
  FORTNIGHTLY: {
    key: 'FORTNIGHTLY',
    displayText: 'Batch send fortnightly',
  },
  MONTHLY: {
    key: 'MONTHLY',
    displayText: 'Batch send monthly',
  },
}

export const RECURRENCE_OPTIONS = {
  DAILY: {
    key: 'DAILY',
    displayText: 'Repeat every day',
  },
  WEEKLY: {
    key: 'WEEKLY',
    displayText: 'Repeat every week',
  },
  MONTHLY: {
    key: 'MONTHLY',
    displayText: 'Repeat every year',
  },
  DO_NOT_REPEAT: {
    key: 'DO_NOT_REPEAT',
    displayText: 'Do not repeat',
  }
};

const { DO_NOT_REPEAT } = RECURRENCE_OPTIONS;

export const DEFAULT_BATCH_OPTIONS = {
  batchSize: 1,
  batchFrequency: null,
  recurrence: DO_NOT_REPEAT.key,
}