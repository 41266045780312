import './ContentFileButton.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function ContentFileButton({ buttonText, buttonIcon, onClick }) {
  return (
    <button className={'content-file-button'} onClick={onClick}>
      <img className={'button-icon'} src={buttonIcon} alt={'Button icon'} />
      <span className={'button-text'}>{buttonText}</span>
    </button>
  );
}

ContentFileButton.displayName = 'ContentFileButton';
ContentFileButton.propTypes = propTypes;
export default ContentFileButton;