import React, { useState, useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from '../../../context/AuthContext';
import DotBricksLoading from '../../common/DotBricksLoading';
import LoginPage, { LOGIN_METHODS } from './LoginPage';
import VerifyPage from './VerifyPage';
import { postLoginVerificationCode } from '../../../apis/LoginApi';

function LoginViewManager() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [loginPage, setLoginPage] = useState(true);
  const [loginDetails, setLoginDetails] = useState({ email: '', sms: '' });
  const [loginMethod, setLoginMethod] = useState(LOGIN_METHODS.SMS);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const verificationCode = searchParams.get('verification_code');
  const loginMethodParam = searchParams.get('login_method');
  const loginDetailsParam = searchParams.get('login_details');
  useEffect(() => {
    if (verificationCode && loginMethodParam && loginDetailsParam) {
      setLoading(true);
      postLoginVerificationCode(loginDetailsParam, loginMethodParam, verificationCode)
        .then((response) => {
          if (response.success) {
            const { user, token } = response;
            login(user, token);
            navigate('/messaging');
          }
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [verificationCode, loginMethodParam, loginDetailsParam, login, navigate]);

  if (loading) { 
    return <DotBricksLoading />;
  }

  if (loginPage) {
    return (
      <LoginPage
        setLoginPage={setLoginPage}
        loginDetails={loginDetails}
        setLoginDetails={setLoginDetails}
        loginMethod={loginMethod}
        setLoginMethod={setLoginMethod}
      />
    );
  }
  return <VerifyPage loginDetails={loginDetails} setLoginPage={setLoginPage} loginMethod={loginMethod} />;
}

export default LoginViewManager;