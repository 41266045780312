import React, { useState, createContext, useContext } from 'react';
import Cookies from 'js-cookie';
import { useLocation, Navigate } from 'react-router-dom';

const AuthContext = createContext();

const useAuth = () => {
  const [authed, setAuthed] = useState(false);
  const [user, setUser] = useState(null);
  const hasAdminAccess = authed && user && (!user.userAccess || user.userAccess === 'ADMIN');
  return {
    authed,
    setAuthed,
    user,
    setUser,
    hasAdminAccess,
    login: (user, token) => {
      Cookies.set('token', token);
      setUser(user);
      setAuthed(true);
    },
    logout: () => {
      return new Promise((res) => {
        Cookies.remove('token');
        setAuthed(false);
        setUser(null);
        res();
      });
    }
  };
};

/** Provider for authorization object (includes user data). */
export const AuthProvider = ({ children }) => {
  const auth = useAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const AuthConsumer = () => {
  return useContext(AuthContext);
};

/** Consumer for authorization object. */
export default AuthConsumer;

export const RequireAdminAuth = ({ children }) => {
  const { authed, user } = AuthConsumer();
  const { pathname } = useLocation();
  const hasAdminAccess = authed && user && (!user.userAccess || user.userAccess === 'ADMIN');
  if (hasAdminAccess) {
    return children;
  }
  else if (authed) {
    return <Navigate to={'/messaging'} replace={'true'} state={{ path: pathname }} />
  }
  return <Navigate to={'/login'} replace={'true'} state={{ path: pathname }} />;
};

/** React wrapper to force the child component to require a certain level of authorization. */
// TODO: Migrate to wrappers
export const RequireAuth = ({ children }) => {
  const { authed } = AuthConsumer();
  const location = useLocation();
  if (authed) {
    return children;
  }
  return <Navigate to={'/login'} replace={'true'} state={{ path: location.pathname }} />;
};

/** React wrapper to force the child component to NOT require a certain level of authorization i.e. pages on available to logged out users. */
// TODO: Migrate to wrappers
export const RequireNoAuth = ({ children }) => {
  const { authed } = AuthConsumer();
  const { pathname, state } = useLocation();
  const path = state && state.path ? state.path : '/messaging';
  if (authed) {
    return <Navigate to={path} replace={'true'} state={{ path: pathname }} />;
  }
  return children;
};
