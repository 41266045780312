import './TeamMemberSearchModal.scss';

import React, { useState } from 'react';
import WorkRexModal from '../../../common/WorkRexModal';
import SearchBar from '../common/SearchBar';

import REMOVE_ICON from '../assets/remove-icon.svg';

const propTypes = {};

function TeamMemberSearchModal({ close, teamMembers, selectedTeamMemberIds, setSelectedTeamMemberIds }) {
  const [teamMemberIds, setTeamMemberIds] = useState(selectedTeamMemberIds);
  const select = (id) => {
    if (teamMemberIds.includes(id)) {
      setTeamMemberIds(teamMemberIds.filter((member) => member !== id));
    }
    else {
      setTeamMemberIds([...teamMemberIds, id]);
    }
  }
  const teamMemberList = teamMembers.map(({ firstName, lastName, _id }) => {
    const firstNameDisplay = firstName ? firstName : '';
    const lastNameDisplay = lastName ? ` ${lastName}` : '';
    return { _id, name: `${firstNameDisplay}${lastNameDisplay}` };
  });
  const filteredTeamMemberList = teamMemberList.filter(({ _id }) => !teamMemberIds.includes(_id));
  const selectedTeamMembers = teamMemberIds
    .map((id) => teamMemberList.find(({ _id }) => _id === id))
    .map(({ name, _id }) => {
      return (
        <li className={'selected-team-member'} key={_id}>
          <span className={'name'}>{name}</span>
          <button className={'remove-button'} onClick={() => select(_id)}>
            <img className={'remove-icon'} src={REMOVE_ICON} alt={''} />
          </button>
        </li>
      );
    });
  const confirm = () => {
    setSelectedTeamMemberIds(teamMemberIds);
    close();
  }
  return (
    <WorkRexModal close={close} additionalClassNames={['team-member-search-modal']}>
      <h2 className={'modal-title'}>{'Search your team members'}</h2>
      <SearchBar
        options={filteredTeamMemberList}
        keyName={'_id'}
        fieldName={'name'}
        onClick={select}
        placeholder={'Search team members'}
      />
      <ul className={'selected-team-members'}>
        {selectedTeamMembers}
      </ul>
      <button className={'confirm-button'} onClick={confirm}>{'Confirm'}</button>
    </WorkRexModal>
  );
};

TeamMemberSearchModal.propTypes = propTypes;
TeamMemberSearchModal.displayName = 'TeamMemberSearchModal';
export default TeamMemberSearchModal;