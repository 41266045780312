import './Breadcrumb.scss';

import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import RIGHT_CARET_ICON from './assets/right-caret-icon.svg';

const propTypes = {};

function Breadcrumb({ options }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const breadcrumbList = options.map(({ displayName, href, state = null }, index) => {
    const onClick = () => navigate(href, { state });
    const navButton = <button className={`breadcrumb-nav-button has-href ${href === pathname ? 'selected' : ''}`} onClick={onClick}>{displayName}</button>
    return (
      <li className={'breadcrumb-option'} key={displayName}>
        { href !== null
            ? navButton
            : <span className={'breadcrumb-nav-button'}>{displayName}</span>
        }
        { index !== options.length - 1 && (
          <span className={'caret-icon-container'}>
            <img alt={'caret-icon'} className={'caret-icon'} src={RIGHT_CARET_ICON} />
          </span> 
        )}
      </li>
    );
  })
  return (
    <ul className={'breadcrumb'}>
      {breadcrumbList}
    </ul>
  );
}

Breadcrumb.displayName = 'Breadcrumb';
Breadcrumb.propTypes = propTypes;
export default Breadcrumb;