import React from 'react';
import { Route, Navigate } from 'react-router-dom';

import WorkrexPage from '../workrex/wrappers/WorkrexPage';
import CampaignsPage from '../workrex/components/managers/communications/campaigns/CampaignsPage';
import CampaignSourceDisplay from '../workrex/components/managers/communications/campaigns/creation/CampaignSourceDisplay';
import CreateCampaignDisplay from '../workrex/components/managers/communications/campaigns/creation/CreateCampaignDisplay';
import CreateChannelsDisplay from '../workrex/components/managers/communications/campaigns/creation/CreateChannelsDisplay';

import { CAMPAIGN_PAGE_TYPES } from '../workrex/components/managers/communications/campaigns/CampaignConstants';
import { sortArrayFn } from '../utils/DataUtils';
import CampaignListContainer from '../workrex/components/managers/communications/campaigns/common/CampaignListContainer';
import CampaignRecipientsDisplay from '../workrex/components/managers/communications/campaigns/displays/CampaignRecipientsDisplay';

const { PUBLISHED, DRAFT, LIBRARY } = CAMPAIGN_PAGE_TYPES;

const DUMMY_DRAFT = {
  _id: 1,
  name: 'Share your latest roster',
  description: 'Sends out your staff roster and identifies team members that haven\'t read it',
  objective: 'Get your team to read the latest roster',
  channels: ['SMS', 'WHATSAPP'],
  offers: [],
  channelOutputs: {
    SMS: [{ name: 'Variant 1', message: 'Hello world!', attachment: null }, { name: 'Variant 3', message: 'Hello world!', attachment: null }],
    WHATSAPP: [{ name: 'Variant 1', message: 'Hello world!', attachment: null }]
  },
  creationDate: Date.now(),
  type: DRAFT,
}

const DUMMY_PUBLISH = {
  _id: 2,
  name: 'Share your latest roster',
  description: 'Sends out your staff roster and identifies team members that haven\'t read it',
  objective: 'Get your team to read the latest roster',
  offers: [],
  channels: ['WHATSAPP'],
  channelOutputs: {
    WHATSAPP: [{ name: 'Variant 1', message: 'Hello world!', attachment: null }]
  },
  creationDate: Date.now(),
  type: PUBLISHED,
}


/**
 * Routes relating to campaigns.
 */
function getCampaignRoutes(userCampaigns) {
  const campaigns = [...userCampaigns];
  const publishedCampaigns = campaigns.filter(({ type }) => type === PUBLISHED).sort(sortArrayFn('creationDate', true));
  const libraryCampaigns = campaigns.filter(({ type }) => type === LIBRARY).sort(sortArrayFn('name'));
  const draftCampaigns = campaigns.filter(({ type }) => type === DRAFT).sort(sortArrayFn('creationDate', true));
  const campaignCounts = {
    [PUBLISHED]: publishedCampaigns.length,
    [DRAFT]: draftCampaigns.length,
    [LIBRARY]: libraryCampaigns.length,
  }
  return (
    <Route path={'/'} element={<WorkrexPage />}>
      { /* TODO: apply some form of authorization to this route */ }
      <Route path={'campaigns'} element={<CampaignsPage campaignCounts={campaignCounts} />}>
        <Route index element={<Navigate to={'/campaigns/published'} replace />} />
        <Route path={'published'} element={<CampaignListContainer campaigns={publishedCampaigns} pageType={PUBLISHED} title={'Published campaigns'} />} />
        <Route path={'draft'} element={<CampaignListContainer campaigns={draftCampaigns} pageType={DRAFT} title={'Draft campaigns'} />} />
        <Route path={'library'} element={<CampaignListContainer campaigns={libraryCampaigns} pageType={LIBRARY} title={'WorkRex campaign library'} />} />
        <Route path={'create'} element={<CampaignSourceDisplay />} />
        <Route path={'create/new'} element={<CreateCampaignDisplay shouldUseAI={false} />} />
        <Route path={'create/ai'} element={<CreateCampaignDisplay shouldUseAI={true} />} />
        <Route path={'create/new/channels'} element={<CreateChannelsDisplay shouldUseAI={false} />} />
        <Route path={'create/ai/channels'} element={<CreateChannelsDisplay shouldUseAI={true} />} />
        <Route path={'view'} element={<CreateCampaignDisplay shouldUseAI={false} viewOnly={true} />} />
        <Route path={'view/channels'} element={<CreateChannelsDisplay shouldUseAI={false} viewOnly={true} />} />
        <Route path={'recipients'} element={<CampaignRecipientsDisplay />} />
      </Route>
    </Route>
  );
}

export default getCampaignRoutes;