import './TasksPage.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TasksList from './TasksList';
import DotBricksLoading from '../../../../../../../common/DotBricksLoading';
import ToggleButtonWithText from '../../../../../../../common/ToggleButtonWithText';

const propTypes = {};

function TasksPage({ selectedRecord, openModal, setSelectedRecord }) {
  const { tasks } = selectedRecord;
  const [hideCompleted, setHideCompleted] = useState(false);

  if (!tasks) {
    return <DotBricksLoading isFixed={false} />
  }

  const completedTasks = tasks.filter(task => task.completed).sort((a, b) => a.dueDate - b.dueDate);
  const incompleteTasks = tasks.filter(task => !task.completed).sort((a, b) => a.dueDate - b.dueDate);

  return (
    <section className={'tasks-container'}>
      <section className={'tasks-header'}>
        <ToggleButtonWithText additionalClassNames={['task-hide-completed-toggle']} active={hideCompleted} setToggleAutomation={setHideCompleted} onLabel={'Show completed'} offLabel={'Hide completed'} />
        <button className={'task-button'} onClick={openModal} type={'button'}>{'New task +'}</button>
      </section>
      <section className={'tasks-list'}>
        {!hideCompleted && completedTasks.length > 0 && <TasksList tasks={completedTasks} subtitle={'Completed'} setSelectedRecord={setSelectedRecord} />}
        {incompleteTasks.length > 0 && <TasksList tasks={incompleteTasks} subtitle={'Not completed'} setSelectedRecord={setSelectedRecord} />}
      </section>
    </section>
  )
}

TasksPage.displayName = 'TasksPage';
TasksPage.propTypes = propTypes;
export default TasksPage;