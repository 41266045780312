import './RecipientsListView.scss';

import React, { useState } from 'react';
import { uniqueId } from 'lodash';

import ManagerTable from '../common/ManagerTable';
import { EMPLOYEE_HEADERS, RECIPIENT_ACTIVE_STATUSES, DELIVERABILITY, EMPTY_RECIPIENT, RECIPIENT_USER_ACCESS, RECIPIENT_ONBOARDING } from './RecipientsConstants';
import TableDropdown from '../common/TableDropdown';
import useAuth from '../../../../context/AuthContext';
import { splitDates } from '../../../../utils/DataUtils';

import ManagerDropdown from './ManagerDropdown';
import TextInput from '../common/TextInput';
import OnboardingButton from './common/OnboardingButton';
import GroupsDropdown from './modals/GroupsDropdown';
import AddGroupsModal from './modals/AddGroupsModal';
import SearchGroupsModal from './modals/SearchGroupsModal';

const { ACTIVE, DELETE } = RECIPIENT_ACTIVE_STATUSES;
const { SUBSCRIBED, OPTED_OUT, UNDELIVERABLE } = DELIVERABILITY;
const { MANAGER, ADMIN, NONE } = RECIPIENT_USER_ACCESS;

const propTypes = {};

function RecipientsListView({ editingRows, setEditingRows, rows, setRows }) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [openCreateGroupModal, setOpenCreateGroupModal] = useState(false);
  const openCreateModal = (index) => () => {
    setOpenCreateGroupModal(true);
    setSelectedIndex(index);
  }
  const closeCreateModal = () => setOpenCreateGroupModal(false);
  const [openSearchGroupModal, setOpenSearchGroupModal] = useState(false);
  const openSearchModal = (index) => () => {
    setOpenSearchGroupModal(true);
    setSelectedIndex(index);
  }
  const closeSearchModal = () => setOpenSearchGroupModal(false);
  const { user } = useAuth();
  const groupNameMap = user.groups.filter(group => group._id !== 'ALL_GROUPS').reduce((groupNames, group) => ({ ...groupNames, [group._id]: { key: group._id, displayText: group.name } }), {});

  const selectGroups = (index) => (newGroup) => {
    if (!editingRows[index]) {
      const newEditingRows = [...editingRows];
      newEditingRows[index] = true;
      setEditingRows(newEditingRows);
    }
    const newRows = [...rows];
    let groupList = newRows[index].groups;
    if (groupList.includes(newGroup)) {
      groupList = groupList.filter(group => group !== newGroup);
    }
    else {
      groupList.push(newGroup);
    }
    newRows[index] = { ...newRows[index], groups: groupList };
    setRows(newRows);
  }
  const managerSelection = rows
    .filter(({ status, deletionDate, userAccess }) => (userAccess === MANAGER.key || userAccess === ADMIN.key) && status !== DELETE && !deletionDate)
    .map(({ _id, firstName, lastName }) => ({ key: _id, displayText: `${firstName} ${lastName}` }));
  
  const generatedRows = rows
    .filter(({ status }, index) => status !== DELETE || editingRows[index])
    .map(({
      firstName,
      lastName,
      roleType,
      groups,
      mobileNumber,
      emailAddress,
      managers,
      address,
      birthday,
      status = ACTIVE.key,
      deliverability = SUBSCRIBED.key,
      undeliverable = false,
      userAccess = NONE.key,
      onboarding = RECIPIENT_ONBOARDING.ONBOARD,
      isNew = false,
      _id,
    }, index) => {
      const setText = (fieldName) => (value) => {
        const newRows = [...rows];
        newRows[index] = { ...newRows[index], [fieldName]: value };
        setRows(newRows);
      };
      const setOnboarding = () => {
        const newRows = [...rows];
        newRows[index] = { ...newRows[index], onboarding: RECIPIENT_ONBOARDING.SENT };
        setRows(newRows);
      }

    const openEditing = () => {
      const newEditingRows = [...editingRows];
      newEditingRows[index] = true;
      setEditingRows(newEditingRows);
    }

    const birthdayNode = editingRows[index] ? <TextInput text={birthday} setText={setText('birthday')} processText={(text) => text.toLowerCase()} /> : <button className={'cell-text'} onClick={openEditing}>{birthday}</button>;

    const firstNameNode = editingRows[index] ? <TextInput text={firstName} setText={setText('firstName')} /> : <button className={'cell-text'} onClick={openEditing}>{firstName}</button>;
    const groupNames = groups.map(group => groupNameMap[group].displayText).join(', ');
    const lastNameNode = editingRows[index] ? <TextInput text={lastName} setText={setText('lastName')} /> : <button className={'cell-text'} onClick={openEditing}>{lastName}</button>;
    const roleTypeNode = editingRows[index] ? <TextInput text={roleType} setText={setText('roleType')} /> : <button className={'cell-text'} onClick={openEditing}>{roleType}</button>;
    const mobileNumberNode = editingRows[index] ? <TextInput text={mobileNumber} setText={setText('mobileNumber')} /> : <button className={'cell-text'} onClick={openEditing}>{mobileNumber}</button>;
    const emailAddressNode = editingRows[index] ? <TextInput text={emailAddress} setText={setText('emailAddress')} processText={(text) => text.toLowerCase()} /> : <button className={'cell-text'} onClick={openEditing}>{emailAddress}</button>;
    const addressNode = editingRows[index] ? <TextInput text={address} setText={setText('address')} processText={(text) => text.toLowerCase()} /> : <button className={'cell-text'} onClick={openEditing}>{address}</button>;
    const onboardingNode = editingRows[index] || !emailAddress || userAccess === NONE.key
      ? null
      : <OnboardingButton
          emailAddress={emailAddress}
          onboarding={onboarding}
          userAccess={userAccess}
          firstName={firstName}
          lastName={lastName}
          hasLoggedIn={false}
          userId={_id}
          setOnboarding={setOnboarding}
        />;

    const selectManager = (id) => {
      const newRows = [...rows];
      if (managers.includes(id)) {
        const newManagers = managers.filter(key => key !== id);
        newRows[index] = { ...newRows[index], managers: newManagers };
      }
      else {
        const newManagers = [...managers, id];
        newRows[index] = { ...newRows[index], managers: newManagers };
      }
      setRows(newRows);
    }

    const managerNames = managers
      .filter(id => {
        const manager = managerSelection.find(manager => manager.key === id);
        return manager && manager.status !== DELETE.key;
      })
      .map((id) => managerSelection.find(manager => manager.key === id).displayText);
    const managerName = managerNames.length > 0 ? managerNames[0] : '';
    const moreThanOneManagerText = managerNames.length - 1 > 0 ? `, +${managerNames.length - 1}` : '';
    const managerNameText = <span className={'manager-text'}>{`${managerName}${moreThanOneManagerText}`}</span>;
    const buttonText = managerNames.length > 0 ? managerNameText : <span className={'manager-text'}>{'Select Manager'}</span>;
    const managerNameNode = editingRows[index]
      ? <ManagerDropdown buttonText={buttonText} options={managerSelection} selectedOptions={managers} select={selectManager} />
      : <button className={'cell-text'} onClick={openEditing}>{managerNameText}</button>;
    
    const groupsTextNode = <span className={`group-cell-text`}>{groupNames}</span>;
    const groupsNode = <GroupsDropdown buttonText={groupsTextNode} options={Object.values(groupNameMap)} select={selectGroups(index)} selected={groups} openCreateGroupModal={openCreateModal(index)} openSearchGroupModal={openSearchModal(index)} />;
    const selectStatus = (newStatus) => {
      if (!editingRows[index]) {
        openEditing();
      }
      const newRows = [...rows];
      newRows[index] = { ...newRows[index], status: newStatus };
      setRows(newRows);
    }
    const statusClass = `${status === ACTIVE.key ? 'active-button' : 'inactive-button'} ${status === DELETE.key ? 'delete' : ''}`;
    const statusText = Object.values(RECIPIENT_ACTIVE_STATUSES).find(({ key }) => key === status).displayText;
    const statusTextNode = <span className={`button-text ${statusClass}`}>{statusText}</span>;
    const statusNode = <TableDropdown buttonText={statusTextNode} options={Object.values(RECIPIENT_ACTIVE_STATUSES)} select={selectStatus} selected={status} />;
    
    const deliverabilityStatus = undeliverable ? UNDELIVERABLE.key : deliverability;
    const isSubscribed = deliverabilityStatus === SUBSCRIBED.key;
    const isOptedOut = deliverabilityStatus === OPTED_OUT.key;
    const selectDeliverability = (newDeliverablityStatus) => {
      if (!editingRows[index]) {
        openEditing();
      }
      const newRows = [...rows];
      newRows[index] = { ...newRows[index], deliverability: newDeliverablityStatus };
      setRows(newRows);
    }
    const deliverabilityClass = `${undeliverable ? 'undeliverable' : ''} ${isSubscribed ? 'subscribed' : ''} ${isOptedOut ? 'opted-out' : ''}`;
    const deliverabilityTextNode = <span className={`button-text ${deliverabilityClass}`}>{deliverabilityStatus}</span>;
    const deliverabilityOptions = Object.values(DELIVERABILITY).filter(({ key }) => key !== UNDELIVERABLE.key);
    const deliverabilityNode = <TableDropdown buttonText={deliverabilityTextNode} options={deliverabilityOptions} select={selectDeliverability} selected={deliverabilityStatus} disabled={undeliverable} />;

    const userAccessKey = userAccess ? userAccess : RECIPIENT_USER_ACCESS.NONE.key;
    const userAccessDisplayText = Object.values(RECIPIENT_USER_ACCESS).find(({ key }) => key === userAccessKey).displayText;
    const userAccessButtonNode = <span className={'button-text user-access'}>{userAccessDisplayText}</span>
    const selectUserAccess = (newUserAccess) => {
      if (!editingRows[index]) {
        openEditing();
      }
      const newRows = [...rows];
      newRows[index] = { ...newRows[index], userAccess: newUserAccess };
      setRows(newRows);
    };
    const userAccessNode = <TableDropdown buttonText={userAccessButtonNode} options={Object.values(RECIPIENT_USER_ACCESS)} select={selectUserAccess} selected={userAccess} />;
    return {
      firstName: firstNameNode,
      lastName: lastNameNode,
      roleType: roleTypeNode,
      groups: groupsNode,
      mobileNumber: mobileNumberNode,
      emailAddress: emailAddressNode,
      managers: managerNameNode,
      address: addressNode,
      birthday: birthdayNode,
      status: statusNode,
      deliverability: deliverabilityNode,
      userAccess: userAccessNode,
      onboarding: onboardingNode,
    };
  });
  const addRecipient = () => {
    const newRows = [...rows, { ...EMPTY_RECIPIENT, _id: uniqueId(), isNew: true }];
    const newEditingRows = [...editingRows, true];
    setRows(newRows);
    setEditingRows(newEditingRows);
  }

  const selectedGroups = selectedIndex !== null ? rows[selectedIndex].groups.map(group => groupNameMap[group]) : [];

  return (
    <ManagerTable headers={EMPLOYEE_HEADERS} rows={generatedRows} noRowsText={'No employees have been added yet. Import your employees CSV or add employees manually below.'}>
      <button className={'add-recipient-button'} onClick={addRecipient}>{'+ Add employees'}</button>
      {openCreateGroupModal && <AddGroupsModal closeModal={closeCreateModal} selectGroup={selectGroups(selectedIndex)} selectedGroups={selectedGroups} /> }
      {openSearchGroupModal && <SearchGroupsModal closeModal={closeSearchModal} selectGroup={selectGroups(selectedIndex)} selectedGroups={selectedGroups} /> }
    </ManagerTable>
  )
}

RecipientsListView.displayName = 'RecipientsListView';
RecipientsListView.propTypes = propTypes;
export default RecipientsListView;
