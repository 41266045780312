import './AutomationList.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AutomationCard from './AutomationCard';

import ArchiveAutomationModal from '../modals/ArchiveAutomationModal';
import SupportRequestAutomationModal from '../modals/SupportRequestAutomationModal';
import AutomationRequestModal from '../modals/AutomationRequestModal';
import useAuth from '../../../../../../context/AuthContext';
import { postAutomationArchive, postAutomationSupport, postAutomationTemplateRequest } from '../../../../../../apis/Api';

const propTypes = {};

function AutomationList({ automations }) {
  const { setUser } = useAuth();
  const [archiveModalId, setArchiveModalId] = useState(null);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const closeArchiveModal = () => setArchiveModalId(null);
  const closeSupportModal = () => setSupportModalOpen(false);
  const closeRequestModal = () => setRequestModalOpen(false);
  const requestAutomation = (key, title, description) => {
    postAutomationTemplateRequest({ key, title, description })
      .then((response) => {
        if (response.user) {
          setUser(response.user);
        }
      });
    setRequestModalOpen(true);
  }
  const automationList = automations.map(({ label, description, active, type, _id, title, recommendedUrl, objective, key }) => {
    const archive = (automationId) => {
      setArchiveModalId(automationId)
    };
    const requestSupport = (automationId, type) => {
      postAutomationSupport(automationId, type);
      setSupportModalOpen(true);
    }
    const automationId = _id ? _id : key;
    return (
      <AutomationCard
        description={description}
        label={label}
        active={active}
        type={type}
        key={`${description}-${_id}`}
        automationId={automationId}
        archive={archive}
        requestSupport={requestSupport}
        request={requestAutomation}
        title={title}
        recommendedUrl={recommendedUrl}
        objective={objective}
      />
    );
  });
  const archiveAutomation = () => {
    postAutomationArchive(archiveModalId)
      .then((response) => {
        if (response.user) {
          setUser(response.user);
          closeArchiveModal();
        }
      });
  }
  const automation = automations.find(({ _id }) => _id === archiveModalId);
  const type = archiveModalId !== null && automation ? automation.type : null;
  return (
    <ul className={'automation-list'}>
      { automation && <ArchiveAutomationModal type={type} close={closeArchiveModal} archive={archiveAutomation} /> }
      { supportModalOpen && <SupportRequestAutomationModal close={closeSupportModal} /> }
      { requestModalOpen && <AutomationRequestModal close={closeRequestModal} /> }
      {automationList}
    </ul>
  );
}

AutomationList.displayName = 'AutomationList';
AutomationList.propTypes = propTypes;
export default AutomationList;