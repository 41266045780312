import './MessagingNavigation.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { MESSAGING_VIEWS } from '../../MessagingConstants';

import MessagingNavDropdown from './dropdown/MessagingNavDropdown';
import GroupsDropdown from './groups/GroupsDropdown';

const propTypes = {};

function MessagingNavigation({ navigationView, setNavigationView, viewFilters, setBroadcastList, setViewFilters, setSelectedRecord }) {
  const [initialLoad, setInitialLoad] = useState(false);
  return (
    <section className={'messaging-body-navigation'}>
      <GroupsDropdown />
      {Object.keys(MESSAGING_VIEWS).map(view => 
        <MessagingNavDropdown
          key={MESSAGING_VIEWS[view].key}
          initialLoad={initialLoad}
          setInitialLoad={setInitialLoad}
          navigationView={navigationView}
          setNavigationView={setNavigationView}
          view={MESSAGING_VIEWS[view]}
          viewFilters={viewFilters}
          setBroadcastList={setBroadcastList}
          setViewFilters={setViewFilters}
          setSelectedRecord={setSelectedRecord}
        />)
      }
    </section>
  )
}

MessagingNavigation.displayName = 'MessagingNavigation';
MessagingNavigation.propTypes = propTypes;
export default MessagingNavigation;