import './LoginHeader.scss';

import React from 'react';

import { ASSET_CDN_URL } from '../../../../Constants';

const logo = `${ASSET_CDN_URL}/logo/workRex_Yellow_Logo.svg`;

function LoginHeader({ headerTitle, headerSubtitle }) {
  return (
    <header className={'login-header'}>
      <img alt={'yellow-rex-logo'} className={'login-rex-logo'} src={logo} />
      <h1 className={'login-title'}>{headerTitle}</h1>
      <p className={'login-subtitle'}>{headerSubtitle}</p>
    </header>
  )
}

export default LoginHeader;