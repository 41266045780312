import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useAuth from './AuthContext';
import { useGetAllBroadcasts, useGetAllTemplates } from '../hooks/DataHooks'
import { getUserVisibleRawData, getUserVisibleRawDataInitialLoad } from '../apis/Api';
import { calculateDerivedFields, getRelatedBroadcasts, getRelatedInteractions } from '../utils/DataUtils';

const MessagingContext = createContext();

const propTypes = {
  /* Children to display. */
  children: PropTypes.node.isRequired,
};

// TODO: Refactor We're using too many variables in this particular messaging provider: let's break these up
// TODO: Add documentation here
export function MessagingProvider({ children }) {
  const { authed, user } = useAuth();

  const [broadcasts, setBroadcasts] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [integrationUser, setIntegrationUser] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [interactions, setInteractions] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [initialLoad, setInitialLoad] = useState(false);

  useGetAllBroadcasts(broadcasts, setBroadcasts, selectedGroup);
  useGetAllTemplates(templates, setTemplates);

  const setGroup = (groupId) => {
    setSelectedGroup(groupId);
    window.localStorage.setItem('selectedGroupId', groupId._id);
  }

  useEffect(() => {
    if (!initialLoad && user && user.groups !== null && selectedGroup === null) {
      const previouslySelectedGroup = user.groups.find(group => group._id === window.localStorage.getItem('selectedGroupId'));
      const noPreviouslySelectedGroup = user.groups[0];
      const groupIdToSet = previouslySelectedGroup ? previouslySelectedGroup : noPreviouslySelectedGroup;
      setGroup(groupIdToSet);
    }
  }, [user, selectedGroup, initialLoad]);
  
  useEffect(() => {
    if (!initialLoad && user && user.groups !== null && selectedGroup === null) {
      const previouslySelectedGroup = user.groups.find(group => group._id === window.localStorage.getItem('selectedGroupId'));
      const noPreviouslySelectedGroup = user.groups[0];
      const groupIdToSet = previouslySelectedGroup ? previouslySelectedGroup : noPreviouslySelectedGroup;
      setGroup(groupIdToSet);
    }
  }, [user, selectedGroup, initialLoad]);

  useEffect(() => {
    if (authed && !initialLoad && selectedGroup && broadcasts) {
      getUserVisibleRawDataInitialLoad(selectedGroup._id).then((response) => {
        console.log(response);
        const initialLoadCustomers = response.customers
          .filter(({ deletionDate }) => !deletionDate)
          .map((customer) => {
            const relatedInteractions = getRelatedInteractions(customer, response.interactions);
            const relatedBroadcasts = getRelatedBroadcasts(customer, broadcasts);
            const formattedBirthday = customer.birthday ? new Date(customer.birthday).toLocaleString('en-au', { day: 'numeric', month: 'numeric', year: 'numeric' }) : null;
            return calculateDerivedFields({ ...customer, formattedBirthday, interactions: relatedInteractions, broadcasts: relatedBroadcasts });
          })
          .sort((a, b) => a.firstName - b.firstName);
        setCustomers(initialLoadCustomers);
        setEmployees(response.employees.sort((a, b) => a.firstName - b.firstName));
        setIntegrationUser(response.integrationUser);
        setInteractions(response.interactions);
        setTasks(response.tasks);
        
        getUserVisibleRawData(selectedGroup._id).then((response) => {
          console.log(response);
          const loadCustomers = response.customers
          .filter(({ deletionDate }) => !deletionDate)
          .map((customer) => {
            const relatedInteractions = getRelatedInteractions(customer, response.interactions);
            const relatedBroadcasts = getRelatedBroadcasts(customer, broadcasts);
            const formattedBirthday = customer.birthday ? new Date(customer.birthday).toLocaleString('en-au', { day: 'numeric', month: 'numeric', year: 'numeric' }) : null;
            return calculateDerivedFields({ ...customer, formattedBirthday, interactions: relatedInteractions, broadcasts: relatedBroadcasts });
          })
          .sort((a, b) => a.firstName - b.firstName);
          setCustomers(loadCustomers);
          setEmployees(response.employees.sort((a, b) => a.firstName - b.firstName));
          setIntegrationUser(response.integrationUser);
          setInteractions(response.interactions);
          setTasks(response.tasks);
        })
        .finally(() => {
          setInitialLoad(true);
        })
        .catch(() => setCustomers([]));
      })
      .finally(() => {
        setInitialLoad(true);
      })
      .catch(() => setCustomers([]));
    }
  }, [authed, selectedGroup, initialLoad, broadcasts]);

  useEffect(() => {
    if (customers !== null && employees !== null) {
      setContacts([ ...customers, ...employees ].sort((a, b) => a.firstName - b.firstName));
    }
  }, [customers, employees]);

  return (
    <MessagingContext.Provider value={{
      broadcasts,
      contacts,
      customers,
      employees,
      interactions,
      integrationUser,
      selectedGroup,
      tasks,
      templates,
      setBroadcasts,
      setCustomers,
      setEmployees,
      setContacts,
      setInitialLoad,
      setInteractions,
      setGroup,
      setTasks,
    }}>
      {children}
    </MessagingContext.Provider>
  );
}

const MessagingConsumer = () => useContext(MessagingContext);

MessagingConsumer.displayName = 'MessagingConsumer';
MessagingConsumer.propTypes = propTypes;
export default MessagingConsumer;