import './InputContainer.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function InputContainer({ label, children, additionalClassNames = [] }) {
  return (
    <section className={`input-container ${additionalClassNames.join(' ')}`}>
      <h5 className={'label'}>{label}</h5>
      {children}
    </section>
  )
}

InputContainer.displayName = 'InputContainer';
InputContainer.propTypes = propTypes;
export default InputContainer;