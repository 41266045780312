import './Task.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../../Constants';
import { REPEAT_MAP } from '../../../../MessagingConstants';
import { postManyTaskCompletion } from '../../../../../../../../apis/Api';
import useAuth from '../../../../../../../../context/AuthContext';

import GenericLabel from '../../../list/views/GenericLabel';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';

const { NONE } = REPEAT_MAP;

const propTypes = {};

const uncheckedCompleteIcon = `${ASSET_CDN_URL}/icons/rex-unchecked-complete-icon.svg`;
const checkedCompleteIcon = `${ASSET_CDN_URL}/icons/rex-checked-complete-icon.svg`;

function Task({ task, setSelectedRecord }) {
  const { label, assignedTo, dueDate, taggedTo, frequency, completed } = task;
  const { user } = useAuth();
  const { setTasks } = MessagingConsumer();

  const dueDateType = new Date(dueDate);
  const dueDateString = `${dueDateType.getDate()}/${dueDateType.getMonth() + 1}/${dueDateType.getFullYear()}`;

  const updatePostComplete = () => {
    const updatedTask = [{ ...task, completed: !completed }];
    postManyTaskCompletion(updatedTask).then(response => {
      let newTaskList = [];
      setTasks(prevState => {
        const filteredTasks = prevState.filter(prevTasks => prevTasks._id !== task._id);
        newTaskList = [ ...filteredTasks, ...response.tasks ];
        return newTaskList;
      });
      setSelectedRecord(newTaskList);
    })
  }

  const checkboxIcon = completed ? checkedCompleteIcon : uncheckedCompleteIcon;
  const assignedToText = assignedTo ? `Assigned to ${assignedTo.firstName} ${assignedTo.lastName}` : 'Unassigned';

  return (
    <button className={'task-card'} type={'button'}>
      <button className={'task-icon-container'} onClick={() => updatePostComplete()} type={'button'}>
        <img alt={'task-type'} className={'task-type-icon'} src={checkboxIcon} />
      </button>
      <section className={'task-details'}>
        <section className={'task-heading'}>
          <span className={'task-due'}>{dueDateString}</span>
          <p className={'task-label'}>{label}</p>
        </section>
        <section className={'task-assigned-details'}>
          <GenericLabel additionalClassNames={['task-assigned']} text={assignedToText} />
          {taggedTo && <GenericLabel additionalClassNames={['task-tagged']} text={`Tagged to ${taggedTo.firstName} ${taggedTo.lastName}`} />}
          {frequency !== NONE.key && <GenericLabel additionalClassNames={[]} text={REPEAT_MAP[frequency].text} />}
        </section>
      </section>
    </button>
  )
}

Task.displayName = 'Task';
Task.propTypes = propTypes;
export default Task;