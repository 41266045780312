import './TaskColumn.scss';

import React from 'react';
import PropTypes from 'prop-types';

import TaskCard from './TaskCard';

const propTypes = {};

function TaskColumn({ title, tasks, setStatus, additionalClassNames }) {
  const taskList = tasks.map(task => {
    const toggleComplete = (isComplete) => setStatus(task, isComplete);
    
    return (
      <TaskCard
        task={task}
        key={task._id}
        toggleComplete={toggleComplete}
      />
    );
  })
  return (
    <section className={`task-column ${additionalClassNames.join(' ')}`}>
      <h4 className={'column-title'}>{`${title} (${tasks.length})`}</h4>
      <ul className={'task-list'}>
        {taskList}
      </ul>
    </section>
  );
}

TaskColumn.displayName = 'TaskColumn';
TaskColumn.propTypes = propTypes;
export default TaskColumn;