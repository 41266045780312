import './FilterButtonList.scss';

import React from 'react';
import PropTypes from 'prop-types';

import FilterButton from './FilterButton';

const propTypes = {};

function FilterButtonList({ filters }) {
  const filterList = filters.map(({ buttonText, icon, onClick, selected, additionalComponent = null }) => {
    return (
      <li className={'filter-button-item'} key={buttonText}>
        <FilterButton
          buttonText={buttonText}
          icon={icon}
          onClick={onClick}
          selected={selected}
          additionalComponent={additionalComponent} />
      </li>
    );
  });
  return (
    <ul className={'filter-button-list'}>
      {filterList}
    </ul>
  )
}

FilterButtonList.displayName = 'FilterButtonList';
FilterButtonList.propTypes = propTypes;
export default FilterButtonList;