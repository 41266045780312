import './SupportRequestAutomationModal.scss';

import React from 'react';
import PropTypes from 'prop-types';
import WorkRexModal from '../../../common/WorkRexModal';

const propTypes = {};

function SupportRequestAutomationModal({ close }) {
  return (
    <WorkRexModal close={close} additionalClassNames={['support-request-automation-modal']}>
      <p className={'description'}>
        {'Your request for further support has been submitted. The WorkRex team will be in touch to provide you with further support.'}
      </p>
    </WorkRexModal>
  )
}

SupportRequestAutomationModal.displayName = 'SupportRequestAutomationModal';
SupportRequestAutomationModal.propTypes = propTypes;
export default SupportRequestAutomationModal;