export const SORT_OPTIONS = {
  recentReplies: {
    displayText: 'Recently received',
    key: 'recentReplies',
  },
  recentlySent: {
    displayText: 'Recently sent',
    key: 'recentlySent',
  },
  firstName: {
    displayText: 'First name',
    key: 'firstName',
  },
  lastName: {
    displayText: 'Last name',
    key: 'lastName',
  },
};