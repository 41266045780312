import { postData } from './Api';

const ASSISTANT_ROUTES = {
  ACTIVATE_ASSISTANT_URL: '/api/assistant/activate',
  DEACTIVATE_ASSISTANT_URL: '/api/assistant/deactivate',
  REQUEST_ASSISTANT_URL: '/api/assistant/request',
};

const { ACTIVATE_ASSISTANT_URL, DEACTIVATE_ASSISTANT_URL, REQUEST_ASSISTANT_URL } = ASSISTANT_ROUTES;

export const postActivateAssistant = (key, context) => {
  return postData(ACTIVATE_ASSISTANT_URL, { key, context });
}

export const postDeactivateAssistant = (key) => {
  return postData(DEACTIVATE_ASSISTANT_URL, { key });
}

export const postAssistantRequest = (request) => {
  return postData(REQUEST_ASSISTANT_URL, { request });
};
