import './TasksList.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DotBricksLoading from '../../../../../../../common/DotBricksLoading';
import Task from './Task';

const propTypes = {};

function TasksList({ tasks, subtitle, setSelectedRecord }) {

  if (tasks === null) {
    return <DotBricksLoading isFixed={false} />
  }
  return (
    <section className={'tasks-list-container'}>
      <section className={'tasks-list-status'}>
        <p className={'tasks-status-subtitle'}>{`${subtitle} (${tasks.length})`}</p>
      </section>
      {tasks.length > 0 && tasks.map(task => <Task key={task._id} task={task} setSelectedRecord={setSelectedRecord} />)}
    </section>
  )
}

TasksList.displayName = 'TasksList';
TasksList.propTypes = propTypes;
export default TasksList;