import './TaskHistoryRow.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function TaskHistoryRow({ taskHistory }) {
  const { creationDate, description, createdBy } = taskHistory;
  const { firstName, lastName } = createdBy;

  const createdDateType = new Date(creationDate);
  const createdDateString = `${createdDateType.getDate()}/${createdDateType.getMonth() + 1}/${createdDateType.getFullYear()}`

  return (
    <section className={'task-history-row'}>
      <span className={'task-history-date'}>{createdDateString}</span>
      <span className={'task-history-description'}>{description}</span>
      <span className={'task-history-user'}>{`${firstName} ${lastName}`}</span>
    </section>
  )
}

TaskHistoryRow.displayName = 'TaskHistoryRow';
TaskHistoryRow.propTypes = propTypes;
export default TaskHistoryRow;