export const DEFAULT_WORKBOOK_FIELDS = [
  {
    key: "firstName",
    label: "First Name",
    type: "string",
    constraints: [
      { type: 'required' }
    ]
  },
  {
    key: "lastName",
    label: "Last Name",
    type: "string",
    constraints: [
      { type: 'required' }
    ]
  },
  {
    key: "emailAddress",
    label: "Email Address",
    type: "string"
  },
  {
    key: "mobileNumber",
    label: "Mobile Number",
    type: "number"
  },
  {
    key: "group",
    label: "Group",
    type: "string"
  },
  {
    key: "deliverability",
    label: "Deliverabilty",
    type: "enum",
    constraints: [
      { type: 'required' }
    ],
    config: {
      options: [
        {
          value: "Subscribed",
          label: "Subscribed",
          color: "#ff0000",
          icon: "fa fa-ban",
        },
        {
          value: "Opted out",
          label: "Opted out",
          color: "#ff0000",
          icon: "fa fa-ban",
        },
        {
          value: "Undeliverable",
          label: "Undeliverable",
          color: "#ff0000",
          icon: "fa fa-ban",
        }
      ]
    }
  },
  {
    key: "formattedBirthday",
    label: "Birthday",
    type: "string"
  },
];

export const interactions_workbook = {
  name: 'Interactions Data',
  labels: ['pinned'],
  sheets: [
    {
      name: 'Interactions Import',
      slug: 'interactions_import',
      fields: [
        {
          key: "firstName",
          label: "First Name",
          type: "string",
          constraints: [
            { type: 'required' }
          ]
        },
        {
          key: "lastName",
          label: "Last Name",
          type: "string",
          constraints: [
            { type: 'required' }
          ]
        },
        {
          key: "emailAddress",
          label: "Email Address",
          type: "string"
        },
        {
          key: "mobileNumber",
          label: "Mobile Number",
          type: "number"
        },
        {
          key: "interactionType",
          label: "Interaction Type",
          type: "enum",
          constraints: [
            { type: 'required' }
          ],
          config: {
            options: [
              {
                value: "APPOINTMENT",
                label: "Appointment",
                color: "#ff0000",
                icon: "fa fa-ban",
              },
              {
                value: "MEETING",
                label: "Meeting",
                color: "#ff0000",
                icon: "fa fa-ban",
              },
              {
                value: "TRANSACTION",
                label: "Transaction",
                color: "#ff0000",
                icon: "fa fa-ban",
              },
              {
                value: "CAMPAIGN",
                label: "Campaign",
                color: "#ff0000",
                icon: "fa fa-ban",
              },
              {
                value: "PHONE",
                label: "Phone call",
                color: "#ff0000",
                icon: "fa fa-ban",
              },
              {
                value: "WHATSAPP_SMS",
                label: "WhatsApp / SMS",
                color: "#ff0000",
                icon: "fa fa-ban",
              },
              {
                value: "EMAIL",
                label: "Email",
                color: "#ff0000",
                icon: "fa fa-ban",
              },
              {
                value: 'VISIT',
                label: 'Visit',
                color: '#ff0000',
                icon: 'fa fa-ban',
              },
              {
                value: 'PURCHASE',
                label: 'Purchase',
                color: '#ff0000',
                icon: 'fa fa-ban',
              },
              {
                value: "OTHER",
                label: "Other",
                color: "#ff0000",
                icon: "fa fa-ban",
              },
            ]
          }
        },
        {
          key: "description",
          label: "Description",
          type: "string"
        },
        {
          key: 'date',
          label: 'Date',
          type: 'date',
          constraints: [
            { type: 'required' }
          ],
        },
        {
          key: "transactionValue",
          label: "Amount",
          type: "number"
        },
        {
          key: "transactionCurrency",
          label: "Currency",
          type: "string"
        },
        {
          key: "referenceId",
          label: "Reference Id",
          type: "string"
        },
        {
          key: "recipientId",
          label: "Recipient Id",
          type: "string"
        },
        {
          key: 'done',
          label: 'Done',
          type: 'boolean',
        }
      ]
    }
  ],
  actions: [
    {
      operation: 'syncInteractions',
      mode: 'foreground',
      label: 'Sync Interactions',
      description: 'Sync data to database',
      primary: true,
      constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
      tooltip: 'Click to run action',
    },
    {
      operation: 'submitInteractions',
      mode: 'foreground',
      label: 'Submit Interactions',
      description: 'Submit data to database',
      primary: true,
      constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
      tooltip: 'Click to run action',
    }
  ]
}

export const customer_workbook = {
  name: "Customer Data",
  labels: ["pinned"],
  sheets: [
    {
      name: "Customer Import",
      slug: "customer_import",
      fields: []
    }
  ],
  actions: [
    {
      operation: "submitCustomers",
      mode: "foreground",
      label: "Submit Customers",
      description: "Submit data to database",
      primary: true,
      constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
      tooltip: 'Click to run action'
    },
  ],
}

export const employee_workbook = {
  name: "Employee Data",
  labels: ["pinned"],
  sheets: [
    {
      name: "Employee Import",
      slug: "employee_import",
      fields: []
    }
  ],
  actions: [
    {
      operation: "submitEmployees",
      mode: "foreground",
      label: "Submit Employees",
      description: "Submit data to database",
      primary: true,
      constraints: [{ type: 'hasAllValid' }, { type: 'hasData' }],
      tooltip: 'Click to run action'
    },
  ],
}

export const interactionTypeField = {

}

export const roleTypeField = {
  key: "roleType",
  label: "Role",
  type: "string"
};

export const groupField = {
  key: "group",
  label: "Group",
  type: "enum",
  constraints: [
    { type: 'required' }
  ],
  config: {
    options: []
  }
};

export const additionalFields = {
  address: {
    key: "address",
    label: "Address",
    type: "string"
  },
  source: {
    key: "source",
    label: "Source",
    type: "string"
  },
  favouriteBrands: {
    key: "favouriteBrands",
    label: "Favourite Brands",
    type: "string"
  },
  customerSegmentCategory: {
    key: "customerSegmentCategory",
    label: "Customer Segment Category",
    type: "string"
  }
};