import './CampaignsPage.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet, useLocation } from 'react-router-dom';

import CampaignPageWrapper from './CampaignPageWrapper';
import CampaignTypeNavigationTabs from './common/CampaignTypeNavigationTabs';
import { CAMPAIGN_PAGE_TYPES, EMPTY_CAMPAIGN } from './CampaignConstants';

const { PUBLISHED, DRAFT, LIBRARY } = CAMPAIGN_PAGE_TYPES;

const propTypes = {};

function CampaignsPage({ campaignCounts }) {
  const previousCampaign = window.localStorage.getItem('campaign');
  const { pathname } = useLocation();
  const [campaign, setCampaign] = useState(previousCampaign ? JSON.parse(previousCampaign) : EMPTY_CAMPAIGN);
  const saveCampaign = (campaign) => {
    window.localStorage.setItem('campaign', JSON.stringify(campaign));
    setCampaign(campaign);
  }
  let showTabs = true;
  let selectedTab = null;
  const options = [];
  if (pathname.includes('published')) {
    selectedTab = PUBLISHED;
    options.push({ displayName: 'Your published campaigns', href: `/campaigns/published` });
  }
  else if (pathname.includes('draft')) {
    selectedTab = DRAFT;
    options.push({ displayName: 'Your draft campaigns', href: `/campaigns/draft` });
  }
  else if (pathname.includes('library')) {
    selectedTab = LIBRARY;
    options.push({ displayName: 'WorkRex campaign library', href: `/campaigns/library` });
  }

  if (pathname.endsWith('/create') || pathname.includes('/create/')) {
    showTabs = false;
    options.push({ displayName: 'Create new campaign', href: `/campaigns/create` });
  }
  if (pathname.endsWith('/recipients')) {
    showTabs = false;
    options.push({ displayName: 'Add recipients', href: `/campaigns/recipients` });
  }
  if (pathname.endsWith('/ai')) {
    showTabs = false;
    options.push({ displayName: 'Create with AI', href: `/campaigns/create/ai` });
  }
  else if (pathname.endsWith('/new')) {
    showTabs = false;
    options.push({ displayName: 'Create from template', href: `/campaigns/create/new` });
  }
  else if (pathname.endsWith('/new/channels')) {
    showTabs = false;
    options.push({ displayName: 'Create from template', href: `/campaigns/create/new` });
    options.push({ displayName: 'Customize channels', href: `/campaigns/create/new/channels` });
  }
  else if (pathname.endsWith('/ai/channels')) {
    showTabs = false;
    options.push({ displayName: 'Create with AI', href: `/campaigns/create/ai` });
    options.push({ displayName: 'Customize channels', href: `/campaigns/create/ai/channels` });
  }
  return (
    <CampaignPageWrapper showTabs={showTabs} additionalOptions={options}>
      { showTabs && <CampaignTypeNavigationTabs selectedTab={selectedTab} campaignCounts={campaignCounts} /> }
      <Outlet context={{ campaign, setCampaign: saveCampaign }} />
    </CampaignPageWrapper>
  );
}

CampaignsPage.displayName = 'CampaignsPage';
CampaignsPage.propTypes = propTypes;
export default CampaignsPage;