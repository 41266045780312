import './VerifyPage.scss';

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { postLogin } from '../../../apis/LoginApi';
import { LOGIN_METHODS } from './LoginPage';
import LoginWrapper from './shared/LoginWrapper';
import LoginHeader from './shared/LoginHeader';
import VerificationCodeInput from './VerificationCodeInput';

const getCensoredText = (loginDetails, loginMethod) => {
  if  (loginMethod === LOGIN_METHODS.SMS) {
    const { sms } = loginDetails;
    return `${'*'.repeat(sms.length - 3)}${sms.substring(sms.length - 3, sms.length)}`;
  }
  else {
    const { email } = loginDetails;
    const emailSplit = email.split('@');
    const emailStart = emailSplit[0];
    const asterixes = '*'.repeat(emailStart.length - 1);
    return `${emailStart[0]}${asterixes}@${emailSplit[1]}`;
  }
}

function VerifyPage({ loginDetails, setLoginPage, loginMethod }) {
  const [searchParams, _] = useSearchParams();
  const skipVerificationCode = searchParams.get('skip_verification_code') || false;
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const censoredText = getCensoredText(loginDetails, loginMethod);
  const isSMS = loginMethod === LOGIN_METHODS.SMS;
  const verificationSubtitle = isSMS
    ? `We've just sent a text message with a fresh verification code to the phone number ending in ${censoredText}.`
    : `We've just sent an email with a fresh verification code to the email address at ${censoredText}.`

  const verificationTitle = 'Enter the verification code.';

  const resendVerificationCode = () => {
    setLoading(true);
    postLogin(loginDetails[loginMethod], loginMethod, skipVerificationCode)
      .then((response) => {
        if (response.success) {
          setError('');
          setVerificationCode('');
        }
        setLoading(false);
      })
      .catch(e => console.error(e))
      .finally(setError(''));
  }

  const returnToLoginPage = () => setLoginPage(true);
  return (
    <LoginWrapper>
      <LoginHeader headerTitle={verificationTitle} headerSubtitle={verificationSubtitle} />
      <section className={'verify-container'}>
        <p className={'sms-validation-error'}>{error}</p>
        <VerificationCodeInput
          error={error}
          loginDetails={loginDetails}
          loginMethod={loginMethod}
          setError={setError}
          verificationCode={verificationCode}
          setVerificationCode={setVerificationCode}
        />
        <button className={'resend-button'} onClick={resendVerificationCode} type={'button'} disabled={loading}>{'Click here to resend...'}</button>
        <button className={'new-verification-method'} onClick={returnToLoginPage} type={'button'}>{'Choose another method'}</button>
      </section>
    </LoginWrapper>
  )
}

export default VerifyPage;
