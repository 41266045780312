import './TableDropdown.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useOutsideClick } from '../../../../hooks/ClickHooks';

import DROPDOWN_ICON from './assets/grey-dropdown-icon.svg';

const propTypes = {};

function TableDropdown({ buttonText, options, select, selected, disabled }) {
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!open);
  const dropdownList = options.map(({ key, displayText }) => {
    const selectKey = () => {
      select(key);
      setOpen(false);
    };
    const selectedClass = selected === key ? 'selected' : ''
    return (
      <li className={'option-item'} key={key}>
        <button className={`option-selection ${selectedClass}`} onClick={selectKey}>
          <span className={'option-text'}>{displayText}</span>
        </button>
      </li>
    )
  });
  useOutsideClick(dropdownRef, () => setOpen(false));
  const isOpen = open && options.length > 0;
  return (
    <section className={`table-dropdown-container ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
      <button className={`table-dropdown-selector ${disabled ? 'disabled' : ''}`} onClick={toggleDropdown} disabled={disabled}>
        {buttonText}
        { !disabled && <img className={'dropdown-arrow'} src={DROPDOWN_ICON} /> }
      </button>
      {
        isOpen && (
          <section className={'table-dropdown-options'}>
            <ul className={'table-dropdown-list'}>
              {dropdownList}
            </ul>
          </section>
        )
      }
    </section>
  );
}

TableDropdown.displayName = 'TableDropdown';
TableDropdown.propTypes = propTypes;
export default TableDropdown;