import './ChannelSelectionButton.scss';

import React, { useState } from 'react';

function ChannelSelectionButton({ displayText, selectedIcon, unselectedIcon, selected, onClick, disabled, tooltipText, hideTooltip = false, viewOnly = false }) {
  const icon = selected ? selectedIcon : unselectedIcon;
  const tooltip = disabled ? 'This channel is not available for use yet' : tooltipText;
  const select = disabled ? () => {} : onClick;
  return (
    <button
      className={`channel-selection-button ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''} ${viewOnly ? 'view-only' : ''}`}
      onClick={select}
      disabled={disabled || viewOnly}
    >
      { !hideTooltip &&
        <span className={'hover-tooltip-container'}>
          <span className={'hover-tooltip'}>{tooltip}</span>
        </span>
      }
      <img className={'icon'} src={icon} alt={''} />
      <span className={'button-text'}>{displayText}</span>
    </button>
  );
};

ChannelSelectionButton.displayName = 'ChannelSelectionButton';
export default ChannelSelectionButton;