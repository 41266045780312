import './FilterDashboard.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { FILTER_FIELDS } from './FilterConstants';

import FilterRow from './FilterRow';

const { ATTRIBUTE_FIELD, CONDITION_FIELD, VALUE_FIELD } = FILTER_FIELDS;

const propTypes = {};

function FilterDashboard({ filters, setFilters, containerToggle, applyFilters }) {
  const addNewFilter = () => {
    const newFilterRow = {
      filterId: filters.length + 1,
      attribute: null,
      condition: null,
      value: null,
    };
    if (filters.length === 0) {
      setFilters([newFilterRow])
    }
    else {
      setFilters([ ...filters, newFilterRow ]);
    }
  }

  const updateFilter = (filterField) => (filterId) => (value) => {
    console.log(filterField);
    console.log(filterId);
    console.log(value);
    const filterToUpdate = filters.find(filter => filter.filterId === filterId);
    
    if (filterField === ATTRIBUTE_FIELD) {
      filterToUpdate[CONDITION_FIELD] = null;
      filterToUpdate[VALUE_FIELD] = null;
    }
    else if (filterField === CONDITION_FIELD) {
      filterToUpdate[VALUE_FIELD] = null;
    }
    filterToUpdate[filterField] = value;
    const unchangedFilters = filters.filter(filter => filter.filterId !== filterId);
    const updatedFilters = [ ...unchangedFilters, filterToUpdate ].sort((a, b) => a.filterId - b.filterId);
    setFilters(updatedFilters);
    console.log(updatedFilters);
  }

  const deleteFilter = (filterId) => () => {
    const updatedFilters = filters.filter(filter => filter.filterId !== filterId);
    setFilters(updatedFilters);
  }

  const resetFilters = () => setFilters([]);

  const filterContacts = () => {
    applyFilters();
    containerToggle(true);
  }

  const filterDisplays = filters.map(filter => 
    <FilterRow
      filter={filter}
      setFilters={setFilters}
      containerToggle={containerToggle}
      deleteFilter={deleteFilter(filter.filterId)}
      updateFilter={updateFilter}
    />
  )

  return (
    <section className={'filter-dashboard'}>
      <ul className={'filter-list'}>
        {filterDisplays}
      </ul>
      <section className={'filter-control-buttons'}>
        <button className={'new-filter-button'} onClick={addNewFilter} type={'button'}>{'New Filter +'}</button>
        <section className={'filter-application-buttons'}>
          <button className={'reset-filter-button'} onClick={resetFilters} type={'button'}>{'Reset'}</button>
          <button className={'apply-filter-button'} onClick={filterContacts} type={'button'}>{'Apply'}</button>
        </section>
      </section>
    </section>
  )
}

FilterDashboard.displayText = 'FilterDashboard';
FilterDashboard.propTypes = propTypes;
export default FilterDashboard;
