import './AutomationListContainer.scss';

import React, { useState } from 'react';

import AutomationSidebar from './AutomationSidebar';
import AutomationList from './AutomationList';
import { AUTOMATION_LABELS, AUTOMATION_TYPES } from '../AutomationsConstants';

import PURPLE_ICON from '../assets/purple-person-icon.svg';
import YELLOW_ICON from '../assets/yellow-person-icon.svg';

const { ALL_INDUSTRIES, OPTICAL_PRACTICES } = AUTOMATION_LABELS;
const { AVAILABLE, REQUESTED } = AUTOMATION_TYPES;

const propTypes = {};

function AutomationListContainer({ automations, isLibrary }) {
  const [searchText, setSearchText] = useState('');
  const [libraryFilters, setLibraryFilters] = useState([]);
  const [typeFilters, setTypeFilters] = useState([]);
  const selectOpticalPractices = () => {
    if (libraryFilters.includes(OPTICAL_PRACTICES)) {
      setLibraryFilters(libraryFilters.filter(s => s !== OPTICAL_PRACTICES));
    }
    else {
      setLibraryFilters([...libraryFilters, OPTICAL_PRACTICES]);
    }
  }
  const selectAllIndustries = () => {
    if (libraryFilters.includes(ALL_INDUSTRIES)) {
      setLibraryFilters(libraryFilters.filter(s => s !== ALL_INDUSTRIES));
    }
    else {
      setLibraryFilters([...libraryFilters, ALL_INDUSTRIES]);
    }
  }
  const selectAvailable = () => {
    if (typeFilters.includes(AVAILABLE.key)) {
      setTypeFilters(typeFilters.filter(s => s !== AVAILABLE.key));
    }
    else {
      setTypeFilters([...typeFilters, AVAILABLE.key]);
    }
  }
  const selectRequested = () => {
    if (typeFilters.includes(REQUESTED.key)) {
      setTypeFilters(typeFilters.filter(s => s !== REQUESTED.key));
    }
    else {
      setTypeFilters([...typeFilters, REQUESTED.key]);
    }
  }
  const moduleFilters = [
    {
      buttonText: 'Optical Practices',
      icon: PURPLE_ICON,
      selected: libraryFilters.includes(OPTICAL_PRACTICES),
      onClick: selectOpticalPractices,
    },
    {
      buttonText: 'All Industries',
      icon: YELLOW_ICON,
      selected: libraryFilters.includes(ALL_INDUSTRIES),
      onClick: selectAllIndustries,
    },
  ];
  const availableFilters = [
    {
      buttonText: 'Available',
      icon: PURPLE_ICON,
      selected: typeFilters.includes(AVAILABLE.key),
      onClick: selectAvailable,
    },
    {
      buttonText: 'Requested',
      icon: YELLOW_ICON,
      selected: typeFilters.includes(REQUESTED.key),
      onClick: selectRequested,
    },
  ]
  const filterList = isLibrary ? moduleFilters : availableFilters;
  const automationList = automations
    .filter(({ title, type }) => (type === REQUESTED.key) || title.toLowerCase().includes(searchText.toLowerCase()))
    .filter(({ description, type }) => (type !== REQUESTED.key) || description.toLowerCase().includes(searchText.toLowerCase()))
    .filter(({ label }) => !isLibrary || libraryFilters.length === 0 || libraryFilters.includes(label))
    .filter(({ type }) => isLibrary || typeFilters.length === 0 || typeFilters.includes(type));
  return (
    <section className={'automation-list-container'}>
      <AutomationSidebar searchText={searchText} setSearchText={setSearchText} filters={filterList} />
      <AutomationList automations={automationList} />
    </section>
  );
}

AutomationListContainer.displayName = 'AutomationListContainer';
AutomationListContainer.propTypes = propTypes;
export default AutomationListContainer;