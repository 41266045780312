import './DeleteCampaignModal.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import WorkRexModal from '../../../common/WorkRexModal';
import useAuth from '../../../../../../context/AuthContext';
import { postDeleteCampaign } from '../../../../../../apis/CampaignApi';

const propTypes = {};

function DeleteCampaignModal({ close, campaignId }) {
  const [loading, setLoading] = useState(false);
  const { setUser } = useAuth();
  const confirm = () => {
    setLoading(true);
    postDeleteCampaign(campaignId)
      .then(res => {
        setUser(res.user);
        setLoading(false);
        close();
      })
  }
  const deleteText = loading ? 'Deleting...' : 'Delete';
  return (
    <WorkRexModal close={close} additionalClassNames={['delete-campaign-modal']}>
      <p className={'description'}>
        {`Confirm deletion of this saved campaign. You will not be able to reverse this action.`}
      </p>
      <button className={`confirm-button ${loading ? 'loading': ''}`} onClick={confirm} disabled={loading}>{deleteText}</button>
    </WorkRexModal>
  );
}

DeleteCampaignModal.displayName = 'DeleteCampaignModal';
DeleteCampaignModal.propTypes = propTypes;
export default DeleteCampaignModal;