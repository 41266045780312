import './NewBroadcastListView.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useAuth from '../../../../../../../../context/AuthContext';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';
import { VALUE_TYPES } from './filterDropdown/FilterConstants';
import { filterContacts, searchFilterContacts } from '../../../MessagingHelpers';

import BroadcastContactsFilter from './filterDropdown/BroadcastContactsFilter';
import BroadcastContactCard from './BroadcastContactCard';
import DotBricksLoading from '../../../../../../../common/DotBricksLoading';
import SearchBar from '../search/SearchBar';
import BroadcastListWrapper from './BroadcastListWrapper';
import SearchButton from '../search/SearchButton';
import { sortContacts } from './sortDropdown/SortHelpers';
import SortDropdown from './sortDropdown/SortDropdown';

const SEARCH_ADDITIONAL_CLASSNAMES = ['new-broadcast', 'max-width'];
const { ARRAY, STRING, DATE, DROPDOWN } = VALUE_TYPES;

const propTypes = {};

function NewBroadcastListView({ broadcastList, setSelected, setBroadcastList }) {
  const { user } = useAuth(); 
  const groupMap = user.groups.reduce((groupMapping, group) => ({ ...groupMapping, [group._id]: group.name.toLowerCase() }), {});
  const { contacts } = MessagingConsumer();
  const [searchText, setSearchText] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [filteredContacts, setFilteredContacts] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const increasePageCount = () => setPageCount(pageCount + 1);

  const [sortBy, setSortBy] = useState(null);
  const [filters, setFilters] = useState([]);

  const [selectAll, setSelectAll] = useState(true);
  const selectAllText = selectAll ? 'Select all' : 'Deselect all';

  const closeSearchBar = () => {
    setOpenSearch(false);
    setSearchText('');
  }

  const applyFilters = (contacts, filters) => {
    const dropdownFilteredContacts = filterContacts(contacts, filters, groupMap);
    const searchFilteredContacts = searchFilterContacts(dropdownFilteredContacts, searchText, groupMap);
    return sortContacts(sortBy, searchFilteredContacts);
  }

  const search = (e) => {
    setSearchText(e.target.value);
  }

  const filterAndSetContacts = () => {
    setSearchText('');
    const dropdownFilteredContacts = applyFilters(contacts, filters);
    setFilteredContacts(dropdownFilteredContacts);
  }

  console.log({ contacts });

  const updateSelectedContacts = () => {
    setSelectAll(!selectAll)
    if (selectAll) {
      const contactIds = filteredContacts.map(contact => contact._id);
      setBroadcastList(contactIds);
    } else {
      setBroadcastList([]);
    }
  }

  useEffect(() => {
    if (contacts !== null) {
      const broadcastContacts = applyFilters(contacts, filters);
      setFilteredContacts(broadcastContacts);
    }
  }, [contacts])

  if (contacts === null || filteredContacts === null) {
    return <DotBricksLoading isFixed={false} />
  }

  console.log(filteredContacts);

  const notShowingAll = 100 * pageCount < filteredContacts.length;
  const broadcastView = filteredContacts.slice(0, 100 * pageCount).map(contact => <BroadcastContactCard key={contact._id} contact={contact} broadcastList={broadcastList} setSelected={setSelected} />)
  const searchComponent = openSearch
    ? <SearchBar additionalClassNames={SEARCH_ADDITIONAL_CLASSNAMES} searchText={searchText} search={search} placeholder={'Search'} setOpenSearch={closeSearchBar} />
    : <SearchButton setOpenSearch={setOpenSearch} searchText={searchText} />;
  return (
    <BroadcastListWrapper broadcastView={broadcastView} notShowingAll={notShowingAll} increasePageCount={increasePageCount}>
      <section className={'new-broadcast-header'}>
        <section className={'broadcast-filter-components'}>
          <BroadcastContactsFilter filters={filters} setFilters={setFilters} applyFilters={filterAndSetContacts} />
          <SortDropdown sortBy={sortBy} setSortBy={setSortBy} />
          {searchComponent}
        </section>
        <button className={'select-all-button'} onClick={() => updateSelectedContacts()} type={'button'}>{selectAllText}</button>
      </section>
    </BroadcastListWrapper>
  )
}

NewBroadcastListView.displayName = 'NewBroadcastListView';
NewBroadcastListView.propTypes = propTypes;
export default NewBroadcastListView;