import './MessagingPageWrapper.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import CommunicationsPageWrapper from '../CommunicationsPageWrapper';
import { COMMUNICATION_TABS } from '../CommunicationsConstants';

const { MESSAGING } = COMMUNICATION_TABS;

const propTypes = {};

function MessagingPageWrapper({ children }) {
  const { pathname } = useLocation();
  const options = [{ displayName: 'Messaging', href: '/messaging' }];

  if (pathname.includes('search')) {
    options.push({ displayName: 'Search', href: '/messaging/search' });
  }
  else if (pathname.includes('broadcasts')) {
    options.push({ displayName: 'Broadcasts', href: '/messaging/broadcasts' });
    if (pathname.includes('new')) {
      options.push({ displayName: 'Create a new broadcast', href: '/messaging/broadcasts/new' });
    }
    if (pathname.includes('upcoming')) {
      options.push({ displayName: 'View upcoming broadcasts', href: '/messaging/broadcasts/upcoming' });
    }
  }
  else if (pathname.includes('tasks')) {
    options.push({ displayName: 'Tasks', href: '/messaging/tasks' });
    if (pathname.includes('outstanding')) {
      options.push({ displayName: 'View outstanding tasks', href: '/messaging/tasks/outstanding' });
    }
    if (pathname.includes('completed')) {
      options.push({ displayName: 'View completed tasks', href: '/messaging/tasks/completed' });
    }
    if (pathname.includes('all')) {
      options.push({ displayName: 'View all tasks', href: '/messaging/tasks/all' });
    }
  }
  else if (pathname.includes('inbox')) {
    options.push({ displayName: 'Inbox', href: '/messaging/inbox' });
    if (pathname.includes('unread')) {
      options.push({ displayName: 'View unread messages', href: '/messaging/inbox/unread' });
    }
    if (pathname.includes('all')) {
      options.push({ displayName: 'View all messages', href: '/messaging/inbox/all' });
    }
  }
  else if (pathname.includes('customers')) {
    options.push({ displayName: 'Customers', href: '/messaging/customers' });
    if (pathname.includes('all')) {
      options.push({ displayName: 'View all customers', href: '/messaging/customers/all' });
    }
    if (pathname.includes('actions')) {
      options.push({ displayName: 'View customers with actions', href: '/messaging/customers/actions' });
    }
  }
  else if (pathname.includes('employees')) {
    options.push({ displayName: 'Employees', href: '/messaging/employees' });
    if (pathname.includes('all')) {
      options.push({ displayName: 'View all employees', href: '/messaging/employees/all' });
    }
    if (pathname.includes('actions')) {
      options.push({ displayName: 'View employees with actions', href: '/messaging/employees/actions' });
    }
  }

  return (
    <CommunicationsPageWrapper
      additionalClassNames={['messaging-page']}
      additionalOptions={options}
      selectedTab={MESSAGING}
    >
      { children }
    </CommunicationsPageWrapper>
  );
}

MessagingPageWrapper.displayName = 'MessagingPageWrapper';
MessagingPageWrapper.propTypes = propTypes;
export default MessagingPageWrapper;