import './MessagingNavDropdown.scss';

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import MessagingConsumer from '../../../../../../../context/MessagingContext';
import { MESSAGING_VIEWS } from '../../../MessagingConstants';

import MessagingNavDropdownOption from './MessagingNavDropdownOption';

import useAuth from '../../../../../../../context/AuthContext';

const { SEARCH, BROADCAST, INBOX, CUSTOMERS, EMPLOYEES, TASKS } = MESSAGING_VIEWS;
const propTypes = {};

function MessagingNavDropdown({ initialLoad, setInitialLoad, navigationView, setNavigationView, view, viewFilters, setBroadcastList, setViewFilters, setSelectedRecord }) {
  const { user } = useAuth();
  const { contacts, customers, employees, tasks } = MessagingConsumer();
  const navigate = useNavigate();
  let selectedNav = navigationView === view.key;
  const [recordType, setRecordType] = useState({});
  const [navCount, setNavCount] = useState(null);
  const broadcastLength = user.broadcasts.filter(({ sentDate }) => sentDate === null).length;

  useEffect(() => {
    setNavCount({
      SEARCH: 0,
      BROADCAST: broadcastLength,
      CUSTOMERS: customers ? customers.length : 0,
      EMPLOYEES: employees ? employees.length : 0,
      INBOX: contacts ? contacts.filter(contact => !!contact.chatHistory.find(chat => !chat.read)).length : 0,
      TASKS: tasks ? tasks.filter(task => !task.completed).length : 0,
    })
  }, [contacts, customers, employees, tasks])

  useEffect(() => {
    let viewCount = {};
    if (navigationView === BROADCAST.key) {
      viewCount = {
        NEW: 0,
        UPCOMING: broadcastLength,
      }
    } else if (navigationView === INBOX.key && contacts !== null) {
      viewCount = {
        ALL: 0,
        UNREAD: contacts.filter(contact => !!contact.chatHistory.find(chat => !chat.read)).length,
      }
    } else if (navigationView === CUSTOMERS.key && customers !== null && tasks !== null) {
      viewCount = {
        ALL: customers.length,
        ACTIONS_REQUIRED: customers.reduce((customerCount, customer) => {
          const hasUnactionedTasks = tasks.find(task => !task.completed && task.taggedTo && customer._id === task.taggedTo._id);
          const hasUnreadMessages = customer.chatHistory.find(chat => !chat.read);
          if (hasUnactionedTasks || hasUnreadMessages) {
            return customerCount + 1;
          }
          return customerCount;
        }, 0)
      }
    } else if (navigationView === EMPLOYEES.key && employees !== null && tasks !== null) {
      viewCount = {
        ALL: employees.length,
        ACTIONS_REQUIRED: employees.reduce((employeeCount, employee) => {
          const hasUnactionedTasks = tasks.find(task => !task.completed && task.assignedTo && employee._id === task.assignedTo._id);
          const hasUnreadMessages = employee.chatHistory.find(chat => !chat.read);
          if (hasUnactionedTasks || hasUnreadMessages) {
            return employeeCount + 1;
          }
          return employeeCount;
        }, 0)
      }
    } else if (navigationView === TASKS.key && tasks !== null) {
      viewCount = {
        ALL: tasks.length,
        OUTSTANDING: tasks.filter(task => !task.completed).length,
        COMPLETED: tasks.filter(task => task.completed).length,
      }
    }
    setRecordType(viewCount);
  }, [contacts, customers, employees, tasks, navigationView]);

  const toggle = useCallback((select) => {
    const wrapper = document.getElementById(`${view.text}-navigation-store-dropdown-wrapper`);
    const dropdownText = document.getElementById(`${view.text}-navigation-store-dropdown-list`);

    if (select === false) {
      setSelectedRecord(null);
      setBroadcastList([]);
    }
    
    if (select !== null) {
      setNavigationView(view.key);
      if (!initialLoad && navigationView !== SEARCH.key) {
        navigate(view.filters[viewFilters].url);
      } else {
        navigate(view.defaultURL);
        setViewFilters(MESSAGING_VIEWS[view.key].defaultFilter);
      }
    }

    wrapper.style.height = select === null ? '0px' : `${dropdownText.clientHeight + 2}px`;
    wrapper.style.maxHeight = select === null ? '0px' : `${dropdownText.clientHeight + 2}px`;
  }, [initialLoad, navigationView, viewFilters, view, navigate, setNavigationView, setViewFilters, setSelectedRecord, setBroadcastList])

  useEffect(() => {
    if (!initialLoad) {
      setInitialLoad(true);
      if (selectedNav) {
        toggle(selectedNav);
      }
    } else if (!selectedNav) {
      toggle(null, false)
    }
    
  }, [selectedNav, toggle])

  return (
    <section className={'navigation-dropdown-container'}>
      <button id={'navigation-dropdown-header'} className={'dropdown-header'} onClick={() => toggle(selectedNav)} type={'button'}>
        <section className={'navigation-view-item'}>
          <img alt={`${view.key}-navigation-button`} className={'messaging-navigation-icon'} src={view.icon} />
          <span className={`messaging-navigation-button-text ${selectedNav ? 'selected' : ''}`}>{view.text}</span>
        </section>
        {(navCount && navCount[view.key] > 0) && <span className={'filter-count'}>{navCount[view.key]}</span>}
      </button>
      <section id={`${view.text}-navigation-store-dropdown-wrapper`} className={'dropdown-content'}>
        <section id={`${view.text}-navigation-store-dropdown-list`} className={'dropdown-answer'}>
          {Object.keys(view.filters).map((option) =>
            <MessagingNavDropdownOption
              key={view.filters[option]}
              count={recordType[view.filters[option].key]}
              option={view.filters[option]}
              viewFilters={viewFilters}
              setViewFilters={setViewFilters}
              setSelectedRecord={setSelectedRecord}
            />)}
        </section>
      </section>
    </section>
  )
}

MessagingNavDropdown.displayText = 'MessagingNavDropdown';
MessagingNavDropdown.propTypes = propTypes;
export default MessagingNavDropdown;
