import './SearchListView.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useAuth from '../../../../../../../../context/AuthContext';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';
import { RECORD_TYPES } from '../../../../MessagingConstants';
import { searchFilterContacts } from '../../../MessagingHelpers';

import ContactCard from '../ContactCard';
import SearchBar from './SearchBar';
import SearchPlaceholder from './SearchPlaceholder';
import ContactDetail from '../ContactDetail';
import SearchButton from './SearchButton';

const { ADMIN, EMPLOYEE } = RECORD_TYPES;
const SEARCH_ADDITIONAL_CLASSNAMES = ['new-broadcast', 'max-width'];

const propTypes = {};

function SearchListView({ selectedRecord, setRecord }) {
  const { user } = useAuth(); 
  const groupMap = user.groups.reduce((groupMapping, group) => ({ ...groupMapping, [group._id]: group.name.toLowerCase() }), {});
  const { customers, employees, tasks } = MessagingConsumer();
  const [searching, setSearching] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);

  const closeSearchBar = () => {
    setOpenSearch(false);
    setSearchText('');
    setSearching(false);
  }

  const search = (e) => {
    if (!searching) {
      setSearching(true);
    }
    if (e.target.value.length === 0) {
      setSearching(false);
    }
    setSearchText(e.target.value);
  }

  useEffect(() => {
    if (searching && customers && employees && tasks && groupMap) {
      const contacts = [ ...customers, ...employees ];
      const populateUnreadActionsOnContacts = contacts.map(contact => {
        if (contact.contactType === EMPLOYEE.key || contact.contactType === ADMIN.key) {
          const hasUnreadAction = !!tasks.find(task => !task.completed && task.assignedTo && task.assignedTo._id === contact._id);
          return { ...contact, hasUnreadAction };
        } else {
          const hasUnreadAction = !!tasks.find(task => !task.completed && task.taggedTo && task.taggedTo._id === contact._id);
          return { ...contact, hasUnreadAction };
        }
      })
      let results = searchFilterContacts(populateUnreadActionsOnContacts, searchText, groupMap);
      setFilteredResults(results);
    }
  }, [customers, employees, tasks, searchText, searching]);

  const results = searching
    ? filteredResults.map(contact => 
      <ContactCard
        contact={contact}
        setRecord={setRecord(contact.contactType)}
      />)
    : <SearchPlaceholder />

  const searchComponent = openSearch
    ? <SearchBar additionalClassNames={SEARCH_ADDITIONAL_CLASSNAMES} searchText={searchText} search={search} placeholder={'Search'} setOpenSearch={closeSearchBar} />
    : <SearchButton setOpenSearch={setOpenSearch} searchText={searchText} />;

  return (
    <section className={'search-list-view'}>
      {
        selectedRecord
          ? <ContactDetail contact={selectedRecord} setRecord={setRecord(null)} />
          : (
            <>
              <section className={'search-controls-container'}>
                {searchComponent}
              </section>
              <section className={'search-results'}>
                {results}
              </section>
            </>
          )
      }
    </section>
  )
}

SearchListView.displayName = 'SearchListView';
SearchListView.propTypes = propTypes;
export default SearchListView;