import './TimeDropdown.scss';

import React, { useState, useRef, useEffect } from 'react';

import CLOCK_ICON from '../assets/clock-icon.svg';
import ARROW_ICON from '../assets/arrow-icon.svg';

import { useOutsideClick } from '../../../../../hooks/ClickHooks';

const propTypes = {};

const getStringFromTime = (time) => {
  const minutes = (time % (60 * 1000 * 60)) / (1000 * 60);
  const hours = Math.floor(time / (1000 * 60 * 60));
  const hoursText = hours >= 10 ? `${hours}` : `0${hours}`;
  const minutesText = minutes > 10 ? `${minutes}` : `0${minutes}`;
  if (hours === 0) {
    return `${12}:${minutesText}`;
  }
  return `${hoursText}:${minutesText}`;
}

const generateStringToTimeMap = () => {
  const numTimeIncrements = 12 * 2;
  const timeInEachIncrementMS = 30 * 60 * 1000;
  const stringToTimeMap = {};
  const timeOptions = [];
  for (let i = 0; i < numTimeIncrements; i += 1) {
    const timeBlock = i * timeInEachIncrementMS;
    const timeString = getStringFromTime(timeBlock);
    stringToTimeMap[timeBlock] = { displayText: timeString, key: timeBlock };
    timeOptions.push({ displayText: timeString, key: timeBlock });
  }
  return { stringToTimeMap, timeOptions };
}

function TimeDropdown({ selectedTime, setTime }) {
  const dropdownRef = useRef(null);
  const listRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { timeOptions, stringToTimeMap } = generateStringToTimeMap();
  useOutsideClick(dropdownRef, () => setOpen(false));
  const times = timeOptions.map(({ displayText, key }) => {
    const onClick = () => {
      setTime(key);
      setOpen(false);
    }
    return (
      <li className={`time-option-item ${key === selectedTime ? 'selected' : ''}`} key={key}>
        <button className={'time-option-selector'} onClick={onClick}>
          {displayText}
        </button>
      </li>
    )
  });
  
  const toggleDropdown = () => {
    setOpen(!open);
  }
  useEffect(() => {
    if (open && listRef.current) {
      const selectedIndex = timeOptions.map(({ key }) => key).indexOf(selectedTime);
      const pxOffset = Math.max(0, selectedIndex * 132.7 + 5);
      listRef.current.scrollTop = pxOffset;
    }
  }, [open])
  const selectedTimeText = stringToTimeMap[selectedTime].displayText;
  return (
    <section className={'time-dropdown-container'} ref={dropdownRef}>
      <section className={'time-selector-container'}>
        <section className={'icon-container'}>
          <img className={'clock-icon'} src={CLOCK_ICON} alt={'Clock icon'} />
        </section>
        <button className={`time-dropdown-button ${open ? 'open' : ''}`} onClick={toggleDropdown}>
          <span className={'button-text'}>{selectedTimeText}</span>
          <img className={'dropdown-arrow'} src={ARROW_ICON} alt={'Arrow icon'} />
        </button>
      </section>
      { open && 
        <section className={'time-list-container'} ref={listRef}>
          <ul className={'time-list'}>
            {times}
          </ul>
        </section>
      }
    </section>
  )
}

TimeDropdown.displayName = 'TimeDropdown';
TimeDropdown.propTypes = propTypes;
export default TimeDropdown;