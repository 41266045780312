import './AutomationRequestContainer.scss';

import React from 'react';
import PropTypes from 'prop-types';
import AutomationRequestInput from './AutomationRequestInput';
import AutomationRequestExamples from './AutomationRequestExamples';

const propTypes = {};

function AutomationRequestContainer() {
  return (
    <section className={`automation-request-container`}>
      <h4 className={'request-title'}>{'What would you like to automate?'}</h4>
      <AutomationRequestInput />
      <AutomationRequestExamples />
    </section>
  )
}

AutomationRequestContainer.displayName = 'AutomationRequestContainer';
AutomationRequestContainer.propTypes = propTypes;
export default AutomationRequestContainer;