import './RecipientsSearch.scss';

import React, { useEffect, useState } from 'react';

import UploadRecipientsModal from '../modals/UploadRecipientsModal';
import AddRecipientsModal from '../modals/AddRecipientsModal'

import SEARCH_ICON from './assets/search-icon.svg';

function RecipientsSearch({ recipients, selectedRecipients, setSelectedRecipients, setSelectedRecipientsToView }) {
  const [searchText, setSearchText] = useState('');
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  console.log({ selectedRecipients });
  return (
    <section className={'recipients-search'}>
      { importModalOpen && <UploadRecipientsModal close={() => setImportModalOpen(false)} /> }
      { addModalOpen &&
        <AddRecipientsModal
          close={() => setAddModalOpen(false)}
          searchText={searchText}
          setSearchText={setSearchText}
          allRecipients={recipients}
          selectedRecipients={selectedRecipients}
          setSelectedRecipientsToView={setSelectedRecipientsToView}
          selectRecipients={setSelectedRecipients}
          filters={filters}
          setFilters={setFilters}
        />
      }
      <section className={'recipients-search-container'}>
        <h4 className={'recipients-search-title'}>{'Add recipients...'}</h4>
        <button className={'recipients-search-input-container'} onClick={() => setAddModalOpen(true)}>
          <span className={'recipients-search-input'}>{'Search or add existing recipients'}</span>
          <img className={'search-icon'} src={SEARCH_ICON} alt={'Search icon'} />
        </button>
        <ul className={'filter-list'}>
        </ul>
      </section>
      <button className={'import-recipients-button'} onClick={() => setImportModalOpen(true)}>
        {'Import recipients'}
      </button>
    </section>
  );
};

RecipientsSearch.displayName = 'RecipientsSearch';
export default RecipientsSearch;