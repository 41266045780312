import React from 'react';
import { useLocation } from 'react-router-dom';

import { useMediaQuery } from '../../../hooks/MediaHooks';

import MobileManagerNavbar from './mobile/manager/MobileManagerNavbar';
import WebManagerNavbar from './web/manager/WebManagerNavbar';

function NavbarManager() {
  const isMobile = useMediaQuery('(max-width: 820px)');

  if (isMobile) {
    return <MobileManagerNavbar />
  }
  return <WebManagerNavbar />
}

export default NavbarManager;
