import './EmailPreview.scss';

import React from 'react';

import PreviewWrapper from './PreviewWrapper';
import EMAIL_PREVIEW_IMAGE from './assets/email-preview-image.png';
import Email from './common/Email';

function EmailPreview({ preview, setPreview, proceed }) {
  return (
    <PreviewWrapper title={'Email'} previewImage={EMAIL_PREVIEW_IMAGE}>
      <Email preview={preview} setPreview={setPreview} proceed={proceed} />
    </PreviewWrapper>
  );
};

EmailPreview.displayName = 'EmailPreview';
export default EmailPreview;