import './ManagerDashboardPage.scss';

import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';


import DashboardNavButtons from './DashboardNavButtons';
import ManagerDashboardWrapper from './ManagerDashboardWrapper';

const propTypes = {};

function ManagerDashboardPage() {
  const additionalClassNames = ['manager-dashboard-page'];
  return (
    <ManagerDashboardWrapper additionalClassNames={additionalClassNames}>
      <DashboardNavButtons />
      <Outlet />
    </ManagerDashboardWrapper>
  );
}

ManagerDashboardPage.displayName = 'ManagerDashboardPage';
ManagerDashboardPage.propTypes = propTypes;
export default ManagerDashboardPage;