import './LoginInput.scss';

import React from 'react';

function LoginInput({ children, error, inputLabel }) {
  const displayText = error ? error : inputLabel;
  return (
    <section className={'login-input-container'}>
      {children}
      <p className={'login-input-label'}>{displayText}</p>
    </section>
  )
}

export default LoginInput;