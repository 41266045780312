import './GroupOption.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function GroupOption({ selectedGroups, group, setGroup }) {
  console.log(group);
  console.log(selectedGroups);
  const selectedOption = selectedGroups.find(selectedGroup => selectedGroup._id === group._id);
  const selectedClass = selectedOption ? 'selected' : '';
  return (
    <button className={'select-group-button'} onClick={() => setGroup(group._id)} type={'button'}>
      <span className={selectedClass}>{group.name}</span>
    </button>
  )
}

GroupOption.displayName = 'GroupOption';
GroupOption.propTypes = propTypes;
export default GroupOption;