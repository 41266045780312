import './LoginPage.scss';
import 'react-phone-input-2/lib/style.css';

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { postLogin } from '../../../apis/LoginApi';

import ChangeLoginMethod from './shared/ChangeLoginMethod';
import LoginInputContainer from './shared/LoginInputContainer';
import LoginWrapper from './shared/LoginWrapper';
import LoginHeader from './shared/LoginHeader';
import SwapLoginSignUp from './shared/SwapLoginSignUp';

const propTypes = {};

const INPUT_LABELS = {
  sms: 'Phone Number',
  email: 'Email address'
}

export const LOGIN_METHODS = {
  SMS: 'sms',
  EMAIL: 'email'
}

function LoginPage({ loginDetails, setLoginDetails, setLoginPage, loginMethod, setLoginMethod }) {
  const [searchParams, _] = useSearchParams();
  const skipVerificationCode = searchParams.get('skip_verification_code') || false;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const checkSubmit = (e) => {
    if (e.type === 'submit' || e.key === 'Enter') {
      e.preventDefault();
      proceedToVerify();
    }
  }

  const onChange = (fieldName) => (e) => {
    if (fieldName === LOGIN_METHODS.SMS) {
      setLoginDetails({ ...loginDetails, [fieldName]: e}); 
    }
    else {
      setLoginDetails({ ...loginDetails, [fieldName]: e.target.value}); 
    }
    setError('');
  }

  const smsMethodSelected = loginMethod === LOGIN_METHODS.SMS;

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

  const proceedToVerify = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (loginMethod === LOGIN_METHODS.SMS) {
      if (loginDetails[loginMethod].length < 8) {
        setError('Please enter a valid Mobile Number.');
        setLoading(false);
        return;
      }
    }
    else if (loginMethod === LOGIN_METHODS.EMAIL) {
      if (!emailRegex.test(loginDetails[loginMethod])) {
        setError('Please enter a valid Email Address.');
        setLoading(false);
        return;
      }
    }

    postLogin(loginDetails[loginMethod], loginMethod, skipVerificationCode)
      .then((response) => {
        if (response.success) {
          setLoginPage(false);
        }
        else {
          setError(response.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        const { message } = err;
        setError(message);
      });
  }

  const changeLoginMethod = (newLoginMethod) => {
    setLoginDetails({ email: '', sms: '' });
    setError('');
    setLoginMethod(newLoginMethod);
  }

  const otherLoginMethod = smsMethodSelected ? LOGIN_METHODS.EMAIL : LOGIN_METHODS.SMS;
  const optionText = smsMethodSelected ? 'Continue with Email' : 'Continue with SMS';

  return (
    <LoginWrapper>
      <LoginHeader headerTitle={'Welcome'} headerSubtitle={'Login to WorkRex'} />
      <section className={'login-body-container'}>
        <LoginInputContainer
          checkSubmit={checkSubmit}
          error={error}
          inputLabel={INPUT_LABELS[loginMethod]}
          loginDetails={loginDetails}
          loginMethod={loginMethod}
          onChange={onChange}
        />
        <button className={'login-proceed-button'} onClick={proceedToVerify} type={'button'}>{'Continue'}</button>
        <SwapLoginSignUp />
        <section className={'sign-in-options-divider'}>
          <p className={'sign-in-options-divider-text'}>{'OR'}</p>
        </section>
        <section className={'sign-in-options-container'}>
          <ChangeLoginMethod optionText={optionText} setLoginMethod={changeLoginMethod} newLoginMethod={otherLoginMethod} />
        </section>
      </section>
    </LoginWrapper>
  )
}

LoginPage.propTypes = propTypes;
export default LoginPage;