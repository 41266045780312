import './IntegrationCard.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IntegrationEllipsisDropdown from './IntegrationEllipsisDropdown';
import EditIntegrationAPIKeyAndUserModal from '../modals/EditIntegrationAPIKeyAndUserModal';
import RequestExistingIntegrationModal from '../modals/RequestExistingIntegrationModal';

const propTypes = {};

function IntegrationCard({ type, displayText, icon, comingSoon, hasCredentials, active }) {
  const [open, setOpen] = useState(false);
  const buttonText = active ? 'Active' : 'Request';
  const onClick = () => setOpen(true);
  const close = () => setOpen(false);
  return (
    <section className={'integration-card'}>
      { open && comingSoon && <RequestExistingIntegrationModal close={close} type={type} />}
      { open && !comingSoon && <EditIntegrationAPIKeyAndUserModal close={close} type={type} />}
      <section className={'ellipsis-container'}>
        { active && <IntegrationEllipsisDropdown type={type} hasCredentials={hasCredentials} disabled={comingSoon} isActive={active} /> }
      </section>
      <section className={'integration-icon-container'}>
        <img className={'integration-icon'} src={icon} alt={displayText} />
      </section>
      <button className={`request-button ${active ? 'active' : ''}`} disabled={active} onClick={onClick}>{buttonText}</button>
    </section>
  );
}

IntegrationCard.displayName = 'IntegrationCard';
IntegrationCard.propTypes = propTypes;
export default IntegrationCard;