import './FieldSection.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function FieldSection({ children, inputLabel }) {
  return (
    <section className={'field-section'}>
      {inputLabel.length > 0 && <label className={'field-section-label'}>{inputLabel}</label>}
      {children}
    </section>
  )
}

FieldSection.displayName = 'FieldSection';
FieldSection.propTypes = propTypes;
export default FieldSection;