import './ViewAutomation.scss';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ViewAutomationHeader from './ViewAutomationHeader';
import AutomationActivityLog from './AutomationActivityLog';
import AutomationActionCards from './AutomationActionCards';
import AutomationFlowDisplay from './AutomationFlowDisplay';
import useAuth from '../../../../../../../context/AuthContext';
import { AUTOMATION_TYPES, AUTOMATION_ACTIVITY_EVENT_TYPES, DUMMY_AUTOMATION } from '../../AutomationsConstants';
import { postAutomationActiveToggle } from '../../../../../../../apis/Api';
import DotBricksLoading from '../../../../../../common/DotBricksLoading';

const { AVAILABLE } = AUTOMATION_TYPES;
const { AUTOMATION_RUN } = AUTOMATION_ACTIVITY_EVENT_TYPES;

const propTypes = {};


function ViewAutomation() {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const { state } = useLocation();
  const stateAutomationId = state && state.automationId ? state.automationId : window.localStorage.getItem('lastAutomationId');
  const [automationId, setAutomationId] = useState(stateAutomationId);
  const automations = user && user.automations ? user.automations : [];
  const [isActive, setIsActive] = useState(active);
  let automation = null;
  useEffect(() => {
    if (stateAutomationId !== null) {
      setAutomationId(stateAutomationId);
      window.localStorage.setItem('lastAutomationId', stateAutomationId);
      let newAutomation = automations.find(({ _id }) => stateAutomationId.toString() === _id.toString());
      if (newAutomation) {
        setIsActive(newAutomation.active);
      }
    }
    else if (window.localStorage.getItem('lastAutomationId')) {
      const lastAutomationId = window.localStorage.getItem('lastAutomationId');
      setAutomationId(window.localStorage.getItem('lastAutomationId'));
      let newAutomation = automations.find(({ _id }) => lastAutomationId.toString() === _id.toString());
      if (newAutomation) {
        setIsActive(newAutomation.active);
      }
    }
  }, []);
  if (automationId) {
    automation = automations.find(({ _id }) => automationId.toString() === _id.toString());
  }
  if (automationId && !automation) {
    return <Navigate to={'/automations/available'} />;
  }
  if (automation === null) {
    return <DotBricksLoading />;
  }

  // TODO: modify this using the state + fetching the right automationId
  const { _id, description, type, actions, active, automationHistory, title } = automation;

  const setActive = () => {
    postAutomationActiveToggle(automationId, !isActive)
      .then(res => {
        if (res.user) {
          setUser(res.user);
        }
      });
    setIsActive(!isActive);
  }
  const back = () => {
    const backUrl = type === AVAILABLE.key ? '/automations/available' : '/automations/edit';
    navigate(backUrl);
  }
  const viewMore = () => {
    navigate('/automations/edit', { state: { automationId: _id } });
  }
  return (
    <article className={'view-automation'}>
      <ViewAutomationHeader title={title} initialDescription={description} active={isActive} automationId={_id} type={type} back={back} setActive={setActive} />
      <section className={'automation-details-container'}>
        <section className={'automation-details'}>
          <AutomationFlowDisplay type={type} active={isActive} />
          <AutomationActionCards actions={actions} />
        </section>
        <section className={'automation-activity-container'}>
          <AutomationActivityLog automationHistory={automationHistory} />
          <section className={'automation-buttons'}>
            <button className={'view-button'} onClick={viewMore}>{'View / edit'}</button>
            <button className={'close-button'} onClick={back}>{'Close'}</button>
          </section>
        </section>
      </section>
    </article>
  )
}

ViewAutomation.displayName = 'ViewAutomation';
ViewAutomation.propTypes = propTypes;
export default ViewAutomation;