import './TemplateCard.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import EllipsisDropdown from '../../../common/EllipsisDropdown';
import DeleteSavedTemplateModal from '../modals/DeleteSavedTemplateModal';

import useAuth from '../../../../../../context/AuthContext';

import { TEMPLATE_TYPES, TEMPLATE_PAGE_TYPES } from '../TemplatesConstants';
import { postArchiveTemplate } from '../../../../../../apis/TemplateApi';

const { PUBLISHED, DRAFT, EMPLOYEE, CUSTOMER } = TEMPLATE_TYPES;

const propTypes = {};

function TemplateCard({ label, type, title, description, template, templatePageType, recommendedUrl = null }) {
  const { setUser } = useAuth();
  let labelText = (type === PUBLISHED || type === DRAFT) ? 'Your custom template' : label;
  const [openDeletionModal, setOpenDeletionModal] = useState(false);
  const closeDeletionModal = () => setOpenDeletionModal(false);
  const openTemplateDeletionModal = () => setOpenDeletionModal(true);
  const deleteTemplate = () => {
    postArchiveTemplate(template._id)
      .then((res) => {
        if (res.user) {
          setUser(res.user);
        }
      });
  }
  const navigate = useNavigate();
  let options = [];
  let labelPrefix = '';
  let buttonText = '';
  let viewMore = () => {};
  let additionalClassNames = [];
  let hasRecommendedUrl = false;
  let isDraft = false;
  if (templatePageType === TEMPLATE_PAGE_TYPES.LIBRARY) {
    if (type === CUSTOMER) {
      labelPrefix = 'Customer library - ';
    }
    else if (type === EMPLOYEE) {
      labelPrefix = 'Employee library - ';
    }
    options.push({ displayText: 'Use template', onClick: () => navigate('/templates/library/new', { state: { template } }) });
    options.push({ displayText: 'View template', onClick: () => navigate('/templates/library/view', { state: { template } }) });
    buttonText = 'Use';
    viewMore = () => navigate('/templates/library/new', { state: { template } });
    hasRecommendedUrl = !!recommendedUrl;
    additionalClassNames = ['library'];
  }
  else if (templatePageType === TEMPLATE_PAGE_TYPES.DRAFT) {
    options = [
      {
        displayText: 'Edit template',
        onClick: () => navigate('/templates/draft/new', { state: { template } }),
      },
      {
        displayText: 'Delete template',
        onClick: () => {
          openTemplateDeletionModal();
        },
      },
      {
        displayText: 'View template',
        onClick: () => navigate('/templates/draft/view', { state: { template } }),
      }
    ];
    viewMore = () => navigate('/templates/draft/new', { state: { template } });
    buttonText = 'Resume';
    additionalClassNames = ['draft'];
    isDraft = 'true';
  }
  else if (templatePageType === TEMPLATE_PAGE_TYPES.PUBLISHED) {
    options = [
      {
        displayText: 'Duplicate template',
        onClick: () => navigate('/templates/library/new', { state: { template } }),
      },
      {
        displayText: 'Delete template',
        onClick: () => {
          openTemplateDeletionModal();
        },
      },
      {
        displayText: 'View template',
        onClick: () => navigate('/templates/published/view', { state: { template } }),
      }
    ];
    buttonText = 'View';
    viewMore = () => navigate('/templates/published/view', { state: { template } });
    additionalClassNames = ['published'];
  }
  return (
    <section className={`template-card ${additionalClassNames.join(' ')}`}>
      { openDeletionModal && <DeleteSavedTemplateModal close={closeDeletionModal} confirm={deleteTemplate} /> }
      <section className={'card-header'}>
        <span className={'template-label'}>{`${labelPrefix}${labelText}`}</span>
        <EllipsisDropdown options={options} />
      </section>
      <section className={'template-details'}>
        <h5 className={'template-title'}>{title}</h5>
        <p className={'template-description'}>{description}</p>
      </section>
      <section className={`card-buttons ${(hasRecommendedUrl || isDraft) ? '' : 'single-button'}`}>
        { hasRecommendedUrl && <a href={recommendedUrl} className={'recommendation-url'} target={'_blank'} rel={'noreferrer'}>{'Best used for ...'}</a> }
        { isDraft && <span className={'draft-label'}>{'DRAFT'}</span> }
        <button className={'view-more-button'} onClick={viewMore}>{buttonText}</button>
      </section>
    </section>
  );
}

TemplateCard.displayName = 'TemplateCard';
TemplateCard.propTypes = propTypes;
export default TemplateCard;