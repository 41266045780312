import './ButtonWithBorder.scss';

import React from 'react';

function ButtonWithBorder({ additionalClassNames = [], disabled = false, buttonText, onClick = () => {} }) {
  return (
    <button className={`button-with-border ${additionalClassNames.join(' ')}`} disabled={disabled} onClick={onClick} type='button'>
      {buttonText}
    </button>
  )
}

export default ButtonWithBorder;