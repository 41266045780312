import './ChannelPreview.scss';

import React, { useState } from 'react';

import { PREVIEWS, EMPTY_CHANNEL_PREVIEW } from '../../CampaignConstants';

function ChannelPreview({ channel, variantIndex, setVariantIndex, channelOutput, setChannelOutput, viewOnly = false, proceed }) {
  const { PreviewComponent } = PREVIEWS[channel];
  const preview = channelOutput[variantIndex];
  const setPreview = (preview) => {
    const newChannelOutput = [...channelOutput];
    newChannelOutput[variantIndex] = preview;
    setChannelOutput(newChannelOutput);
  }
  const setVariantName = (name) => {
    const newChannelOutput = [...channelOutput];
    newChannelOutput[variantIndex] = { ...newChannelOutput[variantIndex], name };
    setChannelOutput(newChannelOutput);
  }
  const leftClick = () => setVariantIndex((variantIndex - 1 + channelOutput.length) % channelOutput.length);
  const rightClick = () => setVariantIndex((variantIndex + 1 + channelOutput.length) % channelOutput.length);
  const addVariant = () => {
    const newChannelOutput = [...channelOutput, { ...EMPTY_CHANNEL_PREVIEW[channel], name: `Variant ${channelOutput.length + 1}` }];
    setChannelOutput(newChannelOutput);
    setVariantIndex(newChannelOutput.length - 1);
  }
  const removeVariant = () => {
    if (channelOutput.length === 1) return;
    const newChannelOutput = channelOutput.filter((_, index) => index !== variantIndex);
    setVariantIndex((variantIndex - 1 + newChannelOutput.length) % newChannelOutput.length);
    setChannelOutput(newChannelOutput);
  }
  return (
    <section className={'channel-preview-container'}>
      <section className={'channel-preview'}>
        <PreviewComponent
          preview={preview}
          setPreview={setPreview}
          title={preview.name}
          setTitle={setVariantName}
          goToPreviousVariant={leftClick}
          goToNextVariant={rightClick}
          hasMultipleVariants={channelOutput.length > 1}
          addVariant={addVariant}
          removeVariant={removeVariant}
          viewOnly={viewOnly}
          proceed={proceed}
        />
      </section>
    </section>
  );
};

ChannelPreview.displayName = 'ChannelPreview';
export default ChannelPreview;