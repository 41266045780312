import { SORT_OPTIONS } from './SortConstants';

const { firstName, lastName, recentReplies, recentlySent } = SORT_OPTIONS;

export const sortContacts = (sortMethod, contacts) => {
  switch (sortMethod) {
    case firstName.key:
      return contacts.sort((a, b) => {
        const lowerCaseFirstNameA = a.firstName.toLowerCase();
        const lowerCaseFirstNameB = b.firstName.toLowerCase();
        if (lowerCaseFirstNameA < lowerCaseFirstNameB) {
          return -1;
        }
        if (lowerCaseFirstNameA > lowerCaseFirstNameB) {
          return 1;
        }
        return 0;
      })
    case lastName.key:
      return contacts.sort((a, b) => {
        const lowerCaseLastNameA = a.lastName.toLowerCase();
        const lowerCaseLastNameB = b.lastName.toLowerCase();
        if (lowerCaseLastNameA < lowerCaseLastNameB) {
          return -1;
        }
        if (lowerCaseLastNameA > lowerCaseLastNameB) {
          return 1;
        }
        return 0;
      })
    case recentReplies.key:
      return contacts.sort((a, b) => {
        const chatHistoryA = a.chatHistory.filter(chat => chat.senderId !== chat.businessId);
        const chatHistoryB = b.chatHistory.filter(chat => chat.senderId !== chat.businessId);
        const valueA = chatHistoryA.length > 0 ? chatHistoryA.slice(-1)[0].creationDate : -1;
        const valueB = chatHistoryB.length > 0 ? chatHistoryB.slice(-1)[0].creationDate : -1;

        if (valueA < valueB) { 
          return 1;
        }
        else if (valueA > valueB) {
          return -1;
        }
        else {
          return 0;
        }
      })
    case recentlySent.key:
      return contacts.sort((a, b) => {
        const chatHistoryA = a.chatHistory.filter(chat => chat.senderId === chat.businessId);
        const chatHistoryB = b.chatHistory.filter(chat => chat.senderId === chat.businessId);
        const valueA = chatHistoryA.length > 0 ? chatHistoryA.slice(-1)[0].creationDate : -1;
        const valueB = chatHistoryB.length > 0 ? chatHistoryB.slice(-1)[0].creationDate : -1;

        if (valueA < valueB) { 
          return 1;
        }
        else if (valueA > valueB) {
          return -1;
        }
        else {
          return 0;
        }
      })
    default: 
      return contacts;
  }
}