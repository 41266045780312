import './SearchInput.scss';

import React from 'react';
import PropTypes from 'prop-types';

import SEARCH_ICON from './assets/search-icon.svg';

const propTypes = {};

function SearchInput({ searchText, placeholder = 'Search', setSearchText }) {
  const onChange = ({ target }) => setSearchText(target.value);
  return (
    <section className={'search-input-container'}>
      <input type={'text'} className={'search-input'} onChange={onChange} value={searchText} placeholder={placeholder} />
      <img className={'search-icon'} src={SEARCH_ICON} alt={'Search icon'} />
    </section>
  )
}

SearchInput.displayName = 'SearchInput';
SearchInput.propTypes = propTypes;
export default SearchInput;