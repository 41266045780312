import './LoginWrapper.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {};

function LoginWrapper({ children }) {
  return (
    <article className={'login-page'}>
      <section className={'login-page-container'}>
        {children}
      </section>
    </article>
  )
}

LoginWrapper.propTypes = propTypes;
export default LoginWrapper;