import './TaskDetail.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../../Constants';
import { REPEAT_MAP } from '../../../../MessagingConstants';
import { postManyTaskCompletion } from '../../../../../../../../apis/Api';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';
import { useOutsideClick } from '../../../../../../../../hooks/ClickHooks';

import GenericLabel from '../../../list/views/GenericLabel';
import TaskHistoryRow from './TaskHistoryRow';
import EditModalDropdown from '../../../list/views/tasks/EditModalDropdown';

const propTypes = {};

const editIcon = `${ASSET_CDN_URL}/icons/workrex_edit_triple_dot_selection.svg`;

const uncheckedCompleteIcon = `${ASSET_CDN_URL}/icons/rex-unchecked-complete-icon.svg`;
const checkedCompleteIcon = `${ASSET_CDN_URL}/icons/rex-checked-complete-icon.svg`;

const editMenuWidthPx = 160;
const { NONE } = REPEAT_MAP;

function TaskDetail({ editTask, openArchiveModal, openReminderModal, selectedRecord, setSelectedRecord, viewFilters }) {
  const { label, assignedTo, creationDate, dueDate, taggedTo, frequency, completed, taskHistory } = selectedRecord;
  const { setTasks } = MessagingConsumer();

  const [additionalClassNames, setAdditionalClassNames] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsEditing(false));
  const toggleEdit = (e) => {
    e.preventDefault();
    setIsEditing(!isEditing);
    if (dropdownRef !== null && dropdownRef.current !== null) {
      const leftAlign = dropdownRef.current.offsetParent.clientWidth - dropdownRef.current.offsetLeft < editMenuWidthPx;
      setAdditionalClassNames(leftAlign ? ['left-align'] : ['']);
    }
  }

  const createdDateType = new Date(creationDate);
  const createdDateString = `${createdDateType.getDate()}/${createdDateType.getMonth() + 1}/${createdDateType.getFullYear()}`

  const pastDueDate = !completed && Date.now() > dueDate;
  const checkboxIcon = completed ? checkedCompleteIcon : uncheckedCompleteIcon;

  const repeatString = frequency === NONE.key ? 'This task does not repeat' : `This tasks repeats ${REPEAT_MAP[frequency].text.toLowerCase()}`;
  const completeString = completed ? 'Completed.' : 'Not yet complete.';

  const updatePostComplete = () => {
    const updatedTask = [{ ...selectedRecord, completed: !completed }];
    postManyTaskCompletion(updatedTask).then(response => {
      if (viewFilters === 'ALL') {
        setSelectedRecord(response.tasks[0]);
      } else {
        setSelectedRecord(null);
      }
      setTasks(prevState => {
        const filteredTasks = prevState.filter(prevTasks => prevTasks._id !== selectedRecord._id);
        return [ ...filteredTasks, ...response.tasks ];
      });
    })
  }

  return (
    <section className={'task-detail-container'}>
      <section className={'task-detail-body-container'}>
        <button className={'task-icon-container'} onClick={() => updatePostComplete()} type={'button'}>
          <img alt={'task-type'} className={'task-type-icon'} src={checkboxIcon} />
        </button>
        <section className={'task-detail-body'}>
          <section className={'task-heading'}>
            <p className={'task-label'}>{label}</p>
            <button className={'edit-selected-button'} ref={dropdownRef} onClick={toggleEdit} type={'button'}>
              <img alt={'edit-icon'} className={'edit-icon'} src={editIcon} />
              {isEditing && 
                <EditModalDropdown additionalClassNames={additionalClassNames}>
                  <button className={'edit-button'} onClick={editTask} type={'button'}>{'Edit'}</button>
                  {!completed && <button className={'edit-button'} onClick={openReminderModal} type={'button'}>{'Send reminder'}</button>}
                  {completed && <button className={'edit-button'} onClick={openArchiveModal} type={'button'}>{'Archive task'}</button>}
                </EditModalDropdown>
              }
            </button>
          </section>
          {pastDueDate && <GenericLabel additionalClassNames={['outstanding-task']} text={'Outstanding'} /> }
          <section className={'task-creation-date-container'}>
            <span className={'task-creation-date'} >{`Added on ${createdDateString}`}</span>
          </section>
          <section className={'task-assigned-labels'}>
            {assignedTo && <GenericLabel additionalClassNames={['task-assigned']} text={`Assigned to ${assignedTo.firstName} ${assignedTo.lastName}`} />}
            {taggedTo && <GenericLabel additionalClassNames={['task-tagged']} text={`Tagged to ${taggedTo.firstName} ${taggedTo.lastName}`} />}
          </section>
          <section className={'task-repeat-container'}>
            <span>{repeatString}</span>
          </section>
          <section>
            <span>{completeString}</span>
          </section>
        </section>
      </section>
      <section className={'task-history'}>
        <p className={'task-history-title'}>{'Task History'}</p>
        {taskHistory.map(history => <TaskHistoryRow taskHistory={history} />)}
      </section>
    </section>
  )
}

TaskDetail.displayName = 'TaskDetail';
TaskDetail.propTypes = propTypes;
export default TaskDetail;