import './ManagerRecipientsPageWrapper.scss';

import React from 'react';

import WorkRexPageWrapper from '../common/WorkRexPageWrapper';

const propTypes = {};

function ManagerRecipientsPageWrapper({ children }) {
  const options = [{ displayName: 'Recipients', href: '/recipients' }];
  return (
    <WorkRexPageWrapper
      additionalClassNames={['recipients-page']}
      additionalOptions={options}
    >
      { children }
    </WorkRexPageWrapper>
  );
}

ManagerRecipientsPageWrapper.displayName = 'ManagerRecipientsPageWrapper';
ManagerRecipientsPageWrapper.propTypes = propTypes;
export default ManagerRecipientsPageWrapper;