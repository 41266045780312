import { ASSET_CDN_URL } from "../../../../Constants";

const OPP_SCORE_NO_DATA_ICON = `${ASSET_CDN_URL}/icons/workrex_opp_score_no_data.svg`;
const OPP_SCORE_QUARTER_DATA_ICON = `${ASSET_CDN_URL}/icons/workrex_opp_score_quarter_data.svg`;
const OPP_SCORE_HALF_DATA = `${ASSET_CDN_URL}/icons/workrex_opp_score_half_data.svg`;
const OPP_SCORE_THREE_QUARTER_DATA = `${ASSET_CDN_URL}/icons/workrex_opp_score_three_quarter_data.svg`;
const OPP_SCORE_FULL_DATA = `${ASSET_CDN_URL}/icons/workrex_opp_score_full_data.svg`;

export const COMMUNICATION_TABS = {
  MESSAGING: 'MESSAGING',
  TEMPLATES: 'TEMPLATES',
  CONTENT: 'CONTENT',
  CAMPAIGNS: 'CAMPAIGNS',
  AUTOMATIONS: 'AUTOMATIONS',
  ENGAGEMENTS: 'ENGAGEMENTS',
}

export const KEYWORD_TYPES = {
  VARIABLE: 'variable',
  NUMERIC: 'numeric',
  TIME: 'time',
}

const { VARIABLE, TIME } = KEYWORD_TYPES;

export const SPECIAL_KEYWORDS = {
  employeeFirstName: {
    keyword: 'employeeFirstName',
    displayText: 'Employee First Name',
    type: VARIABLE,
  },
  employeeLastName: {
    keyword: 'employeeLastName',
    displayText: 'Employee Last Name',
    type: VARIABLE,
  },
  employeeFullName: {
    keyword: 'employeeFullName',
    displayText: 'Employee Full Name',
    type: VARIABLE,
  },
  employeePhoneNumber: {
    keyword: 'employeePhoneNumber',
    displayText: 'Employee Phone Number',
    type: VARIABLE,
  },
  employeeManagerFirstName: {
    keyword: 'employeeManagerFirstName',
    displayText: 'Employee Manager\'s First Name',
    type: VARIABLE,
  },
  employeeManagerLastName: {
    keyword: 'employeeManagerLastName',
    displayText: 'Employee Manager\'s Last Name',
    type: VARIABLE,
  },
  employeeManagerFullName: {
    keyword: 'employeeManagerFullName',
    displayText: 'Employee Manager\'s Full Name',
    type: VARIABLE,
  },
  employeeEmailAddress: {
    keyword: 'employeeEmailAddress',
    displayText: 'Employee Email Address',
    type: VARIABLE,
  },
  customerFirstName: {
    keyword: 'customerFirstName',
    displayText: 'Customer First Name',
    type: VARIABLE,
  },
  customerLastName: {
    keyword: 'customerLastName',
    displayText: 'Customer Last Name',
    type: VARIABLE,
  },
  customerFullName: {
    keyword: 'customerFullName',
    displayText: 'Customer Full Name',
    type: VARIABLE,
  },
  customerPhoneNumber: {
    keyword: 'customerPhoneNumber',
    displayText: 'Customer Phone Number',
    type: VARIABLE,
  },
  customerEmailAddress: {
    keyword: 'customerEmailAddress',
    displayText: 'Customer Email Address',
    type: VARIABLE,
  },
  groupName: {
    keyword: 'groupName',
    displayText: 'Group Name',
    type: VARIABLE,
  },
  after: {
    keyword: 'after',
    displayText: 'after',
    type: TIME,
  },
  daily: {
    keyword: 'daily',
    displayText: 'daily',
    type: TIME,
  },
}

export const PERFORMANCE_SCORE = {
  NO_DATA: {
    key: 'NO_DATA',
    icon: OPP_SCORE_NO_DATA_ICON,
  },
  QUARTER_DATA: {
    key: 'QUARTER_DATA',
    icon: OPP_SCORE_QUARTER_DATA_ICON,
  },
  HALF_DATA: {
    key: 'HALF_DATA',
    icon: OPP_SCORE_HALF_DATA,
  },
  THREE_QUARTER_DATA: {
    key: 'THREE_QUARTER_DATA',
    icon: OPP_SCORE_THREE_QUARTER_DATA,
  },
  FULL_DATA: {
    key: 'FULL_DATA',
    icon: OPP_SCORE_FULL_DATA,
  },
};