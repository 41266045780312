import React from 'react';
import { Route, Navigate } from 'react-router-dom';

import WorkrexPage from '../workrex/wrappers/WorkrexPage';
import ManagerPage from '../workrex/components/managers/ManagerPage';
import ManagerWaitlistPage from '../workrex/components/managers/ManagerWaitlistPage';
import ManagerDashboardPage from '../workrex/components/managers/dashboard/ManagerDashboardPage';
import ManagerRecipientsPage from '../workrex/components/managers/recipients/ManagerRecipientsPage';
import { RequireAdminAuth, RequireAuth, RequireNoAuth } from '../context/AuthContext';
import TemplatesPage from '../workrex/components/managers/communications/templates/TemplatesPage';
import IntegrationsPage from '../workrex/components/managers/account/integrations/IntegrationsPage';
import MyTasksPage from '../workrex/components/managers/tasks/MyTasksPage';
import MessagingPage from '../workrex/components/managers/communications/messaging/MessagingPage';
import AutomationsPage from '../workrex/components/managers/communications/automations/AutomationsPage';
import { CUSTOMER_TEMPLATES, EMPLOYEE_TEMPLATES, TEMPLATE_TYPES, TEMPLATE_PAGE_TYPES } from '../workrex/components/managers/communications/templates/TemplatesConstants';
import TemplateListContainer from '../workrex/components/managers/communications/templates/common/TemplateListContainer';
import { AUTOMATION_TYPES, AUTOMATION_MODULES } from '../workrex/components/managers/communications/automations/AutomationsConstants';
import AutomationListContainer from '../workrex/components/managers/communications/automations/common/AutomationListContainer';
import ViewAutomation from '../workrex/components/managers/communications/automations/views/view-automation/ViewAutomation';
import EditAutomation from '../workrex/components/managers/communications/automations/views/edit-automation/EditAutomation';
import TemplatePage from '../workrex/components/managers/communications/templates/template/TemplatePage';
import BroadcastsDisplay from '../workrex/components/managers/dashboard/displays/BroadcastsDisplay';
import AnalysisDisplay from '../workrex/components/managers/dashboard/displays/AnalysisDisplay';
import { sortArrayFn } from '../utils/DataUtils';
import EditAccountPage from '../workrex/components/managers/account/edit/EditAccountPage';
import AccountPage from '../workrex/components/managers/account/AccountPage';
import GroupsPage from '../workrex/components/managers/account/groups/GroupsPage';
import AutomationRequestContainer from '../workrex/components/managers/communications/automations/common/automation-request/AutomationRequestContainer';

const { REQUESTED, AVAILABLE } = AUTOMATION_TYPES;
const { CUSTOMER, PUBLISHED, EMPLOYEE, DRAFT } = TEMPLATE_TYPES;

/**
 * TODO: Split up the routes into different groupings:
 * 
 * content route
 * account route
 * signin route
 * signup route
 * messaging route
 * dashboard route
 * recipients route
 * templates route
 * automations route
 * 
 * fb/google/linkedin link removal <- Ask Lance
 * 
 * 
 */
function getManagerRoutes(userAutomations, userTemplates) {
  const templates = [...userTemplates, ...Object.values(CUSTOMER_TEMPLATES), ...Object.values(EMPLOYEE_TEMPLATES)].sort(sortArrayFn('title'));
  const automations = [...userAutomations].sort(sortArrayFn('title'));
  const requestedAutomations = automations.filter(({ type }) => type === REQUESTED.key).sort(sortArrayFn('description'));
  const availableAutomations = automations.filter(({ type }) => type === AVAILABLE.key).sort(sortArrayFn('title'));
  const yourAvailableAutomations = [...availableAutomations, ...requestedAutomations];
  const automationModules = Object.values(AUTOMATION_MODULES).sort(sortArrayFn('title'));
  const publishedTemplates = templates.filter(({ type }) => type === PUBLISHED).sort(sortArrayFn('title'));
  const libraryTemplates = templates.filter(({ type }) => type === EMPLOYEE || type === CUSTOMER).sort(sortArrayFn('title'));
  const draftTemplates = templates.filter(({ type }) => type === DRAFT).sort(sortArrayFn('title'));
  const templateCounts = {
    [TEMPLATE_PAGE_TYPES.PUBLISHED]: publishedTemplates.length,
    [TEMPLATE_PAGE_TYPES.DRAFT]: draftTemplates.length,
    [TEMPLATE_PAGE_TYPES.LIBRARY]: libraryTemplates.length,
  }

  return (
    <Route path={'/'} element={<WorkrexPage />}>
      <Route index={true} element={<RequireNoAuth><ManagerPage /></RequireNoAuth>} />
      <Route path={'fb'} element={<ManagerPage />} />
      <Route path={'google'} element={<ManagerPage />} />
      <Route path={'linkedin'} element={<ManagerPage />} />
      <Route path={'waitlist'} element={<RequireNoAuth><ManagerWaitlistPage /></RequireNoAuth>} />
      <Route path={'automations'} element={<AutomationsPage selectedTab={null} />} />
      <Route path={'account'} element={<AccountPage />}>
        <Route index element={<Navigate to={'/account/edit'} replace />} />
        <Route path={'edit'} element={<EditAccountPage />} />
        <Route path={'groups'} element={<RequireAdminAuth><GroupsPage /></RequireAdminAuth>} />
        <Route path={'integrations'} element={<RequireAdminAuth><IntegrationsPage /></RequireAdminAuth>} />
      </Route>
      <Route path={'tasks'} element={<RequireAuth><MyTasksPage /></RequireAuth>} />
      { /* TODO: Refactor to follow index and outlet structure */ }
      <Route path={'messaging/broadcasts/new'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
      <Route path={'messaging/broadcasts/upcoming'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
      <Route path={'messaging/tasks/outstanding'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
      <Route path={'messaging/tasks/completed'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
      <Route path={'messaging/tasks/all'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
      <Route path={'messaging/inbox/unread'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
      <Route path={'messaging/inbox/all'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
      <Route path={'messaging/customers/all'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
      <Route path={'messaging/customers/actions'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
      <Route path={'messaging/employees/all'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
      <Route path={'messaging/employees/actions'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
      <Route path={'messaging'} element={<RequireAuth><MessagingPage /></RequireAuth>}>
        <Route index element={<Navigate to={'/messaging/search'} replace />} />
        <Route path={'search'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
        <Route path={'broadcasts'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
        <Route path={'inbox'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
        <Route path={'tasks'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
        <Route path={'customers'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
        <Route path={'employees'} element={<RequireAuth><MessagingPage /></RequireAuth>} />
      </Route>
      <Route path={'dashboard'} element={<RequireAdminAuth><ManagerDashboardPage /></RequireAdminAuth>}>
        <Route index element={<Navigate to={'/dashboard/broadcasts'} />} replace />
        <Route path={'broadcasts'} element={<BroadcastsDisplay />} />
        <Route path={'analysis'} element={<AnalysisDisplay />} />
      </Route>
      <Route path={'recipients'} element={<RequireAdminAuth><ManagerRecipientsPage /></RequireAdminAuth>} />
      <Route path={'templates/published/new'} element={<TemplatePage editable={true} />} />
      <Route path={'templates/draft/new'} element={<TemplatePage editable={true} />} />
      <Route path={'templates/library/new'} element={<TemplatePage editable={true} />} />
      <Route path={'templates/published/view'} element={<TemplatePage editable={false} />} />
      <Route path={'templates/draft/view'} element={<TemplatePage editable={false} />} />
      <Route path={'templates/library/view'} element={<TemplatePage editable={false} />} />
      <Route path={'templates'} element={<TemplatesPage templateCounts={templateCounts} />}>
        <Route index element={<Navigate to={'/templates/published'} />} replace />
        <Route path={'published'} element={<TemplateListContainer href={'/templates/published'} templates={publishedTemplates} templatePageType={TEMPLATE_PAGE_TYPES.PUBLISHED} />} />
        <Route path={'draft'} element={<TemplateListContainer href={'/templates/draft'} templates={draftTemplates} templatePageType={TEMPLATE_PAGE_TYPES.DRAFT} />} />
        <Route path={'library'} element={<TemplateListContainer href={'/templates/library'} templates={libraryTemplates} templatePageType={TEMPLATE_PAGE_TYPES.LIBRARY} />} />
      </Route>
      <Route path={'automations'} element={<RequireAdminAuth><AutomationsPage /></RequireAdminAuth>}>
        <Route path={'available'} element={<AutomationListContainer automations={yourAvailableAutomations} isLibrary={false} />} />
        <Route path={'library'} element={<AutomationListContainer automations={automationModules} isLibrary={true} />} />
        <Route path={'request'} element={<AutomationRequestContainer />} />
        <Route path={'view'} element={<ViewAutomation automations={automations} />} />
        <Route path={'edit'} element={<EditAutomation automations={automations} />} />
      </Route>
      <Route path={'*'} element={<ManagerPage />}  />
    </Route>
  );
}

export default getManagerRoutes;