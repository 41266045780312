import './GroupsDropdown.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../Constants';
import { getCRMDataByGroup, getCRMDataByGroupInitialLoad } from '../../../../../../../apis/Api';
import useAuth from '../../../../../../../context/AuthContext';
import MessagingConsumer from '../../../../../../../context/MessagingContext';

import GroupsDropdownOption from './GroupsDropdownOption';
import DotBricksLoading from '../../../../../../common/DotBricksLoading';
import { useOutsideClick } from '../../../../../../../hooks/ClickHooks';
import { getRelatedBroadcasts, getRelatedInteractions, calculateDerivedFields } from '../../../../../../../utils/DataUtils';

const DropdownArrow = `${ASSET_CDN_URL}/icons/solid-filled-dropdown-arrow.svg`;

const propTypes = {};

function GroupsDropdown() {
  const { selectedGroup, setBroadcasts, setCustomers, setEmployees, setInteractions, setGroup, setTasks } = MessagingConsumer();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const [initialController, setInitialController] = useState(null);
  const [controller, setController] = useState(null);
  const dropDownRef = useRef(null);
  useOutsideClick(dropDownRef, () => toggle(true));

  const selectGroup = (option) => {
    // Abort
    if (controller !== null || initialController !== null) {
      initialController.abort();
      controller.abort();
    }
    
    // Set up new abortController
    const initialAbortController = new AbortController();
    setInitialController(initialAbortController);
    const abortController = new AbortController();
    setController(abortController);
    
    setGroup(option);

    // set into loading state.
    setBroadcasts(null);
    setCustomers(null);
    setEmployees(null);
    setInteractions(null);
    setTasks(null);
    setLoading(true);
    getCRMDataByGroupInitialLoad(option._id, initialAbortController.signal)
      .then(response => {
        const newCustomers = response
          .customers 
          .filter(({ deletionDate }) => !deletionDate)
          .map((customer) => {
            const relatedInteractions = getRelatedInteractions(customer, response.interactions);
            const relatedBroadcasts = getRelatedBroadcasts(customer, response.broadcasts);
            const formattedBirthday = customer.birthday ? new Date(customer.birthday).toLocaleString('en-au', { day: 'numeric', month: 'numeric', year: 'numeric' }) : null;
            return calculateDerivedFields({ ...customer, formattedBirthday, interactions: relatedInteractions, broadcasts: relatedBroadcasts });
          })
          .sort((a, b) => a.firstName - b.firstName);

        setBroadcasts(response.broadcasts);
        setCustomers(newCustomers);
        setEmployees(response.employees);
        setInteractions(response.interactions);
        setTasks(response.tasks);

        getCRMDataByGroup(option._id, abortController.signal)
          .then(response => {
            if (response) {
              const newCustomers = response
              .customers 
              .filter(({ deletionDate }) => !deletionDate)
              .map((customer) => {
                const relatedInteractions = getRelatedInteractions(customer, response.interactions);
                const relatedBroadcasts = getRelatedBroadcasts(customer, response.broadcasts);
                const formattedBirthday = customer.birthday ? new Date(customer.birthday).toLocaleString('en-au', { day: 'numeric', month: 'numeric', year: 'numeric' }) : null;
                return calculateDerivedFields({ ...customer, formattedBirthday, interactions: relatedInteractions, broadcasts: relatedBroadcasts });
              })
              .sort((a, b) => a.firstName - b.firstName);
              setBroadcasts(response.broadcasts);
              setCustomers(newCustomers);
              setEmployees(response.employees);
              setInteractions(response.interactions);
              setTasks(response.tasks);
            }
          })
          .catch(err => {
          })
          .finally(() => {
            setLoading(false);
          })
      })
      .catch(err => {
      })
      .finally(() => {
        setLoading(false);
      })
    toggle(open);
  }

  const toggle = useCallback((select) => {
    const wrapper = document.getElementById(`groups-store-dropdown-wrapper`);
    const dropdownText = document.getElementById(`groups-store-dropdown-list`);
    const header = document.getElementById('groups-dropdown-header');

    if (open !== null) {
      setOpen(!select);
    }

    wrapper.style.height = select ? '0px' : `${dropdownText.clientHeight}px`;
    wrapper.style.maxHeight = select ? '0px' : '400px';
    wrapper.style.top = `${header.clientHeight}px`;
  }, [open])

  useEffect(() => {
    if (open) {
      toggle(null)
    }
  }, [open, toggle])

  useEffect(() => {
    if (user.groups !== null && selectedGroup === null) {
      const previouslySelectedGroup = user.groups.find(group => group._id === window.localStorage.getItem('selectedGroupId'));
      const noPreviouslySelectedGroup = user.groups[0];
      const groupIdToSet = previouslySelectedGroup ? previouslySelectedGroup : noPreviouslySelectedGroup;
      setGroup(groupIdToSet);
    }
  }, [user.groups, selectedGroup, setGroup]);

  if (user.groups === null || selectedGroup === null || loading) {
    return (
      <section className={`groups-dropdown-container loading`}>
        <DotBricksLoading isFixed={false} />
      </section>
    )
  }
  return (
    <section className={'groups-dropdown-container'} ref={dropDownRef}>
      <section id={'groups-dropdown-header'} className={'dropdown-header'} onClick={() => toggle(open)}>
        {selectedGroup.name}
        <img src={DropdownArrow} alt={''} className={`rec-header-icon ${open ? 'open' : ''}`} />
      </section>
      <section id={`groups-store-dropdown-wrapper`} className={'dropdown-content'}>
        <section id={`groups-store-dropdown-list`} className={'dropdown-answer'}>
          {user.groups.map((group) => <GroupsDropdownOption option={group} selected={selectedGroup} setSelected={selectGroup} key={group._id} />)}
        </section>
      </section>
    </section>
  )
}

GroupsDropdown.displayText = 'GroupsDropdown';
GroupsDropdown.propTypes = propTypes;
export default GroupsDropdown;