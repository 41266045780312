import './EngagementLibrary.scss';

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ENGAGEMENT_LEVERS } from '../EngagementConstants';

import EngagementsSidebar from '../common/EngagementsSidebar';
import { calculateDerivedFields, getRelatedInteractions } from '../../../../../../utils/DataUtils';
import Engagement from './table/Engagement';
import MessagingConsumer from '../../../../../../context/MessagingContext';

const propTypes = {};

const getInitialCustomerRows = (customers, interactions, forceRecalculate = false) => {
  if (customers && interactions) {
    return customers
      .filter(({ deletionDate }) => !deletionDate)
      .map((customer) => {
        const relatedInteractions = getRelatedInteractions(customer, interactions);
        if (!forceRecalculate && customer.formulaFieldsCalculated) {
          return { ...customer, interactions: relatedInteractions };
        }
        return calculateDerivedFields({ ...customer, interactions: relatedInteractions });
      })
  }
  return [];
}

const processAvailableCustomersPerOpportunity = (customerRows, opportunities) => {
  if (customerRows) {
    return opportunities
      .map(opportunity => {
        const { dataRequired } = opportunity;
        const customersAvailable = customerRows.filter(customer => dataRequired.length === 0 || dataRequired.every(dataField => customer[dataField]));
        return { ...opportunity, customersAvailable };
      })
  }
  return opportunities;
}

function EngagementLibrary({ children, displayTitle, engagements, showAvailable = false, tab }) {
  const { state } = useLocation();
  const [searchText, setSearchText] = useState('');
  const [selectedEngagementLeverKey, setEngagementLeverKey] = useState(state && state.engagementLeverKey ? state.engagementLeverKey : null);
  const select = (key) => setEngagementLeverKey(selectedEngagementLeverKey === key ? null : key);

  const { customers, interactions, setCustomers } = MessagingConsumer();

  const [customerRows, setCustomerRows] = useState(getInitialCustomerRows(customers));
  const [opportunities, setOpportunities] = useState(engagements);

  const engagementOpportunities = customerRows && opportunities.length > 0
    ? opportunities
      .filter(opportunity => {
        const { customersAvailable, title } = opportunity;
        const foundInSearch = searchText.length > 0 ? title.toLowerCase().includes(searchText.toLowerCase()) : true;
        const opportunityHasEngagementLever = opportunity.engagementLever.includes(selectedEngagementLeverKey);
        if (showAvailable) {
          return foundInSearch && customersAvailable.length > 0 && (!selectedEngagementLeverKey || opportunityHasEngagementLever);
        }
        return foundInSearch && (!selectedEngagementLeverKey || opportunityHasEngagementLever);
      })
      .map((opportunity, index) => <Engagement firstEngagement={index === 0} customerRows={customerRows} engagement={opportunity} tab={tab} />)
    : null;

  useEffect(() => {
    if (customers != null && interactions != null) {
      const formulaCalculatedCustomers = getInitialCustomerRows(customers, interactions, true);
      if (formulaCalculatedCustomers.length > 0) {
        setCustomerRows(formulaCalculatedCustomers);
        const updatedOpportunities = processAvailableCustomersPerOpportunity(formulaCalculatedCustomers, engagements);
        setOpportunities(updatedOpportunities);
        if (formulaCalculatedCustomers.length !== customers.length) {
          setCustomers(formulaCalculatedCustomers);
        }
      }
    }
  }, [customers, interactions, engagements])

  return (
    <section className={'all-engagements-page'}>
      <EngagementsSidebar
        assistants={Object.values(ENGAGEMENT_LEVERS)}
        selectedAssistantKey={selectedEngagementLeverKey}
        selectAssistantKey={select}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <section className={'all-engagements'}>
        <section className={'all-engagements-display'}>
          <header className={'all-engagements-header'}>
            <h2 className={'all-engagements-title'}>{displayTitle}</h2>
            {children}
          </header>
          <section className={'engagement-opportunities'}>
            {engagementOpportunities}
          </section>
        </section>
      </section>
    </section>
  );
}

EngagementLibrary.displayName = 'EngagementLibrary';
EngagementLibrary.propTypes = propTypes;
export default EngagementLibrary;