import './DashboardNavButtons.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { DASHBOARD_STATES } from './ManagerDashboardConstants';
import CommunicationsNavigationTab from '../communications/navigation/CommunicationsNavigationTab';
import { useLocation } from 'react-router-dom';

const { TEMPLATES, ANALYSIS } = DASHBOARD_STATES;

const propTypes = {};

function DashboardNavButtons() {
  const { pathname } = useLocation();
  return (
    <ul className={'communication-navigation-tabs'}>
      <nav className={'nav-tab'}>
        <CommunicationsNavigationTab
          href={'/dashboard/broadcasts'}
          selected={pathname.startsWith('/dashboard/broadcasts')}
          displayText={'Broadcasts'}
        />
      </nav>
    </ul>
  )
}

DashboardNavButtons.displayName = 'DashboardNavButtons';
DashboardNavButtons.propTypes = propTypes;
export default DashboardNavButtons;