import './RequestExistingIntegrationModal.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IntegrationModal from './IntegrationModal';

import { postIntegrationRequest } from '../../../../../../apis/IntegrationApi';

const propTypes = {};

function RequestExistingIntegrationModal({ close, type }) {
  const [loading, setLoading] = useState(false);
  const confirm = () => {
    setLoading(true);
    postIntegrationRequest(type)
      .then(() => {
        setLoading(false);
        close();
      })
  }
  return (
    <IntegrationModal additionalClassNames={['request-existing-integration-modal']} close={close}>
      <h3 className={'modal-title'}>
        {'Once you confirm the request, the WorkRex team will get in touch to set up the integration for you'}
      </h3>
      <button className={'confirm-button'} onClick={confirm} disabled={loading}>{'Request'}</button>
    </IntegrationModal>
  );
}

RequestExistingIntegrationModal.displayName = 'RequestExistingIntegrationModal';
RequestExistingIntegrationModal.propTypes = propTypes;
export default RequestExistingIntegrationModal;