import './EditAccountPage.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useAuth from '../../../../../context/AuthContext';
import { postManagerAccount } from '../../../../../apis/Api';

import InputContainer from '../../common/InputContainer';
import TextInput from '../../common/TextInput';
import CommunicationHours from './CommunicationHours';
import ToggleInput from '../../common/ToggleInput';

const FORWARD_SMS = {
  DO_NOT_FORWARD: 'DO_NOT_FORWARD',
  FORWARD_ALL: 'FORWARD_ALL',
  REQUIRES_ATTENTION: 'REQUIRES_ATTENTION',
};

const { DO_NOT_FORWARD, FORWARD_ALL, REQUIRES_ATTENTION } = FORWARD_SMS;

const propTypes = {};

const DUMMY_ACCOUNT = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  phoneNumber: '',
  companyName: '',
  role: '',
  brandName: '',
  communicationHours: {
    employee: {
      startTime: 0,
      endTime: 0,
      sendWithinHours: true,
    },
    customer: {
      startTime: 0,
      endTime: 0,
      sendWithinHours: true,
    }
  },
  emailToSmsPreference: FORWARD_ALL,
}

function EditAccountPage() {
  const { user, setUser } = useAuth();
  const initialAccount = { ...DUMMY_ACCOUNT, ...user };
  const [account, setAccount] = useState(initialAccount);
  const [changed, setChanged] = useState(false);
  const setAccountDetails = (value) => {
    setAccount(value);
    setChanged(true);
  }
  const {
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    companyName,
    role,
    brandName,
    communicationHours,
    emailToSmsPreference = FORWARD_ALL,
  } = account;
  const { employee, customer } = communicationHours;
  const iconLetter = firstName.length > 0 ? firstName[0] : '';
  const fullName = `${firstName} ${lastName}`;
  const setText = (fieldName) => (value) => setAccountDetails({ ...account, [fieldName]: value });
  const setCommunicationHours = (fieldName) => (value) => setAccountDetails(
    { ...account, communicationHours: { ...communicationHours, [fieldName]: value }}
  );
  const toggleEmailToSmsPreference = (value) => {
    if (value === emailToSmsPreference) {
      if (value === DO_NOT_FORWARD || value === REQUIRES_ATTENTION) {
        setAccountDetails({ ...account, emailToSmsPreference: FORWARD_ALL });
      }
      else if (value === FORWARD_ALL) {
        setAccountDetails({ ...account, emailToSmsPreference: DO_NOT_FORWARD });
      }
    }
    else {
      setAccountDetails({ ...account, emailToSmsPreference: value });
    }
  }
  const confirm = () => {
    postManagerAccount(account)
      .then(({ user }) => {
        setUser(user);
        setChanged(false);
      });
  };
  return (
    <section className={'edit-account-page'}>
      <section className={'edit-account-container'}>
        <header className={'edit-account-header'}>
          <section className={'name-icon'}>
            <span className={'icon-letter'}>{iconLetter}</span>
          </section>
          <section className={'name'}>{fullName}</section>
        </header>
        <section className={'edit-account-details'}>
          <section className={'details-column'}>
            <section className={'detail-row name-component'}>
              <InputContainer label={'First name'}>
                <TextInput text={firstName} setText={setText('firstName')}  placeholder={'[first name]'} />
              </InputContainer>
              <InputContainer label={'Last name'}>
                <TextInput text={lastName} setText={setText('lastName')}  placeholder={'[last name]'} />
              </InputContainer>
            </section>
            <section className={'detail-row'}>
              <InputContainer label={'Email address'}>
                <TextInput text={emailAddress} setText={setText('emailAddress')}  placeholder={'[email address]'} />
              </InputContainer>
            </section>
            <section className={'detail-row'}>
              <InputContainer label={'Contact number'}>
                <TextInput text={mobileNumber} setText={setText('mobileNumber')}  placeholder={'[123456789]'} />
              </InputContainer>
            </section>
          </section>
          <section className={'details-column'}>
            <section className={'detail-row'}>
              <InputContainer label={'Company name'}>
                <TextInput text={companyName} setText={setText('companyName')}  placeholder={'[Company name]'} />
              </InputContainer>
            </section>
            <section className={'detail-row'}>
              <InputContainer label={'Role'}>
                <TextInput text={role} setText={setText('role')}  placeholder={'[Role]'} />
              </InputContainer>
            </section>
            <section className={'detail-row'}>
              <InputContainer label={'Brand name'}>
                <TextInput text={brandName} setText={setText('brandName')}  placeholder={'[Brand name]'} />
              </InputContainer>
            </section>
          </section>
          <section className={'details-column times'}>
          <section className={'detail-row'}>
              <InputContainer label={'Employee communication hours (AEDT)'}>
                <CommunicationHours communicationHours={employee} setHours={setCommunicationHours('employee')} />
              </InputContainer>
            </section>
            <section className={'detail-row'}>
              <InputContainer label={'Customer communication hours (AEDT)'}>
                <CommunicationHours communicationHours={customer} setHours={setCommunicationHours('customer')} />
              </InputContainer>
            </section>
          <section className={'detail-row'}>
            <InputContainer label={'Email-to-SMS preferences'}>
              <ToggleInput
                label={'Do not forward SMS responses to email'}
                toggle={toggleEmailToSmsPreference}
                value={DO_NOT_FORWARD}
                checked={emailToSmsPreference === DO_NOT_FORWARD}
              />
              <ToggleInput
                label={'Forward all SMS responses to email'}
                toggle={toggleEmailToSmsPreference}
                value={FORWARD_ALL}
                checked={emailToSmsPreference === FORWARD_ALL}
              />
              <ToggleInput
                label={'Only forward SMS responses that require attention'}
                toggle={toggleEmailToSmsPreference}
                value={REQUIRES_ATTENTION}
                checked={emailToSmsPreference === REQUIRES_ATTENTION}
              />
            </InputContainer>
          </section>
          </section>
        </section>
        <section className={'confirm-button-container'}>
          { changed && <button className={`confirm-button`} onClick={confirm}>{'Confirm'}</button>}
        </section>
      </section>
    </section>
  )
}

EditAccountPage.displayName = 'EditAccountPage';
EditAccountPage.propTypes = propTypes;
export default EditAccountPage;