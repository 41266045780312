import './ButtonWithTooltip.scss';

import React from 'react';

function ButtonWithTooltip({ children, onClick, tooltipText }) {
  return (
    <button className={'button-with-tooltip'} onClick={onClick}>
      {children}
      <section className={'tooltip-container'}>
        <span className={'tooltip'}>
          {tooltipText}
        </span>
      </section>
    </button>
  );
};

ButtonWithTooltip.displayName = 'ButtonWithTooltip';
export default ButtonWithTooltip;