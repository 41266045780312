import './EditIntegrationAPIKeyAndUserModal.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IntegrationModal from './IntegrationModal';

import { postNewIntegration } from '../../../../../../apis/IntegrationApi';
import useAuth from '../../../../../../context/AuthContext';

const propTypes = {};

function EditIntegrationAPIKeyAndUserModal({ type, close }) {
  const { setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const onChangeApiKey = ({ target }) => setApiKey(target.value);
  const onChangeUsername = ({ target }) => setUsername(target.value);
  const onChangePassword = ({ target }) => setPassword(target.value);
  const confirm = () => {
    setLoading(true);
    setError(null);
    postNewIntegration(type, { apiKey, username, password })
      .then((response) => {
        const { user } = response;
        setUser(user);
        setLoading(false);
        close();
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }
  return (
    <IntegrationModal additionalClassNames={['edit-api-key-and-user']} close={close}>
      <h3 className={'modal-title'}>{'Please provide your API key'}</h3>
      <input className={'api-key'} placeholder={'API Key'} value={apiKey} onChange={onChangeApiKey} />
      <h3 className={'modal-title second'}>{'Please provide your username and password'}</h3>
      <input type={'text'} className={'api-key'} placeholder={'Username'} value={username} onChange={onChangeUsername} />
      <input type={'password'} className={'api-key'} placeholder={'Password'} value={password} onChange={onChangePassword} />
      { error && <span className={'error-text'}>{error}</span>}
      <button className={'confirm-button'} onClick={confirm} disabled={loading}>{'Confirm'}</button>
    </IntegrationModal>
  );
}

EditIntegrationAPIKeyAndUserModal.displayName = 'EditIntegrationAPIKeyAndUserModal';
EditIntegrationAPIKeyAndUserModal.propTypes = propTypes;
export default EditIntegrationAPIKeyAndUserModal;