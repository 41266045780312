import './DropdownFieldOption.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {};

function DropdownFieldOption({ option, selected, setSelected, useSearch, dataOptions }) {
  const answerSelected = selected === option;
  const selectedStyle = useSearch ? 'search-selected' : 'selected';

  return (
    <button className={`dropdown-field-button ${answerSelected ? selectedStyle : ''}`} onClick={() => setSelected(option)} type={'button'}>
      <span className={'dropdown-button-text'}>{dataOptions[option].text}</span>
    </button>
  )
}

DropdownFieldOption.defaultProps = { useSearch: false };
DropdownFieldOption.displayText = 'DropdownFieldOption';
DropdownFieldOption.propTypes = propTypes;
export default DropdownFieldOption;