import './CreateTemplateConfirmationModal.scss';

import React from 'react';
import PropTypes from 'prop-types';
import WorkRexModal from '../../../common/WorkRexModal';

const propTypes = {};

function CreateTemplateConfirmationModal({ close, confirm, loading }) {
  return (
    <WorkRexModal close={close} additionalClassNames={['create-template-confirmation']}>
      <p className={'description'}>
        {`Confirm you’d like to publish this template and save it to 'Your published templates'`}
      </p>
      <button className={'confirm-button'} onClick={confirm} disabled={loading}>{'Confirm'}</button>
    </WorkRexModal>
  );
}

CreateTemplateConfirmationModal.displayName = 'CreateTemplateConfirmationModal';
CreateTemplateConfirmationModal.propTypes = propTypes;
export default CreateTemplateConfirmationModal;