import './ManagerDashboardWrapper.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import WorkRexPageWrapper from '../common/WorkRexPageWrapper';

const propTypes = {};

function ManagerDashboardWrapper({ children }) {
  const { pathname } = useLocation();
  const options = [{ displayName: 'Dashboard', href: '/dashboard' }];
  if (pathname.includes('broadcasts')) {
    options.push({ displayName: 'Broadcasts', href: '/dashboard/broadcasts' });
  }
  else if (pathname.includes('analysis')) {
    options.push({ displayName: 'Analysis', href: '/dashboard/analysis' });
  }

  return (
    <WorkRexPageWrapper
      additionalClassNames={['manager-dashboard-page']}
      additionalOptions={options}
    >
      { children }
    </WorkRexPageWrapper>
  );
}

ManagerDashboardWrapper.displayName = 'ManagerDashboardWrapper';
ManagerDashboardWrapper.propTypes = propTypes;
export default ManagerDashboardWrapper;