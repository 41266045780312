import './AutomationFlowUnit.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function AutomationFlowUnit({ selectedIcon, unselectedIcon, selected, text }) {
  const icon = selected ? selectedIcon : unselectedIcon;
  return (
    <section className={'automation-flow-unit'}>
      <section className={'flow-icon-container'}>
        <img className={'flow-icon'} src={icon} />
      </section>
      <p className={'label'}>{text}</p>
    </section>
  )
}

AutomationFlowUnit.displayName = 'AutomationFlowUnit';
AutomationFlowUnit.propTypes = propTypes;
export default AutomationFlowUnit;