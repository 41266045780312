import './PreviewWrapper.scss';

import React from 'react';

import EditableTextInput from '../common/EditableTextInput';

function PreviewWrapper({ title, children, previewImage = null, previewText = null, additionalClassNames = [], setTitle, viewOnly = false }) {
  return (
    <section className={`preview-wrapper ${additionalClassNames.join(' ')}`}>
      <EditableTextInput text={title} setText={setTitle} disableResize={true} disabled={viewOnly} />
      <section className={'preview-content'}>
        <section className={'preview-editor'}>
          {children}
        </section>
       { previewImage !== null && <img className={'preview-image'} src={previewImage} alt={'preview'} /> }
       {previewText}
      </section>
    </section>
  );
};

PreviewWrapper.displayName = 'PreviewWrapper';
export default PreviewWrapper;