import { postData, postFormData } from './Api';

const CAMPAIGN_ROUTES = {
  PUBLISH_CAMPAIGN_URL: '/api/campaign/publish',
  DRAFT_CAMPAIGN_URL: '/api/campaign/draft',
  GENERATE_CHANNEL_OUTPUTS_URL: '/api/campaign/generate',
  ATTACH_FILE_TO_CAMPAIGN_URL: '/api/campaign/attach',
  DELETE_CAMPAIGN_URL: '/api/campaign/delete',
};

const {
  PUBLISH_CAMPAIGN_URL,
  DRAFT_CAMPAIGN_URL,
  GENERATE_CHANNEL_OUTPUTS_URL,
  ATTACH_FILE_TO_CAMPAIGN_URL,
  DELETE_CAMPAIGN_URL,
} = CAMPAIGN_ROUTES;

export const postPublishCampaign = (campaign) => {
  return postData(PUBLISH_CAMPAIGN_URL, { campaign });
}
export const postDraftCampaign = (campaign) => {
  return postData(DRAFT_CAMPAIGN_URL, { campaign });
}
export const postGenerateChannelOutputs = (campaign) => {
  return postData(GENERATE_CHANNEL_OUTPUTS_URL, { campaign });
}
export const postAttachFileToCampaign = (attachment) => {
  const { attachmentType, type, size, fileName, file } = attachment;
  const formData = new FormData();
  formData.append('fileName', fileName);
  formData.append('size', size);
  formData.append('attachmentType', attachmentType);
  formData.append('type', type);
  formData.append('file', file);
  return postFormData(ATTACH_FILE_TO_CAMPAIGN_URL, formData);
}
export const postDeleteCampaign = (campaignId) => {
  return postData(DELETE_CAMPAIGN_URL, { campaignId });
}