import './DraftEngagements.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { ENGAGEMENT_TABS, OUTREACH_OPPORTUNITIES } from '../EngagementConstants';

import EngagementLibrary from '../library/EngagementLibrary';

const { DRAFT_ENGAGEMENTS } = ENGAGEMENT_TABS;
const propTypes = {};

function DraftEngagements({ engagements }) {
  const filteredEngagements = engagements
    .filter(engagement => DRAFT_ENGAGEMENTS.filterState.includes(engagement.state))
    .map(engagement => {
      const engagementTemplate = OUTREACH_OPPORTUNITIES.find(opportunity => engagement.key === opportunity.key);
      return { ...engagementTemplate, state: engagement.state };
    });
  return (
    <EngagementLibrary engagements={filteredEngagements} displayTitle={'Draft engagement opportunities'} tab={DRAFT_ENGAGEMENTS.key}>
    </EngagementLibrary>
  );
}

DraftEngagements.displayName = 'DraftEngagements';
DraftEngagements.propTypes = propTypes;
export default DraftEngagements;