import './AutomationRequestExamples.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

const EXAMPLES_LIST = [
  'Conduct an employee pulse check every month and assign an action to their manager to check-in on those with low engagement',
  'Send customers a thank you message after their appointment and ask for a google review if their experience was positive',
  'Send customers a personalised recall message if it has been 12 or 24 months since their appointment',
  'Conduct an employee performance self-assessment every 3 months and assign an action to their manager to discuss the results',
];

function AutomationRequestExamples() {
  const [showExamples, setShowExamples] = useState(true);
  const toggleShow = () => setShowExamples(!showExamples);
  const examples = EXAMPLES_LIST.map(text => <li className={'example-item'} key={text}>{text}</li>);
  return (
    <section className={'automation-request-examples-container'}>
      <button className={'view-examples-toggle'} onClick={toggleShow}>{'View examples'}</button>
      { showExamples && (
        <ul className={'example-list'}>
          {examples}
        </ul>
      )}
    </section>
  )
}

AutomationRequestExamples.displayName = 'AutomationRequestExamples';
AutomationRequestExamples.propTypes = propTypes;
export default AutomationRequestExamples;