import './Attachment.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function Attachment({ attachment, disabled = false, deleteAttachment }) {
  return (
    <section className={'attachment'}>
      <span className={'attachment-name'}>
        {attachment.fileName}
      </span>
      <section className={'delete-button-container'}>
        <button className={`delete-button ${disabled ? 'disabled' : ''}`} onClick={deleteAttachment} disabled={disabled}>
          <span className={'delete-sign'}>{'×'}</span>
        </button>
      </section>
    </section>
  );
}

Attachment.displayName = 'Attachment';
Attachment.propTypes = propTypes;
export default Attachment;