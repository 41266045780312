import { postData } from './Api';

const ENGAGEMENT_ROUTES = {
  ACTIVATE_ENGAGEMENT_URL: '/api/engagement/activate',
  DELETE_ENGAGEMENT_URL: '/api/engagement/delete',
  REQUEST_ENGAGEMENT_URL: '/api/engagement/request',
  CREATE_ENGAGEMENT_URL: '/api/engagement/create',
};

const { ACTIVATE_ENGAGEMENT_URL, DELETE_ENGAGEMENT_URL, REQUEST_ENGAGEMENT_URL, CREATE_ENGAGEMENT_URL } = ENGAGEMENT_ROUTES;

export const postActivateEngagement = (engagement) => {
  return postData(ACTIVATE_ENGAGEMENT_URL, { engagement });
}

export const postDeleteEngagement = (key) => {
  return postData(DELETE_ENGAGEMENT_URL, { key });
}

export const postEngagementRequest = (engagement) => {
  return postData(REQUEST_ENGAGEMENT_URL, { engagement });
};

export const postEngagementCreation = () => {
  return postData(CREATE_ENGAGEMENT_URL, {});
};
