import BIRTHDAY_ICON from './assets/birthday-assistant-icon.svg';
import MARKETING_ICON from './assets/marketing-assistant-icon.svg';
import REPUTATION_ICON from './assets/reputation-assistant-icon.svg';
import RECALL_ICON from './assets/recall-assistant-icon.svg';
import RECEPTIONIST_ICON from './assets/receptionist-assistant-icon.svg';
import LEADS_ICON from './assets/leads-assistant-icon.svg';
import REACTIVATION_ICON from './assets/reactivation-assistant-icon.svg';
import REORDER_ICON from './assets/reorder-assistant-icon.svg';

import BirthdaySelection from './available-old/selections/BirthdaySelection';
import MarketingSelection from './available-old/selections/MarketingSelection';
import ReputationSelection from './available-old/selections/ReputationSelection';
import RecallSelection from './available-old/selections/RecallSelection';
import ReceptionistSelection from './available-old/selections/ReceptionistSelection';
import LeadSelection from './available-old/selections/LeadsSelection';
import ReactivationSelection from './available-old/selections/ReactivationSelection';
import ReorderSelection from './available-old/selections/ReorderSelection';
import { SALES_STAGE } from '../../recipients/RecipientsConstants';
import { ASSET_CDN_URL } from '../../../../../Constants';

const LIST_STYLE_ICON = `${ASSET_CDN_URL}/icons/rex-messaging-navigation-sub-category-icon.svg`;
const OPP_SCORE_NO_DATA_ICON = `${ASSET_CDN_URL}/icons/workrex_opp_score_no_data.svg`;
const OPP_SCORE_QUARTER_DATA_ICON = `${ASSET_CDN_URL}/icons/workrex_opp_score_quarter_data.svg`;
const OPP_SCORE_HALF_DATA = `${ASSET_CDN_URL}/icons/workrex_opp_score_half_data.svg`;
const OPP_SCORE_THREE_QUARTER_DATA = `${ASSET_CDN_URL}/icons/workrex_opp_score_three_quarter_data.svg`;
const OPP_SCORE_FULL_DATA = `${ASSET_CDN_URL}/icons/workrex_opp_score_full_data.svg`;

const ENGAGEMENT_LIBRARY_ICON = `${ASSET_CDN_URL}/icons/workrex_engagement_library.svg`;
const ACTIVE_ENGAGEMENTS_ICON = `${ASSET_CDN_URL}/icons/workrex_active_engagements.svg`;
const DRAFT_ENGAGEMENTS_ICON = `${ASSET_CDN_URL}/icons/workrex_draft_engagements.svg`;

export const ENGAGEMENT_TABS = {
  ENGAGEMENT_LIBRARY: {
    key: 'ENGAGEMENT_LIBRARY',
    filterState: ['all'],
  },
  ACTIVE_ENGAGEMENTS: {
    key: 'ACTIVE_ENGAGEMENTS',
    filterState: ['active'],
  },
  DRAFT_ENGAGEMENTS: {
    key: 'DRAFT_ENGAGEMENTS',
    filterState: ['draft', 'requested'],
  },
};

const { ENGAGEMENT_LIBRARY, ACTIVE_ENGAGEMENTS, DRAFT_ENGAGEMENTS } = ENGAGEMENT_TABS;

export const ENGAGEMENT_TAB_LIST = [
  {
    key: ACTIVE_ENGAGEMENTS.key,
    displayText: 'Active engagements',
    url: '/engagements/active',
    unselectedIcon: ACTIVE_ENGAGEMENTS_ICON,
    selectedIcon: null,
  },
  {
    key: DRAFT_ENGAGEMENTS.key,
    displayText: 'Draft engagements',
    url: '/engagements/draft',
    unselectedIcon: DRAFT_ENGAGEMENTS_ICON,
    selectedIcon: null,
  },
  {
    key: ENGAGEMENT_LIBRARY.key,
    displayText: 'WorkRex engagement library',
    url: '/engagements/library',
    unselectedIcon: ENGAGEMENT_LIBRARY_ICON,
    selectedIcon: null,
  },
];

export const RECEPTIONIST_TASKS = {
  CUSTOMER_BOOKING_REQUESTS: 'CUSTOMER_BOOKING_REQUESTS',
  CUSTOMER_PURCHASE_ORDERS: 'CUSTOMER_PURCHASE_ORDERS',
  POSITIVE_FEEDBACK: 'POSITIVE_FEEDBACK',
  NEGATIVE_FEEDBACK: 'NEGATIVE_FEEDBACK',
};

const { CUSTOMER_BOOKING_REQUESTS, CUSTOMER_PURCHASE_ORDERS, POSITIVE_FEEDBACK, NEGATIVE_FEEDBACK } = RECEPTIONIST_TASKS;

export const ENGAGEMENT_TYPES = {
  BIRTHDAY: 'BIRTHDAY',
  MARKETING: 'MARKETING',
  REPUTATION: 'REPUTATION',
  RECALL: 'RECALL',
  RECEPTIONIST: 'RECEPTIONIST',
  LEADS: 'LEADS',
  REACTIVATION: 'REACTIVATION',
  REORDER: 'REORDER',
}

const { BIRTHDAY, MARKETING, REPUTATION, RECALL, RECEPTIONIST, LEADS, REACTIVATION, REORDER } = ENGAGEMENT_TYPES;

export const ENGAGEMENTS = {
  BIRTHDAY: {
    key: BIRTHDAY,
    displayText: 'Birthday assistant',
    title: 'Birthday Assistant',
    icon: BIRTHDAY_ICON,
    AssistantComponent: BirthdaySelection,
    context: {
      message: 'The team would like to wish you a happy birthday! Hope you have a fantastic day',
      offers: [
        'Receive 20% off a complete pair of spectacles purchased, eligible for up to 30 days after their birthday',
      ],
      isOfferOn: true,
      autopilot: false,
    },
    active: false,
  },
  MARKETING: {
    key: MARKETING,
    displayText: 'Marketing assistant',
    title: 'Marketing Assistant',
    icon: MARKETING_ICON,
    AssistantComponent: MarketingSelection,
    context: {
      selectedChannels: [],
      frequency: null,
      shouldUseStoreContent: false,
      teamMemberIds: [],
    },
    active: false,
  },
  REPUTATION: {
    key: REPUTATION,
    displayText: 'Reputation assistant',
    title: 'Reputation Assistant',
    icon: REPUTATION_ICON,
    AssistantComponent: ReputationSelection,
    context: {
      autopilot: false,
      frequency: null,
      campaign: null,
      appointmentDateFieldName: null,
    },
    active: false,
  },
  RECALL: {
    key: RECALL,
    displayText: 'Recall assistant',
    title: 'Recall Assistant',
    icon: RECALL_ICON,
    AssistantComponent: RecallSelection,
    context: {
      autopilot: false,
      frequency: null,
      campaign: null,
      contactFrequency: '',
      duration: '',
      appointmentDateFieldName: null,
      teamMemberIds: [],
    },
    active: false,
  },
  LEADS: {
    key: LEADS,
    displayText: 'Leads assistant',
    title: 'Leads Assistant',
    icon: LEADS_ICON,
    AssistantComponent: LeadSelection,
    context: {
      autopilot: false,
      appointmentDateFieldName: null,
      template: null,
      contactFrequency: '',
      trigger: null,
      messages: [],
    },
    active: false,
  },
  REACTIVATION: {
    key: REACTIVATION,
    displayText: 'Reactivation assistant',
    title: 'Reactivation Assistant',
    icon: REACTIVATION_ICON,
    AssistantComponent: ReactivationSelection,
    context: {
      lostCustomerDescription: 'Customer has not visited for 2 years or more',
      checkInMessage: 'The team would love to see you for an eye test',
      offers: [
        'Take $75 off a complete pair of spectacles purchased on your next visit',
      ],
      frequency: null,
      autopilot: false,
    },
    active: false,
  },
  REORDER: {
    key: REORDER,
    displayText: 'Reorder assistant',
    title: 'Reorder Assistant',
    icon: REORDER_ICON,
    AssistantComponent: ReorderSelection,
    context: {
      product: 'Contact lenses',
      checkInMessage: 'How are your contact lenses going? We\'d be happy to re-order you some if you\'ve run out',
      offers: [
        'Take 10% off your purchase amount',
      ],
      frequency: null,
      autopilot: false,
    }
  },
  RECEPTIONIST: {
    key: RECEPTIONIST,
    displayText: 'Receptionist',
    title: 'Receptionist',
    icon: RECEPTIONIST_ICON,
    AssistantComponent: ReceptionistSelection,
    context: {
      tasks: [
        {
          key: CUSTOMER_BOOKING_REQUESTS,
          name: 'Customer booking requests',
          members: [],
          active: false,
        },
        {
          key: CUSTOMER_PURCHASE_ORDERS,
          name: 'Customer purchase orders',
          members: [],
          active: false,
        },
        {
          key: POSITIVE_FEEDBACK,
          name: 'Positive feedback',
          members: [],
          active: false,
        },
        {
          key: NEGATIVE_FEEDBACK,
          name: 'Negative feedback',
          members: [],
          active: false,
        },
      ],
      autoAssignment: false,
      shouldSendSumaryReports: false,
    },
    active: false,
  },
};

export const ENGAGEMENTS_LIST = Object.values(ENGAGEMENTS);

export const WEEK_FREQUENCY = {
  WEEKLY: {
    key: 'WEEKLY',
    displayText: 'Send weekly',
  },
  FORTNIGHTLY: {
    key: 'FORTNIGHTLY',
    displayText: 'Send fortnightly',
  },
  MONTHLY: {
    key: 'MONTHLY',
    displayText: 'Send monthly',
  },
  QUARTERLY: {
    key: 'QUARTERLY',
    displayText: 'Send quarterly',
  }
}

export const DAILY_FREQUENCY = {
  DAILY: {
    key: 'DAILY',
    displayText: 'Send daily',
  },
  TWO_DAILY: {
    key: 'TWO_DAILY',
    displayText: 'Send every 2 days',
  },
  THREE_DAILY: {
    key: 'THREE_DAILY',
    displayText: 'Send every 3 days',
  },
  FOUR_DAILY: {
    key: 'FOUR_DAILY',
    displayText: 'Send every 4 days',
  },
  FIVE_DAILY: {
    key: 'FIVE_DAILY',
    displayText: 'Send every 5 days',
  },
  SIX_DAILY: {
    key: 'SIX_DAILY',
    displayText: 'Send every 6 days',
  },
  SEVEN_DAILY: {
    key: 'SEVEN_DAILY',
    displayText: 'Send every 7 days',
  },
}

export const DISABLED_ENGAGEMENTS = [MARKETING, LEADS];

export const WEEKLY_FREQUENCY_OPTIONS = Object.values(WEEK_FREQUENCY);

export const DAILY_FREQUENCY_OPTIONS = Object.values(DAILY_FREQUENCY);

export const SALES_STAGES_LIST = Object.values(SALES_STAGE);

export const DUMMY_CAMPAIGNS = [
  {
    key: 'C_1',
    displayText: 'Campaign 1',
  },
  {
    key: 'C_2',
    displayText: 'Campaign 2',
  },
  {
    key: 'C_3',
    displayText: 'Campaign 3',
  },
  {
    key: 'C_4',
    displayText: 'Campaign 4',
  }
];

export const APPOINTMENT_DATE_FIELDS = [
  {
    key: 'Appointment',
    displayText: 'Appointment',
  },
  {
    key: 'First Name',
    displayText: 'First Name',
  }
];

export const DUMMY_TEAM_MEMBERS = [
  {
    _id: 1,
    firstName: 'Jane',
    lastName: 'Doe',
  },
  {
    _id: 2,
    firstName: 'Jane',
    lastName: 'Plath',
  },
  {
    _id: 3,
    firstName: 'Jane',
    lastName: 'Irwin',
  },
  {
    _id: 4,
    firstName: 'Jane',
    lastName: 'Finley',
  },
  {
    _id: 5,
    firstName: 'John',
    lastName: 'Doe',
  },
];

export const INDUSTRIES = {
  OPTOMETRY: {
    key: 'OPTOMETRY',
    displayText: 'Optometry',
  }
};

const { OPTOMETRY } = INDUSTRIES;

export const TRIGGER_OBJECTIVES = {
  REVENUE: {
    key: 'REVENUE',
    displayText: 'Revenue',
  },
  ENGAGEMENT: {
    key: 'ENGAGEMENT',
    displayText: 'Engagement',
  },
  LOYALTY: {
    key: 'LOYALTY',
    displayText: 'Loyalty',
  },
  REVIEWS: {
    key: 'REVIEWS',
    displayText: 'Reviews',
  },
  REFERRALS: {
    key: 'REFERRALS',
    displayText: 'Referrals',
  },
}

const { REVENUE, ENGAGEMENT, LOYALTY, REVIEWS, REFERRALS } = TRIGGER_OBJECTIVES;

export const ENGAGEMENT_LEVERS = {
  MILESTONE: {
    key: 'MILESTONE',
    displayText: 'Milestone based',
    icon: LIST_STYLE_ICON,
  },
  PRODUCT_AND_SERVICE: {
    key: 'PRODUCT_AND_SERVICE',
    displayText: 'Product & service based',
    icon: LIST_STYLE_ICON,
  },
  TIME: {
    key: 'TIME',
    displayText: 'Time of year based',
    icon: LIST_STYLE_ICON,
  },
  BEHAVIOUR: {
    key: 'BEHAVIOUR',
    displayText: 'Behaviour based',
    icon: LIST_STYLE_ICON,
  },
  OTHER: {
    key: 'OTHER',
    displayText: 'Other opportunities',
    icon: LIST_STYLE_ICON,
  },
}

const { MILESTONE, PRODUCT_AND_SERVICE, TIME, BEHAVIOUR, OTHER } = ENGAGEMENT_LEVERS;

export const OUTREACH_OPPORTUNITIES = [
  {
    key: BIRTHDAY,
    displayText: 'Customer Birthday',
    title: 'Customer Birthday',
    engagementRationale: 'The customer’s own birthday is an opportunity to personalise and delight',
    description: 'Send a happy birthday message and gift to your customers',
    triggerObjective: [REVENUE.key, ENGAGEMENT.key, LOYALTY.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['birthday'],
    engagementLever: [OTHER.key],
    unitOfImpact: 'Annual revenue (Driven by Appointment Bookings)',
    icon: BIRTHDAY_ICON,
    AssistantComponent: BirthdaySelection,
    context: {
      message: 'The team would like to wish you a happy birthday! Hope you have a fantastic day',
      offers: [
        'Receive 20% off a complete pair of spectacles purchased, eligible for up to 30 days after their birthday',
      ],
      isOfferOn: true,
      autopilot: false,
    },
  },
  {
    key: 'RECENT_APPOINTMENT_REVIEWS',
    displayText: 'Recent Appointment Reviews',
    title: 'Recent Appointment Reviews',
    engagementRationale: 'The customer has recently had their appointment with your business and had a good experience',
    description: 'Ask a favour from your customer to review your business on google',
    triggerObjective: [REVIEWS.key, REFERRALS.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['lastAppointmentDate'],
    engagementLever: [BEHAVIOUR.key],
    unitOfImpact: 'Annual google reviews',
    icon: REPUTATION_ICON,
    AssistantComponent: ReputationSelection,
    context: {
      autopilot: false,
      frequency: null,
      campaign: null,
      appointmentDateFieldName: null,
    },
  },
  {
    key: 'RECENT_APPOINTMENT_REFERRALS',
    displayText: 'Recent Appointment Referrals',
    title: 'Recent Appointment Referrals',
    engagementRationale: 'The customer has recently had their appointment with your business and had a good experience',
    description: 'Provide a referral code to your customer to share with anyone else they’d like',
    triggerObjective: [REVIEWS.key, REFERRALS.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['lastAppointmentDate', 'source'],
    engagementLever: [BEHAVIOUR.key],
    unitOfImpact: 'Annual revenue (Driven by Appointment Bookings)',
    icon: REPUTATION_ICON,
    AssistantComponent: ReputationSelection,
    context: {
      autopilot: false,
      frequency: null,
      campaign: null,
      appointmentDateFieldName: null,
    },
  },
  {
    key: 'RECALL_ACTIVE_CUSTOMERS',
    displayText: 'Recall active customers based on last appointment date',
    title: 'Recall active customers based on last appointment date',
    engagementRationale: 'The customer is due for another appointment or check-up as their annual eye test',
    description: 'Send a reminder for an annual eye test to your customers',
    triggerObjective: [REVENUE.key, LOYALTY.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['lastAppointmentDate'],
    engagementLever: [PRODUCT_AND_SERVICE.key],
    unitOfImpact: 'Annual revenue (Driven by Appointment Bookings)',
    icon: RECALL_ICON,
    AssistantComponent: RecallSelection,
    context: {
      autopilot: false,
      frequency: null,
      campaign: null,
      contactFrequency: '',
      duration: '',
      appointmentDateFieldName: null,
      teamMemberIds: [],
    },
  },
  {
    key: 'CONTACT_LENS_RUNNING_OUT',
    displayText: 'Contact Lens Running Out',
    title: 'Contact Lens Running Out',
    engagementRationale: 'The customer will run out of contact lenses soon and need to re-order',
    description: 'Offer to re-order contact lenses for customers that may soon or have run out',
    triggerObjective: [REVENUE.key, LOYALTY.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['lastTransactionDate'],
    engagementLever: [PRODUCT_AND_SERVICE.key],
    unitOfImpact: 'Annual Revenue (Driven by Purchases)',
    icon: REORDER_ICON,
    AssistantComponent: ReorderSelection,
    context: {
      product: 'Contact lenses',
      checkInMessage: 'How are your contact lenses going? We\'d be happy to re-order you some if you\'ve run out',
      offers: [
        'Take 10% off your purchase amount',
      ],
      frequency: null,
      autopilot: false,
    },
  },
  {
    key: 'SPECTACLES_READJUSTMENT',
    displayText: 'Spectacles Re-adjustment',
    title: 'Spectacles Re-adjustment',
    engagementRationale: 'The customer’s glasses may need a re-adjustment, which helps to build your relationship',
    description: 'Offer to provide a free re-adjustment for customer spectacles after 6 months',
    triggerObjective: [ENGAGEMENT.key, LOYALTY.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['lastTransactionDate'],
    engagementLever: [PRODUCT_AND_SERVICE.key],
    unitOfImpact: 'Annual Revenue (Driven by Purchases)',
    icon: REORDER_ICON,
    AssistantComponent: ReorderSelection,
    context: {
      product: 'Contact lenses',
      checkInMessage: 'How are your contact lenses going? We\'d be happy to re-order you some if you\'ve run out',
      offers: [
        'Take 10% off your purchase amount',
      ],
      frequency: null,
      autopilot: false,
    },
  },
  {
    key: 'SPECTACLES_FITTING_AFTERCARE',
    displayText: 'Spectacles fitting aftercare',
    title: 'Spectacles fitting aftercare',
    engagementRationale: 'The customer’s new glasses may require adjusting after 1 to 2 weeks',
    description: 'Ask if the customer is happy with the adjustment of their new spectacles after 2 weeks',
    triggerObjective: [ENGAGEMENT.key, LOYALTY.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['lastTransactionDate'],
    engagementLever: [PRODUCT_AND_SERVICE.key],
    unitOfImpact: 'Improved Loyalty',
    icon: REORDER_ICON,
    AssistantComponent: ReorderSelection,
    context: {
      product: 'Contact lenses',
      checkInMessage: 'How are your contact lenses going? We\'d be happy to re-order you some if you\'ve run out',
      offers: [
        'Take 10% off your purchase amount',
      ],
      frequency: null,
      autopilot: false,
    },
  },
  {
    key: 'SPECTACLES_NEXT_PURCHASE_PROMOTION',
    displayText: 'Spectacles next-purchase promotion',
    title: 'Spectacles next-purchase promotion',
    engagementRationale: 'Customer has recently made a purchase for a pair of spectacles',
    description: 'Offer customers a promotion for the next time they purchase spectacles from your business',
    triggerObjective: [ENGAGEMENT.key, LOYALTY.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['lastTransactionDate'],
    engagementLever: [PRODUCT_AND_SERVICE.key],
    unitOfImpact: 'Annual Revenue (Driven by repeat purchases)',
    icon: REORDER_ICON,
    AssistantComponent: ReorderSelection,
    context: {
      product: 'Contact lenses',
      checkInMessage: 'How are your contact lenses going? We\'d be happy to re-order you some if you\'ve run out',
      offers: [
        'Take 10% off your purchase amount',
      ],
      frequency: null,
      autopilot: false,
    },
  },
  {
    key: 'REWARD_MULTIPLE_CUSTOMER_PURCHASES',
    displayText: 'Reward multiple customer purchases',
    title: 'Reward multiple customer purchases',
    engagementRationale: 'Customers on their 2nd or greater purchase to the practice, indicating significant loyalty',
    description: 'Reward customers that make multiple purchases',
    triggerObjective: [ENGAGEMENT.key, REFERRALS.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['countAppointments'],
    engagementLever: [MILESTONE.key],
    unitOfImpact: 'Annual Revenue (Driven by repeat purchases)',
    icon: REORDER_ICON,
    AssistantComponent: ReorderSelection,
    context: {
      product: 'Contact lenses',
      checkInMessage: 'How are your contact lenses going? We\'d be happy to re-order you some if you\'ve run out',
      offers: [
        'Take 10% off your purchase amount',
      ],
      frequency: null,
      autopilot: false,
    },
  },
  {
    key: 'REQUEST_REFERRALS_FROM_LOYAL_CUSTOMERS',
    displayText: 'Request referrals from loyal customers',
    title: 'Request referrals from loyal customers',
    engagementRationale: 'Customers on their 2nd or greater purchase to the practice, indicating significant loyalty',
    description: 'Ask for referrals from your most loyal customers',
    triggerObjective: [ENGAGEMENT.key, REFERRALS.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['countAppointments', 'source'],
    engagementLever: [MILESTONE.key],
    unitOfImpact: 'Annual revenue (Driven by Appointment Bookings)',
    icon: REORDER_ICON,
    AssistantComponent: ReorderSelection,
    context: {
      product: 'Contact lenses',
      checkInMessage: 'How are your contact lenses going? We\'d be happy to re-order you some if you\'ve run out',
      offers: [
        'Take 10% off your purchase amount',
      ],
      frequency: null,
      autopilot: false,
    },
  },
  {
    key: 'MULTIPLE_CUSTOMER_VISITS_MADE',
    displayText: 'Multiple Customer Visits Made',
    title: 'Multiple Customer Visits Made',
    engagementRationale: 'Customers on their 2nd or greater purchase to the practice, indicating significant loyalty',
    description: 'Reward customers that make multiple visits',
    triggerObjective: [ENGAGEMENT.key, REFERRALS.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['countAppointments'],
    engagementLever: [MILESTONE.key],
    unitOfImpact: 'Improved Customer Lifetime Value',
    icon: REORDER_ICON,
    AssistantComponent: ReorderSelection,
    context: {
      product: 'Contact lenses',
      checkInMessage: 'How are your contact lenses going? We\'d be happy to re-order you some if you\'ve run out',
      offers: [
        'Take 10% off your purchase amount',
      ],
      frequency: null,
      autopilot: false,
    },
  },
  {
    key: 'CUSTOMER_REACHES_HIGHER_LOYALTY_TIER_BASED_ON_SPEND_VALUE',
    displayText: 'Customer reaches a higher loyalty tier based on spend value',
    title: 'Customer reaches a higher loyalty tier based on spend value',
    engagementRationale: 'Customers once their lifetime spend reaches a certain number',
    description: 'Reward customers that reach a certain lifetime spend with your business',
    triggerObjective: [ENGAGEMENT.key, REFERRALS.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['lifetimeValue'],
    engagementLever: [MILESTONE.key],
    unitOfImpact: 'Improved Customer Lifetime Value',
    icon: REORDER_ICON,
    AssistantComponent: ReorderSelection,
    context: {
      product: 'Contact lenses',
      checkInMessage: 'How are your contact lenses going? We\'d be happy to re-order you some if you\'ve run out',
      offers: [
        'Take 10% off your purchase amount',
      ],
      frequency: null,
      autopilot: false,
    },
  },
  {
    key: 'REACTIVATE_CHURNED_CUSTOMERS_BASED_ON_LAST_APPOINTMENT_DATE',
    displayText: 'Re-activate churned customers based on last appointment date',
    title: 'Re-activate churned customers based on last appointment date',
    engagementRationale: 'Customers that are likely to have churned with no recent visits',
    description: 'Ask if you can be of help to past customers (>2 years), including a promotion to return',
    triggerObjective: [REVENUE.key],
    industries: [OPTOMETRY.key],
    dataRequired: ['lastAppointmentDate'],
    engagementLever: [BEHAVIOUR.key],
    unitOfImpact: 'Annual revenue (Driven by Appointment Bookings)',
    icon: REORDER_ICON,
    AssistantComponent: ReorderSelection,
    context: {
      product: 'Contact lenses',
      checkInMessage: 'How are your contact lenses going? We\'d be happy to re-order you some if you\'ve run out',
      offers: [
        'Take 10% off your purchase amount',
      ],
      frequency: null,
      autopilot: false,
    },
  },
  {
    key: 'ENHANCE_ZERO_PARTY_DATA_BY_ASKING_CUSTOMERS_FOR_THEIR_PREFERENCES',
    displayText: 'Enhance zero-party data by asking customers for their preferences',
    title: 'Enhance zero-party data by asking customers for their preferences',
    engagementRationale: 'Customers with missing or incomplete profiles',
    description: 'Create complete and comprehensive customer profiles through engaging questions',
    triggerObjective: [ENGAGEMENT.key],
    industries: [OPTOMETRY.key],
    dataRequired: [],
    engagementLever: [OTHER.key],
    unitOfImpact: 'Improved engagement',
    icon: REORDER_ICON,
    AssistantComponent: ReorderSelection,
    context: {
      product: 'Contact lenses',
      checkInMessage: 'How are your contact lenses going? We\'d be happy to re-order you some if you\'ve run out',
      offers: [
        'Take 10% off your purchase amount',
      ],
      frequency: null,
      autopilot: false,
    },
  },
  {
    key: 'SEASONAL_BASED_PROMOTIONS',
    displayText: 'Seasonal Based Promotions',
    title: 'Seasonal Based Promotions',
    engagementRationale: 'Calendar-based promotions based on important sales or appointment events',
    description: 'Leverage every sales and engagement opportunity using calendar / seasonal based events',
    triggerObjective: [REVENUE.key],
    industries: [OPTOMETRY.key],
    dataRequired: [],
    engagementLever: [TIME.key],
    unitOfImpact: 'Annual Revenue (Driven by Purchases)',
    icon: REORDER_ICON,
    AssistantComponent: ReorderSelection,
    context: {
      product: 'Contact lenses',
      checkInMessage: 'How are your contact lenses going? We\'d be happy to re-order you some if you\'ve run out',
      offers: [
        'Take 10% off your purchase amount',
      ],
      frequency: null,
      autopilot: false,
    },
  },
]

export const OPPORTUNITY_SCORE = {
  NO_DATA: {
    key: 'NO_DATA',
    icon: OPP_SCORE_NO_DATA_ICON,
  },
  QUARTER_DATA: {
    key: 'QUARTER_DATA',
    icon: OPP_SCORE_QUARTER_DATA_ICON,
  },
  HALF_DATA: {
    key: 'HALF_DATA',
    icon: OPP_SCORE_HALF_DATA,
  },
  THREE_QUARTER_DATA: {
    key: 'THREE_QUARTER_DATA',
    icon: OPP_SCORE_THREE_QUARTER_DATA,
  },
  FULL_DATA: {
    key: 'FULL_DATA',
    icon: OPP_SCORE_FULL_DATA,
  },
};