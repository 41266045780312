import { postData } from './Api';

const LOGIN_ROUTES = {
  SUBMIT_URL: '/api/login/submit',
  VERIFY_URL: '/api/login/verify',
};

const { SUBMIT_URL, VERIFY_URL } = LOGIN_ROUTES;

/**
 * Api function call to send a post request for logging a user in via sms or email.
 * @param {String} loginCredentials - email address or sms number
 * @param {String} loginMethod - enum of either 'email' or 'sms'
 * @param {Boolean} skipVerificationCode - true iff we should skip sending the verification code
 * @returns Promise - resolves login response from server
 */
export const postLogin = async (loginCredentials, loginMethod, skipVerificationCode) => {
  const params = { loginCredentials, loginMethod, skipVerificationCode, originUrl: window.origin };
  return await postData(SUBMIT_URL, params);
};

/**
 * Api function call to send a post request for verifying a user's login code.
 * @param {String} loginCredentials - email address or sms number
 * @param {String} loginMethod - enum of either 'email' or 'sms'
 * @param {String} code - 6 digit verification code
 * @returns Promise - resolves login verification response from server
 */
export const postLoginVerificationCode = async (loginCredentials, loginMethod, code) => {
  const params = { loginCredentials, loginMethod, code };
  return await postData(VERIFY_URL, params);
}