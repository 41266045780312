import { postData } from './Api';

const INTEGRATION_ROUTES = {
  NEW_INTEGRATION_URL: '/api/integration/new',
  REMOVE_INTEGRATION_URL: '/api/integration/remove',
  INTEGRATION_REQUEST_URL: '/api/integration/request',
};

const { NEW_INTEGRATION_URL, REMOVE_INTEGRATION_URL, INTEGRATION_REQUEST_URL } = INTEGRATION_ROUTES;


// TODO: Group under integration routes
export const postNewIntegration = (type, credentials) => {
  return postData(NEW_INTEGRATION_URL, { type, credentials });
}
export const postIntegrationRemoval = (type) => {
  return postData(REMOVE_INTEGRATION_URL, { type });
}
export const postIntegrationRequest = (type) => {
  return postData(INTEGRATION_REQUEST_URL, { type });
}
