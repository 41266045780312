import './ContentFileDescription.scss';

import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { FILE_TYPE_TO_SUFFIX } from '../ContentConstants';
import ContentFileButton from './ContentFileButton';

import DOWNLOAD_ICON from './assets/download-icon.svg';
import EYE_ICON from './assets/eye-icon.svg';
import SHARE_ICON from './assets/share-icon.svg';
import LinkCopiedModal from '../modals/LinkCopiedModal';

import TAG_ICON from './assets/tag-icon.svg';
import REMOVE_ICON from './assets/remove-icon.svg';
import useAuth from '../../../../../../context/AuthContext';
import { postFileDescription, postFileTags } from '../../../../../../apis/ContentApi';

const propTypes = {};

const MAX_TAG_LENGTH = 20;

const AUTOSAVE_TIMEOUT = 2000;

function ContentFileDescription({ fileId, description, setDescription, code, url, title, fileType, tags = [], setTags = [], disabled }) {
  const { setUser } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const timeoutIdRef = useRef(null);
  const [tag, setTag] = useState('');
  const [tagError, setTagError] = useState('');
  const onChange = ({ target }) => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      postFileDescription(fileId, target.value)
        .then((res) => setUser(res.user));
    }, AUTOSAVE_TIMEOUT);
    setDescription(target.value);
  };
  const navigate = useNavigate();
  const viewFile = () => navigate(`/content/${code}`);
  const close = () => setModalOpen(false);
  const downloadFile = () => {
    /** TODO: Create an endpoint to fetch file from S3 and send it back over. */
    /**
     * fetch(`/api/content/fetch/${code}`, {
        method: 'GET',
        headers: {
          'Content-Type': contentType,
        },
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
    );
     */
    const a = document.createElement('a');
    a.href = url;
    a.setAttribute('download', `${title}${FILE_TYPE_TO_SUFFIX[fileType]}`);
    a.setAttribute('target', '_blank');
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
    return;
  };
  const copyFileLink = async () => {
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/content/${code}`);
      setModalOpen(true);
    }
    catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  const onTagChange = ({ target }) => {
    if (target.value.length <= MAX_TAG_LENGTH) {
      setTag(target.value);
    }
  };
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (tags.length < 6) {
        if (!tags.includes(tag)) {
          setTags([...tags, tag]);
          setTag('');
          postFileTags(fileId, [...tags, tag])
            .then((res) => setUser(res.user));
        }
      }
      else {
        setTagError('You can only have up to 6 tags');
      }
    }
  }
  const tagList = tags.map((tag, currentIndex) => {
    const removeTag = () => {
      if (disabled) {
        return;
      }
      setTags(tags.filter((_, index) => index !== currentIndex));
      postFileTags(fileId, tags.filter((_, index) => index !== currentIndex))
        .then((res) => setUser(res.user));
      setTagError('')
    }
    return (
      <li key={tag} className={'tag'}>
        {tag}
        { !disabled &&
          <button className={'remove-tag-button'} onClick={removeTag}>
            <img className={'remove-icon'} src={REMOVE_ICON} alt={'remove tag'} />
          </button>
        }
      </li>
    );
});
  return (
    <section className={'content-file-description'}>
      { modalOpen && <LinkCopiedModal close={close} /> }
      <section className={'file-description-input-container'}>
        <label className={'file-description-label'} htmlFor={'description-input'}>{'Edit description'}</label>
        <textarea
          className={'file-description-input'}
          id={'description-input'}
          value={description}
          onChange={onChange}
          disabled={disabled}
        />
      </section>
      <section className={'tag-editor'}>
        <section className={'tag-input-container'}>
          <img className={'tag-icon'} src={TAG_ICON} alt={'tag icon'} />
          <input type={'text'} className={'tag-input'} placeholder={'Add a tag...'} value={tag} onChange={onTagChange} onKeyDown={onKeyDown} disabled={disabled} />
        </section>
        { tagError && <span className={'error-text'}>{tagError}</span> }
        <ul className={'tags'}>
          {tagList}
        </ul>
      </section>
      <section className={'buttons'}>
        <ContentFileButton buttonText={'View file'} buttonIcon={EYE_ICON} onClick={viewFile} />
        <ContentFileButton buttonText={'Download file'} buttonIcon={DOWNLOAD_ICON} onClick={downloadFile} />
        <ContentFileButton buttonText={'Share file link'} buttonIcon={SHARE_ICON} onClick={copyFileLink} />
      </section>
    </section>
  );
}

ContentFileDescription.displayName = 'ContentFileDescription';
ContentFileDescription.propTypes = propTypes;
export default ContentFileDescription;