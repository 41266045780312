import { FILE_TYPES, CONTENT_TABS } from '../ContentConstants';

import LUNAR_NEW_YEAR_ONE from './assets/lunar-new-year-1.png';
import LUNAR_NEW_YEAR_TWO from './assets/lunar-new-year-2.png';
import LUNAR_NEW_YEAR_THREE from './assets/lunar-new-year-3.png';
import LUNAR_NEW_YEAR_FOUR from './assets/lunar-new-year-4.png';

import HAPPY_NEW_YEAR_ONE from './assets/happy-new-year-1.png';
import HAPPY_NEW_YEAR_TWO from './assets/happy-new-year-2.png';
import HAPPY_NEW_YEAR_THREE from './assets/happy-new-year-3.png';
import HAPPY_NEW_YEAR_FOUR from './assets/happy-new-year-4.png';

import HAPPY_HOLIDAYS_ONE from './assets/happy-holidays-1.png';
import HAPPY_HOLIDAYS_TWO from './assets/happy-holidays-2.png';
import HAPPY_HOLIDAYS_THREE from './assets/happy-holidays-3.png';
import HAPPY_HOLIDAYS_FOUR from './assets/happy-holidays-4.png';

import HAPPY_BIRTHDAY_ONE from './assets/happy-birthday-1.png';
import HAPPY_BIRTHDAY_TWO from './assets/happy-birthday-2.png';
import HAPPY_BIRTHDAY_THREE from './assets/happy-birthday-3.png';
import HAPPY_BIRTHDAY_FOUR from './assets/happy-birthday-4.png';

const { LIBRARY } = CONTENT_TABS;
const { PNG } = FILE_TYPES;

export const WORKREX_CONTENT_LIBRARY = [
  {
    _id: 1,
    title: 'Lunar New Year 1',
    code: 'AAAA',
    description: 'Graphic that can be used for Happy Lunar New Year promotions or announcements',
    fileType: PNG,
    url: LUNAR_NEW_YEAR_ONE,
    status: LIBRARY,
    tags: ['Lunar New Year'],
  },
  {
    _id: 2,
    title: 'Lunar New Year 2',
    code: 'AAAB',
    description: 'Graphic that can be used for Happy Lunar New Year promotions or announcements',
    fileType: PNG,
    url: LUNAR_NEW_YEAR_TWO,
    status: LIBRARY,
    tags: ['Lunar New Year'],
  },
  {
    _id: 3,
    title: 'Lunar New Year 3',
    code: 'AAAC',
    description: 'Graphic that can be used for Happy Lunar New Year promotions or announcements',
    fileType: PNG,
    url: LUNAR_NEW_YEAR_THREE,
    status: LIBRARY,
    tags: ['Lunar New Year'],
  },
  {
    _id: 4,
    title: 'Lunar New Year 4',
    code: 'AAAD',
    description: 'Graphic that can be used for Happy Lunar New Year promotions or announcements',
    fileType: PNG,
    url: LUNAR_NEW_YEAR_FOUR,
    status: LIBRARY,
    tags: ['Lunar New Year'],
  },
  {
    _id: 5,
    title: 'New Year 1',
    code: 'AAAE',
    description: 'Graphic that can be used for Happy New Year promotions or announcements',
    fileType: PNG,
    url: HAPPY_NEW_YEAR_ONE,
    status: LIBRARY,
    tags: ['New Year'],
  },
  {
    _id: 6,
    title: 'New Year 2',
    code: 'AAAF',
    description: 'Graphic that can be used for Happy New Year promotions or announcements',
    fileType: PNG,
    url: HAPPY_NEW_YEAR_TWO,
    status: LIBRARY,
    tags: ['New Year'],
  },
  {
    _id: 7,
    title: 'New Year 3',
    code: 'AAAG',
    description: 'Graphic that can be used for Happy New Year promotions or announcements',
    fileType: PNG,
    url: HAPPY_NEW_YEAR_THREE,
    status: LIBRARY,
    tags: ['New Year'],
  },
  {
    _id: 8,
    title: 'New Year 4',
    code: 'AAAH',
    description: 'Graphic that can be used for Happy New Year promotions or announcements',
    fileType: PNG,
    url: HAPPY_NEW_YEAR_FOUR,
    status: LIBRARY,
    tags: ['New Year'],
  },
  {
    _id: 9,
    title: 'Happy Holidays 1',
    code: 'AAAI',
    description: 'Graphic that can be used for general Holidays promotions and/or announcements',
    fileType: PNG,
    url: HAPPY_HOLIDAYS_ONE,
    status: LIBRARY,
    tags: ['Happy Holidays'],
  },
  {
    _id: 10,
    title: 'Happy Holidays 2',
    code: 'AAAJ',
    description: 'Graphic that can be used for general Holidays promotions and/or announcements',
    fileType: PNG,
    url: HAPPY_HOLIDAYS_TWO,
    status: LIBRARY,
    tags: ['Happy Holidays'],
  },
  {
    _id: 11,
    title: 'Happy Holidays 3',
    code: 'AAAK',
    description: 'Graphic that can be used for general Holidays promotions and/or announcements',
    fileType: PNG,
    url: HAPPY_HOLIDAYS_THREE,
    status: LIBRARY,
    tags: ['Happy Holidays'],
  },
  {
    _id: 12,
    title: 'Happy Holidays 4',
    code: 'AAAL',
    description: 'Graphic that can be used for general Holidays promotions and/or announcements',
    fileType: PNG,
    url: HAPPY_HOLIDAYS_FOUR,
    status: LIBRARY,
    tags: ['Happy Holidays'],
  },
  {
    _id: 13,
    title: 'Happy Birthday 1',
    code: 'AAAM',
    description: 'Graphic that can be used for offers to recipients that have birthdays upcoming',
    fileType: PNG,
    url: HAPPY_BIRTHDAY_ONE,
    status: LIBRARY,
    tags: ['Happy Birthday'],
  },
  {
    _id: 14,
    title: 'Happy Birthday 2',
    code: 'AAAN',
    description: 'Graphic that can be used for offers to recipients that have birthdays upcoming',
    fileType: PNG,
    url: HAPPY_BIRTHDAY_TWO,
    status: LIBRARY,
    tags: ['Happy Birthday'],
  },
  {
    _id: 15,
    title: 'Happy Birthday 3',
    code: 'AAAO',
    description: 'Graphic that can be used for offers to recipients that have birthdays upcoming',
    fileType: PNG,
    url: HAPPY_BIRTHDAY_THREE,
    status: LIBRARY,
    tags: ['Happy Birthday'],
  },
  {
    _id: 16,
    title: 'Happy Birthday 4',
    code: 'AAAP',
    description: 'Graphic that can be used for offers to recipients that have birthdays upcoming',
    fileType: PNG,
    url: HAPPY_BIRTHDAY_FOUR,
    status: LIBRARY,
    tags: ['Happy Birthday'],
  },
]