import './AnalyticsTable.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function AnalyticsTable({ additionalClassNames = [], headers = [], rows = [], noRowsText, rowToExpand, children = null, loading = false }) {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [clickedCell, setClickedCell] = useState(null);
  const handleMouseOver = (hoveredRow) => () => {
    setHoveredRow(hoveredRow)
    setClickedCell(null);
  };
  const handleMouseOut = () => {
    setHoveredRow(null);
    setClickedCell(null);
  };
  const handleClick = (cellIndex) => () => {
    setHoveredRow(null);
    setClickedCell(cellIndex);
  };

  const header = (
    <thead className={`table-header ${additionalClassNames.join(' ')}`}>
      <tr className={'table-row'}>
        {headers.map(({ display }, index) => <th className={`table-header-cell`} key={`header-${index}`}><span className={'table-item'}>{display}</span></th>)}
      </tr>
    </thead>
  );
  const tableRows = rows.map((row, x) => (
    <>
      <tr className={`table-row ${hoveredRow === x ? 'hovering' : ''}`} key={`row-${x}`} onMouseOver={handleMouseOver(x)} onMouseOut={handleMouseOut}>
        <th tabIndex={x * 10} className={`table-row-header-cell ${rowToExpand === x ? 'display-channels' : ''} ${hoveredRow === x ? 'hovering' : ''} ${clickedCell === (x * 10) ? 'clicked-highlight' : ''}`} onClick={handleClick(x * 10)} key={`row-header-${x}`}><span className={'table-item'}>{row[headers[0].key]}</span></th>
        {headers.slice(1,).map(({ key }, y) => <td tabIndex={(x * 10) + y + 1} className={`table-cell ${key} ${rowToExpand === x ? 'hide-borders' : ''} ${clickedCell === (x * 10) + y + 1 ? 'clicked-highlight' : ''}`} onClick={handleClick((x * 10) + y + 1)} key={`cell-${x}-${y}`}>{<span className={'table-item'}>{row[key]}</span>}</td>)}
      </tr>
      {rowToExpand === x &&
        Object.keys(row.channelAnalytics).map((channel, channelIndex) => (
          <tr className={`table-row`} key={`row-${x}-${channel}`} onMouseOver={handleMouseOver(x)} onMouseOut={handleMouseOut}>
            <th className={`table-row-channel-cell ${Object.keys(row.channelAnalytics).length - 1 === channelIndex ? 'last-channel' : ''}`} onClick={handleClick(x * 10)} key={`row-header-${x}-${channel}`}><span className={'table-item channel-analytic'}>{channel}</span></th>
            {headers.slice(1,).map(({ key }, y) => <td tabIndex={(x * 10) + y + 1} className={`table-cell ${Object.keys(row.channelAnalytics).length - 1 !== channelIndex ? 'not-last-channel' : ''} ${key}`} key={`cell-${x}-${y}`}>{<span className={'table-item'}>{row.channelAnalytics[channel][key]}</span>}</td>)}
          </tr>
        ))
      }
    </>
  ));
  return (
    <section className={'analytics-overflow-container'}>
      <section className={'analytics-table-container'}>
        <table className={`analytics-table ${additionalClassNames.join(' ')}`}>
          {header}
          <tbody>
            {tableRows}
          </tbody>
        </table>
        {!loading && rows.length === 0 && <span className={'no-rows-text'}>{noRowsText}</span>}
        {children}
      </section>
    </section>
  );
}

AnalyticsTable.displayName = 'AnalyticsTable';
AnalyticsTable.propTypes = propTypes;
export default AnalyticsTable;