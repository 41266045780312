import './TemplatesPage.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Outlet, useLocation } from 'react-router-dom';
import TemplatesPageWrapper from './TemplatesPageWrapper';
import TemplateTypeNavigationTabs from './common/TemplateTypeNavigationTabs';
import { TEMPLATE_PAGE_TYPES } from './TemplatesConstants';

const { PUBLISHED, DRAFT, LIBRARY } = TEMPLATE_PAGE_TYPES;

const propTypes = {};

function TemplatesPage({ templateCounts }) {
  const { pathname } = useLocation();
  let showTabs = true;
  let selectedTab = null;
  if (pathname.includes('new') || pathname.includes('view')) {
    showTabs = false;
  }
  if (pathname.includes('published')) {
    selectedTab = PUBLISHED;
  }
  else if (pathname.includes('draft')) {
    selectedTab = DRAFT;
  }
  else if (pathname.includes('library')) {
    selectedTab = LIBRARY;
  }
  return (
    <TemplatesPageWrapper showTabs={showTabs}>
      { showTabs && <TemplateTypeNavigationTabs selectedTab={selectedTab} templateCounts={templateCounts} /> }
      <Outlet />
    </TemplatesPageWrapper>
  );
}

TemplatesPage.displayName = 'TemplatesPage';
TemplatesPage.propTypes = propTypes;
export default TemplatesPage;