import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import ContentPageWrapper from './ContentPageWrapper';
import NavigationTabList from '../../common/NavigationTabList';

import { CONTENT_TAB_LIST, CONTENT_TABS } from './ContentConstants';

const { LIBRARY, UNPUBLISHED, PUBLISHED } = CONTENT_TABS;

const propTypes = {};

function ContentManagementPage({ showTabs = true, counts = null }) {
  const { pathname } = useLocation();
  let additionalOptions = [];
  let selectedTab = null;
  if (pathname.includes('published')) {
    selectedTab = PUBLISHED;
    additionalOptions = [{ displayName: 'Published content', href: '/content/published' }];
  }
  if (pathname.includes('unpublished')) {
    selectedTab = UNPUBLISHED;
    additionalOptions = [{ displayName: 'Unpublished content', href: '/content/unpublished' }];
  }
  if (pathname.includes('library')) {
    selectedTab = LIBRARY;
    additionalOptions = [{ displayName: 'WorkRex content library', href: '/content/library' }];
  }
  return (
    <ContentPageWrapper additionalOptions={additionalOptions}>
      { showTabs && <NavigationTabList selectedTab={selectedTab} tabs={CONTENT_TAB_LIST} counts={counts} additionalClassNames={['content-navigation']} /> }
      <Outlet />
    </ContentPageWrapper>
  );
}

ContentManagementPage.displayName = 'ContentManagementPage';
ContentManagementPage.propTypes = propTypes;
export default ContentManagementPage;