import './CampaignSidebar.scss';

import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import PropTypes from 'prop-types';

import AddButton from '../../../common/AddButton';
import SearchInput from '../../../common/SearchInput';
import FilterButtonList from '../../../common/FilterButtonList';

import DIAMOND_LIST_ICON from '../assets/diamond-list-icon.svg';
import { EMPTY_CAMPAIGN } from '../CampaignConstants';


const propTypes = {};

function CampaignSidebar({ searchText, setSearchText, isLibrary, selectedTag, selectTag, tags}) {
  const { setCampaign } = useOutletContext();
  const navigate = useNavigate();
  const filterList = tags.map((tag) => {
    return {
      buttonText: tag,
      icon: DIAMOND_LIST_ICON,
      selected: selectedTag === tag,
      onClick: () => selectTag(tag),
    };
  });
  const createCampaign = () => {
    setCampaign(EMPTY_CAMPAIGN);
    window.localStorage.removeItem('campaign');
    navigate('/campaigns/create');
  }
  return (
    <aside className={'campaign-sidebar'}>
      <AddButton buttonText={'Add campaign'} onClick={createCampaign} />
      <span className={'divider'} />
      <SearchInput searchText={searchText} setSearchText={setSearchText} />
      { isLibrary && <FilterButtonList filters={filterList} /> }
    </aside>
  );
}

CampaignSidebar.displayName = 'CampaignSidebar';
CampaignSidebar.propTypes = propTypes;
export default CampaignSidebar;