import './SearchDropdown.scss';

import React, { useState, useRef } from 'react';

import { useOutsideClick } from '../../../../../../../../hooks/ClickHooks';

import SEARCH_ICON from '../assets/search.svg';

function SearchDropdown({ options, selected, select }) {
  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  let optionList = options
    .sort((a, b) => a.displayText.localeCompare(b.displayText))
    .filter(({ displayText }) => displayText.toLowerCase().includes(searchText.toLowerCase()))
    .map(({ key, displayText }) => {
      const onClick = () => {
        select(key);
        setOpen(false);
      }
      return (
        <li key={key} className={`search-item ${selected === key ? 'selected' : ''}`}>
          <button className={'search-item-button'} onClick={onClick}>
            {displayText}
          </button>
        </li>
      )
    });
  if (optionList.length === 0) {
    optionList = <span className={'no-results'}>{'No results found'}</span>;
  }
  const openDropdown = () => setOpen(true);
  const closeDropdown = () => setOpen(false);
  const onChangeText = ({ target }) => setSearchText(target.value);
  useOutsideClick(dropdownRef, closeDropdown);
  return (
    <section className={'search-dropdown-container'} ref={dropdownRef}>
      <section className={'input-container'}>
        <input
          className={'search-dropdown-input'}
          placeholder={'Search campaigns'}
          onFocus={openDropdown}
          onChange={onChangeText}
        />
        <img className={'icon'} src={SEARCH_ICON} alt={''} />
      </section>
      { open &&
        <section className={'dropdown-container'}>
          <ul className={'search-dropdown-list'}>
            {optionList}
          </ul>
        </section>
      }
    </section>
  )
}

export default SearchDropdown;