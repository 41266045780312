import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { COMMUNICATION_TABS } from '../CommunicationsConstants';
import CommunicationsPageWrapper from '../CommunicationsPageWrapper';

const { CAMPAIGNS } = COMMUNICATION_TABS;

const propTypes = {};

function CampaignPageWrapper({ children, showTabs = true, additionalClassNames = [], additionalOptions = [] }) {
  const location = useLocation();
  const options = [{ displayName: 'Campaigns', href: '/campaigns' }, ...additionalOptions];
  return (
    <CommunicationsPageWrapper
      additionalClassNames={['campaign-page', ...additionalClassNames]}
      additionalOptions={options}
      selectedTab={CAMPAIGNS}
      showNavigationTabs={showTabs}
    >
      { children }
    </CommunicationsPageWrapper>
  );
}

CampaignPageWrapper.displayName = 'CampaignPageWrapper';
CampaignPageWrapper.propTypes = propTypes;
export default CampaignPageWrapper;