import React from 'react';
import PhoneInput from 'react-phone-input-2';

import LoginInput from './LoginInput';

function LoginInputContainer({ checkSubmit, error, inputLabel, loginDetails, loginMethod, onChange }) {
  const hasError = error.length > 0;
  const hasInput = loginDetails[loginMethod].length > 0;

  const login = loginMethod === 'sms'
    ? (
      <LoginInput error={error} inputLabel={inputLabel}>
        <PhoneInput
          country={'au'}
          onlyCountries={['au', 'nz']}
          value={loginDetails[loginMethod]}
          onChange={(sms) => onChange(loginMethod)(sms)}
          onKeyDown={(e) => checkSubmit(e)}
          containerClass={`${hasInput ? 'has-input' : ''} ${hasError ? 'has-error' : ''}`}
        />
      </LoginInput>
    )
    :
    <LoginInput error={error} inputLabel={inputLabel}>
      <input className={`login-input ${hasInput ? 'has-input' : ''} ${hasError ? 'has-error' : ''}`} onChange={(e) => onChange(loginMethod)(e)} type={'text'} value={loginDetails[loginMethod]} />
    </LoginInput>

  return (
    <form onSubmit={checkSubmit}>
      {login}
    </form>
  )
}

export default LoginInputContainer;