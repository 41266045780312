import './RecipientsView.scss';

import React from 'react';

import RecipientsTable from './RecipientsTable';
import EmptyRecipients from './EmptyRecipients';

function RecipientsView({ recipients, selectedRecipients, selectRecipient  }) {
  const recipientList = recipients.length === 0
    ? <EmptyRecipients />
    : <RecipientsTable
        recipients={recipients}
        selectedRecipients={selectedRecipients}
        selectRecipient={selectRecipient}
      />;
  return (
    <section className={'recipients-view'}>
      {recipientList}
    </section>
  );
};

RecipientsView.displayName = 'RecipientsView';
export default RecipientsView;