import './AllEngagements.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ENGAGEMENT_TABS, OUTREACH_OPPORTUNITIES } from '../EngagementConstants';

import EngagementLibrary from '../library/EngagementLibrary';
import ToggleButton from '../available-old/common/ToggleButton';

const { ENGAGEMENT_LIBRARY } = ENGAGEMENT_TABS;
const propTypes = {};

function AllEngagements({ engagements }) {
  const [showAvailable, setShowAvailable] = useState(false);
  const toggleAvailableFilter = () => setShowAvailable(!showAvailable);
  const formattedEngagements = OUTREACH_OPPORTUNITIES
    .map(opportunity => {
      const existingEngagement = engagements.find(engagement => opportunity.key === engagement.key);
      return existingEngagement ? { ...opportunity, ...existingEngagement } : opportunity;
    })
  return (
    <EngagementLibrary engagements={formattedEngagements} displayTitle={'Your engagement opportunities'} showAvailable={showAvailable} tab={ENGAGEMENT_LIBRARY.key}>
      <section className={'show-available-section'}>
        <h6 className={'available-section-title'}>{'Show available only'}</h6>
        <ToggleButton active={showAvailable} setActive={toggleAvailableFilter} disabled={false} />
      </section>
    </EngagementLibrary>
  );
}

AllEngagements.displayName = 'AllEngagements';
AllEngagements.propTypes = propTypes;
export default AllEngagements;