import './ContentLibrary.scss';

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import ContentLibraryList from './ContentLibraryList';
import ContentLibrarySidebar from './ContentLibrarySidebar';
import LinkCopiedModal from '../modals/LinkCopiedModal';
import UploadContentModal from '../modals/UploadContentModal';
import { postRemoveFileTag } from '../../../../../../apis/ContentApi';
import useAuth from '../../../../../../context/AuthContext';

const propTypes = {};

function ContentLibrary({ files = [] }) {
  const { setUser } = useAuth();
  const [searchParams, _] = useSearchParams();
  const tag = searchParams.get('tag');
  const hasTag = files.flatMap(({ tags }) => tags).includes(tag);
  const [searchText, setSearchText] = useState('');
  const [selectedTag, setFilterTag] = useState(hasTag ? tag : null);
  const [linkCopied, setLinkCopied] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const filteredFiles = files
    .filter(({ title, tags }) =>
      title.toLowerCase().includes(searchText.toLowerCase()) && (selectedTag === null || tags.includes(selectedTag)));
  const tags = [...new Set(files.flatMap(({ tags }) => tags))];
  const removeTag = (tag) => {
    postRemoveFileTag(tag)
      .then(res => setUser(res.user));
    if (selectedTag === tag) {
      setFilterTag(null);
    }
  }
  const openUploadModal = () => setUploadModalOpen(true);
  return (
    <section className={'content-library'}>
      { linkCopied && <LinkCopiedModal close={() => setLinkCopied(false)} /> }
      { uploadModalOpen && <UploadContentModal close={() => setUploadModalOpen(false)} />}
      <ContentLibrarySidebar
        searchText={searchText}
        setSearchText={setSearchText}
        tags={tags}
        selectedTag={selectedTag}
        setFilterTag={setFilterTag}
        setLinkCopied={setLinkCopied}
        remove={removeTag}
        openUploadModal={openUploadModal}
      />
      <ContentLibraryList files={filteredFiles} setLinkCopied={setLinkCopied} />
    </section>
  );
}

ContentLibrary.displayName = 'ContentLibrary';
ContentLibrary.propTypes = propTypes;
export default ContentLibrary;