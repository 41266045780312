import './EmptyRecipients.scss';

import React from 'react';

function EmptyRecipients() {
  return (
    <section className={'empty-recipients-container'}>
      <span className={'empty-recipients'}>
        {'No recipients selected. Import recipients or add recipients through manual search or with filters.'}
      </span>
    </section>
  );
};

EmptyRecipients.displayName = 'EmptyRecipients';
export default EmptyRecipients;