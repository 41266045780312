import { usePortal } from "@flatfile/react";
import { DEFAULT_WORKBOOK_FIELDS, customer_workbook, employee_workbook, roleTypeField, additionalFields } from "./workbooks";
import { listener } from "./listener";
import { useEffect } from "react";

import useAuth from '../../../../../context/AuthContext';
import MessagingConsumer from '../../../../../context/MessagingContext';
import { getManagerUser, getMoreCustomersToCustomersTable } from "../../../../../apis/Api";
import { nonProdFlatFileAuth, prodFlatFileAuth } from "../../../../../Constants";
import { calculateDerivedFields, getRelatedInteractions } from "../../../../../utils/DataUtils";

const { environmentId, publishableKey } = prodFlatFileAuth;
const { source, address, favouriteBrands, customerSegmentCategory } = additionalFields;

const FlatfilePortal = ({ employeeView, closeFlatFileModal, setEmployeeRows, setCustomerRows, pageCount }) => {
  const workbook = employeeView ? employee_workbook : customer_workbook;
  const { user, setUser } = useAuth();
  const { interactions } = MessagingConsumer();
  const groupMap = user.groups.reduce((groupMapping, group) => ({ ...groupMapping, [group._id]: group.name.toLowerCase() }), {});

  const updateUser = () => {
    getManagerUser()
      .then(({ user }) => {
        getMoreCustomersToCustomersTable(pageCount)
          .then(response => {
            const userObj = { ...user, ...response };
            setUser(prevState => ({ ...prevState, ...userObj }));
            setEmployeeRows(user.recipients);

            const updatedCustomers = userObj.customers
              .map((customer) => {
                const relatedInteractions = getRelatedInteractions(customer, interactions);
                const formattedBirthday = customer.birthday ? new Date(customer.birthday).toLocaleString('en-au', { day: 'numeric', month: 'numeric', year: 'numeric' }) : null;
                return calculateDerivedFields({ ...customer, group: groupMap[customer.groupId], formattedBirthday, interactions: relatedInteractions });
              })
            setCustomerRows(updatedCustomers);
          })
      })
      .finally(() => {
        closeFlatFileModal();
      })
  }

  useEffect(() => {
    // groupField.config.options = user.groups.map(group => ({ value: group.name, label: group.name, color: '#ff0000', icon: 'fa fa-ban' }));
    // const additionalFields = employeeView ? [ groupField, roleTypeField ] : [ groupField ];
    const additionalFields = employeeView ? [ roleTypeField ] : [ source, address, favouriteBrands, customerSegmentCategory ];
    const allFields = [ ...DEFAULT_WORKBOOK_FIELDS, ...additionalFields ];
    workbook.sheets[0].fields = allFields;
  }, []);

  return usePortal({
    environmentId,
    publishableKey,
    spaceBody: {
      namespace: "portal",
    },
    listener,
    workbook,
    closeSpace: {
      operation: "closeFlatFile",
      onClose: updateUser,
    },
  });
};

export default FlatfilePortal;