import './ChannelDropdownOption.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {};

function ChannelDropdownOption({ option, selected, setSelected, useSearch }) {
  const answerSelected = selected.includes(option.key);
  const selectedStyle = useSearch ? 'search-selected' : 'selected';

  return (
    <button className={`channel-dropdown-button ${answerSelected ? selectedStyle : ''}`} onClick={() => setSelected(option.key)} type={'button'}>
      <span className={'channel-dropdown-button-text'}>{option.displayText}</span>
    </button>
  )
}

ChannelDropdownOption.defaultProps = { useSearch: false };
ChannelDropdownOption.displayText = 'ChannelDropdownOption';
ChannelDropdownOption.propTypes = propTypes;
export default ChannelDropdownOption;