import './AddButton.scss';

import React from 'react';
import PropTypes from 'prop-types';

import PLUS_ICON from './assets/plus-icon.svg';

const propTypes = {};

function AddButton({ buttonText, onClick }) {
  return (
    <button className={'add-button'} onClick={onClick}>
      <span className={'text'}>{buttonText}</span>
      <img className={'plus-icon'} src={PLUS_ICON} alt={'Plus icon'} />
    </button>
  );
}

AddButton.displayName = 'AddButton';
AddButton.propTypes = propTypes;
export default AddButton;