import './VariableTextSuggestions.scss';

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { sortArrayFn } from '../../../../../../utils/DataUtils';
import { SPECIAL_KEYWORDS, KEYWORD_TYPES } from '../../CommunicationsConstants';

const { VARIABLE } = KEYWORD_TYPES;

const propTypes = {};

function VariableTextSuggestions({ left, top, keywordInput, onSelect, disableKeyMovement = false }) {
  const [selectionIndex, setSelectionIndex] = useState(0);
  const listRef = useRef(null);
  const possibleVariables = Object.values(SPECIAL_KEYWORDS)
    .filter(({ type }) => type === VARIABLE)
    .sort(sortArrayFn('displayText'))
    .filter(({ keyword }) => keyword.toLowerCase().includes(keywordInput.toLowerCase()));
  const variableList = possibleVariables
    .map(({ keyword, displayText }, index) => {
      const onClick = () => {
        onSelect(keyword);
      }
      const selected = selectionIndex === index && !disableKeyMovement;
      return (
        <li className={`variable-name ${selected ? 'selected' : ''}`} key={keyword}>
          <button className={'variable-selection'} onClick={onClick}>
            <span className={'variable'}>{displayText}</span>
          </button>
        </li>
      );
  });
  const numVariables = possibleVariables.length;
  const onKeyDown = (e) => {
    const { key } = e;
    if (numVariables === 0 || disableKeyMovement) {
      return;
    }
    if (key === 'ArrowUp') {
      const newSelectionIndex = selectionIndex === 0 ? numVariables - 1 : selectionIndex - 1;
      setSelectionIndex(newSelectionIndex);
      listRef.current.scrollTo(0, newSelectionIndex * 31.5);
    }
    else if (key === 'ArrowDown') {
      const newSelectionIndex = selectionIndex === numVariables - 1 ? 0 : selectionIndex + 1;
      setSelectionIndex(newSelectionIndex);
      listRef.current.scrollTo(0, newSelectionIndex * 31.5);
    }
    else if (key === 'Enter') {
      onSelect(possibleVariables[selectionIndex].keyword);
      e.preventDefault();
    }
  }
  useEffect(() => setSelectionIndex(0), [numVariables]);
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown, true);
    return () => window.removeEventListener('keydown', onKeyDown, true);  
  }, [selectionIndex, numVariables]);
  return (
    <section className={`variable-text-suggestions ${disableKeyMovement ? 'key-movement-disabled' : ''}`}>
      <section className={'variable-list-container'} style={{ left, top }}>
        <ul className={'variable-list'} ref={listRef}>
          {variableList}
        </ul>
        { !disableKeyMovement && 
          <span className={'variable-select-text'}>
            {'hit enter to select variable'}
          </span>
        }
      </section>
    </section>
  );
}

VariableTextSuggestions.displayName = 'VariableTextSuggestions';
VariableTextSuggestions.propTypes = propTypes;
export default VariableTextSuggestions;