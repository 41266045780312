import './ContactCard.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../Constants';
import { CONTACT_TYPES } from '../../../MessagingConstants';
import GenericLabel from './GenericLabel';

const { CUSTOMER } = CONTACT_TYPES;

const unreadIcon = `${ASSET_CDN_URL}/icons/rex-messaging-unread-icon.svg`;

const propTypes = {};

function ContactCard({ children, contact, setRecord, selectedContact }) {
  const { firstName = '', lastName = '', contactType, hasUnreadAction } = contact;
  const hasFirstAndLastName = firstName && lastName;
  const contactFullName = hasFirstAndLastName ? `${firstName} ${lastName}` : (firstName ? firstName : lastName);
  const additionalClassNames = contactType === CUSTOMER.key ? ['customer-contact'] : ['employee-contact'];
  const selected = contact && selectedContact && contact._id === selectedContact._id;

  return (
    <button className={`contact-card-container ${selected ? 'selected' : ''}`} onClick={() => setRecord(contact)} type={'button'}>
      <section className={'contact-card-details'}>
        <section className={'contact-name-container'}>
          <span className={'contact-name'}>{contactFullName}</span>
          {hasUnreadAction && <img alt={'unread/incomplete task'} className={'unread-task'} src={unreadIcon} />}
        </section>
        <GenericLabel additionalClassNames={additionalClassNames} text={CONTACT_TYPES[contactType].text} />
      </section>
      {children}
    </button>
  )
}

ContactCard.displayName = 'ContactCard';
ContactCard.propTypes = propTypes;
export default ContactCard;