import './CampaignList.scss';

import React from 'react';
import PropTypes from 'prop-types';
import CampaignRow from './CampaignRow';

const propTypes = {};

function CampaignList({ campaigns, searchText, selectedTag = null }) {
  console.log({ campaigns });
  const campaignList = campaigns
    .filter(({ name }) => name.toLowerCase().includes(searchText.toLowerCase()))
    .filter(({ tag }) =>  selectedTag === null || tag === selectedTag)
    .map((campaign) => {
      const { _id, name, description, creationDate, channels, channelOutputs, type } = campaign;
      const numVariants = Object.keys(channelOutputs).reduce((acc, key) => acc + channelOutputs[key].length, 0);
      return (
        <CampaignRow
          key={_id}
          campaignId={_id}
          name={name}
          creationDate={new Date(creationDate)}
          description={description}
          channels={channels}
          numVariants={numVariants}
          type={type}
          campaign={campaign}
        />
      );
    })
  return (
    <ul className={'campaign-list'}>
      {campaignList}
    </ul>
  );
}

CampaignList.displayName = 'CampaignList';
CampaignList.propTypes = propTypes;
export default CampaignList;