import './CreateCampaignDisplay.scss';

import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import CampaignInputSection from './common/CampaignInputSection';
import CampaignTextInput from './common/CampaignTextInput';
import PlusButton from '../../engagements/available-old/common/PlusButton';
import AddOfferModal from './modals/AddOfferModal';
import ViewOffersModal from './modals/ViewOffersModal';
import ChannelSelectionOptions from '../common/ChannelSelectionOptions';
import { postGenerateChannelOutputs } from '../../../../../../apis/CampaignApi';

import { CHANNELS, EMPTY_CAMPAIGN, EMPTY_CHANNEL_PREVIEW, PENDING_CHANNEL_LIST } from '../CampaignConstants';

const { EMAIL, SMS, WHATSAPP, FACEBOOK, INSTAGRAM, LINKEDIN, VOICEMAIL } = CHANNELS;

const CHANNEL_LIST = [EMAIL, SMS, WHATSAPP, FACEBOOK, INSTAGRAM, LINKEDIN, VOICEMAIL].map(({ key }) => key);

function CreateCampaignDisplay({ shouldUseAI, editing = true, viewOnly = false }) {
  const { campaign, setCampaign } = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const navigate = useNavigate();
  const { name, description, objective, offers, channels } = campaign;
  const setName = (name) => setCampaign({ ...campaign, name });
  const setDescription = (description) => setCampaign({ ...campaign, description });
  const setObjective = (objective) => setCampaign({ ...campaign, objective });
  const setChannels = (channels) => {
    const channelOutputs = campaign.channelOutputs || {};
    channels.forEach((channel) => {
      const channelArray = channelOutputs[channel] || [];
      const channelLength = channelArray.length;
      const offersLength = offers.length > 0 ? offers.length : 1;
      const lengthDifference = Math.max(offersLength - channelLength, 0);
      console.log({ channel, channelArray, channelLength, offersLength, lengthDifference });
      let remainingChannels = lengthDifference > 0 ? Array(lengthDifference).fill({ ...EMPTY_CHANNEL_PREVIEW[channel] }) : [];
      remainingChannels = remainingChannels.map((output, index) => ({ ...output, name: `Variant ${channelLength + index + 1}` }));
      channelOutputs[channel] = [...channelArray, ...remainingChannels];
    });
    setCampaign({ ...campaign, channels, channelOutputs })
  };
  const setOffers = (offers) => {
    const channelOutputs = {};
    channels.forEach((channel) => {
      channelOutputs[channel] = Array(offers.length > 0 ? offers.length : 1).fill({ ...EMPTY_CHANNEL_PREVIEW[channel] });
      channelOutputs[channel] = channelOutputs[channel].map((output, index) => ({ ...output, name: `Variant ${index + 1}` }));
    });
    setCampaign({ ...campaign, offers, channelOutputs })
  };
  const addOffer = (offer) => setOffers([...offers, offer]);
  const viewAddModal = () => {
    setOpenViewModal(false);
    setOpenAddModal(true);
  }
  const cancel = () => {
    setCampaign(EMPTY_CAMPAIGN);
    window.localStorage.removeItem('campaign');
    navigate('/campaigns');
  }
  const continueToChannels = () => {
    if (shouldUseAI && !viewOnly) {
      // TODO: Implement AI backend to generate channels
      setLoading(true);
      postGenerateChannelOutputs(campaign)
        .then((response) => {
          setLoading(false);
          const { channelOutputs } = response;
          setCampaign({ ...campaign, channelOutputs });
          navigate('/campaigns/create/ai/channels');
        });
    }
    else if (viewOnly) {
      navigate('/campaigns/view/channels');
    }
    else {
      navigate('/campaigns/create/new/channels');
    }
  }
  const hideOffers = viewOnly && offers.length === 0;
  let campaignTitle = '';
  if (viewOnly) {
    campaignTitle = 'View your campaign';
  }
  else {
    campaignTitle = editing ? 'Edit your campaign' : 'Create your campaign';
  }
  const disabled = name.length === 0 || channels.length === 0;
  return (
    <section className={'create-campaign-display'}>
      { openAddModal && <AddOfferModal close={() => setOpenAddModal(false)} confirm={addOffer} /> }
      { openViewModal && <ViewOffersModal close={() => setOpenViewModal(false)} addOffer={viewAddModal} offers={offers} setOffers={setOffers} viewOnly={viewOnly} /> }
      <h3 className={'title'}>{campaignTitle}</h3>
      <section className={'campaign-inputs'}>
        <CampaignInputSection title={'Campaign name'}>
          <CampaignTextInput setText={setName} text={name} placeholder={'Provide a name for your campaign'} disabled={viewOnly} />
        </CampaignInputSection>
        <CampaignInputSection title={'Campaign description'}>
          <CampaignTextInput setText={setDescription} text={description} placeholder={'Describe what your campaign should do'} disabled={viewOnly} />
        </CampaignInputSection>
        <CampaignInputSection title={'Campaign objective'}>
          <CampaignTextInput setText={setObjective} text={objective} placeholder={'Describe the goal of this campaign. Eg. generate more orders'} disabled={viewOnly} />
        </CampaignInputSection>
       { !hideOffers &&
          <CampaignInputSection title={'Campaign offers'} description={'Add multiple offers to test which one is more effective'}>
            <section className={'offer-buttons'}>
              { !viewOnly && <PlusButton onClick={() => setOpenAddModal(true)} buttonText={'Add offer'} /> }
              { offers.length > 0 &&
                <button className={'view-offers-button'} onClick={() => setOpenViewModal(true)}>
                  {`View your offers (${offers.length})`}
                </button>
              }
            </section>
          </CampaignInputSection>
        }
        <CampaignInputSection title={'Select channels'}>
          <ChannelSelectionOptions availableOptions={PENDING_CHANNEL_LIST} selectedChannels={channels} setSelectedChannels={setChannels} showTitle={false} viewOnly={viewOnly} />
        </CampaignInputSection>
      </section>
      <section className={'create-campaign-buttons'}>
        <button className={'cancel-button'} onClick={cancel}>{'Cancel'}</button>
        <button
          className={`continue-button ${disabled || loading ? 'disabled' : ''}`}
          onClick={continueToChannels}
          disabled={disabled || loading}
        >
          {loading ? 'Loading...' : 'Continue'}
        </button>
      </section>
    </section>
  );
};

CreateCampaignDisplay.displayName = 'CreateCampaignDisplay';
export default CreateCampaignDisplay;