import './CampaignTextInput.scss';

import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

const MIN_TEXTAREA_HEIGHT = 32;

function CampaignTextInput({ text, setText, placeholder, additionalButton = null, additionalClassNames = [], disabled = false }) {
  const textareaRef = React.useRef(null);
  const onChange = ({ target }) => {
    setText(target.value)
  };
  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    if (textareaRef.current.scrollHeight < MIN_TEXTAREA_HEIGHT) {
      return;
    }
    textareaRef.current.style.height = "inherit";
    // Set height
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      MIN_TEXTAREA_HEIGHT
    )}px`;
  }, [text]);
  return (
    <section className={`campaign-text-input-container ${additionalClassNames.join(' ')}`}>
      <section className={'campaign-text-input-container-border'}>
        <textarea className={'campaign-text-input'} value={text} onChange={onChange} placeholder={placeholder} ref={textareaRef} disabled={disabled} />
      </section>
    {additionalButton}
    </section>
  );
}

CampaignTextInput.displayName = 'CampaignTextInput';
CampaignTextInput.propTypes = propTypes;
export default CampaignTextInput;