import './DotBricksLoading.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  /* Text to inform user regarding purpose of loading screen */
  text: PropTypes.string,
};

function DotBricksLoading({ text, isFixed = true, tableSize = false }) {
  const fixedPositioning = isFixed ? '' : 'absolute' ;
  const tableSized = tableSize ? 'table-size' : '';
  return (
    <section className={`stage ${fixedPositioning} ${tableSized}`}>
      <p className={`dot-bricks-text ${fixedPositioning}`}>{text}</p>
      <span className={`dot-bricks ${fixedPositioning}`}/>
    </section>
  )
}

DotBricksLoading.defaultProps = { text: '' };
DotBricksLoading.displayName = 'DotBricksLoading';
DotBricksLoading.propTypes = propTypes;
export default DotBricksLoading;
