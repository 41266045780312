import './ToggleButtonWithText.scss';

import React from 'react';

function ToggleButtonWithText({ additionalClassNames = [], active, setToggleAutomation, onLabel = 'Active', offLabel = 'Not active' }) {
  return (
    <label className={`toggle ${additionalClassNames.join(' ')}`}>
      <input type={'checkbox'} checked={active} onClick={() => setToggleAutomation(!active)} />
      <span className={`slider ${additionalClassNames.join(' ')}`}></span>
      <span className={`labels ${additionalClassNames.join(' ')}`} data-on={onLabel} data-off={offLabel}></span>
    </label>
  )
}

export default ToggleButtonWithText;