import './CommunicationsNavigationTab.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {};

function CommunicationsNavigationTab({ displayText, selected, href, style = null }) {
  const navigate = useNavigate();
  const navigateTo = (href) => () => navigate(href);
  return (
    <button
      className={`communications-nav-button ${selected ? 'selected' : ''}`}
      onClick={navigateTo(href)}
      style={style}
    >
      {displayText}
    </button>
  )
}

CommunicationsNavigationTab.displayName = 'CommunicationsNavigationTab';
CommunicationsNavigationTab.propTypes = propTypes;
export default CommunicationsNavigationTab;