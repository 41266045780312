import './ContentLibrarySidebar.scss';

import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddButton from '../../../common/AddButton';
import SearchInput from '../../../common/SearchInput';
import FilterButtonList from '../../../common/FilterButtonList';

import WHITE_UPLOAD_ICON from '../assets/white-upload-icon.svg';
import DIAMOND_LIST_ICON from '../assets/diamond-list-icon.svg';
import EllipsisDropdown from '../../../common/EllipsisDropdown';

const propTypes = {};

function ContentLibrarySidebar({ searchText, setSearchText, tags, setFilterTag, selectedTag, remove, setLinkCopied, openUploadModal }) {
  const { pathname } = useLocation();
  const filters = tags.map(tag => {
    const options = [
      {
        displayText: 'Share tag',
        onClick: async () => {
          try {
            await navigator.clipboard.writeText(`${window.location.origin}${pathname}?tag=${tag}`);
            setLinkCopied(true)
          }
          catch (err) {
            console.error('Failed to copy: ', err);
          }
        
        },
      },
      {
        displayText: 'Remove tag',
        onClick: () => remove(tag),
      }
    ]
    return {
      buttonText: tag,
      onClick: () => {
        if (selectedTag === tag) {
          setFilterTag(null);
        }
        else {
          setFilterTag(tag);
        }
      },
      selected: selectedTag === tag,
      icon: DIAMOND_LIST_ICON,
      additionalComponent: <EllipsisDropdown options={options} />
    };
  });
  const disabled = true;
  return (
    <aside className={'content-library-sidebar'}>
      <AddButton buttonText={'Add content'} onClick={openUploadModal} />
      <span className={'divider'} />
      <SearchInput searchText={searchText} setSearchText={setSearchText} />
      <FilterButtonList filters={filters} />
      { !disabled &&
      <button className={'share-library-button'}>
        <img className={'share-library-icon'} src={WHITE_UPLOAD_ICON} alt={''} />
        <span className={'button-text'}>{'Share library'}</span>
      </button>
      }
    </aside>
  );
}

ContentLibrarySidebar.displayName = 'ContentLibrarySidebar';
ContentLibrarySidebar.propTypes = propTypes;
export default ContentLibrarySidebar;