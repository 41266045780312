import './WhatsAppPreview.scss';

import React from 'react';
import PreviewWrapper from './PreviewWrapper';

import DirectMessage from './common/DirectMessage';
import SEND_ICON from './assets/send-icon.svg';

function WhatsAppPreview({ preview, setPreview, proceed = null, title = 'WhatsApp', goToNextVariant, goToPreviousVariant, addVariant, hasMultipleVariants, viewOnly = false, removeVariant }) {
  const previewTextInput = preview.message ? preview.message : 'Type your message here...';
  const previewText = (
    <section className={'preview-text'}>
      <h5 className={'preview-text-title'}>{'Preview'}</h5>
      <section className={'preview-box'}>
        <section className={'preview-message'} dangerouslySetInnerHTML={{ __html: previewTextInput }} />
        <section className={'preview-footer'}>
          <p className={'preview-footer-text'}>{'Write something...'}</p>
          <span className={'send-button'}>
            <img className={'send-icon'} src={SEND_ICON} alt={'Send icon'} />
          </span>
        </section>
      </section>
    </section>
  );
  const setTitle = (name) => setPreview({ ...preview, name });
  return (
    <PreviewWrapper title={title} previewText={previewText} additionalClassNames={['whatsapp-preview']} setTitle={setTitle} viewOnly={viewOnly}>
      <DirectMessage
        preview={preview}
        setPreview={setPreview}
        proceed={proceed}
        goToNextVariant={goToNextVariant}
        goToPreviousVariant={goToPreviousVariant}
        addVariant={addVariant}
        removeVariant={removeVariant}
        hasMultipleVariants={hasMultipleVariants}
        viewOnly={viewOnly}
      />
    </PreviewWrapper>
  );
};

WhatsAppPreview.displayName = 'WhatsAppPreview';
export default WhatsAppPreview;