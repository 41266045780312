import './EngagementDataField.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useOutsideClick } from '../../../../../../../hooks/ClickHooks';
import EditModalDropdown from '../../../messaging/list/views/tasks/EditModalDropdown';

const additionalClassNames = ['data-field-popup'];
const additionalHoverClassNames = ['engagement-hover'];
const propTypes = {};

function EngagementDataField({ availableDataFields, dataRequired, hoverData, firstEngagement }) {
  const { hoverText = '', hoverEnabled = false } = hoverData;
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => noDataRequirement
    ? setIsHovering(false)
    : setIsHovering(true);
  const handleMouseOut = () => noDataRequirement
    ? setIsHovering(false)
    : setIsHovering(false);
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef(null);
  useOutsideClick(modalRef, () => setOpenModal(false));
  const toggleModalState = (e) => {
    e.preventDefault();
    setOpenModal(!openModal);
  }

  const noDataRequirement = dataRequired.length === 0;
  const dataFieldDisplay = !noDataRequirement
    ? <span className={`data-field ${availableDataFields.includes(dataRequired[0]) ? 'available' : ''}`}>{`@${dataRequired[0]}`}</span>
    : <span className={'data-field none-required'}>{'None Required'}</span>;

  const modalDataFieldMap = dataRequired.map(dataField => 
    <span className={`data-field full-width-variable ${availableDataFields.includes(dataField) ? 'available' : ''}`}>{`@${dataField}`}</span>
  )

  const moreThanOneField = dataRequired.length - 1 > 0;
  const moreThanOneDataFieldText = moreThanOneField ? `+${dataRequired.length - 1}` : '';
  return (
    <button
      className={'data-field-engagement'}
      disabled={noDataRequirement}
      onClick={toggleModalState}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      ref={modalRef}
      type={'button'}
    >
      {dataFieldDisplay}
      {moreThanOneField && <span className={'additional-data-required'}>{moreThanOneDataFieldText}</span>}
      {openModal && (
        <EditModalDropdown additionalClassNames={firstEngagement ? [ ...additionalClassNames, 'first-engagement' ] : additionalClassNames}>
          {modalDataFieldMap}
        </EditModalDropdown>
      )}
      {!openModal && isHovering && hoverEnabled &&
        <EditModalDropdown additionalClassNames={firstEngagement ? [ ...additionalHoverClassNames, 'first-engagement' ] : additionalHoverClassNames}>
          {hoverText}
        </EditModalDropdown>
      }
    </button>
  );
}

EngagementDataField.displayName = 'EngagementDataField';
EngagementDataField.propTypes = propTypes;
export default EngagementDataField;