import './BroadcastContactCard.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { ASSET_CDN_URL } from '../../../../../../../../Constants';
import ContactCard from '../ContactCard';

const uncheckedSelectIcon = `${ASSET_CDN_URL}/icons/rex-unchecked-select-icon.svg`;
const checkedSelectIcon = `${ASSET_CDN_URL}/icons/rex-checked-select-icon.svg`;

const propTypes = {};

function BroadcastContactCard({ contact, selectedContact, broadcastList, setSelected, setRecord = () => {} }) {

  const { _id } = contact;
  const checkboxIcon = broadcastList.includes(contact._id) ? checkedSelectIcon : uncheckedSelectIcon;

  const updateBroadcastList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelected(_id);
  }

  return (
    <ContactCard contact={contact} setRecord={setRecord} selectedContact={selectedContact}>
      <button className={'broadcast-card-checkbox'} onClick={updateBroadcastList} type={'button'}>
        <img alt={'broadcast-checkbox'} className={'broadcast-checkbox'} src={checkboxIcon} />
      </button>
    </ContactCard>
  )
}

BroadcastContactCard.displayName = 'BroadcastContactCard';
BroadcastContactCard.propTypes = propTypes;
export default BroadcastContactCard;