import './UploadContentModal.scss';

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import WorkRexModal from '../../../common/WorkRexModal';
import Attachment from '../../templates/common/Attachment';
import { postContentFile } from '../../../../../../apis/ContentApi';
import { FILE_TYPES } from '../ContentConstants';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../../../context/AuthContext';

const { PNG, JPEG, PDF, MP4, CSV, SVG } = FILE_TYPES;

const propTypes = {};

function getFileType(type) {
  if (type.includes('image/png')) {
    return PNG;
  }
  else if (type.includes('image/jpeg') || type.includes('image/jpg')) {
    return JPEG;
  }
  else if (type.includes('video/mp4')) {
    return MP4;
  }
  else if (type.includes('application/pdf')) {
    return PDF;
  }
  else if (type.includes('text/csv')) {
    return CSV;
  }
  else if (type.includes('image/svg')) {
    return SVG;
  }
  return type;
}

function UploadContentModal({ close }) {
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const attachmentRef = useRef(null);
  const inputRef = useRef(null);
  const [draggingOver, setDraggingOver] = useState(false);
  const extractFile = (newFile) => {
    const { name,  size, type } = newFile;
    setError(null);
    const newFileObject = { fileName: name, url: '', size, type, file: newFile, fileType: getFileType(type) };
    return newFileObject;
  }
  const handleFile = ({ target })  => {
    const newFile = target.files[0];
    if (!newFile) {
      return;
    }
    const fileWithoutUrl = extractFile(newFile);
    setFile(fileWithoutUrl);
  }

  const onDragOver = (event) => {
    event.preventDefault();
    setDraggingOver(true);
  }

  const onDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    const newFile = files.length > 0 ? files[0] : file;
    const fileWithoutUrl = extractFile(newFile);
    setFile(fileWithoutUrl);
    setError(null);
    setDraggingOver(false);
  }

  const onClick = async () => {
    setLoading(true);
    const response = await postContentFile(file);
    setUser(response.user);
    setLoading(false);
    navigate(`/file/${response.file.code}`);
  }

  const deleteAttachment = () => setFile(null);
  const clearValue = () => inputRef.current.value = '';

  return (
    <WorkRexModal close={close} additionalClassNames={['upload-content-modal']}>
      <section className={'modal-description'}>
        <h4 className={'attachment-modal-title'}>{`Add content`}</h4>
        <p className={'description'}>{'Stored securely and never shared without your permission'}</p>
      </section>
      <section className={`attachment-container ${draggingOver ? 'dragging' : ''}`} ref={attachmentRef} onDragOver={onDragOver} onDrop={onDrop}>
        { error && <span className={'error-text'}>{error}</span>}
        <input ref={inputRef} type={'file'} id={'attachment-file-input'} className={'attachment-input'} onChange={handleFile} />
        <label htmlFor={'attachment-file-input'} className={'attachment-input-label'} onClick={clearValue}>{draggingOver ? 'Drop here' :  'Choose file'}</label>
        <p className={'input-tip'}>{'or drag and drop file here'}</p>
        { file && <Attachment attachment={file} disabled={loading} deleteAttachment={deleteAttachment} />}
      </section>
      <button className={`upload-button ${loading ? 'loading' : ''}`} onClick={onClick} disabled={loading}>
        {loading ? 'Uploading...' : 'Upload'}
      </button>
    </WorkRexModal>
  );
}

UploadContentModal.displayName = 'UploadContentModal';
UploadContentModal.propTypes = propTypes;
export default UploadContentModal;