import './TaskDetailContainer.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ACTION_VIEWS, DEFAULT_TASK } from '../../../../MessagingConstants';
import { postManyTaskArchive, postManyTaskReminders } from '../../../../../../../../apis/Api';
import useAuth from '../../../../../../../../context/AuthContext';
import MessagingConsumer from '../../../../../../../../context/MessagingContext';

import CreateTaskModal from '../modal/CreateTaskModal';
import Modal from '../../../../../../../common/Modal';
import TaskDetail from './TaskDetail';

const propTypes = {};

function TaskDetailContainer({ selectedRecord, setSelectedRecord, viewFilters }) {
  const { user } = useAuth();
  const { setTasks } = MessagingConsumer();
  const [showModal, setShowModal] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const closeArchiveModal = () => setShowArchiveModal(false);
  const openArchiveModal = () => setShowArchiveModal(true);

  const [showReminderModal, setShowReminderModal] = useState(false);
  const closeReminderModal = () => setShowReminderModal(false);
  const openReminderModal = () => setShowReminderModal(true);

  const createTask = () => {
    setCreateMode(true);
    openModal();
  }

  const editTask = () => {
    setCreateMode(false);
    openModal();
  }

  const sendReminders = () => {
    if (selectedRecord) {
      postManyTaskReminders([selectedRecord._id], user._id)
        .then(response => {
          const newTaskHistory = { taskId: selectedRecord._id, creationDate: Date.now(), createdById: user._id, createdBy: user, description: 'Reminder sent' };
          setSelectedRecord({ ...selectedRecord, taskHistory: [ newTaskHistory, ...selectedRecord.taskHistory ] });
        })
        .finally(() => {
          closeReminderModal();
        })
    }
  }

  const archiveTask = () => {
    if (selectedRecord) {
      postManyTaskArchive([selectedRecord], user._id)
        .then(response => {
          setTasks(prevState => {
            return prevState.filter(prevTasks => prevTasks._id !== selectedRecord._id);
          });
          setSelectedRecord(null);
        })
        .finally(closeArchiveModal)
    }
  }

  return (
    <section className={'task-detail'}>
      <section className={'task-header'}>
        <button className={'task-button'} onClick={createTask} type={'button'}>{'New task +'}</button>
      </section>
      {selectedRecord && 
        <TaskDetail
          editTask={editTask}
          openArchiveModal={openArchiveModal}
          openReminderModal={openReminderModal}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          viewFilters={viewFilters}
        />
      }
      {showModal &&
        <CreateTaskModal closeModal={closeModal} modalType={ACTION_VIEWS.TASKS} selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} existingTask={createMode ? DEFAULT_TASK : selectedRecord} createMode={createMode} />
      }
      {showArchiveModal &&
        <Modal closeModal={closeArchiveModal} additionalClassNames={['archive']}>
          <section className={'archive-task-modal'}>
            <p className={'archive-text'}>{'Confirm archiving of this task which will remove this from your task list.'}</p>
            <button className={'confirm-archive'} onClick={archiveTask} type={'button'}>{'Archive'}</button>
          </section>
        </Modal>
      }
      {showReminderModal &&
        <Modal closeModal={closeReminderModal} additionalClassNames={['archive']}>
          <section className={'archive-task-modal'}>
            <p className={'archive-text'}>{'Are you sure you want to send a reminder for this task?'}</p>
            <button className={'confirm-archive'} onClick={sendReminders} type={'button'}>{'Send Reminder'}</button>
          </section>
        </Modal>
      }
    </section>
  )
}

TaskDetailContainer.displayName = 'TaskDetailContainer';
TaskDetailContainer.propTypes = propTypes;
export default TaskDetailContainer;