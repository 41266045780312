import './AutomationActionCard.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { SPECIAL_KEYWORDS } from '../../../CommunicationsConstants';

const propTypes = {};

const getTemplateText = (displayText, type) => {
  return `<span class="container" contenteditable="false"><span class="${type}" contenteditable="false">${displayText}</span></span>`;
}

const processText = (text) => {
  let processedText = text;
  for (const specialKeyword of Object.values(SPECIAL_KEYWORDS)) {
    const { keyword, displayText, type } = specialKeyword;
    processedText = processedText.replaceAll(`@${keyword}`, getTemplateText(displayText, type));
  }
  const matches = processedText.match('@[0-9]+');
  if (matches) {
    for (const match of [...matches]) {
      const number = match.replaceAll('@', '');
      processedText = processedText.replaceAll(`@${number}`, getTemplateText(number, 'numeric'));
    }
  }
  return processedText;
}

function AutomationActionCard({ title, actionNumber, totalActions, target, trigger, output }) {
  const targetDescription = processText(target);
  const triggerDescription = processText(trigger);
  const outputDescription = processText(output);
  return (
    <section className={'automation-action-card'}>
      <h5 className={'action-header'}>
        <span className={'action-title'}>{title}</span>
        <span className={'action-number'}>{`(${actionNumber}/${totalActions})`}</span>
      </h5>
      <section className={'action-details'}>
        <section className={'detail-container'}>
          <p className={'label'}>{'Who:'}</p>
          <p className={'detail'} dangerouslySetInnerHTML={{ __html: targetDescription }} />
        </section>
        <section className={'detail-container'}>
          <p className={'label'}>{'Triggered when:'}</p>
          <p className={'detail'} dangerouslySetInnerHTML={{ __html: triggerDescription }} />
        </section>
        <section className={'detail-container'}>
          <p className={'label'}>{'What happens:'}</p>
          <p className={'detail'} dangerouslySetInnerHTML={{ __html: outputDescription }} />
        </section>
      </section>
    </section>
  );
}

AutomationActionCard.displayName = 'AutomationActionCard';
AutomationActionCard.propTypes = propTypes;
export default AutomationActionCard;