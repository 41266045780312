import './ConfirmCampaignModal.scss';

import React from 'react';
import PropTypes from 'prop-types';
import WorkRexModal from '../../../common/WorkRexModal';

const propTypes = {};

function ConfirmCampaignModal({ close, confirm, loading }) {
  return (
    <WorkRexModal close={close} additionalClassNames={['confirm-campaign-modal']}>
      <p className={'description'}>
        {'Once you confirm, we will do final checks on the campaign and let you know when it\’s live'}
      </p>
      <button className={'confirm-button'} onClick={confirm} disabled={loading}>{'Confirm'}</button>
    </WorkRexModal>
  );
}

ConfirmCampaignModal.displayName = 'ConfirmCampaignModal';
ConfirmCampaignModal.propTypes = propTypes;
export default ConfirmCampaignModal;