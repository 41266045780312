import './SearchBar.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import SearchInput from '../../../common/SearchInput';
import { useOutsideClick } from '../../../../../../hooks/ClickHooks';

const propTypes = {};

function SearchBar({ options, fieldName = null, keyName = null, onClick, placeholder }) {
  const searchRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const setText = (text) => {
    setSearchText(text);
    if (text.length > 0) {
      setSearchOpen(true);
    }
  }
  const searchList = options
    .filter((option) => {
      if (fieldName === null) {
        return option.toLowerCase().startsWith(searchText.toLowerCase())
      }
      return option[fieldName].toLowerCase().startsWith(searchText.toLowerCase());
    })
    .map((option) => {
      const key = keyName === null ? option : option[keyName];
      const displayText = fieldName === null ? option : option[fieldName];
      const resultClick = () => {
        setSearchOpen(false);
        onClick(key);
      }
      return (
        <li className={'search-result'} key={key}>
          <button className={'result-button'} onClick={resultClick}>{displayText}</button>
        </li>
      );
    })
  useOutsideClick(searchRef, () => setSearchOpen(false));

  const searchResults = searchList.length > 0
    ? <section className={'search-list-container'}><ul className={'search-list'}>{searchList}</ul></section>
    : <section className={'search-list-container'}><span className={'no-results'}>{'No results found'}</span></section>;
  return (
    <section className={'search-bar-container'} ref={searchRef}>
      <SearchInput searchText={searchText} setSearchText={setText} placeholder={placeholder} />
      { searchOpen && searchResults }
    </section>
  );
}

SearchBar.displayName = 'SearchBar';
SearchBar.propTypes = propTypes;
export default SearchBar;