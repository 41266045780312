import './ActivityLogEntry.scss';

import React from 'react';
import PropTypes from 'prop-types';
import AutomationRunLogEntry from './AutomationRunLogEntry';

import { AUTOMATION_ACTIVITY_EVENT_TYPES } from '../../../AutomationsConstants';

const { AUTOMATION_RUN } = AUTOMATION_ACTIVITY_EVENT_TYPES;

const propTypes = {};

function ActivityLogEntry({ eventType, context }) {
  switch (eventType) {
    case AUTOMATION_RUN:
      return <AutomationRunLogEntry context={context} />;
    default: 
      return null;
  }
}

ActivityLogEntry.displayName = 'ActivityLogEntry';
ActivityLogEntry.propTypes = propTypes;
export default ActivityLogEntry;