import './IntegrationEllipsisDropdown.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EllipsisDropdown from '../../../common/EllipsisDropdown';

import EditIntegrationAPIKeyAndUserModal from '../modals/EditIntegrationAPIKeyAndUserModal';
import ConfirmIntegrationRemovalModal from '../modals/ConfirmIntegrationRemovalModal';

const propTypes = {};

function IntegrationEllipsisDropdown({ disabled, isActive, type }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const openModal = () => setIsEditModalOpen(true);
  const openConfirmationModal = () => setIsConfirmModalOpen(true);
  let options;
  if (isActive) {
    options = [
      { displayText: 'Edit integration', onClick: openModal },
      { displayText: 'Remove integration', onClick: openConfirmationModal },
    ];
  }
  else {
    options = [
      { displayText: 'Provide API key', onClick: openModal },
    ];
  }
  const closeEditModal = () => setIsEditModalOpen(false);
  const closeConfirmModal = () => setIsConfirmModalOpen(false);
  return (
    <EllipsisDropdown disabled={disabled} options={options}>
      { isEditModalOpen && <EditIntegrationAPIKeyAndUserModal close={closeEditModal} type={type} /> }
      { isConfirmModalOpen && <ConfirmIntegrationRemovalModal close={closeConfirmModal} type={type} /> }
    </EllipsisDropdown>
  );
}

IntegrationEllipsisDropdown.displayName = 'IntegrationEllipsisDropdown';
IntegrationEllipsisDropdown.propTypes = propTypes;
export default IntegrationEllipsisDropdown;