import React from 'react';
import { Outlet } from 'react-router-dom';

import NavbarManager from '../components/navbar/NavbarManager';
import ScrollToTopHook from '../../hooks/ScrollToTopHook';

function WorkrexPage() {
  return (
    <>
      <NavbarManager />
      <ScrollToTopHook />
      <Outlet />
    </>
  );
}

WorkrexPage.displayName = 'WorkrexPage';
export default WorkrexPage;
