import './NavigationTab.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function NavigationTab({ unselectedIcon, selectedIcon = null, displayText, onClick, selected, additionalClassNames = [], count = null }) {
  const icon = selected && selectedIcon ? selectedIcon : unselectedIcon;
  return (
    <nav className={`navigation-tab ${additionalClassNames.join(' ')} ${selected ? 'selected' : ''}`}>
      <button className={'navigation-tab-button'} onClick={onClick}>
        <section className={'navigation-display'}>
          <img className={'icon'} src={icon} alt={displayText} />
          <span className={'text'}>{displayText}</span>
        </section>
        {count !== null && <span className={'count'}>{count}</span>}
      </button>
    </nav>
  );
}

NavigationTab.displayName = 'NavigationTab';
NavigationTab.propTypes = propTypes;
export default NavigationTab;