import './RequestIntegrationModal.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IntegrationModal from './IntegrationModal';

import { postIntegrationRequest } from '../../../../../../apis/IntegrationApi';

const propTypes = {};

function RequestIntegrationModal({ close }) {
  const [integration, setIntegration] = useState('');
  const [loading, setLoading] = useState(false);
  const onChange = ({ target }) => setIntegration(target.value);
  const confirm = () => {
    setLoading(true);
    postIntegrationRequest(integration)
      .then(() => {
        setLoading(false);
        close();
      });
  }
  return (
    <IntegrationModal additionalClassNames={['request-integration-modal']} close={close}>
      <h3 className={'modal-title'}>{'Please tell us what integration you\'d like'}</h3>
      <input className={'integration-name'} placeholder={'Integration request'} value={integration} onChange={onChange} />
      <button className={'confirm-button'} onClick={confirm} disabled={loading}>{'Confirm'}</button>
    </IntegrationModal>
  );
}

RequestIntegrationModal.displayName = 'RequestIntegrationModal';
RequestIntegrationModal.propTypes = propTypes;
export default RequestIntegrationModal;