import './MobileManagerNavbar.scss';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import useAuth from '../../../../../context/AuthContext';

import Hamburger from '../Hamburger';
import MobileManagerNavOptions from './MobileManagerNavOptions';
import MobileManagerAuthOptions from './MobileManagerAuthOptions';

import WORKREX_LOGO from '../../assets/workrex-logo.svg';

import { ASSET_CDN_URL } from '../../../../../Constants';

const logo = `${ASSET_CDN_URL}/logo/Rex_Yellow_White_Logo.svg`;

const propTypes = {};

function MobileManagerNavbar() {
  const { authed } = useAuth();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const navigateHome = () => navigate('/');
  return (
    <nav className={'mobile-navbar'}>
      <section className={'logo-container'}>
        <button className={'logo-button'} onClick={navigateHome} type={'button'}>
          <img alt={'workrex logo'} className={'workrex-logo'} src={WORKREX_LOGO} />
        </button>
      </section>
      <button className={'mobile-navbar-hamburger'} onClick={() => setOpen(!open)} type={'button'}>
        <Hamburger open={open} />
      </button>
      { authed ? <MobileManagerAuthOptions open={open} setOpen={setOpen} /> : <MobileManagerNavOptions open={open} setOpen={setOpen} /> }
    </nav>
  )
}

MobileManagerNavbar.displayName = 'MobileManagerNavbar';
MobileManagerNavbar.propTypes = propTypes;
export default MobileManagerNavbar;