import './AutomationRequestInput.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SUBMIT_ICON from '../../assets/submit-icon.svg';
import { postAutomationInputRequest } from '../../../../../../../apis/Api';
import useAuth from '../../../../../../../context/AuthContext';

import AutomationRequestModal from '../../modals/AutomationRequestModal';

const propTypes = {};

function AutomationRequestInput() {
  const { setUser } = useAuth();
  const [requestInput, setRequestInput] = useState('');
  const onChange = ({ target }) => setRequestInput(target.value);
  const [modalOpen, setModalOpen] = useState(false);
  const submitRequest = () => {
    postAutomationInputRequest(requestInput)
      .then(res => {
        if (res.user) {
          setUser(res.user)
        }
      });
    setModalOpen(true);
    setRequestInput('');
  };
  const closeModal = () => setModalOpen(false);
  return (
    <section className={'automation-request-input-container'}>
      { modalOpen && <AutomationRequestModal close={closeModal} /> }
      <textarea
        className={'request-input'}
        type={'text'}
        placeholder={'When X happens, do Y, then do Z.'}
        onChange={onChange}
        value={requestInput}
      />
      <section className={'submit-request-container'}>
        <button className={'submit-request-button'} onClick={submitRequest}>
          <img className={'submit-icon'} src={SUBMIT_ICON} alt={'Submit request'} />
        </button>
        <span className={'button-label'}>{'Request'}</span>
      </section>
    </section>
  )
}

AutomationRequestInput.displayName = 'AutomationRequestInput';
AutomationRequestInput.propTypes = propTypes;
export default AutomationRequestInput;