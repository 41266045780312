import './FacebookPreview.scss';

import React from 'react';
import PreviewWrapper from './PreviewWrapper';

import FACEBOOK_PREVIEW_IMAGE from './assets/facebook-preview-image.png';
import SocialMediaPost from './common/SocialMediaPost';

function FacebookPreview({ preview, setPreview, proceed }) {
  return (
    <PreviewWrapper title={'Facebook'} previewImage={FACEBOOK_PREVIEW_IMAGE}>
      <SocialMediaPost preview={preview} setPreview={setPreview} proceed={proceed} />
    </PreviewWrapper>
  );
};

FacebookPreview.displayName = 'FacebookPreview';
export default FacebookPreview;