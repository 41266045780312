import './GroupsModalWrapper.scss';

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../common/Modal';
import ButtonWithBorder from '../../../../common/ButtonWithBorder';

const propTypes = {};

function GroupsModalWrapper({ additionalClassNames = [], children, closeModal, modalTitle, confirmModal, confirmButtonText }) {

  return (
    <Modal closeModal={closeModal} additionalClassNames={[ ...additionalClassNames, 'groups']}>
      <section className={`new-broadcast-modal-container ${additionalClassNames.join(' ')}`}>
        <section className={'broadcast-modal-body'}>
          <h1 className={`modal-title ${additionalClassNames.join(' ')}`}>{modalTitle}</h1>
          {children}
        </section>
        <section className={`modal-button-container ${additionalClassNames.join(' ')}`}>
          <ButtonWithBorder additionalClassNames={['purple-bg xl-padding']} onClick={confirmModal} buttonText={confirmButtonText} />
        </section>
      </section>
    </Modal>
  )
}

GroupsModalWrapper.displayName = 'GroupsModalWrapper';
GroupsModalWrapper.propTypes = propTypes;
export default GroupsModalWrapper;