import './DoubleToggleButton.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function DoubleToggleButton({ leftButtonText, rightButtonText, isLeftSelected, selectLeft, selectRight }) {
  return (
    <section className={'double-toggle-button-container'}>
      <button className={`double-toggle-button left ${isLeftSelected ? 'selected' : ''}`} onClick={selectLeft}>
        <span className={'button-text'}>
          {leftButtonText}
        </span>
      </button>
      <button className={`double-toggle-button right ${isLeftSelected ? '' : 'selected'}`} onClick={selectRight}>
        <span className={'button-text'}>
          {rightButtonText}
        </span>
        </button>
    </section>
  )
}

DoubleToggleButton.displayName = 'DoubleToggleButton';
DoubleToggleButton.propTypes = propTypes;
export default DoubleToggleButton;