import './AutomationActivityLog.scss';

import React from 'react';
import PropTypes from 'prop-types';
import ActivityLogEntry from './log/ActivityLogEntry';

const propTypes = {};

function AutomationActivityLog({ automationHistory }) {
  const activityLog = automationHistory.map(({ eventType, context }) => {
    return <ActivityLogEntry eventType={eventType} context={context} key={context.date} />;
  });
  return (
    <section className={'automation-activity-log'}>
      <h4 className={'activity-log-title'}>{'Activity log'}</h4>
      <ul className={'automation-history'}>
        {activityLog}
      </ul>
    </section>
  );
}

AutomationActivityLog.displayName = 'AutomationActivityLog';
AutomationActivityLog.propTypes = propTypes;
export default AutomationActivityLog;