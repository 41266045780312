import './CreateChannelsDisplay.scss';

import React, { useState } from 'react';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';

import ChannelTabList from './channels/ChannelTabList';
import ChannelPreview from './channels/ChannelPreview';
import EditableTextInput from '../common/EditableTextInput';
import useAuth from '../../../../../../context/AuthContext';

import { postDraftCampaign, postPublishCampaign } from '../../../../../../apis/CampaignApi';

function CreateChannelsDisplay({ shouldUseAI, viewOnly = false }) {
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const [draftLoading, setDraftLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const { campaign, setCampaign } = useOutletContext();
  const { name, channels, channelOutputs } = campaign;
  const [variantIndex, setVariantIndex] = useState(0);
  const [selectedChannel, setSelectedChannel] = useState(channels && channels.length > 0 ? channels[0] : null);
  const selectedChannelOutput = channelOutputs[selectedChannel];
  let backUrl;
  if (viewOnly) {
    backUrl = '/campaigns/view';
  }
  else {
    backUrl = shouldUseAI ? '/campaigns/create/ai' : '/campaigns/create/new';
  }
  const back = () => navigate(backUrl);
  if (channels.length === 0 || !selectedChannelOutput) {
    return <Navigate to={backUrl} replace />;
  }
  const selectChannel = (channel) => {
    setSelectedChannel(channel);
    setVariantIndex(0);
  };
  const setChannelOutput = (channelOutput) => {
    const newChannelOutputs = { ...channelOutputs };
    newChannelOutputs[selectedChannel] = channelOutput;
    setCampaign({ ...campaign, channelOutputs: newChannelOutputs });
  }
  const setName = (text) => setCampaign({ ...campaign, name: text });

  const draftCampaign = () => {
    setDraftLoading(true);
    postDraftCampaign(campaign)
      .then((res) => {
        setUser(res.user);
        setDraftLoading(false);
        navigate('/campaigns/draft');
      });
  }
  const publishCampaign = () => {
    setPublishLoading(true);
    postPublishCampaign(campaign)
      .then((res) => {  
        setUser(res.user);
        setPublishLoading(false);
        navigate('/campaigns/published');
      });
  }
  const sendCampaign = async () => {
    if (viewOnly) {
      navigate('/messaging/broadcasts/new', { state: { campaignId: campaign._id } });
      return;
    }
    return postPublishCampaign(campaign)
      .then((res) => {
        setUser(res.user);
        navigate('/messaging/broadcasts/new', { state: { campaignId: res.campaignId } });
      })
  };
  const disabled = draftLoading;
  const draftText = draftLoading ? 'Saving draft...' : 'Save draft';
  const publishText = publishLoading ? 'Publishing...' : 'Publish';
  return (
    <section className={'create-channels-display'}>
      <section className={'creation-form'}>
        <header className={'header'}>
          <EditableTextInput text={name} setText={setName} disabled={viewOnly} />
          <section className={'header-buttons'}>
            <button className={'back-button'} onClick={back} disabled={disabled}>{'Back'}</button>
            { !viewOnly && <button className={`draft-button ${draftLoading ? 'loading' : ''}`} onClick={draftCampaign} disabled={disabled}>
              {draftText}
            </button>
            }
            { !viewOnly && <button className={`publish-button ${publishLoading ? 'loading' : ''}`} disabled={disabled} onClick={publishCampaign}>
              {publishText}
            </button>
            }
          </section>
        </header>
      </section>
      <section className={'channels-form'}>
        <ChannelTabList channels={channels} selectedTab={selectedChannel} selectChannel={selectChannel} />
        <ChannelPreview
          channel={selectedChannel}
          channelOutput={selectedChannelOutput}
          variantIndex={variantIndex}
          setVariantIndex={setVariantIndex}
          setChannelOutput={setChannelOutput}
          viewOnly={viewOnly}
          proceed={sendCampaign}
        />
      </section>
    </section>
  );
};

CreateChannelsDisplay.displayName = 'CreateChannelsDisplay';
export default CreateChannelsDisplay;