import './VoicemailPreview.scss';

import React from 'react';
import PreviewWrapper from './PreviewWrapper';

import Voicemail from './common/Voicemail';

function VoicemailPreview({ preview, setPreview, proceed }) {
  return (
    <PreviewWrapper title={'Voicemail'} previewImage={null}>
      <Voicemail preview={preview} setPreview={setPreview} proceed={proceed} />
    </PreviewWrapper>
  );
};

VoicemailPreview.displayName = 'VoicemailPreview';
export default VoicemailPreview;