import './ContactListView.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useAuth from '../../../../../../../context/AuthContext';
import MessagingConsumer from '../../../../../../../context/MessagingContext';
import { MESSAGING_VIEWS } from '../../../MessagingConstants';
import { VALUE_TYPES } from './broadcasts/filterDropdown/FilterConstants';
import { filterContacts, searchFilterContacts } from '../../MessagingHelpers';

import ContactCard from './ContactCard';
import ContactDetail from './ContactDetail';
import DotBricksLoading from '../../../../../../common/DotBricksLoading';
import ButtonWithBorder from '../../../../../../common/ButtonWithBorder';
import SearchBar from './search/SearchBar';
import SearchButton from './search/SearchButton';
import { sortContacts } from './broadcasts/sortDropdown/SortHelpers';
import SortDropdown from './broadcasts/sortDropdown/SortDropdown';
import BroadcastContactsFilter from './broadcasts/filterDropdown/BroadcastContactsFilter';

const { ARRAY, STRING, DATE } = VALUE_TYPES;
const { EMPLOYEES } = MESSAGING_VIEWS;
const SEARCH_ADDITIONAL_CLASSNAMES = ['new-broadcast', 'max-width'];

const propTypes = {};

function ContactListView({ contacts, selectedRecord, setRecord, view, viewFilters }) {
  const { user } = useAuth(); 
  const groupMap = user.groups.reduce((groupMapping, group) => ({ ...groupMapping, [group._id]: group.name.toLowerCase() }), {});
  const { tasks } = MessagingConsumer();
  const [searchText, setSearchText] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [filters, setFilters] = useState([]);
  const increasePageCount = () => setPageCount(pageCount + 1);

  console.log(contacts);

  const applyFilters = () => {
    const populateUnreadActionsOnContacts = contacts.map(contact => {
      const hasUnreadMessage = contact.chatHistory.find(chat => !chat.read);
      if (view === EMPLOYEES.key) {
        const hasActionedTask = !!tasks.find(task => !task.completed && task.assignedTo && task.assignedTo._id === contact._id);
        return { ...contact, hasUnreadAction: hasActionedTask || hasUnreadMessage };
      } else {
        const hasActionedTask = !!tasks.find(task => !task.completed && task.taggedTo && task.taggedTo._id === contact._id);
        return { ...contact, hasUnreadAction: hasActionedTask || hasUnreadMessage };
      }
    })
    
    const navFilteredContacts = populateUnreadActionsOnContacts.filter(contact => {
      if (viewFilters === EMPLOYEES.filters.ACTIONS_REQUIRED.key) {
        return contact.hasUnreadAction;
      }
      return true;
    })

    const dropdownFilteredContacts = filterContacts(navFilteredContacts, filters, groupMap);
    const searchFilteredContacts = searchFilterContacts(dropdownFilteredContacts, searchText, groupMap);
    return sortContacts(sortBy, searchFilteredContacts);
  }

  const closeSearchBar = () => {
    setOpenSearch(false);
    filterAndSetContacts();
  }

  const filterAndSetContacts = () => {
    setSearchText('');
    const dropdownFilteredContacts = applyFilters();
    setFilteredContacts(dropdownFilteredContacts);
  }

  useEffect(() => {
    if (contacts !== null && tasks !== null) {
      const filteredContacts = applyFilters();
      setFilteredContacts(filteredContacts);
    }
  }, [contacts, viewFilters, sortBy, searchText])

  if (contacts === null || tasks === null) {
    return <DotBricksLoading isFixed={false} />
  }

  const notShowingAll = 100 * pageCount < filteredContacts.length;
  const contactCount = `Showing ${filteredContacts.slice(0, 100 * pageCount).length}/${filteredContacts.length}`;

  const search = (e) => {
    setSearchText(e.target.value);
  }

  const searchComponent = openSearch
    ? <SearchBar additionalClassNames={SEARCH_ADDITIONAL_CLASSNAMES} searchText={searchText} search={search} placeholder={'Search'} setOpenSearch={closeSearchBar} />
    : <SearchButton setOpenSearch={setOpenSearch} searchText={searchText} />;

  return (
    <section className={'customer-list-view'}>
      {
        selectedRecord
        ? <ContactDetail contact={selectedRecord} setRecord={setRecord(null)} />
        :
        <>
          <section className={'list-controls-container'}>
            <section className={'filter-sort-container'}>
              <BroadcastContactsFilter filters={filters} setFilters={setFilters} applyFilters={filterAndSetContacts} />
              <SortDropdown sortBy={sortBy} setSortBy={setSortBy} />
              {searchComponent}
            </section>
            <section className={'customer-count'}>
              <span className={'customer-label'}>{contactCount}</span>
            </section>
          </section>
          <section className={'customer-rows'}>
            {filteredContacts.slice(0, 100 * pageCount).map(contact =>
              <ContactCard
                contact={contact}
                setRecord={setRecord(contact.contactType)}
              />
            )}
            { notShowingAll && 
              <section className={'show-more-container'}>
                <ButtonWithBorder additionalClassNames={['white-bg']} onClick={increasePageCount} buttonText={'Show more'} />
              </section>
            }
          </section>
        </>
      }
    </section>
  )
}

ContactListView.displayName = 'ContactListView';
ContactListView.propTypes = propTypes;
export default ContactListView;