import './AdditionalBroadcastButtons.scss';

import React from 'react';

import ATTACHMENT_ICON from './assets/attachment-icon.svg';
import CAMPAIGN_ICON from './assets/select-campaign-icon.svg';
import LIBRARY_ICON from './assets/add-from-library-icon.svg';

function AdditionalBroadcastButtons({ onAttach, onSelectCampaign, onAddFromLibrary, hideAttached = false, hideLibrary = false }) {
  return (
    <section className={'additional-broadcast-buttons'}>
      { !hideAttached &&
        <button className={'additional-button'} onClick={onAttach}>
          <img className={'icon'} src={ATTACHMENT_ICON} alt={''} />
          {'Add an attachment'}
        </button>
      }
      <button className={'additional-button'} onClick={onSelectCampaign}>
        <img className={'icon'} src={CAMPAIGN_ICON} alt={''} />
        {'Select existing campaign'}
      </button>
      { !hideLibrary &&
        <button className={'additional-button'} onClick={onAddFromLibrary}>
          <img className={'icon'} src={LIBRARY_ICON} alt={''} />
          {'Add from library'}
        </button>
      }
    </section>
  )
}

export default AdditionalBroadcastButtons;