import './EditAutomationActions.scss';

import React from 'react';
import PropTypes from 'prop-types';

import EditAutomationAction from './EditAutomationAction';

const propTypes = {};

function EditAutomationActions({ actions = [] }) {
  const automationActions = actions.map(({ title, target, trigger, output }) => {
    return <EditAutomationAction title={title} target={target} trigger={trigger} output={output} key={title} />;
  });
  return (
    <ul className={'edit-automation-actions'}>
      {automationActions}
    </ul>
  );
}

EditAutomationActions.displayName = 'EditAutomationActions';
EditAutomationActions.propTypes = propTypes;
export default EditAutomationActions;