import './CreateTemplateErrorModal.scss';

import React from 'react';
import PropTypes from 'prop-types';

import WorkRexModal from '../../../common/WorkRexModal';

const propTypes = {};

function CreateTemplateErrorModal({ close, isDraft }) {
  const actionText = isDraft ? 'draft' : 'create';
  return (
    <WorkRexModal close={close} additionalClassNames={['create-template-error']}>
      <p className={'description'}>
        {`Please complete required section inputs to ${actionText} this template`}
      </p>
      <button className={'confirm-button'} onClick={close}>{'Back'}</button>
    </WorkRexModal>
  );
}

CreateTemplateErrorModal.displayName = 'CreateTemplateErrorModal';
CreateTemplateErrorModal.propTypes = propTypes;
export default CreateTemplateErrorModal;