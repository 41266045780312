import './RecallSelection.scss';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import AssistantSelection from '../common/AssistantSelection';
import AssistantSelectionSection from '../common/AssistantSelectionSection';
import AssistantDropdown from '../common/AssistantDropdown';
import PlusButton from '../common/PlusButton';
import AssistantTextInput from '../common/AssistantTextInput';
import TeamPicker from '../common/TeamPicker';
import ToggleButton from '../common/ToggleButton';
import useAuth from '../../../../../../../context/AuthContext';

import { DUMMY_CAMPAIGNS, APPOINTMENT_DATE_FIELDS, DUMMY_TEAM_MEMBERS, WEEKLY_FREQUENCY_OPTIONS } from '../../EngagementConstants';
import { postActivateAssistant } from '../../../../../../../apis/AssistantApi';

const DIGIT_REGEX = new RegExp('^[0-9]+$');

const propTypes = {};

function RecallSelection({ context, active, setAssistantKey, assistantKey }) {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const teamMembers = user && user.recipients ? user.recipients : DUMMY_TEAM_MEMBERS;
  const campaignOptions = user && user.savedTemplates ? user.savedTemplates.map(({ _id, title }) => ({ key: _id, displayText: title })) : [];
  const navigate = useNavigate();
  const [assistantContext, setAssistantContext] = useState(context);
  const cancel = () => setAssistantKey(null);
  const { autopilot, campaign, frequency, contactFrequency, duration, appointmentDateFieldName, teamMemberIds } = assistantContext;
  const [selectedTeamMemberIds, setSelectedTeamMemberIds] = useState(teamMemberIds);
  const setAutopilot = (autopilot) => setAssistantContext({ ...assistantContext, autopilot });
  const setFrequency = (frequency) => setAssistantContext({ ...assistantContext, frequency });
  const setCampaign = (campaign) => setAssistantContext({ ...assistantContext, campaign });
  const setAppointmentDateFieldName = (appointmentDateFieldName) => setAssistantContext({ ...assistantContext, appointmentDateFieldName });
  const setDuration = (duration) => {
    // TODO: Provide proper validation
    setAssistantContext({ ...assistantContext, duration });
  };
  const setContactFrequency = (contactFrequency) => {
        // TODO: Provide proper validation
    setAssistantContext({ ...assistantContext, contactFrequency });
  };
  const autopilotDescription = autopilot 
    ? 'Rex will draft and send a campaign out to your recipients automatically.'
    : 'Rex will create a draft campaign for you, and you import recipients manually.';
  const frequencyDescription = 'How often would you like this campaign launched?';
  const campaignDescription = 'Select and review a campaign template for Rex to use.';
  const durationDescription = 'How many months since last appointment date should recall happen? (Recommended is 12).';
  const maxContactDescription = 'Enter the maximum number of recalls per recipient each year (recommended is 1).';
  const alertsDescription = 'Assign team members who should be alerted for booking requests.';

  // TODO: Implement is integrated check
  const isIntegrated = false;
  const integrationDescription = isIntegrated
    ? 'Select the field that represents ‘appointment date’.'
    : 'Find your CRM from available integrations and click on ‘request’.';
  const integrationText = isIntegrated ? 'Integrated' : 'Not integrated';
  const integrationTextDisplay = <span className={`integration-text ${isIntegrated ? 'integrated' : ''}`}>{integrationText}</span>;
  const integrationButton = isIntegrated 
    ? <AssistantDropdown
        defaultButtonText={'Select field'}
        options={APPOINTMENT_DATE_FIELDS}
        select={setAppointmentDateFieldName}
        selected={appointmentDateFieldName}
      />
    : <PlusButton onClick={() => navigate('/integrations')} buttonText={'Integrate CRM'} />;

  const activate = (callback) => {
    if (loading) {
      return;
    }
    setLoading(true);
    postActivateAssistant(assistantKey, { ...assistantContext, teamMemberIds: selectedTeamMemberIds })
      .then((res) => {
        setUser(res.user);
        setLoading(false);
        callback();
      });
  }
  const autopilotDisabled = true;
  return (
    <AssistantSelection title={'Recall Assistant'} additionalClassNames={['recall-assistant']} cancel={cancel} activateAssistant={activate} assistantKey={assistantKey}>
      <AssistantSelectionSection
        title={'Auto pilot'}
        description={autopilotDescription}
        toggleButton={<ToggleButton active={autopilot} setActive={setAutopilot} />}
        hidden={true}
      />
      { autopilot && !autopilotDisabled && (
        <AssistantSelectionSection title={'CRM Integration'} description={integrationDescription} toggleButton={integrationTextDisplay}>
          {integrationButton}
        </AssistantSelectionSection>
      )}
      <AssistantSelectionSection title={'Select frequency'} description={frequencyDescription}>
        <AssistantDropdown
          defaultButtonText={'Frequency'}
          options={WEEKLY_FREQUENCY_OPTIONS}
          selected={frequency}
          select={setFrequency}
        />
      </AssistantSelectionSection>
      <AssistantSelectionSection title={'Select campaign template'} description={campaignDescription}>
        <AssistantDropdown
          defaultButtonText={'Campaign'}
          options={campaignOptions}
          selected={campaign}
          select={setCampaign}
        />
      </AssistantSelectionSection>
      <AssistantSelectionSection title={'Recall duration'} description={durationDescription}>
        <AssistantTextInput placeholder={'Type a number'} setText={setDuration} text={duration} type={'number'} />
      </AssistantSelectionSection>
      <AssistantSelectionSection title={'Max contact frequency'} description={maxContactDescription}>
        <AssistantTextInput placeholder={'Type a number'} setText={setContactFrequency} text={contactFrequency} type={'number'} />
      </AssistantSelectionSection>
      <AssistantSelectionSection title={'Assign team member'} description={alertsDescription}>
        <TeamPicker
          selectedTeamMemberIds={selectedTeamMemberIds}
          setSelectedTeamMemberIds={setSelectedTeamMemberIds}
          teamMembers={teamMembers}
        /> 
      </AssistantSelectionSection>
    </AssistantSelection>
  );
}

RecallSelection.displayName = 'RecallSelection';
RecallSelection.propTypes = propTypes;
export default RecallSelection;