import './CampaignSourceDisplay.scss';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CampaignSource from './common/CampaignSource';

import REX_ICON from './assets/rex-icon.svg';

function CampaignSourceDisplay() {
  const navigate = useNavigate();
  const fromScratchTag = <span className={'source-tag'}>{'Standard'}</span>;
  const fromAITag = (
    <span className={'source-tag'}>
      <img className={'rex-icon'} src={REX_ICON} alt={'Rex icon'} />
      {'AI Assistant'}
    </span>
  );
  return (
    <section className={'campaign-source-display'}>
      <h3 className={'source-title'}>{'Create new campaign'}</h3>
      <section className={'sources'}>
        <CampaignSource
          tagComponent={fromScratchTag}
          additionalClassNames={['from-scratch']}
          title={'Create from scratch'}
          description={'Quickly and easily launch a campaign from one of our handy templates. You can customise and revise each step of the way.'}
          onClick={() => navigate('/campaigns/create/new')}
          buttonText={'Create from scratch'}
        />
        <CampaignSource
          tagComponent={fromAITag}
          additionalClassNames={['from-ai']}
          title={'Create with AI'}
          description={'Give us a few campaign guidelines and let WorkRex do the rest.'}
          onClick={() => navigate('/campaigns/create/ai')}
          buttonText={'Create with AI'}
        />
      </section>
    </section>
  );
};

CampaignSourceDisplay.displayName = 'CampaignSourceDisplay';
export default CampaignSourceDisplay;