import './AddRecipientsModal.scss';

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import WorkRexModal from '../../../common/WorkRexModal';
import useAuth from '../../../../../../context/AuthContext';

import { VALUE_TYPES } from '../../messaging/list/views/broadcasts/filterDropdown/FilterConstants';
import SEARCH_ICON from '../recipients/assets/search-icon.svg';
import BroadcastContactsFilter from '../../messaging/list/views/broadcasts/filterDropdown/BroadcastContactsFilter';
import RecipientsTable from '../recipients/RecipientsTable';

const { STRING, DATE } = VALUE_TYPES;

const propTypes = {};

function AddRecipientsModal({
  close,
  searchText,
  setSearchText,
  filters,
  setFilters,
  allRecipients,
  setSelectedRecipientsToView,
  selectRecipients,
}) {
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const onChange = ({ target }) => setSearchText(target.value);
  const { user } = useAuth();
  const groups = user && user.groups ? user.groups : [];
  const groupMap = groups.reduce((groupMapping, group) => ({ ...groupMapping, [group._id]: group.name.toLowerCase() }), {});
  const filterRecipients = () => {
    return allRecipients.filter(contact => {
      const populatedFilters = filters.filter(filter => filter.condition);
      let validContact = true;
      for (const filter of populatedFilters) {
        const { attribute, condition, value } = filter;
        const contactVal = contact[attribute.key];

        let filterResult = false;
        if (attribute.type === DATE) {
          if (condition.key === 'inMonth' && value && !!contactVal) {
            filterResult = condition.filterFunction(value.value, new Date(contactVal).getMonth());
          }
        }
        else if (attribute.type === STRING) {
          if (attribute.key === 'groupId') {
            filterResult = condition.filterFunction.length === 2
              ? condition.filterFunction(groupMap[contactVal], value)
              : condition.filterFunction(groupMap[contactVal]);
          }
          else {
            filterResult = condition.filterFunction.length === 2
              ? condition.filterFunction(contactVal, value)
              : condition.filterFunction(contactVal);
          }
        }
        if (!filterResult) {
          return false;
        }
      }

      return validContact;
    });
  }

  const applyFilters = () => setSelectedRecipients(filterRecipients(allRecipients).map(({ _id }) => _id));
  const confirm = () => {
    selectRecipients(selectedRecipients);
    setSelectedRecipientsToView(allRecipients.filter(({ _id }) => selectedRecipients.includes(_id)));
    close();
  }
  const selectRecipient = (recipientId) => {
    if (selectedRecipients.includes(recipientId)) {
      setSelectedRecipients(selectedRecipients.filter(id => id !== recipientId));
    }
    else {
      setSelectedRecipients([...selectedRecipients, recipientId]);
    }
  }
  const recipientList = allRecipients || [];
  const filteredRecipients = recipientList.filter(({ firstName, lastName }) => {
    const firstNameDisplay = `${firstName || ''}`.toLowerCase();
    const lastNameDisplay = `${lastName || ''}`.toLowerCase();
    const fullName = `${firstNameDisplay} ${lastNameDisplay}`;
    return fullName.includes(searchText.toLowerCase());
  });
  return (
    <WorkRexModal close={close} additionalClassNames={['add-recipients-modal']}>
      <section className={'modal-description'}>
        <h4 className={'title'}>{`Add recipients`}</h4>
        <section className={'recipients-search-input-container'}>
          <input
            type={'text'}
            className={'recipients-search-input'}
            placeholder={'Search or add existing recipients'}
            value={searchText}
            onChange={onChange}
          />
          <button className={'search-button'} onClick={() => {}}>
            <img className={'search-icon'} src={SEARCH_ICON} alt={'Search icon'} />
          </button>
        </section>
      </section>
      <BroadcastContactsFilter filters={filters} setFilters={setFilters} applyFilters={applyFilters} />
      <RecipientsTable recipients={filteredRecipients || []} searchText={searchText} selectRecipient={selectRecipient} selectedRecipients={selectedRecipients} />
      <section className={'buttons'}>
        <button className={'cancel-button'} onClick={close}>{'Cancel'}</button>
        <button className={'confirm-button'} onClick={confirm}>{'Confirm'}</button>
      </section>
    </WorkRexModal>
  );
}

AddRecipientsModal.displayName = 'AddRecipientsModal';
AddRecipientsModal.propTypes = propTypes;
export default AddRecipientsModal;