import { getData, postData } from './Api';

const TASK_ROUTES = {
  USER_TASKS_URL: '/api/task/user',
  ARCHIVE_TASK_URL: '/api/task/archive',
  CREATE_TASK_URL: '/api/task/create',
  EDIT_TASK_URL: '/api/task/edit',
  COMPLETE_TASK_URL: '/api/task/complete',
  REMIND_TASK_URL: '/api/task/remind',
};

const { USER_TASKS_URL, ARCHIVE_TASK_URL, CREATE_TASK_URL, EDIT_TASK_URL, COMPLETE_TASK_URL, REMIND_TASK_URL } = TASK_ROUTES;

export const getUserTasks = async () => getData(USER_TASKS_URL);

// TODO: Group under tasks routes
// TODO: Refactor names and backend routes once ready - also remove the unused userId
export const postManyTaskArchive = async (tasks, userId) => {
  return postData(ARCHIVE_TASK_URL, { tasks, userId });
}
export const postTaskCreation = async (task, userId) => {
  return postData(CREATE_TASK_URL, { task, userId });
}
export const postTaskEdit = async (modifiedTask, taskHistory, userId) => {
  return postData(EDIT_TASK_URL, { modifiedTask, taskHistory, userId });
}
export const postManyTaskCompletion = async (tasks, userId) => {
  return postData(COMPLETE_TASK_URL, { tasks, userId });
}
export const postManyTaskReminders = async (tasks, userId) => {
  return postData(REMIND_TASK_URL, { tasks, userId });
}