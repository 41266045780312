import './TemplateList.scss';

import React from 'react';
import PropTypes from 'prop-types';
import TemplateCard from './TemplateCard';

const propTypes = {};

function TemplateList({ templates, templatePageType, searchText, libraryTypeFilter }) {
  const templateList = templates
    .filter(({ title }) => title.toLowerCase().includes(searchText.toLowerCase()))
    .filter(({ type }) => libraryTypeFilter.length === 0 || libraryTypeFilter.includes(type))
    .map((template) => {
      const { title, type, label, description, _id, key, recommendedUrl } = template;
      return (
        <TemplateCard
          key={_id ? _id : key}
          title={title}
          type={type}
          label={label}
          description={description}
          template={template}
          recommendedUrl={recommendedUrl}
          templatePageType={templatePageType}
        />
      );
    })
  return (
    <ul className={'template-list-v2'}>
      {templateList}
    </ul>
  );
}

TemplateList.displayName = 'TemplateList';
TemplateList.propTypes = propTypes;
export default TemplateList;