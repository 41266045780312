import './TemplateTextInput.scss';

import React, { useState, useRef } from 'react';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';
import { convert } from 'html-to-text';

import { SPECIAL_KEYWORDS } from '../../CommunicationsConstants';

import VariableTextSuggestions from './VariableTextSuggestions';

const propTypes = {};

const getTemplateText = (displayText, type) => {
  return `<span class="container" contenteditable="false"><span class="${type}" contenteditable="false">${displayText}</span></span>`;
}

const getCaretTopPoint = () => {
  const sel = document.getSelection();
  if (sel.rangeCount === 0) {
    return;
  }
  const r = sel.getRangeAt(0)
  let rect;
  let r2;
  // supposed to be textNode in most cases
  // but div[contenteditable] when empty
  const node = r.startContainer
  const offset = r.startOffset
  if (offset > 0) {
    // new range, don't influence DOM state
    r2 = document.createRange()
    r2.setStart(node, (offset - 1))
    r2.setEnd(node, offset)
    // https://developer.mozilla.org/en-US/docs/Web/API/range.getBoundingClientRect
    // IE9, Safari?(but look good in Safari 8)
    rect = r2.getBoundingClientRect()
    return { left: rect.right, top: rect.top }
  } else if (offset < node.length) {
    r2 = document.createRange()
    // similar but select next on letter
    r2.setStart(node, offset)
    r2.setEnd(node, (offset + 1))
    rect = r2.getBoundingClientRect()
    return { left: rect.left, top: rect.top }
  } else { // textNode has length
    // https://developer.mozilla.org/en-US/docs/Web/API/Element.getBoundingClientRect
    rect = node.getBoundingClientRect()
    const styles = getComputedStyle(node)
    const lineHeight = parseInt(styles.lineHeight)
    const fontSize = parseInt(styles.fontSize)
    // roughly half the whitespace... but not exactly
    const delta = (lineHeight - fontSize) / 2
    return { left: rect.left, top: (rect.top + delta) }
  }
}

const SPAN_REGEX = new RegExp('</?span[^>]*>', 'g');
const DIV_REGEX = new RegExp('</?div[^>]*>', 'g');
const BR_REGEX = new RegExp('</?br[^>]*>', 'g');

const TEXT_ARTIFACTS = [
  SPAN_REGEX,
  DIV_REGEX,
  BR_REGEX,
];

const removeTextArtifacts = (text) => {
  let newText = text;
  for (const textArtifact of TEXT_ARTIFACTS) {
    newText = newText.replaceAll(textArtifact, '');
  }
  newText = newText.replaceAll('&nbsp;', ' ');
  newText = convert(newText);
  return newText;
}

function TemplateTextInput({
  editable,
  text,
  setText,
  placeholder,
  shouldUseVariables,
  additionalClassNames = [],
  showCharCount = false,
  maxCharCount = null,
  xOffset = 10,
  initialYOffset = -40,
  initialXOffset = 30,
  additionalButtons = null,
  campaignName = null,
  removeCampaign = () => {},
}) {
  const [suggestionCoordinates, setSuggestionCoordinates] = useState({ left: 0, top: 0 });
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const keywordInput = useRef(null);
  const textInputRef = useRef(null);
  const processText = (text) => {
    let processedText = text;
    if (shouldUseVariables) {
      for (const specialKeyword of Object.values(SPECIAL_KEYWORDS)) {
        const { keyword, displayText, type } = specialKeyword;
        processedText = processedText.replaceAll(`@${keyword}`, getTemplateText(displayText, type));
      }
      const matches = processedText.match('@[0-9]+');
      if (matches) {
        for (const match of [...matches]) {
          const number = match.replaceAll('@', '');
          processedText = processedText.replaceAll(`@${number}`, getTemplateText(number, 'numeric'));
        }
      }
    }
    return processedText;
  }
  const unprocessText = (text) => {
    let processedText = text;
    if (shouldUseVariables) {
      for (const specialKeyword of Object.values(SPECIAL_KEYWORDS)) {
        const { keyword, displayText, type } = specialKeyword;
        processedText = processedText.replaceAll(getTemplateText(displayText, type), `@${keyword}`);
      }
      const matches = processedText.match('@[0-9]+');
      if (matches) {
        for (const match of [...matches]) {
          const number = match.replaceAll('@', '');
          processedText = processedText.replaceAll(getTemplateText(number, 'numeric'), `@${number}`);
        }
      }
    }
    return sanitizeHtml(processedText);
  }
  const { left, top } = suggestionCoordinates;
  const onChange = ({ target }) => {
    const unprocessedText = unprocessText(target.value);
    setText(unprocessedText);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    if (shouldUseVariables && key === '@') {
      keywordInput.current = '';
      const selection = document.getSelection();
      const range = selection.getRangeAt(0);
      const clientRects = range.getBoundingClientRect();
      const outerRects = textInputRef.current.getBoundingClientRect();
      // const { left, top } = clientRects;
      let coordinates;
      try {
        coordinates = getCaretTopPoint();
      }
      catch {
        coordinates = clientRects;
      }
      const { left, top } = coordinates;
      setDisplaySuggestions(true);
      const isAtEdge = left - outerRects.left === 0;
      setSuggestionCoordinates({ left: isAtEdge ? initialXOffset : left - outerRects.left + xOffset, top: 0.8 * (top - outerRects.top) + initialYOffset });
    }
    else if (keywordInput.current !== null) {
      if (key.match('[a-zA-Z]{1}') && key.length === 1) {
        const newKeywordInput = `${keywordInput.current}${key}`;
        if (Object.values(SPECIAL_KEYWORDS).some(({ keyword }) => keyword === newKeywordInput)) {
          keywordInput.current = null;
        }
        else {
          keywordInput.current = newKeywordInput;
        }
      }
      else if (key !== 'Shift') {
        if (key === 'Backspace' || key === 'Delete') {
          if (keywordInput.current.length > 0) {
            keywordInput.current = keywordInput.current.slice(0, keywordInput.current.length - 1);
            return;
          }
        }
        if (key === 'ArrowDown' || key === 'ArrowUp') {
          e.preventDefault();
          return false;
        }
        if (key === 'Enter') {
          e.preventDefault();
          return;
        }
        setSuggestionCoordinates({ left: 0, top: 0 });
        keywordInput.current = null;   
      }
    }
  }
  const onClickText = () => {
    if (campaignName) {
      return;
    }
    keywordInput.current = null;
    // setSuggestionCoordinates({ left: 0, top: 0 });
    setDisplaySuggestions(false);
    textInputRef.current.focus();
  }
  const onSelectVariable = (keyword) => {
    const numCharsToDelete = keywordInput.current.length;
    for (let i = 0; i < numCharsToDelete; i++) {
      document.execCommand('delete', false, null);
    }
    document.execCommand('insertText', false, `${keyword}`);
    keywordInput.current = null;
    setSuggestionCoordinates({ left: 0, top: 0 });
    setDisplaySuggestions(false);
  }
  const charCount = removeTextArtifacts(unprocessText(text)).length;
  const displayText = processText(text);
  const disabled = !editable;
  const inputComponent = campaignName === null
    ? (
      <span className={'text'} onClick={onClickText}>
        <ContentEditable
          html={displayText}
          onChange={onChange}
          className={`template-text-input ${editable ? 'editing' : ''}`}
          disabled={disabled}
          onKeyDown={onKeyDown}
          innerRef={textInputRef}
          placeholder={placeholder}
        />
      </span>
    )
    : (
      <span className={'text'}>
        <section className={'campaign-name-container'}>
          <span className={'campaign-name'}>
            {campaignName}
            <button className={'remove-button'} onClick={removeCampaign}>{'×'}</button>
          </span>
        </section>
      </span>
    )
  return (
    <section className={`template-text-input-container ${additionalClassNames.join(' ')}`}>
      { keywordInput.current !== null && displaySuggestions &&
        <VariableTextSuggestions top={top} left={left} keywordInput={keywordInput.current} onSelect={onSelectVariable} /> 
      }
      {inputComponent}
      <section className={'word-count-and-editing-button'}>
        {additionalButtons}
        { showCharCount &&
            <span className={'word-count'}>{`${charCount}/${maxCharCount}`}</span>
        }
      </section>
    </section>
  )
}

TemplateTextInput.displayName = 'TemplateTextInput';
TemplateTextInput.propTypes = propTypes;
export default TemplateTextInput;