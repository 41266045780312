import './ActiveEngagements.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { ENGAGEMENT_TABS, OUTREACH_OPPORTUNITIES } from '../EngagementConstants';

import EngagementLibrary from '../library/EngagementLibrary';

const { ACTIVE_ENGAGEMENTS } = ENGAGEMENT_TABS;
const propTypes = {};

function ActiveEngagements({ engagements }) {
  const filteredEngagements = engagements
    .filter(engagement => ACTIVE_ENGAGEMENTS.filterState.includes(engagement.state))
    .map(engagement => {
      const engagementTemplate = OUTREACH_OPPORTUNITIES.find(opportunity => engagement.key === opportunity.key);
      return { ...engagementTemplate, state: engagement.state };
    });
  return (
    <EngagementLibrary engagements={filteredEngagements} displayTitle={'Active engagement opportunities'} tab={ACTIVE_ENGAGEMENTS.key} />
  );
}

ActiveEngagements.displayName = 'ActiveEngagements';
ActiveEngagements.propTypes = propTypes;
export default ActiveEngagements;