import './CommunicationsNavigationTabs.scss';

import React from 'react';
import PropTypes from 'prop-types';
import CommunicationsNavigationTab from './CommunicationsNavigationTab';
import useAuth from '../../../../../context/AuthContext';
import { COMMUNICATION_TABS } from '../CommunicationsConstants';

const { MESSAGING, TEMPLATES, AUTOMATIONS, CONTENT, CAMPAIGNS, ENGAGEMENTS } = COMMUNICATION_TABS;

const propTypes = {};

function CommunicationsNavigationTabs({ selectedTab }) {
  const { hasAdminAccess } = useAuth();
  const campaignsDisabled = true;
  return (
    <ul className={'communication-navigation-tabs'}>
      <nav className={'nav-tab'}>
        <CommunicationsNavigationTab
          href={'/messaging'}
          selected={MESSAGING === selectedTab}
          displayText={'Messaging'}
        />
      </nav>
      { (hasAdminAccess || true) &&
        <nav className={'nav-tab'}>
          <CommunicationsNavigationTab
            href={'/templates'}
            selected={TEMPLATES === selectedTab}
            displayText={'Templates'}
            style={{ left: '-2px' }}
          />
        </nav>
      }
      { (hasAdminAccess || true) &&
        <nav className={'nav-tab'}>
          <CommunicationsNavigationTab
            href={'/engagements'}
            selected={ENGAGEMENTS === selectedTab}
            displayText={'Engagements'}
            style={{ left: '-4px' }}
          />
        </nav>
      }
      {/** 
      { (hasAdminAccess || true) &&
        <nav className={'nav-tab'}>
          <CommunicationsNavigationTab
            href={'/automations'}
            selected={AUTOMATIONS === selectedTab}
            displayText={'Automations'}
            style={{ left: '-4px' }}
          />
        </nav>
      }
      */}
      { (hasAdminAccess || true) &&
        <nav className={'nav-tab'}>
          <CommunicationsNavigationTab
            href={'/content'}
            selected={CONTENT === selectedTab}
            displayText={'Content'}
            style={{ left: '-6px' }}
          />
        </nav>
      }
      { (hasAdminAccess || true) &&
        <nav className={'nav-tab'}>
          <CommunicationsNavigationTab
            href={'/campaigns'}
            selected={CAMPAIGNS === selectedTab}
            displayText={'Campaigns'}
            style={{ left: '-8px' }}
          />
        </nav>
      }
    </ul>
  );
}

CommunicationsNavigationTabs.displayName = 'CommunicationsNavigationTabs';
CommunicationsNavigationTabs.propTypes = propTypes;
export default CommunicationsNavigationTabs;