import './MyTasksPage.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useAuth from '../../../../context/AuthContext';
import { RECORD_TYPES } from '../communications/MessagingConstants';

import TaskColumn from './TaskColumn';
import EllipsisDropdown from '../common/EllipsisDropdown';
import DotBricksLoading from '../../../common/DotBricksLoading';
import { postManyTaskCompletion } from '../../../../apis/Api';
import { getUserTasks } from '../../../../apis/TaskApi';

const propTypes = {};

function MyTasksPage() {
  const { user } = useAuth();
  const [showingIncomplete, setShowingIncomplete] = useState(true);

  const [userTasks, setUserTasks] = useState(null);

  useEffect(() => {
    getUserTasks()
      .then(response => setUserTasks(response.tasks))
      .catch(err => {
        console.error(err);
        setUserTasks([]);
      });
  }, []);

  if (userTasks === null) {
    return <DotBricksLoading />
  }

  const incompleteTasks = userTasks.filter(({ completed }) => !completed);
  const completeTasks = userTasks.filter(({ completed }) => completed);

  const setStatus = (task, completed) => {
    const updatedTask = [{ ...task, completed }];
    postManyTaskCompletion(updatedTask).then(response => {
      setUserTasks(prevState => {
        const filteredTasks = prevState.filter(prevTasks => prevTasks._id !== task._id);
        return [ ...filteredTasks, ...response.tasks ];
      });
    })
  }
  const dropdownOptions = [
    {
      displayText: 'Complete tasks',
      onClick: () => setShowingIncomplete(false),
      selected: !showingIncomplete,
    },
    {
      displayText: 'Incomplete tasks',
      onClick: () => setShowingIncomplete(true),
      selected: showingIncomplete,
    },
  ]
  return (
    <article className={'my-tasks-page'}>
      <section className={'my-tasks'}>
        <h2 className={'title'}>
          <span className={'title-text'}>{`Hi, ${user.firstName}!`}</span>
          <EllipsisDropdown disabled={false} options={dropdownOptions} />
        </h2>
        <h3 className={'subtitle'}>{'Here is your tasklist...'}</h3>
        <section className={`task-columns ${showingIncomplete ? 'showing-incomplete' : ''}`}>
          <TaskColumn additionalClassNames={['incomplete']} title={'Not completed'} tasks={incompleteTasks} setStatus={setStatus} />
          <TaskColumn additionalClassNames={['completed']} title={'Completed'} tasks={completeTasks} setStatus={setStatus} />
        </section>
      </section>
    </article>
  );
}

MyTasksPage.displayName = 'MyTasksPage';
MyTasksPage.propTypes = propTypes;
export default MyTasksPage;